<template>
  <div class="text-center pb-4 rounded-top node_bg_custom" style="width: 600px;">
    <TitleNode
        :node-name="this.data.name"
        :node-uuid="this.data.uuid"
        :node-position="this.data.position"
        :items="this.items"
        color="#a423b3"
        @pressed-remove="() => { this.$emit('remove', this.data.uuid, true) }"
    />

    <EditableContent :is-read-only="true" :disable-save-button="true">
      <div class="m-2">
        <Editor v-model="this.data.text" :readonly="true" :pt="{toolbar: {hidden: true}}"/>

        <FilesViewer v-model="this.data.files" :show-remove-button="false"/>

        <p class="m-0">Тип вопроса:</p>
        <Dropdown
            :model-value="this.data.answer_type"
            :options="this.getTypeOptions()"
            option-label="name"
            option-value="code"
            :disabled="this.readOnly"
        />

        <ButtonsPanel
            v-if="this.data.answer_type === AnswerTypeEnum.Buttons"
            :buttons="this.data.buttons"
        />

        <QuestionRegexp v-if="this.isShowReferenceInput" :regexp_pattern="this.data.reference_value" :is-edit-mode="!this.readOnly"/>
        <QuestionCalendar v-if="this.isShowCalendar" :is-edit-mode="!this.readOnly" :calendar="this.data.calendar"/>

        <p class="mt-2 mb-1 p-0">Сохранить ответ в переменную:</p>
        <Dropdown
            v-model="this.data.save_answer_to_context"
            :options="this.saveAnswerToContextOptions"
            option-label="name"
            option-value="uuid"
            :disabled="this.readOnly"
            scroll-height="500px"
            class="w-50"
        />

        <div class="mt-5">
          <SourceHandle :id="EdgeType.questionAnswer" style="bottom: 40px; top: auto; background-color: #31f10f">
            <div style="white-space: nowrap; position: absolute; top: -10px; right: 20px; ">
              <p>Ответил</p>
            </div>
          </SourceHandle>

          <SourceHandle :id="EdgeType.questionInvalidAnswer" style="bottom: 10px; top: auto; background-color: #f10f17;">
            <div style="white-space: nowrap; position: absolute; top: -10px; right: 20px; ">
              <p>Ответил не верно</p>
            </div>
          </SourceHandle>
        </div>

  <!--   todo: временно скрыл   -->
  <!--      <SourceHandle :id="EdgeType.questionDidntAnswer" style="bottom: 20px; top: auto; background-color: #f10f17">не ответил</SourceHandle>-->
        <TargetHandle/>
      </div>
    </EditableContent>
  </div>
</template>

<script lang="ts">
import {Handle, Position} from "@vue-flow/core";
import {defineComponent, PropType} from "vue";
import {EdgeType, QuestionButton, QuestionNode, QuestionNodeCalendar, RowButtons} from "@/api/graph";
import Multiselect from "@vueform/multiselect";
import {AnswerTypeEnum, updateQuestionNode} from "@/api/questionNode";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputText from "primevue/inputtext";
import Panel from "primevue/panel";
import Checkbox from "primevue/checkbox";
import AutoComplete from "primevue/autocomplete";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea"
import TitleNode from "@/components/view/Bots/TitleNode.vue";
import Dropdown, {DropdownChangeEvent} from "primevue/dropdown";
import ButtonsPanel from "@/components/view/Bots/ButtonsPanel.vue";
import QuestionCalendar from "@/components/view/Bots/Question/QuestionCalendar.vue";
import QuestionSettings from "@/components/view/Bots/Question/QuestionSettings.vue";
import EditableContent from "@/components/view/Bots/EditableContent.vue";
import {MenuItem} from "primevue/menuitem";
import QuestionRegexp from "@/components/view/Bots/Question/QuestionRegexp.vue";
import SelectButton from "primevue/selectbutton";
import TargetHandle from "@/components/view/Bots/TargetHandle.vue";
import SourceHandle from "@/components/view/Bots/SourceHandle.vue";
import {ContextItemForBuilder} from "@/api/context";
import TextAreaInput from "@/components/common/TextAreaInput.vue";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import Editor from "primevue/editor";
import FilesViewer from "@/components/common/FilesViewer.vue";

export default defineComponent({
  name: 'Question',
  props: {
    data: {
      type: Object as PropType<QuestionNode>,
      required: true,
    },
    contextOptions: {
      required: true,
      type: Array as PropType<ContextItemForBuilder>,
    },
  },
  emits: [
    'remove',
  ],
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  computed: {
    AnswerTypeEnum() {
      return AnswerTypeEnum
    },
    EdgeType() {
      return EdgeType
    },
    Position() {
      return Position
    },
    isShowReferenceInput: function () {
      return this.data.answer_type === AnswerTypeEnum.Regexp
    },
    isShowCalendar: function () {
      return this.data.answer_type === AnswerTypeEnum.Calendar
    },
    items: function (): MenuItem[] {
      if (this.graphConfigStorage.isSharedTemplate) {
        return []
      }

      let result = [] as MenuItem[]

      if (this.readOnly) {
        result.push({
          label: 'Редактировать',
          icon: 'pi pi-file-edit',
          command: () => {
            this.readOnly = false
          },
        })
      }

      result.push({
        label: 'Удалить',
        icon: 'pi pi-trash',
        command: () => {
          this.$emit('remove', this.data.uuid, true)
        }
      })

      return result
    },

    saveAnswerToContextOptions: function () {
      if (this.data.answer_type === AnswerTypeEnum.Calendar) {
        // в будущем будут отображаться переменные только datetime типа
        return []
      }

      const clonedOptions = this.contextOptions.map(a => {return {...a}})
      clonedOptions.unshift({
        name: '',
        uuid: null,
      })

      return clonedOptions
    },
  },
  components: {
    FilesViewer,
    Editor,
    TextAreaInput,
    SourceHandle,
    TargetHandle,
    QuestionRegexp,
    EditableContent,
    QuestionSettings,
    QuestionCalendar,
    ButtonsPanel,
    TitleNode,
    Handle,
    Multiselect,
    InputGroup,
    InputGroupAddon,
    InputText,
    Panel,
    Checkbox,
    AutoComplete,
    InputNumber,
    Textarea,
    Dropdown,
    SelectButton,
  },
  data: function () {
    return {
      readOnly: true,
      questionNode: this.data,
    }
  },
  methods: {
    getTypeOptions: function () {
      return [
        {
          code: AnswerTypeEnum.withoutValidation,
          name: 'Ожидаем сообщения пользователя',
        },
        {
          code: AnswerTypeEnum.Buttons,
          name: 'Выбор ответа через кнопки',
        },
        {
          code: AnswerTypeEnum.Regexp,
          name: 'Ответ соответствует регулярному выражению',
        },
        {
          code: AnswerTypeEnum.IsNumber,
          name: 'Ответ пользователя является числом',
        },
        {
          code: AnswerTypeEnum.Calendar,
          name: 'Запись на прием (календарь)',
        },
      ]
    },
  },
})
</script>
