<template>
  <div class="p-2">
    <DataTable :value="this.subscribers" tableStyle="min-width: 50rem" @row-click="($event) => { console.log($event) }">
      <Column field="name" header="Имя">
        <template #body="slotProps">
          <div v-if="slotProps.data.link_to_telegram">
            <a :href="slotProps.data.link_to_telegram" target="_blank">{{ slotProps.data.name }}</a>
          </div>
          <div v-else>
            <p>{{slotProps.data.name}}</p>
          </div>
        </template>
      </Column>
<!--      <Column field="joined_at" header="Дата присоединения"/>-->
<!--      <Column field="current_step" header="Текущий шаг"/>-->
      <Column :exportable="false" style="min-width:8rem">
        <template #body="slotProps">
          <div class="d-flex justify-content-end">
            <Button
                label="Просмотр профиля"
                class="rounded"
                severity="secondary"
                outlined
                @click="showSubscriberCard(slotProps.data)"
                :loading="this.selectedSubscriberUuidLoading == slotProps.data.uuid"
            />
          </div>
        </template>
      </Column>
    </DataTable>

<!--   Модальное окно просмотра карточки подписчика (todo: менять URL, чтобы иметь возможность шарить сабскрайбера)  -->
    <Dialog v-model:visible="this.dialogSubscriber.isVisible" header="Подписчик" :style="{ width: '75vw' }" maximizable modal :contentStyle="{ height: '500px' }" :draggable="false">
      <p>{{this.dialogSubscriber.subscriber.name}}</p>

<!--      <p>Тут фотография</p>-->
<!--      <p>Первое сообщение (дата):</p>-->
<!--      <p>Последнее сообщение (дата):</p>-->
<!--      <p>Количество сообщений</p>-->
<!--      <p>Статистика кол-ва сообщений по месяцам (а нужна ли вообще)</p>-->
<!--      <p>Бан: (отображать если забанен) + причину бана (автоматически, или администратором, если админом, то кем конкретно)</p>-->

<!--      <p>Переменные (таблица)</p>-->
<!--      <p>События (таблица)</p>-->
<!--      <p>Отправка хуков (надо ли?)</p>-->

      <DataTable :value="this.dialogSubscriber.subscriber.contexts" scrollable class="custom-border" tableStyle="min-width: 50rem">
        <template #header>
          <p class="m-0">Персональные переменные</p>
        </template>

        <Column field="code" header="Код переменной"/>
        <Column field="value" header="Значение переменной"/>
      </DataTable>

      <template #footer>
        <Button
            label="Ok"
            class="rounded"
            icon="pi pi-check"
            severity="contrast"
            @click="this.closeDialogSubscriber"
        />
      </template>
    </Dialog>
  </div>
  <router-view />
</template>

<script lang="ts">
import {getForCardSubscriber, getForListSubscribers, SubscriberForCard, SubscriberForListItem} from "@/api/subscriber";
import {defineComponent} from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import {fetchScenarioList, Scenario} from "@/api/scenario";
import MultiSelect from 'primevue/multiselect';

export default defineComponent({
  name: 'SubscribersLists',
  components: {
    MultiSelect,
    DataTable,
    Column,
    Button,
    Dialog,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      dialogVisible: false,
      dialogSubscriber: {
        isVisible: false as boolean,
        subscriber: null as SubscriberForCard|null,
        dialogUuid: null as string|null,
      },
      subscribers: [] as SubscriberForListItem[],
      selectedSubscriberUuidLoading: null as null|string,
    }
  },
  mounted() {
    getForListSubscribers(this.scenario_uuid).then((res) => {
      this.subscribers = res.items;
    })
  },
  methods: {
    showSubscriberCard: function(subscriber: SubscriberForListItem) {
      this.selectedSubscriberUuidLoading = subscriber.uuid

      getForCardSubscriber(subscriber.uuid, this.scenario_uuid)
          .then((subscriberForCard: SubscriberForCard) => {
            this.dialogSubscriber.subscriber = subscriberForCard
            this.dialogSubscriber.isVisible = true
          })
          .finally(() => {
            this.selectedSubscriberUuidLoading = null
          })
    },
    closeDialogSubscriber: function () {
      this.dialogSubscriber.isVisible = false
      this.dialogSubscriber.subscriber = null
    },
  }
})
</script>
