<template>
  <div class="p-2">
    <TabView @tab-change="this.handleTabChange" :pt="{panelContainer: {hidden: true}}" :active-index="this.activeTabIndex" >
      <TabPanel v-for="item in this.getMenuItems()" :header="item.header">
      </TabPanel>
    </TabView>

    <router-view/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import PostsList from "@/components/view/ContentPlan/PostsList.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default defineComponent({
  name: "ContentPlanCalendar",
  components: {PostsList, TabView, TabPanel},
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      activeTabIndex: 0 as number,
    }
  },
  methods: {
    getMenuItems: function () {
      return [
        {
          header: 'Публикации',
          route_name: 'posts',
        },
        {
          header: 'Очереди публикаций',
          route_name: 'queue_list',
        },
        {
          header: 'Календарь публикаций',
          route_name: 'posts_calendar',
        },
        {
          header: 'Перенаправления',
          route_name: 'forwarder_list',
        },
      ]
    },

    handleTabChange: function (event: {index: number, originalEvent: PointerEvent}) {
      const menuItem = this.getMenuItems()[event.index]

      if (!menuItem) {
        throw new Error('Обратитесь в тех.поддержку')
      }

      this.$router.push({name: menuItem.route_name})
    },
  },
  created() {
    this.getMenuItems().forEach((menu, index) => {
      if (menu.route_name === this.$route.name) {
        this.activeTabIndex = index
        return
      }
    })
  }
})
</script>
