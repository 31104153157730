import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-266279b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quill_editor = _resolveComponent("quill-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_quill_editor, {
      value: this.modelValueData,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((this.modelValueData) = $event)),
      options: this.editorOption,
      disabled: this.disabled
    }, null, 8, ["value", "options", "disabled"])
  ]))
}