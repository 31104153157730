import {FilterWarning} from "./groupSettings";
import _default from "chart.js/dist/plugins/plugin.tooltip";
import type = _default.defaults.animations.numbers.type;


export enum AdminCommand
{
    kick = 'kick',
    ban = 'ban',
    warn = 'warn',
    mute = 'mute',
    mute_media = 'mute_media',
    report = 'report',
}

export abstract class AbstractAdminCommand
{
    abstract type: AdminCommand
    is_enabled: boolean = false
    is_visible: boolean = false
}

export class KickCommand extends AbstractAdminCommand
{
    type: AdminCommand.kick
}

export class BanCommand extends AbstractAdminCommand
{
    type: AdminCommand.ban
}

export class WarnCommand extends AbstractAdminCommand
{
    type: AdminCommand.warn
    filter: FilterWarning
}

export class ReportCommand extends AbstractAdminCommand
{
    type: AdminCommand.report
}


export class CommandsSettingsUpdate
{
    kick: KickCommand
    ban: BanCommand
    warn: WarnCommand
    report: ReportCommand

    constructor() {
        this.warn = new WarnCommand();
        this.kick = new KickCommand();
        this.ban = new BanCommand();
        this.report = new ReportCommand();
    }
}

export class CommandsSettingsGetForCard extends CommandsSettingsUpdate
{

}