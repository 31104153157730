import _default from "chart.js/dist/core/core.interaction";
import index = _default.modes.index;
import {uuid} from "vue-uuid";
import {Type} from "class-transformer";


export enum ButtonGroupType
{
    link = 'link',
    hiddenContent = 'hidden_content',
    counter = 'counter',
    scenario = 'scenario',
}

export abstract class AbstractButton
{
    uuid: string

    abstract type: ButtonGroupType
    name: string = ''


    constructor() {
        this.uuid = uuid.v4();
    }
}

export class ButtonLink extends AbstractButton
{
    type: ButtonGroupType = ButtonGroupType.link
    link: string = ''
}

export class ButtonScenario extends AbstractButton
{
    type: ButtonGroupType = ButtonGroupType.scenario
}

export class ButtonHiddenContent extends AbstractButton
{
    type: ButtonGroupType = ButtonGroupType.hiddenContent
    // todo: тут данные для данного типа кнопки
}

export class ButtonCounter extends AbstractButton
{
    type: ButtonGroupType = ButtonGroupType.counter
    // todo: тут данные для данного типа кнопки
}

export class ButtonRow
{
    @Type(() => {}, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: ButtonLink, name: ButtonGroupType.link },
                { value: ButtonScenario, name: ButtonGroupType.scenario },
            ]
        }
    })
    items: AbstractButton[] = []

    constructor(items: AbstractButton[]) {
        this.items = items;
    }
}

export class SelectButtonPosition
{
    // todo: избавиться?
    index: number = 0
    row: number = 0

    constructor(index: number, row: number) {
        this.index = index;
        this.row = row;
    }
}


export function resolveButtonIcon(buttonType: ButtonGroupType): string {
    switch (buttonType) {
        case ButtonGroupType.link:
            return 'pi pi-share-alt'

        case ButtonGroupType.counter:
            return 'pi pi-heart'

        case ButtonGroupType.hiddenContent:
            return 'pi pi-eye-slash'

        case ButtonGroupType.scenario:
            return 'pi pi-play'
    }

    return ''
}

export function resolveButtonName(buttonType: ButtonGroupType): string {
    switch (buttonType) {
        case ButtonGroupType.link:
            return 'Ссылка'

        case ButtonGroupType.counter:
            return 'Счетчик'

        case ButtonGroupType.hiddenContent:
            return 'Скрытое продолжение'

        case ButtonGroupType.scenario:
            return 'Сценарий'
    }

    return ''
}