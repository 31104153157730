import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostForm = _resolveComponent("PostForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PostForm, {
      scenario_uuid: this.scenario_uuid,
      "model-value": this.post,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.post) = $event))
    }, null, 8, ["scenario_uuid", "model-value"])
  ]))
}