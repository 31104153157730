import {AxiosRequestConfig, AxiosResponse} from "axios";
import client from "./client";
import {plainToInstance} from "class-transformer";
import {SubscriberForCard} from "./subscriber";
import {SourceItemResult} from "./forwarder";


export class LogCaptcha
{
    success: boolean = false
    fail: boolean = false
}

export class LogFilter
{
    abuse: boolean = false
    link: boolean = false
    telegram_link: boolean = false
    forward_message: boolean = false
    spam: boolean = false
    stop_words: boolean = false
    mixed_abc: boolean = false
}

export class LogAdminCommand
{
    kick: boolean = false
    ban: boolean = false
    unban: boolean = false
    mute: boolean = false
    unmute: boolean = false
}

export class UpdateServiceGroup
{
    dialog_uuid: string
    service_group: SourceItemResult|null
    active: boolean = false

    captcha: LogCaptcha
    admin_command: LogAdminCommand
    filter: LogFilter

    constructor(dialog_uuid: string) {
        this.dialog_uuid = dialog_uuid;

        this.captcha = new LogCaptcha()
        this.admin_command = new LogAdminCommand()
        this.filter = new LogFilter()
    }
}

export class GetServiceGroup extends UpdateServiceGroup
{
    name: string
}

export function updateServiceGroup(cmd: UpdateServiceGroup): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/dialog/group/update-service-group', cmd)
}

export function getServiceGroup(dialog_uuid: string): Promise<GetServiceGroup>
{
    return client.post('/api/v1/dialog/group/get-service-group', {
            dialog_uuid: dialog_uuid,
        })
        .then((response: AxiosResponse) => {
            return plainToInstance(SubscriberForCard, response.data);
        })
}