<template>
  <div>
    <div>
      <p class="required m-0">Название</p>
      <InputText v-model="this.tagModel.name"/>
      <small class="p-error d-block mb-1" v-if="this.errors['name']">{{ this.errors['name'] }}</small>
    </div>

    <div class="mt-2">
      <p class="m-0">Список подписчиков с тегом:</p>
      <AutoComplete
          v-model="this.searchSubscriberAutocomplete"
          placeholder="Добавить тег подписчику..."
          optionLabel="name"
          data-key="uuid"
          @item-select="($event) => { this.tagModel.subscribers.push($event.value); this.searchSubscriberAutocomplete = '' }"
          :suggestions="this.computedSearchSubscriberOptions"
          @complete="($event) => {
            this.searchSubscriberLoading = true
            searchSubscriberByName($event.query, this.scenario_uuid)
              .then((result: SearchSubscriberRes) => {
                this.searchSubscriberOptions = result.items
              })
              .finally(() => {
                this.searchSubscriberLoading = false
              })
          }"
          :loading="this.searchSubscriberLoading"
          :dropdown="true"
      />

      <div class="mt-2">
        <div v-for="(subscriber, index) in this.tagModel.subscribers" :key="subscriber.uuid" class="d-inline-block bg-secondary text-white p-2 rounded me-2" style="border: 1px solid #cdcdcd;">
          <span>{{subscriber.name}}</span>
          <button class="bg-transparent border-0" @click="this.tagModel.subscribers.splice(index, 1)">
            <span class="pi pi-trash text-white"/>
          </button>
        </div>
      </div>

      <div class="position-absolute bottom-0 end-0 m-4">
        <Button
            label="Сохранить"
            class="rounded"
            severity="contrast"
            :loading="this.isSaveTagLoading"
            @click="() => {
              this.isSaveTagLoading = true

              const promise = this.tagModel instanceof UpdateTagCommand ?
                updateTag(this.tagModel) :
                createTag(this.tagModel)

              promise
                .then(() => {
                  this.$emit('was-saved')
                })
                .catch((error: ErrorResponse) => {
                  this.errors = error.getValidationErrors()
                })
                .finally(() => { this.isSaveTagLoading = false })
            }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import {searchSubscriberByName, SearchSubscriberItem, SearchSubscriberRes} from "@/api/edge";
import {createTag, CreateTagCommand, getForListTags, TagForList, updateTag, UpdateTagCommand} from "@/api/tags";
import {ErrorResponse} from "@/api/common";

export default defineComponent({
  name: "TagForm",
  methods: {updateTag, createTag, searchSubscriberByName},
  computed: {
    UpdateTagCommand() {
      return UpdateTagCommand
    },
    ErrorResponse() {
      return ErrorResponse
    },
    CreateTagCommand() {
      return CreateTagCommand
    },
    SearchSubscriberRes() {
      return SearchSubscriberRes
    },
    
    computedSearchSubscriberOptions: function () {
      return this.searchSubscriberOptions.filter((filterItem: SearchSubscriberItem) => {
        const isAlreadySelected = this.tagModel.subscribers.find((value: SearchSubscriberItem) => {
          return value.uuid === filterItem.uuid
        })

        if (isAlreadySelected) {
          return false
        }
        return true
      })
    }
  },
  inject: [
      'scenario_uuid'
  ],
  emits: [
      'was-saved',
      'update-dialog-open-status',
      'tag:update',
  ],
  props: {
    tag: {
      required: true,
      type: Object as PropType<CreateTagCommand>
    }
  },
  data: function () {
    return {
      tagModel: this.tag,
      searchSubscriberLoading: false,
      searchSubscriberOptions: [] as SearchSubscriberItem[],
      searchSubscriberAutocomplete: '',
      isSaveTagLoading: false,
      errors: [],
    }
  },
  components: {
    InputText,
    Dialog,
    AutoComplete,
    Button
  },
  watch: {
    tagModel: function (val) {
      this.$emit('tag:update', val)
    },

    tag: function (val) {
      this.tagModel = val
    },
  }
})
</script>