<template>
  <div class="m-2">
    <h3>История обновлений проекта</h3>

    <div class="mt-3">
      <Panel toggleable :header="changelog.header" :collapsed="true" v-for="changelog in this.changelogs">
        <div>
          <ul class="ms-2">
            <li v-for="item in changelog.items">{{item}}</li>
          </ul>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import Panel from "primevue/panel";

export default defineComponent({
  name: 'ChangeLog',
  components: {
    Panel,
  },
  data: function () {
    return {
      changelogs: [
        {
          header: 'Обновление от 22.08.2024',
          items: [
              'Добавлена статистика по подпискам и отпискам в группах',
              'Добавлен режим работы бота "Обратная связь"'
          ],
        }
      ]
    }
  }
})
</script>
