<template>
  <div class="p-4" style="background: white;">
    <FullCalendar :options="this.calendarOptions" ref="calendar" />

    <EventRecordCard
      :uuid="this.selectRecordUuid"
      @close-record-item="this.selectRecordUuid = null"
    />

  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {
  getForCardCalendar, GetForCardRecord,
  GetForCardResult,
  getForListCalendar,
  GetForListItem,
  GetForListResult
} from "@/api/calendar";
import {CalendarApi} from "@fullcalendar/core";
import {EventImpl} from "@fullcalendar/core/internal";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import TimeInput from "@/components/common/TimeInput.vue";
import {start} from "@popperjs/core";
import DateInput from "@/components/common/DateInput.vue";
import EventRecordCard from "@/components/view/Calendar/EventRecordCard.vue";

let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

function createEventId() {
  return String(eventGuid++)
}

const INITIAL_EVENTS = [
  // {
  //   id: createEventId(),
  //   title: 'All-day event',
  //   start: todayStr,
  //   end: null,
  // },
  // {
  //   id: createEventId(),
  //   title: 'Timed event',
  //   start: todayStr + 'T12:00:00',
  //   end: null,
  // }
]

export default defineComponent({
  name: 'EventCalendar',
  components: {
    EventRecordCard,
    DateInput,
    TimeInput,
    Column, Button, DataTable, Dialog,
    FullCalendar,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  computed: {
  },
  data: function () {
    return {
      calendarOptions: {
        timeZone: 'UTC',
        locale: 'ru',
        weekNumberCalculation: 'ISO',

        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed

        // todo: пока запрещает перемещать записи, в следующих релизах реализовать такой функционал
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,

        // https://fullcalendar.io/docs/event-dragging-resizing почитать про обработку событий
        eventResize: () => {
          console.log('eventResize')
        },
        eventDrag: () => {
          console.log('eventDrag')
        },
        eventDragMutationMassagers: () => {
          console.log('eventDragMutationMassagers')
        },
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */

        buttonText: {
          today: 'Сегодня',
          prev: '<',
          next: '>',
          prevYear: 'Следующий год',
          nextYear: 'Предыдущий год',
          month: 'Месяц',
          week: 'Неделя',
          day: 'День',
        },
      },
      currentEvents: [],
      selectRecordUuid: null as string|null,
    }
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo: any) {
      return;

      // todo: реализовать поддержку создания записи на прием из UI

      let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay
        })
      }
    },
    handleEventClick(clickInfo: Object) {
      const event = clickInfo.event as EventImpl
      this.selectRecordUuid = event.id

      // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      //   clickInfo.event.remove()
      // }
    },
    handleEvents(events: any) {
      console.log(events)
      this.currentEvents = events
    },


    handleOnDrag: function () {
      console.log('handleOnDrag')
    }
  },

  mounted() {


    getForListCalendar(this.scenario_uuid).then((result: GetForListResult) => {
      if (result.items.length !== 0) {
        getForCardCalendar(result.items[0].uuid).then((resultForCard: GetForCardResult) => {
          if (resultForCard.items.length !== 0) {


            // let calendarApi = selectInfo.view.calendar
            // calendarApi.addEvent({
            //   id: createEventId(),
            //   title,
            //   start: selectInfo.startStr,
            //   end: selectInfo.endStr,
            //   allDay: selectInfo.allDay
            // })

            // @ts-ignore
            const calendarApi = this.$refs?.calendar?.calendar as CalendarApi
            if (calendarApi) {

              resultForCard.items.forEach((item: GetForCardRecord) => {

                calendarApi.addEvent({
                  id: item.uuid,
                  title: 'Запись',
                  start: item.start_at,
                  end: item.end_at,
                })
              })
            }
          }
        })
      }
    })
  }
})
</script>


<style lang='css' scoped>

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

</style>