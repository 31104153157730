import {AxiosResponse} from "axios";
import {plainToClass, plainToInstance} from "class-transformer";
import client from './client';
import {ContextType} from "@/api/context";
import {Scenario} from "@/api/scenario";


export class SubscriberForCard
{
    // @ts-ignore
    uuid: string
    // @ts-ignore
    name: string
    link_to_telegram: string|null = null
    // @ts-ignore
    contexts: Variable[]
    // @ts-ignore
    dialog: string
}

export class Variable
{
    // @ts-ignore
    uuid: string
    // @ts-ignore
    value: string|number
    // @ts-ignore
    code: string
    // @ts-ignore
    type_print: string
    // @ts-ignore
    type: ContextType
}

export async function getForCardSubscriber(subscriberUuid: string, scenarioUuid: string): Promise<SubscriberForCard>
{
    return await client
        .post('/api/v1/subscriber/get-for-card', {subscriber_uuid: subscriberUuid, scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(SubscriberForCard, response.data);
        })
}

export class SubscriberForListItem
{
    uuid: string
    name: string
    link_to_telegram: string

    constructor(uuid: string, name: string, link_to_telegram: string) {
        this.uuid = uuid;
        this.name = name;
        this.link_to_telegram = link_to_telegram;
    }
}

export class SubscriberGetForListRes
{
    items: SubscriberForListItem[] = []
}

export async function getForListSubscribers(scenario_uuid: string): Promise<SubscriberGetForListRes>
{
    return await client
        .post('/api/v1/subscriber/get-for-list', {scenario_uuid: scenario_uuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(SubscriberGetForListRes, response.data);
        })
}
