<template>
  <div class="bg-white rounded p-2">
    <PostForm
        :scenario_uuid="this.scenario_uuid"
        v-model:model-value="this.post"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref} from 'vue'
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import TabPanel from "primevue/tabpanel";
import Checkbox from "primevue/checkbox";
import TabView from "primevue/tabview";
import {getPostForCard, PostGetForCard, SendPreviewPostCmd, sendPreviewPost, updatePostContent} from "@/api/post";
import PostForm from "@/components/view/ContentPlan/PostForm.vue";
import {ErrorResponse, ValidationErrorItem} from "@/api/common";

export default defineComponent({
  name: 'PostCard',
  computed: {
    SendPreviewPost() {
      return SendPreviewPostCmd
    },
    ValidationErrorItem() {
      return ValidationErrorItem
    },
    ErrorResponse() {
      return ErrorResponse
    }
  },
  methods: {sendPreviewPost, updatePostContent},
  components: {
    PostForm,
    TabView,
    Checkbox,
    TabPanel,
    Dialog,
    Button,
    DialogTagPanel
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    post_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      post: new PostGetForCard,
      isLoading: false,
    }
  },
  mounted() {
    getPostForCard(this.post_uuid).then((value: PostGetForCard) => {
      this.post = value
    })
  }
})
</script>