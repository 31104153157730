<template>
  <div>
    <h5 class="fw-bold d-inline-block">Задержка</h5>

    <p class="text-break">
      Служит для отсрочки момент выполнения следующего блока. Есть 3 вида задержки:
    </p>
    <p class="fw-bold m-0">1. Определенная дата</p>
    <p class="text-break">
      Исполнение бота перейдет на следующий блок в выбранную дату, если указать время отправки, то в выбранное время (если не указать то в 00:00 указанного дня)
    </p>
    <Image
        src="/delay_1.png"
        alt="Блок задержки"
        height="300"
        preview
        class="m-1"
        :image-style="{
                objectFit: 'contain',
                width: '100px',
                height: '100px',
                }"
    />

    <p class="fw-bold m-0">2. Определенное время</p>
    <p class="text-break">
      Исполнение бота перейдет на следующий блок в выбранное время. Так же можно ограничить дни недели в которые будет произведена отправка.
      Например если бот перешел на блок задержки в субботу 15:00, а в задержке выбрано время 16:00 (пн-пт), то переход на следующий блок будет осуществлен в 15:00 понедельника.
      (время и даты необходимо указывать в часовом поясе сценария)
    </p>
    <Image
        src="/delay_2.png"
        alt="Блок задержки"
        height="300"
        preview
        class="m-1"
        :image-style="{
                objectFit: 'contain',
                width: '100px',
                height: '100px',
                }"
    />

    <p class="fw-bold m-0">3. Отправить через</p>
    <p class="text-break">
      Указывается ед.измерения задержки (секунды, минуты, часы, дни) и значение задержки, переход на следующий блок будет осуществлен согласно заданному времени.
      (Если выбраны дни недели, то переход на следующий блок будет осуществлен в ближайший доступный день)
    </p>
    <Image
        src="/delay_3.png"
        alt="Блок задержки"
        height="300"
        preview
        class="m-1"
        :image-style="{
                objectFit: 'contain',
                width: '100px',
                height: '100px',
                }"
    />
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Divider from "primevue/divider";

export default defineComponent({
  name: "DocsBuilderDelay",
  components: {
    Divider,
    Image,
  },
  created() {

  },

})
</script>