import {Command, CommandGroupNode, Trigger} from "./graph";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import client from "./client";
import {CreateForwardCmd, ForwardedForList} from "./forwarder";
import {plainToClass} from "class-transformer";
import {uuid} from "vue-uuid";
import {SearchDialogItem} from "./edge";


export class CreateScenarioTriggerCmd
{
    name: string
    continue_check_triggers: boolean = true
    handle_edit_message: boolean = true
    active: boolean = true
    triggers: TriggerGroupItem[] = []
    commands: CommandGroupItem[] = []
    scenario_uuid: string
    priority: number = 10
    dialog_uuid: string

    constructor(scenario_uuid: string, dialog_uuid: string) {
        this.scenario_uuid = scenario_uuid;
        this.dialog_uuid = dialog_uuid

        this.triggers.push(new TriggerGroupItem())
        this.commands.push(new CommandGroupItem())
    }
}

export class UpdateScenarioTriggerCmd extends CreateScenarioTriggerCmd
{
    uuid: string
    dialog_name: string

    constructor(scenario_uuid: string, dialog_uuid: string, uuid: string) {
        super(scenario_uuid, dialog_uuid);
        this.uuid = uuid;
    }
}

export class TriggerGroupItem
{
    uuid: string
    items: Trigger[] = []

    constructor() {
        this.uuid = uuid.v4();
    }
}

export class CommandGroupItem
{
    uuid: string
    items: Command[] = []

    constructor() {
        this.uuid = uuid.v4();
    }
}

export function createScenarioTrigger(cmd: CreateScenarioTriggerCmd): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/scenario-trigger/create', cmd)
}

export function updateScenarioTrigger(cmd: UpdateScenarioTriggerCmd): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/scenario-trigger/update', cmd)
}

export function removeScenarioTrigger(uuid: string): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/scenario-trigger/remove', {uuid: uuid})
}

export function copyScenarioTrigger(scenario_trigger_uuid: string, dialog_uuid: string): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/scenario-trigger/copy', {
        scenario_trigger_uuid: scenario_trigger_uuid,
        dialog_uuid: dialog_uuid,
    })
}

export class ScenarioTriggerForList
{
    dialog_name: string
    items: ScenarioTriggerForListItem[] = []
}

export class ScenarioTriggerForListItem
{
    uuid: string
    name: string
    active: boolean
    priority: number
    dialogs: string
}

export function getScenarioTriggerItemsForList(dialog_uuid: string): Promise<ScenarioTriggerForList>
{
    // @ts-ignore
    return client
        .post('/api/v1/scenario-trigger/get-for-list', {dialog_uuid: dialog_uuid})
        .then((response: AxiosResponse) => {
            return plainToClass(ScenarioTriggerForList, response.data);
        })
}

export function getScenarioTriggerItemsForCard(uuid: string): Promise<UpdateScenarioTriggerCmd>
{
    // @ts-ignore
    return client
        .post('/api/v1/scenario-trigger/get-for-card', {uuid: uuid})
        .then((response: AxiosResponse) => {
            return plainToClass(UpdateScenarioTriggerCmd, response.data);
        })
}