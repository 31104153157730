import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DictionaryForm = _resolveComponent("DictionaryForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DictionaryForm, {
      "model-value": this.updateDictionaryWord,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.updateDictionaryWord) = $event)),
      "scenario-uuid": this.scenario_uuid
    }, null, 8, ["model-value", "scenario-uuid"])
  ]))
}