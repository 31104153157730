<template>
  <Handle :id="this.targetHandleId" type="target" :position="Position.Left" :style="this.style" :connectable-start="false"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {Handle, Position} from "@vue-flow/core";

export const TARGET_READONLY_HANDLE_ID = 'read-only';

// Handler В который может входить ребро
export default defineComponent({
  name: 'TargetHandle',
  components: {Handle},
  props: {
    style: {
      required: false,
      default: {top: '30px'}
    },
  },
  data: function () {
    return {
      targetHandleId: TARGET_READONLY_HANDLE_ID,
    }
  },
  computed: {
    Position() {
      return Position
    }
  },
})
</script>

<style scoped>
.vue-flow__handle {
  width: 10px;
  height: 10px;
}
</style>
