<template>
  <div class="m-2">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <div class="content-group mt-2">
      <p class="m-0">Выберите группу в которую будут записываться действия совершаемые ботом</p>

      <div>
        <DialogTagPanel
            :dialog-types="[DialogType.group]"
            :scenario-uuid="this.scenario_uuid"
            :model-value="this.selectedServiceGroup"
            @model-value:update-value="($event) => {
              this.selectedServiceGroup = $event
            }"
            :disabled="this.selectedServiceGroup.length > 0"
        />
      </div>
    </div>

    <div class="content-group mt-2">
      <h5 class="fw-bold">Логирование капчи</h5>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.captcha.success"/>
        <span class="m-0 align-middle">Успешное прохождение</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.captcha.fail"/>
        <span class="m-0 align-middle">Не успешное прохождение</span>
      </div>
    </div>

    <div class="content-group mt-2">
      <h5 class="fw-bold">Логирование фильтров</h5>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.filter.abuse"/>
        <span class="m-0 align-middle">Фильтр мата</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.filter.link"/>
        <span class="m-0 align-middle">Фильтр ссылок</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.filter.telegram_link"/>
        <span class="m-0 align-middle">Фильтр Telegram ссылок</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.filter.forward_message"/>
        <span class="m-0 align-middle">Фильтр пересланных сообщений</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.filter.spam"/>
        <span class="m-0 align-middle">Фильтр спама</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.filter.stop_words"/>
        <span class="m-0 align-middle">Фильтр стоп слов</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.filter.mixed_abc"/>
        <span class="m-0 align-middle">Фильтр слов с смешанным алфавитом</span>
      </div>
    </div>

    <div class="content-group mt-2">
      <h5 class="fw-bold">Логирование команд ручной модерации</h5>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.admin_command.kick"/>
        <span class="m-0 align-middle">kick</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.admin_command.ban"/>
        <span class="m-0 align-middle">ban</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.admin_command.unban"/>
        <span class="m-0 align-middle">unban</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.admin_command.mute"/>
        <span class="m-0 align-middle">mute</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.admin_command.unmute"/>
        <span class="m-0 align-middle">unmute</span>
      </div>

    </div>

    <div class="mt-3 d-flex justify-content-end">
      <Button
          label="Сохранить"
          severity="contrast"
          class="rounded"
          :loading="this.isLoadingSave"
          @click="() => {
            this.isLoadingSave = true

            updateServiceGroup(this.card)
              .finally(() => { this.isLoadingSave = false })
          }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import SearchDialog from "@/components/common/SearchDialog.vue";
import {DialogType} from "@/api/dialog";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import {GetServiceGroup, getServiceGroup, updateServiceGroup} from "@/api/serviceGroup";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";

export default defineComponent({
  name: "ServiceGroup",
  methods: {updateServiceGroup},
  computed: {
    DialogType() {
      return DialogType
    },

    selectedServiceGroup: {
      get: function () {
        if (this.card.service_group) {
          return [this.card.service_group]
        }

        return []
      },
      set: function (val) {
        if (val) {
          this.card.service_group = val[0]
        } else {
          this.card.service_group = null
        }
      },
    }
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  components: {
    InputSwitch,
    Breadcrumbs,
    SearchDialog,
    DialogTagPanel,
    Button,
  },
  data: function () {
    return {
      breadcrumbs: [],
      card: new GetServiceGroup(this.dialog_uuid),
      isLoadingSave: false,
    }
  },
  created: function () {
    getServiceGroup(this.dialog_uuid).then(res => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Сервисная группа'),
      ]
    })
  },
})
</script>