import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToClass} from "class-transformer";
import {GetForCardResult} from "./dialog";


export class SourceItemResult
{
    uuid: string
    name: string
    link: string
}

export class GetPostingResult
{
    items: SourceItemResult[] = [];
}

export function getPostingSources(scenarioUuid: string, searchName: string|null): Promise<GetPostingResult>
{
    // @ts-ignore
    return client
        .post('/api/v1/forward/get-sources', {scenario_uuid: scenarioUuid, search_name: searchName})
        .then((response: AxiosResponse) => {
            return plainToClass(GetPostingResult, response.data);
        })
}

// принимаю более широкую структуру чтобы не играться с наследованием или урезанием интерфейса данной функции
export function updatePostingSources(cmd: GetPostingResult): Promise<GetPostingResult>
{
    // @ts-ignore
    return client
        .post('/api/v1/forward/update-sources', cmd)
        .then((response: AxiosResponse) => {
            return plainToClass(GetPostingResult, response.data);
        })
}

export function createForwarder(cmd: CreateForwardCmd): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/forward/create', cmd)
}

export class CreateForwardCmd
{
    scenario_uuid: string
    name: string

    sources: SourceItemResult[] = [];
    targets: SourceItemResult[] = [];

    constructor(scenario_uuid: string, name: string) {
        this.scenario_uuid = scenario_uuid;
        this.name = name;
    }
}

export class ForwardedForList
{
    items: ForwardedForListItem[] = []
}

export class ForwardedForListItem
{
    uuid: string
    name: string
    link: string
    active: boolean
}

export function getForwarderItemsForList(scenarioUuid: string): Promise<ForwardedForList>
{
    // @ts-ignore
    return client
        .post('/api/v1/forward/get-for-list', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(ForwardedForList, response.data);
        })
}

export class UpdateForwardDialogCmd
{
    forwarder_uuid: string
    name: string = ''
    active: boolean = false
    source_dialogs: SourceItemResult[] = [];
    target_dialogs: SourceItemResult[] = [];

    constructor(forwarder_uuid: string, source_dialogs: SourceItemResult[], target_dialogs: SourceItemResult[]) {
        this.forwarder_uuid = forwarder_uuid;
        this.source_dialogs = source_dialogs;
        this.target_dialogs = target_dialogs;
    }
}

export function getForwarderForCard(forwarderUuid: string): Promise<UpdateForwardDialogCmd>
{
    // @ts-ignore
    return client
        .post('/api/v1/forward/get-for-card', {forwarder_uuid: forwarderUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(UpdateForwardDialogCmd, response.data);
        })
}

export function updateForwarderDialogs(updateCmd: UpdateForwardDialogCmd): Promise<AxiosRequestConfig>
{
    // @ts-ignore
    return client.post('/api/v1/forward/update', updateCmd);
}

export function removeForwarder(forwarderUuid: string): Promise<AxiosRequestConfig>
{
    // @ts-ignore
    return client.post('/api/v1/forward/remove', {forwarder_uuid: forwarderUuid});
}