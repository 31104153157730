import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dropdown, {
      "model-value": this.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => { this.$emit('update:modelValue', $event) }),
      "option-label": "label",
      "option-value": "value",
      options: this.getPreviewOptions()
    }, null, 8, ["model-value", "options"])
  ]))
}