import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import client, {Pagination} from "./client";
import {plainToClass, plainToInstance} from "class-transformer";
import {GraphResult} from "@/api/graph";
import {ex} from "@fullcalendar/core/internal-common";

export type Scenario = {
    uuid: string;
    name: string;
    is_active: boolean;
    description: string;
};

export async function fetchScenarioList(): Promise<Scenario[]>
{
    return client
        .get('/api/v1/scenario/get-for-list')
        .then((response: AxiosRequestConfig) => {
            return response.data.items.map((scenario: Scenario) => ({
                uuid: scenario.uuid,
                name: scenario.name,
                is_active: scenario.is_active,
                description: scenario.description,
            }));
        });
}

export async function removeScenario(scenarioUuid: string): Promise<AxiosRequestConfig> {
    return client.post('/api/v1/scenario/remove', {uuid: scenarioUuid});
}

export class CreateScenarioCommand
{
    bot_token: string = '';
    description: string = '';
    time_zone: string = '+03:00'
}

export async function createScenario(command: CreateScenarioCommand): Promise<AxiosRequestConfig> {
    return client.post('/api/v1/scenario/create', command);
}

export class UserBotResult
{
    // @ts-ignore
    items: UserBot[]

    // @ts-ignore
    pagination: Pagination

    // @ts-ignore
    is_can_remove: boolean
}

export class UserBot
{
    // @ts-ignore
    uuid: string

    // @ts-ignore
    name: string
}

export async function getUsersOfScenario(scenarioUuid: string): Promise<UserBotResult>
{
    return client
        .post('/api/v1/scenario/users', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(UserBotResult, response.data);
        })
}

export async function removeUserFromScenario(scenarioUuid: string, userUuid: string): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/scenario/user/remove', {scenario_uuid: scenarioUuid, user_uuid: userUuid})
}

export enum ScenarioMode
{
    scenario = 'scenario',
    feedback = 'feedback',
}

export class ScenarioMember
{
    uuid: string
    name: string
    is_owner: boolean = false
}

export class ScenarioForCard
{
    // @ts-ignore
    uuid: string
    // @ts-ignore
    name: string
    // @ts-ignore
    description: string
    // @ts-ignore
    token: string
    // @ts-ignore
    link_to_telegram: string
    // @ts-ignore
    is_active: boolean
    // @ts-ignore
    time_zone: string

    deactivated_reason_print: string|null = null

    mode: ScenarioMode

    members: ScenarioMember[] = []

    is_owner_bot: boolean = false
}

export async function getScenarioForCard(scenarioUuid: string): Promise<ScenarioForCard>
{
    return client
        .post('/api/v1/scenario/get-for-card', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(ScenarioForCard, response.data);
        })
}

export async function updateBot(scenarioUuid: string, name: string, token: string, description: string, isActive: boolean, timezone: string, mode: ScenarioMode, members: ScenarioMember): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/scenario/update', {
            uuid: scenarioUuid,
            name: name,
            token: token,
            description: description,
            is_active: isActive,
            time_zone: timezone,
            mode: mode,
            members: members,
        })
}

export enum NodeType {
    commandGroup = 'command-group',
    triggerGroup ='trigger-group',
    messageNode ='message-node',
    questionNode ='question-node',
    delayNode ='delay-node',
    startNode ='start-node',
}

export function getTimeZones()
{
    return [
        {
            label: 'UTC +00:00',
            value: '+00:00',
        },
        // {
        //     label: '+01:00',
        //     value: '+01:00',
        // },
        {
            label: 'Калининград +02:00',
            value: '+02:00',
        },
        {
            label: 'Москва +03:00',
            value: '+03:00',
        },
        {
            label: 'Самара +04:00',
            value: '+04:00',
        },
        {
            label: 'Екатеринбург +05:00',
            value: '+05:00',
        },
        {
            label: 'Омск +06:00',
            value: '+06:00',
        },
        {
            label: 'Красноярск +07:00',
            value: '+07:00',
        },
        {
            label: 'Иркутск +08:00',
            value: '+08:00',
        },
        {
            label: 'Якутск +09:00',
            value: '+09:00',
        },
        {
            label: 'Владивосток +10:00',
            value: '+10:00',
        },
    ];
}

export class CreateShareLinkResult
{
    url: string
}

export async function createScenarioShareLink(scenarioUuid: string): Promise<CreateShareLinkResult>
{
    return client
        .post('/api/v1/scenario/share-link/create', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(CreateShareLinkResult, response.data);
        })
}

export async function useScenarioShareLink(shareLinkUuid: string): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/scenario/share-link/use', {share_link_uuid: shareLinkUuid})
}