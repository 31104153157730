<template>
  <div class="m-2">
    <HelpInfo class="mb-2">
      В данном режиме бот пересылает сообщения от клиента к модератору и от модератора к клиенту
    </HelpInfo>

    <h5 class="fw-bold">Настройка обратной связи</h5>

    <div class="content-group">
      <p class="m-0">Выберите группу в которой модераторы будут отвечать на сообщения пользователей</p>
      <p class=""><span class="fw-bold">Внимание!</span> необходимо добавить в группу поддержку тем (в настройках Telegram группы)</p>

      <DialogTagPanel
        :dialog-types="[DialogType.group]"
        :scenario-uuid="this.scenario_uuid"
        v-model:model-value="this.feedbackDialog"
        @model-value:update-value="($event) => { this.feedbackDialog = $event }"
        :disabled="this.feedbackDialog.length > 0"
      />

      <div class="mt-2">
        <h5>Разрешать отправлять медиа файлы</h5>

        <div class="mt-1">
          <InputSwitch
              v-model="this.card.allowed_media_types.audio"
              class="align-middle me-1"
          />
          <span class="align-middle">Аудио</span>
        </div>

        <div class="mt-1">
          <InputSwitch
              v-model="this.card.allowed_media_types.video"
              class="align-middle me-1"
          />
          <span class="align-middle">Видео</span>
        </div>

        <div class="mt-1">
          <InputSwitch
              v-model="this.card.allowed_media_types.photo"
              class="align-middle me-1"
          />
          <span class="align-middle">Фото</span>
        </div>

        <div class="mt-1">
          <InputSwitch
              v-model="this.card.allowed_media_types.document"
              class="align-middle me-1"
          />
          <span class="align-middle">Документы</span>
        </div>

      </div>
    </div>

    <div class="content-group">

      <div class="d-flex justify-content-between">
        <h5 class="fw-bold mt-auto">Сообщение при запуске бота</h5>

        <SearchContext class="mt-auto" :context-options="this.contextOptions"/>
      </div>

      <RichTextEditor class="mt-2" v-model="this.card.first_message.text"/>

      <ButtonGroupPanel
          class="mt-3"
          :button-types="[ButtonGroupType.link]"
          v-model="this.card.first_message.buttons"
      />
    </div>

    <div class="m-2 d-flex justify-content-end">
      <Button
        label="Сохранить"
        severity="contrast"
        class="rounded"
        :loading="this.saveLoading"
        @click="() => {
          this.saveLoading = true

          updateScenarioFeedback(this.card)
            .finally(() => {this.saveLoading = false})
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";
import {getScenarioFeedback, UpdateFeedbackBot, updateScenarioFeedback} from "@/api/feedbackBot";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import {ButtonGroupType} from "@/api/buttonGroupPanel";
import HelpInfo from "@/components/view/HelpInfo.vue";
import SearchContext from "@/components/common/SearchContext.vue";
import {ContextItemForBuilder, fetchContextListForBuilder} from "@/api/context";
import InputSwitch from "primevue/inputswitch";

export default defineComponent({
  name: "FeedbackBot",
  methods: {updateScenarioFeedback},
  computed: {
    ButtonGroupType() {
      return ButtonGroupType
    },
    DialogType() {
      return DialogType
    },

    feedbackDialog: {
      set: function (val) {
        if (val.length === 0) {
          this.card.feedback_dialog = null
          return
        }

        this.card.feedback_dialog = val[0]
      },
      get: function () {
        if (this.card.feedback_dialog === null) {
          return []
        }

        return [this.card.feedback_dialog]
      }
    }
  },
  components: {
    InputSwitch,
    SearchContext,
    HelpInfo,
    ButtonGroupPanel,
    RichTextEditor,
    Checkbox,
    DialogTagPanel,
    Button,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      card: new UpdateFeedbackBot(this.scenario_uuid),
      saveLoading: false,

      contextOptions: [] as ContextItemForBuilder[],
    }
  },
  created() {
    getScenarioFeedback(this.scenario_uuid).then((r) => {
      this.card = r
    })

    fetchContextListForBuilder(this.scenario_uuid).then(res => {
      this.contextOptions = res.global_custom_variables
          .concat(res.global_system_variables)
          .concat(res.person_custom_variables)
          .concat(res.person_system_variables)
    })
  }
})
</script>