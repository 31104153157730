<template>
  <div class="p-2 vh-100 gridmap" style="overflow-y: auto;">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <div class="m-4">
      <div class="bg-primary-subtle p-2 mt-4 rounded">
        <p class="m-0"><span class="pi pi-question-circle me-2"/>Позволяет избавиться от нежелательных сообщений в автоматическом режиме.</p>
        <p class="mt-2 mb-0"><span class="fw-bold">Важно:</span> фильтры не реагируют на администраторов группы</p>
      </div>
    </div>

    <Panel toggleable class="m-4"  :collapsed="true">
      <template #header>
        <InputSwitch class="align-middle me-2" v-model="this.filterStopWords"/>
        <span class="m-0 align-middle">Фильтр стоп слов</span>
      </template>

      <template #default>
        <div class="mt-2" v-if="this.filterStopWords">
          <Filter v-model="this.card.filter_stop_words" :context-options="this.card.context_options.filter_stop_words" />

          <Dropdown
              v-model="this.card.filter_stop_words.type"
              :options="[{code: FilterStopWordSource.words, name: 'Набор слов', }, {code: FilterStopWordSource.dictionary, name: 'Слова из справочников'}]"
              optionLabel="name"
              option-value="code"
          />

          <div class="mt-2">
            <InputSwitch class="align-middle me-2" v-model="this.card.filter_stop_words.sense_mixed_abc"/>
            <span class="m-0 align-middle">Учитывать смешанный алфавит</span>
          </div>

          <div class="mt-2" v-if="this.card.filter_stop_words.type === FilterStopWordSource.words">
            <p class="text-muted">Максимальное количество слов - 100</p>
            <p class="m-0 p-error" v-if="this.errors['filter_stop_words.words']">{{this.errors['filter_stop_words.words']}}</p>
            <WordsList
                :show-word-types="[WordType.fullEquals]"
                :words="this.card.filter_stop_words.words"
                @words:update="($event) => { this.card.filter_stop_words.words = $event }"
            />
          </div>

          <div class="mt-2" v-if="this.card.filter_stop_words.type === FilterStopWordSource.dictionary">
            <MultiSelect
                v-model="this.card.filter_stop_words.dictionaries"
                :options="this.graphConfigStorage.getDictionaries"
                display="chip"
                option-label="name"
                option-value="uuid"
                placeholder="Выберите справочники"
            />
          </div>
        </div>
        <div v-else><p class="text-muted m-0">Необходимо активировать фильтр</p></div>
      </template>
    </Panel>

    <Panel toggleable class="m-4"  :collapsed="true">
      <template #header>
        <InputSwitch class="align-middle me-2" v-model="this.filterAbuse"/>
        <span class="m-0 align-middle">Фильтр мата</span>
      </template>

      <template #default>
        <div v-if="this.filterAbuse">
          <Filter v-model="this.card.filter_abuse" :context-options="this.card.context_options.filter_abuse" class="mt-2"/>

          <div>
            <HelpInfo>Регистр слов не имеет значение - слово "тест" и "Тест" для системы одно и тоже</HelpInfo>
            <p class="mb-1 mt-2">Не считать матом слова</p>
            <WordsList
                :words="this.card.filter_abuse.ignore_words"
                :show-word-types="[WordType.fullEquals]"
                @words:update="($event) => {
                this.card.filter_abuse.ignore_words = $event
              }"
            />
          </div>

          <div class="mt-4">
            <p class="mb-1">Дополнительно считать матом слова <span class="ms-1 pi pi-question-circle" v-tooltip="'Система будет дополнительно фильтровать сообщения на эти слова'"/></p>
            <WordsList
                :words="this.card.filter_abuse.additional_words"
                :show-word-types="[WordType.fullEquals]"
                @words:update="($event) => {
                this.card.filter_abuse.additional_words = $event
              }"
            />
          </div>
        </div>
        <div v-else><p class="text-muted m-0">Необходимо активировать фильтр</p></div>
      </template>
    </Panel>

    <Panel toggleable class="m-4"  :collapsed="true">
      <template #header>
        <InputSwitch class="align-middle me-2" v-model="this.filterLink"/>
        <span class="m-0 align-middle">Фильтр ссылок</span>
      </template>

      <template #default>
        <div v-if="this.filterLink">
          <FilterLink :filter="this.card.filter_link" :context-options="this.card.context_options.filter_links"/>
        </div>
        <div v-else><p class="text-muted m-0">Необходимо активировать фильтр</p></div>
      </template>
    </Panel>

    <Panel toggleable class="m-4" :collapsed="true">
      <template #header>
        <div>
          <span class="pi align-middle p-1" :class="{ 'text-success pi-check': this.filterTelegramLink, 'text-danger pi-pause': !this.filterTelegramLink }"/>
          <span class="pi align-middle p-1" :class="{ 'text-success pi-check': this.filterTelegramLinkNewSubscribers, 'text-danger pi-pause': !this.filterTelegramLinkNewSubscribers }"/>
        </div>

        <div class="mx-auto">
          <span class="m-0 align-middle">Фильтр Telegram ссылок</span>
        </div>
      </template>

      <template #default>
        <div>
          <TabView>
            <TabPanel header="Основной">
              <div class="content-group">
                <FilterTelegramLink
                    :context-options="this.card.context_options.filter_telegram_links"
                    :filter="this.card.filter_telegram_link"
                    @update:filter="($event) => { this.card.filter_telegram_link = $event }"
                />
              </div>
            </TabPanel>

            <TabPanel header="Новые пользователи">
              <div class="content-group">
                <div class="mt-2">
                  <p class="m-0">Какое время считать пользователя новеньким с момента вступления</p>
                  <Period
                      v-model="this.card.filter_telegram_link_new_subscribers.new_subscriber_for_period"
                      :show-all-units="true"
                  />
                </div>

                <div>
                  <p class="m-0">Сколько сообщений имеет пользователь чтобы считаться новичком</p>
                  <InputNumber
                    v-model="this.card.filter_telegram_link_new_subscribers.new_subscriber_for_messages_count"
                    :min="1"
                  />
                </div>
              </div>

              <div class="content-group">
                <FilterTelegramLink
                    :context-options="this.card.context_options.filter_telegram_links"
                    :filter="this.card.filter_telegram_link_new_subscribers"
                    @update:filter="($event) => { this.card.filter_telegram_link_new_subscribers = $event }"
                />
              </div>
            </TabPanel>
          </TabView>
        </div>
      </template>
    </Panel>

    <Panel toggleable class="m-4"  :collapsed="true">
      <template #header>
        <InputSwitch class="align-middle me-2" v-model="this.filterForward"/>
        <span class="m-0 align-middle">Фильтр пересланных сообщений</span>
      </template>

      <template #default>
        <div v-if="this.filterForward">
          <Filter v-model="this.card.filter_forward" :context-options="this.card.context_options.filter_forwarded_message" />

          <div>
            <div class="mt-1">
              <InputSwitch class="align-middle me-2" v-model="this.card.filter_forward.react_bot"/>
              <span class="m-0 align-middle">Запретить пересылать сообщения от ботов</span>
            </div>

            <div class="mt-1">
              <InputSwitch class="align-middle me-2" v-model="this.card.filter_forward.react_username"/>
              <span class="m-0 align-middle">Запретить пересылать сообщения от пользователей</span>
            </div>

            <div class="mt-1">
              <InputSwitch class="align-middle me-2" v-model="this.card.filter_forward.react_channel"/>
              <span class="m-0 align-middle">Запретить пересылать сообщения из каналов</span>
            </div>

            <div class="mt-1">
              <InputSwitch class="align-middle me-2" v-model="this.card.filter_forward.react_group"/>
              <span class="m-0 align-middle">Запретить пересылать сообщения из групп</span>
            </div>
          </div>
        </div>
        <div v-else><p class="text-muted m-0">Необходимо активировать фильтр</p></div>
      </template>
    </Panel>

    <Panel toggleable class="m-4"  :collapsed="true">
      <template #header>
        <InputSwitch class="align-middle me-2" v-model="this.filterSpam"/>
        <span class="m-0 align-middle">Фильтр дублирующихся сообщений</span>
      </template>

      <template #default>
        <div class="mt-2" v-if="this.filterSpam">
          <Filter v-model="this.card.filter_spam" :context-options="this.card.context_options.filter_spam" />

          <div class="mt-2">
            <div>
              <p class="m-0 required">За какой период времени проверять сообщения на уникальность</p>
              <Period v-model="this.card.filter_spam.spam_window"/>

              <p class="m-0 required">Разрешенное количество дублирующихся сообщений одного пользователя за указанный период</p>
              <InputNumber v-model="this.card.filter_spam.allow_duplicate_messages_count" :min="1"/>
            </div>
          </div>
        </div>
        <div v-else><p class="text-muted m-0">Необходимо активировать фильтр</p></div>
      </template>
    </Panel>

    <Panel class="m-4" :toggleable="false" :collapsed="true">
      <template #header>
        <InputSwitch class="align-middle me-2" v-model="this.card.filter_service_message"/>
        <span class="m-0 align-middle">Удалять системные сообщения</span>
      </template>
    </Panel>

    <Panel class="m-4" toggleable :collapsed="true">
      <template #header>
        <InputSwitch class="align-middle me-2" v-model="this.filterMixedAbc"/>
        <span class="m-0 align-middle">Фильтр слов из смешанного алфавита</span>
      </template>

      <template #default>
        <div class="mt-2" v-if="this.filterMixedAbc">
          <Filter v-model="this.card.filter_mixed_abc" :context-options="this.card.context_options.filter_mixed_abc" />
        </div>
        <div v-else><p class="text-muted m-0">Необходимо активировать фильтр</p></div>
      </template>
    </Panel>

    <div class="m-4 pb-4">
      <div class="d-flex justify-content-end">
        <Button
            label="Сохранить"
            severity="secondary"
            class="rounded"
            :loading="this.isLoadingUpdate"
            @click="() => {
               this.isLoadingUpdate = true;
               this.errors = []

               updateGroupSettings(this.card)
                  .catch((error: ErrorResponse) => {
                    this.errors = error.getValidationErrors()
                  })
                  .finally(() => {
                    this.isLoadingUpdate = false
                  })
          }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import InputSwitch from "primevue/inputswitch";
import {FilterStopWordSource, getGroupSettings, GroupSettingsCard, updateGroupSettings,} from "@/api/groupSettings";
import Button from "primevue/button";
import {ErrorResponse} from "@/api/common";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import {TimeUnit} from "@/api/graph";
import {FilterActionMuteSubscriber} from "@/api/abuseFilter";
import FilterAction from "@/components/view/GroupComponents/FilterAction.vue";
import Period from "@/components/common/Period.vue";
import Filter from "@/components/view/GroupComponents/Filter.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import Divider from "primevue/divider";
import Menu from "primevue/menu";
import {Word, WordType} from "@/api/dictionary";
import WordsList from "@/components/view/GroupComponents/WordsList.vue";
import HelpInfo from "@/components/view/HelpInfo.vue";
import Panel from "primevue/panel";
import Checkbox from "primevue/checkbox";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import MultiSelect from "primevue/multiselect";
import FilterTelegramLink from "@/components/view/GroupComponents/FilterTelegramLink.vue";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import FilterLink from "@/components/view/GroupComponents/FilterLink.vue";

export default defineComponent({
  name: "FilterMessages",
  computed: {
    FilterStopWordSource() {
      return FilterStopWordSource
    },
    WordType() {
      return WordType
    },
    Word() {
      return Word
    },
    FilterActionMuteSubscriber() {
      return FilterActionMuteSubscriber
    },
    TimeUnit() {
      return TimeUnit
    },
    ErrorResponse() {
      return ErrorResponse
    },

    filterAbuse: {
      set: function (val) {
        this.card.filter_abuse.active = val
      },
      get: function () {
        return this.card.filter_abuse.active
      }
    },

    filterForward: {
      set: function (val) {
        this.card.filter_forward.active = val
      },
      get: function () {
        return this.card.filter_forward.active
      }
    },

    filterLink: {
      set: function (val) {
        this.card.filter_link.active = val
      },
      get: function () {
        return this.card.filter_link.active
      }
    },

    filterTelegramLink: function () {
        return this.card.filter_telegram_link.active
    },

    filterTelegramLinkNewSubscribers: function () {
        return this.card.filter_telegram_link_new_subscribers.active
    },

    filterSpam: {
      set: function (val) {
        this.card.filter_spam.active = val
      },
      get: function () {
        return this.card.filter_spam.active
      }
    },

    filterStopWords: {
      set: function (val) {
        this.card.filter_stop_words.active = val
      },
      get: function () {
        return this.card.filter_stop_words.active
      }
    },

    filterMixedAbc: {
      set: function (val) {
        this.card.filter_mixed_abc.active = val
      },
      get: function () {
        return this.card.filter_mixed_abc.active
      }
    },
  },
  methods: {
    updateGroupSettings,

    getTimeUnits: function () {
      return [
        {
          label: 'минут',
          value: TimeUnit.minute,
        },
        {
          label: 'часа',
          value: TimeUnit.hour,
        },
        {
          label: 'дней',
          value: TimeUnit.day,
        },
      ]
    },
  },
  components: {
    FilterLink,
    TabView, TabPanel,
    FilterTelegramLink,
    Checkbox,
    HelpInfo,
    WordsList,
    Breadcrumbs,
    Filter,
    Period,
    FilterAction,
    Dropdown,
    Button,
    InputSwitch,
    InputNumber,
    Divider,
    Menu,
    Panel,
    MultiSelect,
  },
  props: {
    dialog_uuid: {
      required: true,
      type: String,
    },
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      card: new GroupSettingsCard(this.dialog_uuid),
      isLoadingUpdate: false,
      errors: [],
      breadcrumbs: [],
    }
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  created() {
    getGroupSettings(this.dialog_uuid).then(res => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Фильтры сообщений'),
      ]
    })
  },
})
</script>