import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "p-2 vh-100 gridmap",
  style: {"overflow-y":"auto"}
}
const _hoisted_2 = { class: "mt-2 p-2 bg-light rounded" }
const _hoisted_3 = { class: "mt-2 p-2 bg-light rounded" }
const _hoisted_4 = { class: "mt-2 p-2 bg-light rounded" }
const _hoisted_5 = { class: "mt-2 p-2 bg-light rounded" }
const _hoisted_6 = { class: "d-flex justify-content-end m-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_AdminCommandItem = _resolveComponent("AdminCommandItem")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumbs, {
      items: this.breadcrumbs,
      class: "bg-white"
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminCommandItem, {
        item: this.card.kick,
        "onUpdate:item": _cache[0] || (_cache[0] = ($event) => { this.card.kick = $event })
      }, null, 8, ["item"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AdminCommandItem, {
        item: this.card.ban,
        "onUpdate:item": _cache[1] || (_cache[1] = ($event) => { this.card.ban = $event })
      }, null, 8, ["item"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AdminCommandItem, {
        item: this.card.warn,
        "onUpdate:item": _cache[2] || (_cache[2] = ($event) => { this.card.warn = $event })
      }, null, 8, ["item"]),
      _createVNode(_component_Filter, {
        modelValue: this.card.warn.filter,
        "onUpdate:modelValue": [
          _cache[3] || (_cache[3] = ($event: any) => ((this.card.warn.filter) = $event)),
          _cache[4] || (_cache[4] = ($event) => { this.card.warn = $event })
        ],
        "context-options": []
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_AdminCommandItem, {
        item: this.card.report,
        "onUpdate:item": _cache[5] || (_cache[5] = ($event) => { this.card.report = $event })
      }, null, 8, ["item"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        label: "Сохранить",
        severity: "secondary",
        class: "rounded"
      })
    ])
  ]))
}