<template>
  <div class="mt-2 mb-2">
    <draggable
        v-model="this.modelValueData"
        class="d-flex flex-wrap"
        item-key="uuid"
    >
      <template #item="{element, index}">

        <div class="mt-2 me-2 rounded d-flex">
          <div class="custom-border flex-grow-1">
            <div v-if="this.isImage(element)">
              <Image
                  :src="element.url"
                  preview
                  height="300"
                  :image-style="{
                      objectFit: 'contain',
                      maxWidth: '100%',
                  }"
              />
            </div>

            <div v-if="this.isVideo(element)">
              <video controls style="height: 300px; max-width: 100%">
                <source :src="element.url" />
              </video>
            </div>

            <div v-if="this.isDocument(element)">
              <a :href="element.url">
                <p class="pi pi-element bg-info text-white p-4 rounded m-0">
                  <span class="ms-2 fw-bold">{{element.name}}</span>
                </p>
              </a>
            </div>

            <div v-if="this.isAudio(element)">
              <audio controls :src="element.url"/>
            </div>
          </div>

          <div v-show="this.showRemoveButton">
            <button
                type="button"
                class="pi pi-trash p-1 bg-transparent m-0 rounded border-0 text-danger"
                @click="() => { this.modelValueData.splice(index, 1) }"
            />
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import draggable from "vuedraggable";
import Button from "primevue/button";
import Image from "primevue/image";
import {NodeFile, NodeFileType} from "@/api/graph";

export default defineComponent({
  name: "FilesViewer",
  components: {
    Image,
    Button,
    draggable,
  },
  props: {
    modelValue: {
      required: true,
      type: Array as PropType<NodeFile[]>,
    },
    showRemoveButton: {
      required: false,
      type: Boolean,
      default: true,
    }
  },
  emits: [
    'update:modelValue',
  ],
  methods: {
    isImage: function (file: NodeFile): boolean {
      return file.type === NodeFileType.image
    },

    isVideo: function (file: NodeFile): boolean {
      return file.type === NodeFileType.video
    },

    isDocument: function (file: NodeFile): boolean {
      return file.type === NodeFileType.document
    },

    isAudio: function (file: NodeFile): boolean {
      return file.type === NodeFileType.audio
    },
  },
  computed: {
    modelValueData: {
      get: function () {
        return this.modelValue
      },
      set: function (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
})
</script>