<template>
  <div>
    <h3 class="fw-bold">Переменные</h3>

    <div class="mt-2 mb-4">
      <p class="bg-primary-subtle p-2 custom-border rounded mt-2">
        Расположение функционала <span class="fw-bold">Боты - Выбираете бота - Переменные</span>
      </p>

      <p class="mt-2">
        <span class="fw-bold">Переменные</span> -
          можно представить как контейнер в котором хранятся данные, это могут быть
          данные которые пользователь ввел в бота сам (ответил на вопрос бота) или данные предоставляемые самим Telegram или нашим сервисом
          (имя подписчика, количество сообщений от подписчика, количество сообщений отправленных ботом и т.д)
      </p>
      <p class="mt-2">
        Откуда следует что, переменные глобально делятся на два типа, системные (те что предоставляет Telegram или наш сервис)
        и пользовательские, те что создаются конкретно вами. Причем важно заметить что системные переменные не поддаются редактированию.
      </p>

      <p class="mt-2">
        Внутри каждой из групп переменных (системные, пользовательские) идет разделение по принципу в рамках чего хранятся данные - бота или подписчика.
      </p>

      <p class="m-0">
        <span class="fw-bold">Глобальная переменная</span> хранит свое значение в рамках всего бота, например счетчик количество пользователей совершивших покупку.
      </p>
      <p>
        <span class="fw-bold">Персональная переменная</span> хранит значение в рамках конкретного Telegram подписчика, например количество заказов которые совершил данный подписчик.
      </p>

      <div class="card p-3">
        <OrganizationChart :value="this.variablesGraph">
          <template #default="slotProps">
            <span>{{ slotProps.node.label }}</span>
          </template>
        </OrganizationChart>
      </div>
    </div>

<!--    <Panel toggleable class="mt-4">-->
<!--      <template #header>-->
<!--        <h5 class="fw-bold">Примеры использования</h5>-->
<!--      </template>-->

<!--      <div>-->
<!--        <h5 class="fw-bold">1. Магазин проводит акцию, первые 100 пользователей которые заполнят анкету через бота получат скидку</h5>-->

<!--        <div class="p-2 custom-border bg-primary-subtle rounded">-->
<!--          <p>1. Заводим глобальную пользовательскую переменную "Количество доступных акций" и устанавливаем ее в значение 100</p>-->
<!--          <p>2. Заводим персональную пользовательскую переменную "Подписчик получил акцию", для защиты чтобы один подписчик получил одну акцию-->
<!--            (эту проверку можно так же реализовать через теги, завести тег получивших акцию и давать тег пользователю который получил акцию и проверять что у него еще нет тега)-->
<!--          </p>-->
<!--          <p>3. После заполнения всех необходимых данных - заполняем переменную из пункта 2 и уменьшаем значение переменной на 1 из пункта 1</p>-->
<!--        </div>-->


<!--        <a href="123">Ссылка на shared шаблон и ссылка на бота в который можно потыкаться и посмотреть функционал</a>-->
<!--      </div>-->

<!--      <div class="mt-4">-->
<!--        <h5 class="fw-bold">2. Выдача подарочного инфо-курса после приобретения 3 товаров</h5>-->

<!--        <div class="p-2 custom-border bg-primary-subtle rounded">-->
<!--          <p>1. </p>-->
<!--        </div>-->

<!--        <a href="123">Ссылка на shared шаблон и ссылка на бота в который можно потыкаться и посмотреть функционал</a>-->
<!--      </div>-->
<!--    </Panel>-->

  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import OrganizationChart from "primevue/organizationchart";
import Panel from "primevue/panel";

export default defineComponent({
  name: "DocsContext",
  components: {
    Image,
    OrganizationChart,
    Panel,
  },
  data: function () {
    return {
      variablesGraph: {
        label: 'Переменные',
        children: [
          {
            label: 'Системные',
            children: [
              {
                label: 'Глобальные'
              },
              {
                label: 'Персональные'
              }
            ]
          },
          {
            label: 'Пользовательские',
            children: [
              {
                label: 'Глобальные'
              },
              {
                label: 'Персональные'
              }
            ]
          },
        ]
      }
    }
  }
})
</script>

<!-- todo: иначе не подсвечивает линии -->
<style>
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #e2e8f0;
}

.p-organizationchart .p-organizationchart-line-right {
  border-left: 1px solid #e2e8f0;
}
</style>