<template>
  <div>
    <div class="text-center">
      <Dropdown
          class="mt-2 mb-2 fw-bold"
          v-model="this.modelValueData.type"
          :options="[
                {value: TriggerGroupType.OR, name: 'Проверка условий через ИЛИ'},
                {value: TriggerGroupType.AND, name: 'Проверка условий через И'}
            ]"
          option-value="value"
          option-label="name"
      />
    </div>

    <div class="content-trigger-group">
      <TriggerItem
          v-for="(element, index) in this.modelValueData.triggers"
          :key="element.uuid"
          :model-value="this.modelValueData.triggers[index]"
          @update:model-value="($event) => {
            this.modelValueData.triggers[index] = $event
          }"
          @pressed-button-remove="() => {
              this.modelValueData.triggers.splice(index, 1)
              this.isShowAddTriggerItem = false
          }"
          @completed-fill-trigger="() => {this.isShowAddTriggerItem = false}"
          :is-edit-mode="true"
          :context-options="this.contextOptionsForTriggers"
          :trigger-options="this.graphConfigStorage.getTriggerGroupTargetTypes"
      />
    </div>

    <div class="text-center">
      <Button
          label="Добавить условие"
          severity="secondary"
          class="mt-2 rounded"
          @click="($event) => { this.$refs.menu.toggle($event); }"
      />

      <Menu
          ref="menu"
          id="overlay_menu"
          :base-z-index="10001"
          :model="this.getMenuContextOptions()"
          :popup="true"
      />
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, inject, PropType} from 'vue'
import {Command, CommandType, DelayBlockNode, Trigger, TriggerGroupNode, TriggerType} from "@/api/graph";
import TriggerItem from "@/components/view/Bots/Trigger/TriggerItem.vue";
import Dropdown from "primevue/dropdown";
import {TriggerGroupType} from "@/api/trigger";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import Button from "primevue/button";
import Menu from "primevue/menu";
import {MenuItem} from "primevue/menuitem";

export default defineComponent({
  name: "TriggerGroupEditNode",
  computed: {
    TriggerGroupType() {
      return TriggerGroupType
    }
  },
  components: {
    Menu,
    Dropdown,
    TriggerItem,
    Button,
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<TriggerGroupNode>,
    },
  },
  inject: ['scenario_uuid'],
  emits: [
    'update:modelValue',
  ],
  data: function () {
    return {
      isShowAddTriggerItem: false,
      modelValueData: this.modelValue,
    }
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    const contextOptionsForTriggers = inject('contextOptionsForTriggers', [])

    return {graphConfigStorage, contextOptionsForTriggers}
  },
  watch: {
    modelValueData: {
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
    },
    modelValue: function (val) {
      this.modelValueData = val
    }
  },
  methods: {
    onClickCreateTrigger: function (event) {
      const eventType = event.item as {code: TriggerType}

      const newTrigger = new Trigger()
      newTrigger.type = eventType.code

      switch (eventType.code) {
        case TriggerType.msg:
          this.modelValueData.triggers.push(Trigger.createTargetMsg())
          break

        case TriggerType.has_tag:
          this.modelValueData.triggers.push(Trigger.createTargetSubscriberHasTag())
          break

        case TriggerType.subscribed_on_chat:
          this.modelValueData.triggers.push(Trigger.createTargetSubscribedOn())
          break

        case TriggerType.date:
          this.modelValueData.triggers.push(Trigger.createTargetDate())
          break

        case TriggerType.time:
          this.modelValueData.triggers.push(Trigger.createTargetTime())
          break

        case TriggerType.context:
          this.modelValueData.triggers.push(Trigger.createTargetContext())
          break

        case TriggerType.current_chat_is:
          this.modelValueData.triggers.push(Trigger.createTargetCurrentChatIs())
          break
      }
    },

    getMenuContextOptions: function () {
      const result: MenuItem[] = []

      this.graphConfigStorage.getTriggerGroupTargetTypes.forEach((item => {
        result.push({
          label: item.name,
          code: item.value,
          command: this.onClickCreateTrigger,
        })
      }))

      return result
    }
  }
})
</script>