import {CommandType, TimeUnit} from "./graph";
import {Period} from "./periodicMessage";
import {Type} from "class-transformer";
import {ButtonGroupType, ButtonLink, ButtonScenario} from "./buttonGroupPanel";

export class FilterAction
{
    abstract type: CommandType
    warning_text: string|null = null

    protected constructor(type: CommandType) {
        this.type = type;
    }
}

export class FilterActionKickSubscriber extends FilterAction
{

    constructor() {
        super(CommandType.kickSubscriber);
    }
}

export class FilterActionBanSubscriber extends FilterAction
{
    period: Period

    constructor() {
        super(CommandType.banSubscriber);
        this.period = new Period(1, TimeUnit.hour)
    }
}

export class FilterActionMuteSubscriber extends FilterAction
{
    period: Period

    constructor() {
        super(CommandType.muteSubscriber);
        this.period = new Period(1, TimeUnit.hour)
    }
}

export class FilterActionNothingDo extends FilterAction
{
    constructor() {
        super(CommandType.nothingDo);
    }
}
