<template>
  <div>
    <h3 class="fw-bold">Отложенные публикации в канал</h3>

    <div class="mt-2 mb-4">
      <p>
        Запланируйте неограниченное количество постов для публикации, задайте интервал повторной публикации, через какое время открепить пост, удалить пост.
        Оповестите модераторов о выходе поста через встроенный механизм оповещения.
      </p>

      <p class="bg-primary-subtle p-2 custom-border rounded mt-2">
        Расположение функционала <span class="fw-bold">Боты - Выбираете бота - Посты - Добавить пост</span>
      </p>
    </div>

    <Panel toggleable class="mt-4">
      <template #header>
        <h5 class="fw-bold">Возможности</h5>
      </template>

      <div class="ms-4">
        <ul>
          <li>Публикация поста одновременно в нескольких каналах</li>
          <li>Форматирование - HTML, Markdown</li>
          <li>Текст поста до 4096 символов</li>
          <li>Прикрепление к посту - фото, видео, документов, аудио</li>
          <li>Прикрепление к посту - кнопок с внешними ссылками</li>
          <li>Уведомление выбранных подписчиков о выходе поста (например рекламодателей)</li>
          <li>Возможность защиты контента от пересылки</li>
          <li>Закрепление публикации и таймер открепление публикации через время/к определенной дате</li>
          <li>Возможность бесшумной отправки (полезно при ночном постинге)</li>
          <li>Таймер удаления поста через время/к определенной дате</li>
          <li>Предварительный просмотр</li>
          <li>
            <p class="m-0">Гибкая система выбора даты публикации</p>
            <div class="ms-3">
              <ul>
                <li>Одноразовая отправка к определенной дате и времени</li>
                <li>Публикация в диапазоне дат с выбором интервала публикации</li>
                <li>Зацикленная рассылка (выбор времени когда будет осуществлена первая публикация, интервал публикации и количество публикаций)</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </Panel>

    <Panel toggleable class="mt-4">
      <template #header>
        <h5 class="fw-bold">Примеры</h5>
      </template>

      <div class="mt-2">
        <div class="mt-2 d-inline-block me-4">
          <p class="m-0">Пост с фотографией и <span class="fw-bold">HTML</span> форматированием</p>
          <Image
              class="mt-1"
              height="300"
              src="/docs_post_example_1.png"
              preview
              :image-style="{
              display: 'block',
              width: 'auto',
              height: '50em',
              maxWidth: '100%',
          }"
          />
        </div>

        <div class="d-inline-block me-4">
          <p class="m-0">Пост с фотографией <span class="fw-bold">Markdown</span> форматированием</p>
          <Image
              class="mt-1"
              height="300"
              src="/docs_post_example_2.png"
              preview
              :image-style="{
              display: 'block',
              width: 'auto',
              height: '50em',
              maxWidth: '100%',
          }"
          />
        </div>
      </div>

      <div class="mt-4">
        <div class="d-inline-block me-4">
          <p class="m-0">Пост с <span class="fw-bold">Markdown</span> форматированием и несколькими фотографиями</p>
          <Image
              class="mt-1"
              src="/docs_post_example_3.png"
              preview
              :image-style="{
              display: 'block',
              width: 'auto',
              height: '50em',
              maxWidth: '100%',
            }"
          />
        </div>

        <div class="d-inline-block">
          <p class="m-0">Пост с <span class="fw-bold">Markdown</span> форматированием, несколькими фотографиями и кнопками</p>
          <Image
              class="mt-1"
              src="/docs_post_example_4.png"
              preview
              :image-style="{
              display: 'block',
              width: 'auto',
              height: '50em',
              maxWidth: '100%',
            }"
          />
        </div>
      </div>

      <div class="mt-4">
        <div class="d-inline-block">
          <p class="m-0">Пост с <span class="fw-bold">Markdown</span> форматированием, несколькими аудио и кнопками</p>
          <Image
              class="mt-1"
              src="/docs_post_example_5.png"
              preview
              :image-style="{
                display: 'block',
                width: 'auto',
                height: '30em',
                maxWidth: '100%',
              }"
          />
        </div>
      </div>
    </Panel>

    <div class="custom-border content-group mt-4">
      <h5 class="fw-bold">Предварительный просмотр</h5>

      <div class="p-2 rounded custom-border bg-primary-subtle">
        <p class="m-0">
          Важное замечание, всегда перед сохранением поста делайте его предварительный просмотр, т.к существуют ограничения самого Telegram которые могут не учитываться нашим сервисом,
          например Telegram не позволяет объединять в один пост аудио и другие виды медиа файлов,
          но наша система позволит сохранить такой пост (но фактически пост опубликован не будет)
        </p>
      </div>

      <Image
          class="mt-4"
          src="/docs_post_example_6.png"
          preview
          :image-style="{
                display: 'block',
                width: 'auto',
                height: '30em',
                maxWidth: '100%',
              }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Panel from "primevue/panel";

export default defineComponent({
  name: "DocsDelayPublish",
  components: {
    Image,
    Panel,
  },
})
</script>

<style scoped>

</style>