import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "position-absolute top-0 end-0 bg-body vh-100 custom-border",
  style: {"z-index":"500","width":"25%","overflow-y":"auto"}
}
const _hoisted_2 = {
  class: "d-flex justify-content-center p-2",
  style: {"background-color":"rgb(120,120,120)"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_MessageNodeEdit = _resolveComponent("MessageNodeEdit")!
  const _component_CommandGroupNodeEdit = _resolveComponent("CommandGroupNodeEdit")!
  const _component_DelayNodeEdit = _resolveComponent("DelayNodeEdit")!
  const _component_TriggerGroupEditNode = _resolveComponent("TriggerGroupEditNode")!
  const _component_QuestionNodeEdit = _resolveComponent("QuestionNodeEdit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputText, {
        class: "w-100 align-middle",
        modelValue: this.modelValueData.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.modelValueData.name) = $event))
      }, null, 8, ["modelValue"]),
      _createElementVNode("button", {
        class: "pi pi-times bg-transparent border-0 ms-2 text-white align-middle",
        onClick: _cache[1] || (_cache[1] = () => {
          this.$emit('collapsed')
        })
      })
    ]),
    (this.modelValueData instanceof _ctx.MessageNode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_MessageNodeEdit, {
            "model-value": this.modelValueData,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => {this.modelValueData = $event; this.$emit('update:modelValue', $event)})
          }, null, 8, ["model-value"])
        ]))
      : _createCommentVNode("", true),
    (this.modelValueData instanceof _ctx.CommandGroupNode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_CommandGroupNodeEdit, {
            "model-value": this.modelValueData,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => {this.modelValueData = $event; this.$emit('update:modelValue', $event)})
          }, null, 8, ["model-value"])
        ]))
      : _createCommentVNode("", true),
    (this.modelValueData instanceof _ctx.DelayBlockNode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_DelayNodeEdit, {
            "model-value": this.modelValueData,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => {this.modelValueData = $event; this.$emit('update:modelValue', $event)})
          }, null, 8, ["model-value"])
        ]))
      : _createCommentVNode("", true),
    (this.modelValueData instanceof _ctx.TriggerGroupNode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_TriggerGroupEditNode, {
            "model-value": this.modelValueData,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => {this.modelValueData = $event; this.$emit('update:modelValue', $event)})
          }, null, 8, ["model-value"])
        ]))
      : _createCommentVNode("", true),
    (this.modelValueData instanceof _ctx.QuestionNode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_QuestionNodeEdit, {
            "model-value": this.modelValueData,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => {this.modelValueData = $event; this.$emit('update:modelValue', $event)})
          }, null, 8, ["model-value"])
        ]))
      : _createCommentVNode("", true)
  ]))
}