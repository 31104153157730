import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "content-trigger-group" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommandItem = _resolveComponent("CommandItem")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.modelValueData.commands, (element, index) => {
        return (_openBlock(), _createBlock(_component_CommandItem, {
          key: element.uuid,
          command_group_uuid: this.modelValueData.uuid,
          modelValue: this.modelValueData.commands[index],
          "onUpdate:modelValue": ($event: any) => ((this.modelValueData.commands[index]) = $event),
          onPressedButtonRemove: ($event: any) => {this.modelValueData.commands.splice(index, 1);},
          onCompletedFilledCommandItem: _cache[0] || (_cache[0] = () => {
            this.$emit('update:modelValue', this.modelValueData)
          }),
          "context-options": this.contextOptionsForCommands,
          "command-options": this.graphConfigStorage.getCommandTypes,
          "read-only": false,
          "read-context-options": [],
          "is-edit-mode": true
        }, null, 8, ["command_group_uuid", "modelValue", "onUpdate:modelValue", "onPressedButtonRemove", "context-options", "command-options"]))
      }), 128)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          label: "Добавить действие",
          severity: "secondary",
          class: "mt-2 rounded",
          onClick: _cache[1] || (_cache[1] = ($event) => { this.$refs.menu.toggle($event); })
        }),
        _createVNode(_component_Menu, {
          ref: "menu",
          id: "overlay_menu",
          "base-z-index": 10001,
          model: this.getMenuContextOptions(),
          popup: true
        }, null, 8, ["model"])
      ])
    ])
  ]))
}