<template>
  <SidebarMenu
      :menu="this.menuItems"
      width="400px"
      :showOneChild="true"
      @update:collapsed="this.collapsed = $event"
      :collapsed="this.collapsed"
      :show-child="false"
      class="flex-fill bd-highlight position-sticky"
      :hide-toggle="this.isMobile"
  >
    <template v-slot:header>
      <div class="text-center m-2 rounded text-white" v-if="!this.collapsed">
        <h5 class="fw-bold">Ninja-bot</h5>
      </div>
    </template>

    <template v-slot:toggle-icon>
      <p v-if="this.collapsed" class="pi pi-angle-double-right"/>
      <p v-else class="pi pi-angle-double-left"/>
    </template>


    <template #footer>
        <a
            v-if="!this.collapsed"
            href="https://t.me/+KcV0DT6Eb0E5N2Vi"
            class="btn btn-light d-block m-2 rounded"
            target="_blank"
        >
          Каталог полезных ТГ-ботов
          <img src="/telegram_icon.svg" alt="telegram-icon" >
        </a>

        <a
            v-if="!this.collapsed"
            href="https://t.me/ninja_bot_ru"
            class="btn btn-primary d-block m-2 rounded"
            target="_blank"
        >
          Тех поддержка
          <img src="/telegram_icon.svg" alt="telegram-icon" >
        </a>
    </template>
  </SidebarMenu>
</template>

<script lang="ts">
import Panel from "primevue/panel";
import Menu from "primevue/menu";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import Sidebar from "primevue/sidebar";
import SideBarItem from "@/components/view/SideBar/SideBarItem.vue";
import {PropType} from "vue";
import {SideBarMenu} from "@/components/view/SideBar/sidebar";
import { SidebarMenu } from 'vue-sidebar-menu'

export default {
  name: 'LeftSideBar',
  components: {
    SideBarItem,
    Panel,
    Menu,
    Button,
    Avatar,
    Sidebar,
    SidebarMenu,
  },
  emits: [
      'update:modelValue',
  ],
  data: function () {
    return {
      collapsed: this.collapsedMenuDefault as boolean,
      isMobile: false,
    }
  },
  props: {
    modelValue: {
      required: true,
      type: Array as PropType<SideBarMenu[]>,
    },
    collapsedMenuDefault: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  computed: {
    menuItems: function () {
      const convertedMenu = []

      this.modelValue.forEach((menuItem: SideBarMenu) => {
        if (menuItem.as_header) {
          convertedMenu.push({
            header: menuItem.name,
          })

          return
        }

        convertedMenu.push({
          href: {name: menuItem.route_name, params: menuItem.route_params},
          title: menuItem.name,
          icon: menuItem.icon,
          attributes: {
            target: menuItem.target,
          },
          child: this.getChildrenMenuItemsRecursive(menuItem),
          class: menuItem.route_name,
        })
      })

      // console.log(convertedMenu)

      return convertedMenu
    },
  },
  methods: {
    getChildrenMenuItemsRecursive: function (item: SideBarMenu): SideBarMenu[] {
      const result = []
      if (!item.submenu.length) {
        return result
      }


      item.submenu.forEach((menu: SideBarMenu) => {
        if (menu.as_header) {
          result.push({
            header: menu.name,
          })

          return
        }

        result.push({
          href: {name: menu.route_name, params: menu.route_params},
          title: menu.name,
          icon: menu.icon,
          attributes: {
            target: menu.target,
          },
          child: this.getChildrenMenuItemsRecursive(menu),
          class: menu.route_name,
        })
      })

      return result
    }
  },
  created() {
    if (window.screen.width < 400) {
      this.isMobile = true
      this.collapsed = true
    }
  }
}
</script>

<style scoped>
/* Google Fonts Import Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');
</style>