<template>
  <div class="mt-4">
    <Dialog
        v-model:visible="this.isOpenDialog"
        header="Запись на прием"
        :style="{ width: '75vw' }"
        maximizable
        modal
        :contentStyle="{ height: '500px' }"
        :draggable="false"
    >
      <p class="p-2 d-inline-block rounded border-black border-1" style="background-color: #dedbdb">Дата и время отображается в соответствии с часовым поясом выбранным в настройках бота</p>

<!--      <p>Запись была осуществлена: {{this.calendarRecord.created_at}}</p>-->

      <div>
        <label>Время начала записи</label>
        <DateInput
            v-model="this.calendarRecord.started_at"
            :show-time="true"
            :disabled="!this.isEdit"
        />
      </div>

      <div>
        <label>Время завершения записи</label>
        <DateInput
            v-model="this.calendarRecord.ended_at"
            :show-time="true"
            :disabled="!this.isEdit"
        />
      </div>

      <template #footer>
<!--        <Button label="Отменить запись" icon="pi pi-delete-left" severity="danger" class="rounded" @click="" />-->
<!--        <Button label="Отменить запись с освобождением окна" icon="pi pi-delete-left" severity="danger" class="rounded" @click="" />-->
        <Button
            :label="this.buttonEditText"
            :severity="this.buttonEditSeverity"
            icon="pi pi-file-edit"
            class="rounded"
            @click="this.onClickSaveButton"
            :loading="this.isLoadingUpdate"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {
  getForCardCalendar, GetForCardRecord,
  GetForCardResult,
  getForListCalendar,
  GetForListItem,
  GetForListResult, GetRecordForCard, getCalendarRecordForCard, updateCalendarRecord
} from "@/api/calendar";
import {CalendarApi} from "@fullcalendar/core";
import {EventImpl} from "@fullcalendar/core/internal";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import TimeInput from "@/components/common/TimeInput.vue";
import {start} from "@popperjs/core";
import DateInput from "@/components/common/DateInput.vue";

export default defineComponent({
  name: 'EventRecordCard',
  components: {
    DateInput,
    TimeInput,
    Button,
    Dialog,
  },
  props: {
    uuid: {
      required: true,
      type: [String, null],
    },
  },
  emits: [
    'close-record-item',
  ],
  data: function () {
    return {
      calendarRecord: null as GetRecordForCard|null,
      isEdit: false,
      isLoadingUpdate: false,
    }
  },
  methods: {
    onClickSaveButton: function () {
      if (this.isEdit) {
        this.isLoadingUpdate = true
        updateCalendarRecord(this.calendarRecord).then(() => {
          this.isLoadingUpdate = false
        })
      }

      this.isEdit = !this.isEdit
    },

    // handleEventClick(clickInfo: Object) {
    //   const event = clickInfo.event as EventImpl
    //
    //   getRecordForCard(event.id).then((result: GetRecordForCard) => {
    //     this.isOpenRecordCard = true
    //     this.calendarRecord = result
    //   })
    // },
  },
  computed: {
    isOpenDialog: {
      set: function (newVal: boolean) {
        if (newVal === false) {
          this.$emit('close-record-item')
        }
      },
      get: function (): boolean {
        return this.calendarRecord !== null
      },
    },

    buttonEditText: function () {
      return this.isEdit ? 'Сохранить' : 'Отредактировать запись'
    },

    buttonEditSeverity: function (): string|null {
      return this.isEdit ? 'info' : null
    }
  },
  watch: {
    uuid: function (newRecordUuid) {
      if (newRecordUuid === null) {
        this.calendarRecord = null
        return
      }

      getCalendarRecordForCard(newRecordUuid).then((result: GetRecordForCard) => {
        this.calendarRecord = result
      })
    }
  }
})
</script>