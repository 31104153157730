import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createBlock(_component_Calendar, {
    ref: "calendar",
    class: _normalizeClass(this.class),
    modelValue: this.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.value) = $event)),
    "time-only": "",
    onFocusout: this.focusOut,
    "show-button-bar": this.showBar,
    invalid: this.invalid,
    disabled: this.disabled,
    placeholder: this.placeholder
  }, null, 8, ["class", "modelValue", "onFocusout", "show-button-bar", "invalid", "disabled", "placeholder"]))
}