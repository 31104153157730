import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "rounded-pill d-inline-block p-1 me-1 mb-1",
  style: {"background-color":"#d7d9d9"}
}
const _hoisted_2 = { class: "d-inline-block" }
const _hoisted_3 = ["onInput"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.wordsList, (word, index) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("span", null, "%", 512), [
            [_vShow, word.type === _ctx.WordType.anyBegin]
          ]),
          _createElementVNode("div", {
            contenteditable: "true",
            class: "d-inline-block p-2 pe-4",
            onInput: ($event: any) => (this.handleEditWord(index, $event))
          }, _toDisplayString(word.value), 41, _hoisted_3),
          _withDirectives(_createElementVNode("span", null, "%", 512), [
            [_vShow, word.type === _ctx.WordType.anyEnd]
          ])
        ]),
        _createElementVNode("button", {
          class: "bg-transparent border-0 pi pi-times-circle text-danger",
          onClick: () => {
            this.wordsList.splice(index, 1)
          }
        }, null, 8, _hoisted_4)
      ]))
    }), 256)),
    _createVNode(_component_Button, {
      icon: "pi pi-plus",
      severity: "contrast",
      label: this.labelAddWordButton,
      class: "rounded-pill align-middle",
      onClick: this.toggle
    }, null, 8, ["label", "onClick"]),
    _createVNode(_component_Menu, {
      ref: "menu",
      popup: true,
      model: this.wordTypeItems
    }, null, 8, ["model"])
  ]))
}