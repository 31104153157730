<template>
  <div class="right-sidebar">
    <p style="background-color: cyan">Название редактируемой сущности</p>
    <div>
      <div>
        <span>Знак удаления</span>
        Текущее время больше <p class="fw-bold">14:00</p>
      </div>
    </div>
    <button class="bg-primary text-white" @click="$emit('add-trigger')">Добавить триггер</button>
  </div>
</template>

<script>
export default {
  name: 'ToolBar',
  props: {
    uuid: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
.right-sidebar {
  right: 0;
  top: 200px;
  position: absolute;
  margin-top: 20px;
  background-color: darkgrey;
}
</style>
