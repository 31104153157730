<template>
<div>
  <p>Выбор даты:</p>
  <Dropdown
      :options="this.getCalendarOptions()"
      option-label="name"
      option-value="code"
      placeholder="Выбор даты"
      :disabled="!this.isEditMode"
      v-model="this.data.choose_date"
      scroll-height="500px"
  />

  <div class="mt-1">
    <div class="mt-2">
      <p>Часы приема:</p>
      <div v-for="(timeSlot, index) in this.data.time_slots">
<!--        todo: подсвечивать поле если start_at > end_at -->
        <TimeInput
            v-model="timeSlot.start_at"
            :disabled="!this.isEditMode"
            class="w-25"
            placeholder="Прием от"
        />
        <TimeInput
            v-model="timeSlot.end_at"
            :disabled="!this.isEditMode"
            class="w-25"
            placeholder="Прием до"
        />
        <button
            class="pi pi-trash border-0"
            :disabled="!this.isEditMode"
            @click="this.data.time_slots.splice(index, 1)"
        />
      </div>

      <Button
        label="Добавить"
        @click="this.data.time_slots.push(new TimeSlot())"
        class="rounded mt-2"
      />
    </div>
  </div>

  <div>
    <p>Не показывать интервалы из:</p>
    <SelectButton
        v-model="this.data.exclude_date"
        class="mt-1"
        option-label="name"
        option-value="value"
        :disabled="!this.isEditMode"
        :options="[
          {name: 'Будущего', value: ExcludeDate.excludeFeature},
          {name: 'Прошлого', value: ExcludeDate.excludePast},
          {name: 'Нет ограничений', value: null}
      ]"
    />
  </div>

  <div class="mt-2">
    <label class="ms-2 me-1">Пн</label>
    <Checkbox v-model="this.data.day1" :binary="true" :disabled="!this.isEditMode"/>

    <label class="ms-2 me-1">Вт</label>
    <Checkbox v-model="this.data.day2" :binary="true" :disabled="!this.isEditMode"/>

    <label class="ms-2 me-1">Ср</label>
    <Checkbox v-model="this.data.day3" :binary="true" :disabled="!this.isEditMode"/>

    <label class="ms-2 me-1">Чт</label>
    <Checkbox v-model="this.data.day4" :binary="true" :disabled="!this.isEditMode"/>

    <label class="ms-2 me-1">Пт</label>
    <Checkbox v-model="this.data.day5" :binary="true" :disabled="!this.isEditMode"/>

    <label class="ms-2 me-1">Сб</label>
    <Checkbox v-model="this.data.day6" :binary="true" :disabled="!this.isEditMode"/>

    <label class="ms-2 me-1">Вс</label>
    <Checkbox v-model="this.data.day7" :binary="true" :disabled="!this.isEditMode"/>
  </div>

  <div v-if="this.data.choose_date == ChooseDate.specifiedDay">
    <DateInput v-model="this.data.specified_day" :disabled="!this.isEditMode"/>
  </div>
</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import Dropdown from "primevue/dropdown";
import {ChooseDate, ExcludeDate, QuestionNodeCalendar, TimeSlot} from "@/api/graph";
import Calendar from "primevue/calendar";
import flatPicker from "vue-flatpickr-component";
import SelectButton from "primevue/selectbutton";
import TimeInput from "@/components/common/TimeInput.vue";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import DateInput from "@/components/common/DateInput.vue";

export default defineComponent({
  name: 'QuestionCalendar',
  props: {
    isEditMode: {
      required: true,
      type: Boolean,
    },
    calendar: {
      required: true,
      type: Object as PropType<QuestionNodeCalendar>,
    }
  },
  data: function() {
    return {
      data: this.calendar as QuestionNodeCalendar,
    }
  },
  components: {
    DateInput,
    Button,
    TimeInput,
    flatPicker,
    Dropdown,
    Calendar,
    SelectButton,
    Checkbox,
  },
  computed: {
    TimeSlot() {
      return TimeSlot
    },
    ExcludeDate() {
      return ExcludeDate
    },
    ChooseDate() {
      return ChooseDate
    },
  },
  emits: [
    'update:calendar',
  ],
  watch: {
    calendar: function (val) {
      this.data = val
    },

    data: function (val) {
      this.$emit('update:calendar', val)
    }
  },
  methods: {
    getCalendarOptions: function () {
      return [
        {
          name: 'Запись на сегодня',
          code: ChooseDate.today,
        },
        {
          name: 'Запись на завтра',
          code: ChooseDate.tomorrow,
        },
        {
          name: 'Запись на конкретный день',
          code: ChooseDate.specifiedDay,
        },
        {
          name: 'Отобразить подписчику календарь',
          code: ChooseDate.withoutFilter,
        },
        // {
        //   name: 'Отобразить подписчику диапазон дат',
        //   code: ChooseDate.range,
        // },
        // {
        //   name: 'Отобразить подписчику N дней',
        //   code: ChooseDate.window_day,
        // },
        // {
        //   name: 'Отобразить подписчику N месяцев',
        //   code: ChooseDate.window_month,
        // },
        // {
        //   name: 'Отобразить подписчику N кварталов',
        //   code: ChooseDate.window_quarter,
        // },
        // {
        //   name: 'Отобразить подписчику N лет',
        //   code: ChooseDate.window_year,
        // },
      ]
    }
  }
})
</script>
