import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {CommandGroupNode, CommandType, GraphResult, NodePosition} from "@/api/graph";
import {plainToClass} from 'class-transformer';
import client from "./client";

export class GetForListItem
{
    // @ts-ignore
    uuid: string;
    // @ts-ignore
    name: string;
}

export class GetForListResult
{
    items: GetForListItem[] = [];
}

export async function getForListCalendar(scenarioUuid: string): Promise<GetForListResult>
{
    const response: AxiosResponse = await client.post('/api/v1/calendar/get-for-list', {scenario_uuid: scenarioUuid})
    const responseData = response.data;
    return plainToClass(GetForListResult, responseData);
}

export class GetForCardRecord
{
    // @ts-ignore
    uuid: string;
    // @ts-ignore
    name: string;
    // @ts-ignore
    start_at: string;
    // @ts-ignore
    end_at: string;
}

export class GetForCardResult
{
    items: GetForCardRecord[] = [];
}

export async function getForCardCalendar(calendarUuid: string): Promise<GetForCardResult>
{
    const response: AxiosResponse = await client.post('/api/v1/calendar/get-for-card', {calendar_uuid: calendarUuid})
    const responseData = response.data;
    return plainToClass(GetForCardResult, responseData);
}

export class GetRecordForCard
{
    uuid: string
    started_at: Date
    ended_at: Date
    subscriber: GetRecordForCardSubscriber|null = null
}

export class GetRecordForCardSubscriber
{
    uuid: string
    telegram_username: string
    telegram_link: string|null = null
}

export async function getCalendarRecordForCard(recordCalendarUuid: string): Promise<GetRecordForCard>
{
    return await client
        .post('/api/v1/calendar/' + recordCalendarUuid + '/record/get-for-card')
        .then((response: AxiosResponse) => {
            // Конвертирование тела ответа в DTO
            return plainToClass(GetRecordForCard, response.data);
        })
}

export async function updateCalendarRecord(command: GetRecordForCard): Promise<GetRecordForCard>
{
    return await client
        .post('/api/v1/calendar/' + command.uuid + '/record/update', command)
}
