import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white p-2" }
const _hoisted_2 = {
  key: 0,
  class: "mt-5 d-flex justify-content-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_TriggerForm = _resolveComponent("TriggerForm")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumbs, {
      items: this.breadcrumbs
    }, null, 8, ["items"]),
    _createVNode(_component_TriggerForm, {
      class: "mt-2",
      errors: this.errors,
      modelValue: this.scenarioTrigger,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.scenarioTrigger) = $event))
    }, null, 8, ["errors", "modelValue"]),
    (!this.graphConfigStorage.isSharedTemplate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Button, {
            label: "Сохранить",
            icon: "pi pi-check",
            severity: "contrast",
            class: "rounded",
            loading: this.updateLoading,
            onClick: _cache[1] || (_cache[1] = () => {
            this.updateLoading = true
            this.errors = []

            _ctx.updateScenarioTrigger(this.scenarioTrigger)
              .then(() => {})
              .catch((error) => {
                this.errors = error.getValidationErrors()
              })
              .finally(() => {this.updateLoading = false})
            }
          )
          }, null, 8, ["loading"])
        ]))
      : _createCommentVNode("", true)
  ]))
}