import {Edge, GraphResult} from "./graph";
import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToClass, plainToInstance} from "class-transformer";


export class ProfileCard
{
    name: string
}

export function logout(): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/auth/logout');
}

export function updateProfile(cmd: ProfileCard): Promise<AxiosRequestConfig>
{
    return client.post('/api/v1/auth/update', cmd);
}

export function getProfile(): Promise<ProfileCard>
{
    return client.post('/api/v1/auth/get-profile')
        .then((response: AxiosResponse) => {
            return plainToInstance(ProfileCard, response.data);
        })
}

