import {AxiosResponse} from "axios";
import {plainToClass} from "class-transformer";
import client from './client';

export enum AuthType {
    google = 'google'
}

export class GetLinkResult
{
    // @ts-ignore
    redirect_url: string
}

export async function getAuthLink(authType: AuthType): Promise<GetLinkResult>
{
    return await client
        .post('/api/v1/auth/link', {auth_type: authType})
        .then((response: AxiosResponse) => {
            return plainToClass(GetLinkResult, response.data);
        })
}
