<template>
  <div class="bg-light">
    <p>Команда: <span class="fw-bold text-muted">{{this.commandTitle}}</span></p>

    <HelpInfo>{{this.helpInfo}}</HelpInfo>

    <div class="mt-2">
      <div>
        <InputSwitch
            v-model="this.item.is_enabled"
            class="align-middle me-1"
            @update:model-value="($event) => {
              if ($event === false) {
                this.item.is_visible = false
              }
            }"
        />
        <span class="align-middle">Активность</span>
      </div>

      <div class="mt-1">
        <InputSwitch
            v-model="this.item.is_visible"
            class="align-middle me-1"
        />
        <span class="align-middle">Показывать команду в выпадающем списке команд</span>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {AbstractAdminCommand, BanCommand, KickCommand, ReportCommand, WarnCommand} from "@/api/commandsSettings";
import InputSwitch from "primevue/inputswitch";
import HelpInfo from "@/components/view/HelpInfo.vue";

export default defineComponent({
  name: "AdminCommandItem",
  components: {
    HelpInfo,
    InputSwitch
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<AbstractAdminCommand>,
    }
  },
  data: function () {
    return {
      card: this.item,
    }
  },
  emits: [
      'update:item'
  ],
  watch: {
    item: function (val) {
      this.card = val
    },

    card: function (val) {
      this.$emit('update:item', val)
    },
  },
  computed: {
    commandTitle: function () {
      switch(true) {
        case this.card instanceof KickCommand:
          return '/kick'

        case this.card instanceof BanCommand:
          return '/ban'

        case this.card instanceof WarnCommand:
          return '/warn'

        case this.card instanceof ReportCommand:
          return '/report'

        return 'unknown'
      }
    },

    helpInfo: function () {
      switch(true) {
        case this.card instanceof KickCommand:
          return 'Выгнать пользователя из чата с возможностью вернуться'

        case this.card instanceof BanCommand:
          return 'Заблокировать пользователя в чате'

        case this.card instanceof WarnCommand:
          return 'Вынести предупреждение'

        case this.card instanceof ReportCommand:
          return 'Возможность пожаловаться на сообщение другого пользователя, в случае жалобы сообщение которое было отмечено отправляется в личный диалог всем админам у которых имеется диалог с ботом'

        return 'unknown'
      }
    },
  }
})
</script>