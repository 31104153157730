<template>
  <div>
    <Filter v-model="this.card" :context-options="this.contextOptions" />

    <div>
      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.react_bot"/>
        <span class="m-0 align-middle">Запретить присылать ссылки на ботов</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.react_username"/>
        <span class="m-0 align-middle">Запретить присылать ссылки на профили пользователей</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.react_channel"/>
        <span class="m-0 align-middle">Запретить присылать ссылки на каналы</span>
      </div>

      <div class="mt-1">
        <InputSwitch class="align-middle me-2" v-model="this.card.react_group"/>
        <span class="m-0 align-middle">Запретить присылать ссылки на группы</span>
      </div>

      <Divider/>

      <div class="mt-2">
        <p>Разрешенный ссылки (в формате https://t.me/test, @test, test)</p>

        <WordsList
            :show-word-types="[WordType.fullEquals]"
            :words="this.card.white_list"
            label-add-word-button="Добавить ссылку"
            @words:update="($event) => { this.card.white_list = $event }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {ContextOption, FilterTelegramLink} from "@/api/groupSettings";
import Filter from "@/components/view/GroupComponents/Filter.vue";
import InputSwitch from "primevue/inputswitch";
import WordsList from "@/components/view/GroupComponents/WordsList.vue";
import {WordType} from "@/api/dictionary";
import Divider from "primevue/divider";

export default defineComponent({
  name: "FilterTelegramLink",
  computed: {
    WordType() {
      return WordType
    }
  },
  components: {WordsList, InputSwitch, Filter, Divider},
  props: {
    filter: {
      type: Object as PropType<FilterTelegramLink>,
      required: true,
    },
    contextOptions: {
      type: Array as PropType<ContextOption>,
      required: true,
    }
  },
  data: function () {
    return {
      card: this.filter,
    }
  },
  emits: ['update:filter'],
  watch: {
    card: function (val) {
      this.$emit('update:filter', val)
    },
    
    filter: function (val) {
      this.card = val
    },
  }
})
</script>