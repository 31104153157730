<template>
  <div>
    <div class="content-group m-2">
      <!--   Медиа файлы к ноде   -->
      <FilesViewer v-model="this.modelValueData.files"/>

      <FileUpload
          class="mt-2"
          name="file[]"
          :showUploadButton="false"
          :multiple="true"
          choose-label="Выберите файлы"
          :show-cancel-button="false"
          mode="basic"
          :auto="true"
          :custom-upload="true"
          @uploader="this.handleUploader"
      />

      <div class="mt-2">
        <SearchContext
            :context-options="this.contextOptionsForQuestions"
            @selected-context="($event) => { this.modelValueData.text += $event }"
        />
      </div>

      <RichTextEditor
          class="mt-2"
          v-model="this.modelValueData.text"
      />
    </div>

    <div class="m-2 mt-4 content-group">
      <p class="m-0">Тип вопроса:</p>
      <Dropdown
          :options="this.getTypeOptions()"
          option-label="name"
          option-value="code"
          :model-value="this.modelValueData.answer_type"
          @change="this.onChangeAnswerType"
          scroll-height="500"
      />

      <div class="mt-2">
        <div v-if="this.modelValueData.answer_type === AnswerTypeEnum.Calendar">
          <QuestionCalendar :is-edit-mode="true" :calendar="this.modelValueData.calendar"/>
        </div>

        <div v-if="this.modelValueData.answer_type === AnswerTypeEnum.Buttons">
          <ButtonGroupPanel v-model="this.modelValueData.buttons" :button-types="[ButtonGroupType.link, ButtonGroupType.scenario]"/>
        </div>

        <!--      <QuestionRegexp v-if="this.isShowReferenceInput" :regexp_pattern="this.modelValueData.reference_value" :is-edit-mode="true"/>-->
      </div>
    </div>

    <div v-if="this.modelValueData.answer_type === AnswerTypeEnum.withoutValidation" class="content-group mt-4 m-2">
      <div>
        <Checkbox :binary="true" v-model="this.modelValueData.allow_media_files_in_answer" class="me-2 align-middle"/>
        <span class="align-middle">Разрешать отправлять медиа файлы в ответ</span>
      </div>
    </div>

    <div class="m-2 mt-4 content-group">
      <p class="mt-2 mb-1 p-0">Сохранить ответ в переменную:</p>
      <Dropdown
          v-model="this.modelValueData.save_answer_to_context"
          :options="this.contextOptions"
          option-label="name"
          option-value="uuid"
          scroll-height="500px"
          class="w-50"
      />
    </div>


    <!--   Дополнительные настройки   -->
    <div class="m-2 mt-4">
      <Accordion>
        <AccordionTab header="Расширенные настройки">

          <PreviewOption v-model="this.modelValueData.preview_option"/>

          <div class="mt-1">
            <Checkbox
                :binary="true"
                class="align-middle me-2"
                v-model="this.modelValueData.protected"
            />
            <span class="align-middle">Запретить копировать</span>
          </div>

          <div>
            <Checkbox
                :binary="true"
                class="align-middle me-2"
                v-model="this.modelValueData.delete_after_transition"
            />
            <span class="align-middle">Удалить сообщение после перехода на следующий блок</span>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import AccordionTab from "primevue/accordiontab";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import SelectButton from "primevue/selectbutton";
import Checkbox from "primevue/checkbox";
import Accordion from "primevue/accordion";
import Button from "primevue/button";
import Image from "primevue/image";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import {ChooseDate, QuestionNode, QuestionNodeCalendar} from "@/api/graph";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import SearchDialogMultiple from "@/components/common/SearchDialogMultiple.vue";
import {FileOwner, uploadFile} from "@/api/file";
import FilesViewer from "@/components/common/FilesViewer.vue";
import Dropdown, {DropdownChangeEvent} from "primevue/dropdown";
import {AnswerTypeEnum} from "@/api/questionNode";
import QuestionSettings from "@/components/view/Bots/Question/QuestionSettings.vue";
import QuestionCalendar from "@/components/view/Bots/Question/QuestionCalendar.vue";
import QuestionRegexp from "@/components/view/Bots/Question/QuestionRegexp.vue";
import {ButtonGroupType} from "@/api/buttonGroupPanel";
import SearchContext from "@/components/common/SearchContext.vue";
import PreviewOption from "@/components/common/PreviewOption.vue";

export default defineComponent({
  name: "QuestionNodeEdit",
  computed: {
    ButtonGroupType() {
      return ButtonGroupType
    },
    AnswerTypeEnum() {
      return AnswerTypeEnum
    },
    contextOptions: function () {
      if (this.modelValueData.answer_type === AnswerTypeEnum.Calendar) {
        return []
      }

      return this.contextOptionsForQuestions
    }
  },
  components: {
    PreviewOption,
    SearchContext,
    QuestionRegexp,
    QuestionCalendar,
    QuestionSettings,
    Dropdown,
    FilesViewer,
    SearchDialogMultiple,
    ButtonGroupPanel,
    RichTextEditor,
    Image,
    Button,
    Accordion,
    Checkbox,
    SelectButton,
    FileUpload,
    AccordionTab,
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<QuestionNode>,
    },
  },
  inject: [
      'scenario_uuid',
      'contextOptionsForQuestions',
  ],
  emits: [
    'update:modelValue',
  ],
  data: function () {
    return {
      modelValueData: this.modelValue,
    }
  },
  watch: {
    modelValueData: {
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
    },
    modelValue: function (val) {
      this.modelValueData = val
    }
  },
  methods: {
    handleUploader: function (event: FileUploadUploaderEvent) {
      const files = event.files instanceof Array ? event.files : [event.files]

      const mustSentFiles = files.filter((item: File) => {
        if (item.uuid) {
          return false
        }
        return true
      })

      uploadFile(this.scenario_uuid, FileOwner.constructor, mustSentFiles).then((res) => {
        mustSentFiles.forEach((item: File, index: number) => {
          item.uuid = res.items[index].uuid
          this.modelValueData.files.push(res.items[index])
        })
      })
    },

    getTypeOptions: function () {
      return [
        {
          code: AnswerTypeEnum.withoutValidation,
          name: 'Вопрос с произвольным ответом',
        },
        {
          code: AnswerTypeEnum.Buttons,
          name: 'Вопрос с вариантами кнопок',
        },
        // {
        //   code: AnswerTypeEnum.Regexp,
        //   name: 'Ответ соответствует регулярному выражению',
        // },
        {
          code: AnswerTypeEnum.IsNumber,
          name: 'Ответ пользователя является числом',
        },
        // {
        //   code: AnswerTypeEnum.Calendar,
        //   name: 'Отображение календаря записи на прием',
        // },
      ]
    },

    onChangeAnswerType: function (event: DropdownChangeEvent) {
      const value = event.value as AnswerTypeEnum

      // Обнуляем подобъекты
      this.modelValueData.calendar = null

      switch (value) {
        case AnswerTypeEnum.Calendar:
          this.modelValueData.calendar = new QuestionNodeCalendar(ChooseDate.today)
          break
      }

      this.modelValueData.answer_type = value
    },
  }
})
</script>