<template>
  <div class="ms-2 me-2">
    <DataTable
        :value="this.items"
        dataKey="uuid"
        :paginator="false"
        :rows="10"
        :rowsPerPageOptions="[5,10,25]"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        selection-mode="multiple"
        data-key="uuid"
        v-model:selection="this.selectedItem"
    >
      <template #empty>
        Список пуст
      </template>

      <template #header>
        <div class="row justify-content-between">
          <div class="col-auto d-flex align-items-center">
            <h5 class="m-0">Пригласительные ссылки</h5>
          </div>
          <div class="col-auto">
            <h5 class="fw-bold mt-2">Название группы в Telegram</h5>
          </div>
          <div class="col-auto">
            <Button
                class="rounded"
                label="Добавить ссылку"
                severity="contrast"
                icon="pi pi-plus"
                @click="() => {  }"
            />
          </div>
        </div>
      </template>

      <Column field="name" header="Название"/>
      <Column field="link" header="">
        <template #body="slotProps">
          <Button
              label="https://t.me/+Fnwl3piF3Mw5MmVi"
              severity="info"
              icon="pi pi-telegram"
              class="rounded"
          />
        </template>
      </Column>
      <Column field="" header="Текущее количество подписанных"/>
      <Column field="" header="Среднее время пребывания"/>
      <Column field="" header="Активность подписчиков">
        <template #header>
          <span class="pi pi-question-circle me-2"  v-tooltip="'Количество сообщений/количество подписавшихся'"/>
        </template>
      </Column>

      <Column :exportable="false" style="min-width:8rem">
        <template #body="slotProps">
          <Button icon="pi pi-trash" class="rounded-circle" outlined severity="danger" @click="" />
        </template>
      </Column>
    </DataTable>

    <Divider/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import HelpInfo from "@/components/view/HelpInfo.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import TriggerForm from "@/components/view/Triggers/TriggerForm.vue";
import Tag from "primevue/tag";
import Divider from "primevue/divider";
import {getGroupsForList, GroupsListItemResult} from "@/api/group";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import Chip from "primevue/chip";
import Chart from "primevue/chart";
import Toolbar from "primevue/toolbar";
import Calendar from "primevue/calendar";

export default defineComponent({
  name: "InviteLink",
  components: {
    Calendar, Toolbar,
    Dropdown,
    InputSwitch,
    Textarea,
    InputText,
    DataTable,
    Button,
    Column,
    Dialog,
    TriggerForm,
    HelpInfo,
    Tag,
    Divider,
    Chip,
    Chart,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');


    return {
      items: [] as GroupsListItemResult[],
      selectedItem: [],

      chartDataGraph: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: documentStyle.getPropertyValue('--cyan-500'),
            tension: 0.4
          },
          {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            borderColor: documentStyle.getPropertyValue('--gray-500'),
            tension: 0.4
          }
        ]
      },

      chartOptionsGraph: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder
            }
          },
          y: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder
            }
          }
        }
      },


      chartData: {
        labels: ['A', 'B', 'C'],
        datasets: [
          {
            data: [540, 325, 702],
            backgroundColor: [documentStyle.getPropertyValue('--cyan-500'), documentStyle.getPropertyValue('--orange-500'), documentStyle.getPropertyValue('--gray-500')],
            hoverBackgroundColor: [documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--gray-400')]
          }
        ]
      },
      chartOptions: {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
              color: textColor
            }
          }
        }
      },
    }
  },
  created() {
    getGroupsForList(this.scenario_uuid).then((res) => {
      this.items = res.items
    })
  },
})
</script>