<template>
  <div class="text-center pb-4 node_bg_custom rounded-top" style="width: 500px;">
    <TitleNode
        :node-uuid="this.data.uuid"
        :node-name="this.data.name"
        :node-position="this.data.position"
        :items="this.items"
        color="#3ac44f"
        @pressed-remove="() => { this.$emit('remove', this.data.uuid, true) }"
    />

    <EditableContent
        :is-read-only="this.readOnly"
        :disable-save-button="this.isValidForm"
    >
      <div class="text-start m-2">
        <Dropdown
            v-model="this.data.operation"
            :options="this.getOperationTypes()"
            optionLabel="name"
            option-value="code"
            placeholder="Тип задержки"
            :disabled="this.readOnly"
        />

        <div class="mt-2" v-show="this.isShowSendThroughParams">
          <InputNumber
              placeholder="Введите интервал задержки"
              class="mt-1"
              v-model="this.data.send_through_days"
              :disabled="this.readOnly"
              :invalid="this.isInvalidSendThroughDays"
          />
          <Dropdown
              :options="this.getSendThroughOptions()"
              optionLabel="name"
              option-value="code"
              class="mt-1"
              v-model="this.data.send_through_meter"
              :disabled="this.readOnly"
              :invalid="this.isInvalidSendThroughMeter"
              scroll-height="500px"
          />
<!--          <p>Время отправки:</p>-->
<!--          <TimeInput-->
<!--              v-model="this.data.send_through_time"-->
<!--              :disabled="this.readOnly"-->
<!--              placeholder="В любое"-->
<!--          />-->
        </div>


        <div class="mt-2" v-show="this.isShowSentAtTimeParams">
          <p>Время отправки:</p>
          <TimeInput
              v-model="this.data.send_at_time"
              :invalid="this.isInvalidSendAtTime"
              :disabled="this.readOnly"
              class="w-100"
          />
        </div>

        <div class="mt-2" v-show="this.isShowSpecifiedDateTimeParams">
          <p>Дата:</p>
          <DateInput
            :disabled="this.readOnly"
            v-model="this.data.specified_date"
            :invalid="this.isInvalidSpecifiedDateTime"
          />

          <p>Время отправки:</p>
          <TimeInput
              :disabled="this.readOnly"
              v-model="this.data.specified_time"
              :show-bar="true"
          />
        </div>


        <div class="mt-2" v-show="this.isShowDynamicParams">
          <Dropdown
              v-model="this.data.dynamic_context_uuid"
              :options="this.getContextVariablesDateTimeTypes()"
              optionLabel="name"
              option-value="code"
              placeholder="Переменная"
              class="mt-1"
              :disabled="this.readOnly"
              :invalid="this.isInvalidDynamicContextUuid"
          />

        <!--          todo: computed сетер и гетер -->
          <SelectButton
              v-model="this.data.dynamicOffset"
              class="mt-1"
              :options="['+', '-']"
              :disabled="this.readOnly"
          />
          <InputNumber
              placeholder="Смещение"
              class="mt-1"
              v-model="this.data.dynamic_offset"
              :disabled="this.readOnly"
              :invalid="this.isInvalidDynamicOffset"
          />

          <Dropdown
              v-model="this.data.dynamic_time_unit_enum"
              :options="this.getDynamicUnitOptions()"
              optionLabel="name"
              option-value="code"
              placeholder="Размерность"
              :disabled="this.readOnly"
              :invalid="this.isInvalidDynamicTimeUnitEnum"
          />
        </div>

        <div class="mt-4 mb-2" v-show="this.isShowDaysOfWeek">
          <div class="text-center">
            <div class="d-inline-block me-2">
              <p class="m-0">Пн</p>
              <Checkbox :binary="true" :disabled="true" v-model="this.data.day1"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Вт</p>
              <Checkbox :binary="true" :disabled="true" v-model="this.data.day2"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Ср</p>
              <Checkbox :binary="true" :disabled="true" v-model="this.data.day3"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Чт</p>
              <Checkbox :binary="true" :disabled="true" v-model="this.data.day4"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Пт</p>
              <Checkbox :binary="true" :disabled="true" v-model="this.data.day5"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Сб</p>
              <Checkbox :binary="true" :disabled="true" v-model="this.data.day6"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Вс</p>
              <Checkbox :binary="true" :disabled="true" v-model="this.data.day7"/>
            </div>
          </div>
        </div>
      </div>

      <SourceHandle/>
      <TargetHandle/>
    </EditableContent>
  </div>
</template>

<script lang="ts">

import {Handle, Position} from "@vue-flow/core";
import {defineComponent, PropType} from "vue";
import {DelayBlockNode, DelayNodeType, TimeUnit} from "@/api/graph";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";
import flatPicker from "vue-flatpickr-component";
import SelectButton from "primevue/selectbutton";
import TitleNode from "@/components/view/Bots/TitleNode.vue";
import {updateDelayNode} from "@/api/delayNode";
import {MenuItem} from "primevue/menuitem";
import EditableContent from "@/components/view/Bots/EditableContent.vue";
import TimeInput from "@/components/common/TimeInput.vue";
import TargetHandle from "@/components/view/Bots/TargetHandle.vue";
import SourceHandle from "@/components/view/Bots/SourceHandle.vue";
import DateInput from "@/components/common/DateInput.vue";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

export default defineComponent({
  components: {
    DateInput,
    SourceHandle,
    TargetHandle,
    EditableContent,
    TitleNode,
    flatPicker,
    Handle,
    Dropdown,
    Checkbox,
    InputNumber,
    TimeInput,
    SelectButton,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<DelayBlockNode>,
    },
  },
  emits: [
    'remove',
  ],
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  computed: {
    // Валидация
    isInvalidSendThroughMeter() {
      return this.data.send_through_meter === null
    },
    isInvalidSendThroughDays() {
      return this.data.send_through_days === null
    },

    isInvalidSendAtTime() {
      return this.data.send_at_time === null
    },

    isInvalidDynamicContextUuid() {
      return this.data.dynamic_context_uuid === null
    },

    isInvalidDynamicOffset() {
      return this.data.dynamic_offset === null
    },

    isInvalidDynamicTimeUnitEnum() {
      return this.data.dynamic_time_unit_enum === null
    },

    isInvalidSpecifiedDateTime() {
      return this.data.specified_date === null
    },

    // disable кнопку сохранения
    isValidForm() {
      switch (this.data.operation) {
        case DelayNodeType.send_through_time:
          return this.isInvalidSendThroughDays || this.isInvalidSendThroughMeter

        case DelayNodeType.send_to_at_time:
          return this.isInvalidSendAtTime

        case DelayNodeType.dynamic_interval:
          return this.isInvalidDynamicContextUuid || this.isInvalidDynamicOffset || this.isInvalidDynamicTimeUnitEnum

        case DelayNodeType.specified_datetime:
          return this.isInvalidSpecifiedDateTime
      }

      return false
    },

    // Остальное
    Position() {
      return Position
    },
    isShowSendThroughParams() {
      return this.data.operation == DelayNodeType.send_through_time
    },
    isShowSentAtTimeParams() {
      return this.data.operation == DelayNodeType.send_to_at_time
    },
    isShowSpecifiedDateTimeParams() {
      return this.data.operation == DelayNodeType.specified_datetime
    },
    isShowDynamicParams() {
      return this.data.operation == DelayNodeType.dynamic_interval
    },
    isShowDaysOfWeek() {
      if (this.data.operation === null) {
        return false
      }

      return [DelayNodeType.send_to_at_time, DelayNodeType.send_through_time, DelayNodeType.dynamic_interval].includes(this.data.operation)
    },
    items: function (): MenuItem[] {
      if (this.graphConfigStorage.isSharedTemplate) {
        return []
      }

      let result = [] as MenuItem[]

      if (this.readOnly) {
        result.push({
          label: 'Редактировать',
          icon: 'pi pi-file-edit',
          command: () => {
            this.readOnly = false
          },
        })
      }

      result.push({
        label: 'Удалить',
        icon: 'pi pi-trash',
        command: () => {
          this.$emit('remove', this.data.uuid, true)
        }
      })

      return result
    }
  },
  data: function () {
    return {
      readOnly: true,
      dynamicOffset: '+',
    }
  },
  methods: {
    getOperationTypes: function () {
      return [
        {
          name: 'Определённая дата',
          code: DelayNodeType.specified_datetime,
        },
        {
          name: 'Определённое время',
          code: DelayNodeType.send_to_at_time,
        },
        // {
        //   name: 'Динамический интервал',
        //   code: DelayNodeType.dynamic_interval,
        // },
        {
          name: 'Отправить через',
          code: DelayNodeType.send_through_time,
        }
      ]
    },

    getSendThroughOptions: function () {
      return [
        {
          name: 'Дней',
          code: TimeUnit.day,
        },
        {
          name: 'Часов',
          code: TimeUnit.hour,
        },
        {
          name: 'Минут',
          code: TimeUnit.minute,
        },
        {
          name: 'Секунд',
          code: TimeUnit.second,
        },
      ]
    },

    getDynamicUnitOptions: function () {
      return this.getSendThroughOptions()
    },

    getContextVariablesDateTimeTypes: function () {
      return [
        {
          name: 'Дата доставки заказа',
          code: 'SUM',
        },
        {
          name: 'Дата оформления заказа',
          code: 'some_parameter',
        },
      ]
    },
  },
})

</script>
