<template>
  <div>
    <div class="m-4">
      <div>
        <div class="m-2 d-inline-block align-top"  v-for="(command, index) in this.commands">
          <Card class="shadow-lg custom-border" style="width: 30rem; height: 20rem">
            <template #header>
            </template>
            <template #title>{{command.title}}</template>

            <template #subtitle>{{ command.example }}</template>

            <template #content>
              <p class="m-0" v-if="command.help_info"><span class="pi pi-question-circle me-2"/>{{ command.help_info }}</p>

              <div style="height: 100px" class="mt-4 d-flex justify-content-center">
                <Image
                    :src="command.git_src"
                    preview
                    :image-style="{
                    display: 'block',
                    width: 'auto',
                    height: '100px',
                    maxWidth: '100%',
              }"/>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Card from "primevue/card";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Image from "primevue/image";

export default defineComponent({
  name: "BotCommandList",
  components: {
    Image,
    InputSwitch,
    Card,
    Button,
    InputText,
  },
  data: function () {
    return {
      commands: [
        {
          title: 'Заблокировать пользователя',
          example: 'Ответить на сообщение пользователя которого хотите забанить командой /ban 1d 5h 30m. Либо через /ban @username 1h 3m',
          help_info: 'Без указания времени ограничение навсегда',
          git_src: '/gifs/kick.gif'
        },
        {
          title: 'Разблокировать пользователя',
          example: 'Ответить на сообщение пользователя /unban или через /unban @username',
          git_src: '/gifs/unban.gif'
        },
        {
          title: 'Запретить писать сообщения',
          example: 'Ответить на сообщение /mute 1h или через /mute @username 3h 30m',
          help_info: 'Без указания времени ограничение навсегда',
          git_src: '/gifs/mute.gif'
        },
        {
          title: 'Вернуть возможность писать сообщения',
          example: 'Ответить на сообщение /unmute или через /unmute @username',
          git_src: '/gifs/unmute.gif'
        },
        {
          title: 'Запретить отправлять медиа',
          example: 'Ответить на сообщение /mute_media 1h или через /mute_media @username 3h 30m',
          help_info: 'Без указания времени ограничение навсегда',
          git_src: '/gifs/mute_media.gif'
        },
        {
          title: 'Выгнать пользователя с возможностью вернуться',
          example: 'Ответить на сообщение /kick или через /kick @username',
          git_src: '/gifs/kick.gif'
        },
      ],
    }
  }
})
</script>
