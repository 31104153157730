<template>
  <div>
    <div class="content-group">
      <div>
        <span class="m-0 required">Название публикации</span>
        <span class="pi pi-question-circle ms-2" v-tooltip="'Не отображается в Telegram'"/>
      </div>
      <InputText v-model="this.modelValue.name"/>
      <p class="p-error">{{this.errors['name']}}</p>


      <div class="mt-2">
        <p class="m-0">Предварительный просмотр поста</p>
        <AutoComplete
            :model-value="this.fakeSearchSearchSubscriberPreview"
            @update:model-value="($event) => {
              this.fakeSearchSearchSubscriberPreview = $event
              this.searchSubscriberPreview = null
            }"
            placeholder="Кому отправить пост"
            option-label="name"
            data-key="uuid"
            :force-selection="true"
            :suggestions="this.searchSubscriberSuggestions"
            @item-select="($event) => {
              this.searchSubscriberPreview = $event.value
            }"
            @complete="($event) => {
              searchSubscriberByName($event.query, this.scenario_uuid)
                .then((res: SearchSubscriberRes) => {
                  this.searchSubscriberSuggestions = res.items
                })
          }"
        />

        <Button
            label="Предпросмотр"
            severity="contrast"
            class="rounded ms-2"
            :loading="this.isPreviewSendLoading"
            :disabled="this.searchSubscriberPreview === null"
            @click="this.previewPost"
        />

        <p class="p-error mt-1" v-if="this.errors['telegram_error']">Ошибка telegram: {{this.errors['telegram_error']}}</p>
      </div>

      <p class="mb-0 mt-3 required">Текст поста</p>
      <RichTextEditor v-model:model-value="this.modelValue.text"/>
      <p class="p-error">{{this.errors['text']}}</p>

      <div class="mt-2" v-show="this.modelValue.text.includes('http')">
        <PreviewOption v-model="this.modelValue.preview_option"/>
      </div>

      <div class="mt-2">
        <FilesViewer
            v-model="this.modelValue.files"
        />

        <FileUpload
            name="file[]"
            :showUploadButton="false"
            :multiple="true"
            choose-label="Выберите файлы"
            :show-cancel-button="false"
            mode="basic"
            :auto="true"
            :custom-upload="true"
            @uploader="this.handleUploader"
        />
      </div>

      <HelpInfo v-if="this.modelValue.buttons.length !== 0 && this.modelValue.files.length > 1">
        В публикации одновременно используется отправка более одного файла и кнопок, пост будет опубликован как два отдельных сообщения - это ограничение самого Telegram
      </HelpInfo>

      <div class="mt-2">
        <div>
          <span>Кнопки</span>
          <span class="pi pi-question-circle ms-2" v-tooltip="'Порядок кнопок можно менять перетаскиванием'"/>
        </div>

        <ButtonGroupPanel v-model="this.modelValue.buttons" :button-types="this.buttonTypes"/>
      </div>
    </div>

    <div class="content-group">
      <p class="m-0">Тип публикации</p>
      <Dropdown
          v-model="this.modelValue.type"
          option-label="label"
          option-value="value"
          :options="this.postTypes"
          @update:model-value="($event) => {
            if ($event === PostType.publishToOneOrSeveralChannels) {
              this.modelValue.forward_post_to_dialogs = []
            }
          }"
      />

      <HelpInfo class="mt-2 mb-2 text-muted">Если канал не отображается, напишите что-нибудь в канале, после сообщение можно удалить</HelpInfo>

      <p class="mb-0 mt-2 required">{{this.sentToDialogsLabel}}</p>
      <DialogTagPanel
          v-model="this.modelValue.sent_to_dialogs"
          :dialog-types="[DialogType.channel]"
          :scenario-uuid="this.scenario_uuid"
          :disabled="this.isDisabledSentToDialogs"
      />
      <p class="p-error">{{this.errors['sent_to_dialogs']}}</p>

      <div v-if="this.modelValue.type === PostType.publishToOneAndForwardToSeveralChannels">
        <p class="m-0">В какие каналы переслать оригинальный пост</p>
        <DialogTagPanel
            v-model="this.modelValue.forward_post_to_dialogs"
            :dialog-types="[DialogType.channel]"
            :scenario-uuid="this.scenario_uuid"
        />
        <p class="p-error">{{this.errors['forward_post_to_dialogs']}}</p>
      </div>
    </div>

    <div class="content-group">
      <div>
        <Checkbox
            :binary="true"
            v-model="this.needNotifierSubscribers"
            class="me-2"
            @update:model-value="($event) => {
              if ($event === false) {
                this.modelValue.notification_subscribers.subscribers = []
                this.modelValue.notification_subscribers.text = ''
              }
            }"
        />
        <span>Необходимо уведомить рекламодателей о выходе поста</span>
      </div>

      <div v-if="this.needNotifierSubscribers" class="mt-3">
        <p class="m-0">Выберите пользователей которым необходимо в ЛС отправить уведомление о выходе поста</p>
        <AutoComplete
            v-model="this.fakeSearchNotificationSubscriber"
            :suggestions="this.notificationSubscribersSuggestions"
            @complete="() => {
            searchSubscriberByName(this.fakeSearchNotificationSubscriber, this.scenario_uuid).then((result: SearchSubscriberRes) => {
              const tmp = []
              result.items.forEach((item: SearchSubscriberItem) => {
                const isExists = this.modelValue.notification_subscribers.subscribers.find((findItem: SearchDialogItem) => {
                  return findItem.uuid === item.uuid
                })

                if (!isExists) {
                  tmp.push(item)
                }
              })

              this.notificationSubscribersSuggestions = tmp
            })
          }"
            @item-select="this.fakeSearchNotificationSubscriber = ''; this.modelValue.notification_subscribers.subscribers.push($event.value);"
            option-label="name"
            data-key="uuid"
            dropdown
            :force-selection="true"
            empty-search-message="Не удалось найти пользователя в базе бота"
        />

        <div class="mt-2">
          <div v-for="(subscriber, index) in this.modelValue.notification_subscribers.subscribers" class="rounded bg-black text-white fw-bold d-inline-block me-2 p-2">
            <span>{{subscriber.name}}</span>
            <button @click="() => { this.modelValue.notification_subscribers.subscribers.splice(index, 1) }" class="bg-transparent border-0 text-white pi pi-trash"/>
          </div>
        </div>

        <p class="mb-0 mt-2">Текст уведомления о выходе поста</p>
        <Textarea
            class="w-100"
            v-model="this.modelValue.notification_subscribers.text"
        />
        <p class="p-error mb-0">{{ this.errors['notification_subscribers.text'] }}</p>
      </div>
    </div>


    <div class="content-group">
      <div>
        <Checkbox
            :binary="true"
            v-model="this.modelValue.protect_content"
        />
        <span class="ms-2">Защитить контент от копирования/пересылки</span>
      </div>

      <div class="mt-2">
        <Checkbox
            :binary="true"
            v-model="this.pin"
        />
        <span class="ms-2">Закрепить публикацию</span>
      </div>

      <div class="mt-2" v-if="this.pin">
        <p class="m-0">Открепить через:</p>
        <Dropdown
            v-model="this.pinUnpinType"
            class="mt-2"
            option-label="label"
            option-value="value"
            :options="this.getUnpinConditions()"
        />

        <!--    todo: Дублируется с функционалом удаления поста, вынести в отд.компонент    -->
        <div v-if="this.pinUnpinType === DeleteType.afterDateTime" class="mt-2">

          <p class="m-0 required">Дата и время открепления:</p>
          <Calendar
              v-model="this.modelValue.pin.unpin.after_datetime.datetime"
              show-time
              hour-format="24"
              :date-format="this.getCalendarFormat()"
          />
          <p class="p-error">{{this.errors['pin.unpin.after_datetime.datetime']}}</p>
        </div>

        <div v-if="this.pinUnpinType === DeleteType.afterTime" class="mt-2">

          <p class="m-0 required">Время открепления</p>
          <TimeInput
              v-model="this.modelValue.pin.unpin.after_time.time"
              class=""
          />
          <p class="p-error">{{this.errors['pin.unpin.after_time.time']}}</p>

          <div class="mt-2 ms-1">
            <div class="d-inline-block me-2">
              <p class="m-0">Пн</p>
              <Checkbox :binary="true" v-model="this.modelValue.pin.unpin.after_time.day1"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Вт</p>
              <Checkbox :binary="true" v-model="this.modelValue.pin.unpin.after_time.day2"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Ср</p>
              <Checkbox :binary="true" v-model="this.modelValue.pin.unpin.after_time.day3"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Чт</p>
              <Checkbox :binary="true" v-model="this.modelValue.pin.unpin.after_time.day4"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Пт</p>
              <Checkbox :binary="true" v-model="this.modelValue.pin.unpin.after_time.day5"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Сб</p>
              <Checkbox :binary="true" v-model="this.modelValue.pin.unpin.after_time.day6"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Вс</p>
              <Checkbox :binary="true" v-model="this.modelValue.pin.unpin.after_time.day7"/>
            </div>
          </div>
        </div>

        <div v-if="this.pinUnpinType === DeleteType.afterValue" class="mt-2">

          <p class="m-0 required">Открепить через</p>
          <InputNumber
              v-model="this.modelValue.pin.unpin.after_value.value"
          />

          <Dropdown
              v-model="this.modelValue.pin.unpin.after_value.time_unit"
              class="ms-2"
              option-label="label"
              option-value="value"
              :options="this.getTimeUnits()"
          />

          <p class="p-error">{{this.errors['pin.unpin.after_value.value']}}</p>
        </div>
      </div>
    </div>

    <div class="content-group">
      <Checkbox
          :binary="true"
          v-model="this.modelValue.disable_notification"
      />
      <span class="ms-2">Бесшумная отправка</span>
      <span class="pi pi-question-circle ms-2" v-tooltip="'Отключает звуковое оповещение при публикации поста, полезно если публикация будет производиться в ночное время'"/>

      <div class="mt-4">
        <div class="d-inline-block">
          <p class="m-0">Когда произвести рассылку</p>
          <Dropdown
              v-model="this.publishType"
              option-label="label"
              option-value="value"
              :options="[
              {
                label: 'Одноразовая публикация',
                value: PublishType.oneTime,
              },
              {
                label: 'Ограниченная по датам',
                value: PublishType.range,
              },
              {
                label: 'Зацикленная публикация',
                value: PublishType.iteration,
              },
              {
                label: 'Публикация в рамках очереди',
                value: PublishType.queue,
              },
          ]"
              scroll-height="500px"
          />
        </div>

        <div class="d-inline-block">
          <p class="ms-2 m-0" v-show="this.publishType !== PublishType.queue">Часовой пояс</p>
          <Dropdown
              v-show="this.publishType !== PublishType.queue"
              v-model="this.modelValue.publication.timezone"
              class="ms-2"
              option-label="label"
              option-value="value"
              :options="getTimeZones()"
              scroll-height="500px"
          />
        </div>
      </div>

      <div class="mt-2" v-if="this.publishType === PublishType.oneTime">
        <p class="m-0 required">Дата публикации:</p>

        <Calendar
            v-model="this.modelValue.publication.one_time.date"
            show-time
            hour-format="24"
            :date-format="this.getCalendarFormat()"
        />
        <p class="p-error">{{this.errors['publication.one_time.date']}}</p>
      </div>

      <!--   todo: валидация что нельзя выбрать прошлое время   -->

      <!--  Блок отображается по условию рассылки ограниченной датами -->
      <div class="mt-2" v-if="this.publishType === PublishType.range">
        <p class="m-0 required">Производить публикацию с:</p>
        <Calendar
            show-time
            hour-format="24"
            v-model="this.modelValue.publication.range.start_at"
            :date-format="this.getCalendarFormat()"
        />
        <p class="p-error">{{this.errors['publication.range.start_at']}}</p>

        <p class="mt-2 mb-0">Производить публикацию до:</p>
        <p class="text-muted m-0 align-middle"><span class="pi pi-question-circle me-1 align-middle"/>Не указывайте для бесконечной публикации</p>
        <Calendar
            show-time
            hour-format="24"
            v-model="this.modelValue.publication.range.end_at"
            :date-format="this.getCalendarFormat()"
        />

        <p class="mt-2 mb-0 required">Пауза между публикациями:</p>
        <InputNumber
            v-model="this.modelValue.publication.range.interval_value"
        />
        <Dropdown
            v-model="this.modelValue.publication.range.interval_unit"
            class="ms-2"
            option-label="label"
            option-value="value"
            :options="this.getTimeUnits()"
            scroll-height="500px"
            :date-format="this.getCalendarFormat()"
        />
        <p class="p-error">{{this.errors['publication.range.interval_value']}}</p>
      </div>

      <!--  Блок отображается по условию количества отправлений -->
      <div class="mt-2" v-if="this.publishType === PublishType.iteration">
        <p class="mt-2 mb-0 required">Сколько раз рассылать пост</p>
        <InputNumber
            v-model="this.modelValue.publication.iteration.iteration_count"
        />
        <p class="p-error">{{this.errors['publication.iteration.iteration_count']}}</p>

        <p class="mt-2 mb-0 required">Когда начать производить рассылку</p>
        <Calendar
            show-time
            hour-format="24"
            v-model="this.modelValue.publication.iteration.start_at"
            :date-format="this.getCalendarFormat()"
        />
        <p class="p-error">{{this.errors['publication.iteration.start_at']}}</p>

        <p class="mt-2 mb-0 required">Пауза между постами:</p>
        <InputNumber
            v-model="this.modelValue.publication.iteration.interval_value"
        />
        <Dropdown
            v-model="this.modelValue.publication.iteration.interval_unit"
            class="ms-2"
            option-label="label"
            option-value="value"
            :options="this.getTimeUnits()"
            scroll-height="500px"
        />
        <p class="p-error">{{this.errors['publication.iteration.interval_value']}}</p>
      </div>

      <!--  Блок отображается по условию публикации через очередь -->
      <div class="mt-2" v-if="this.publishType === PublishType.queue">
        <p class="m-0 required">Опубликовать в рамках очереди:</p>
        <p class="p-error m-0">{{this.errors['publication.queue.queue_uuid']}}</p>

        <Dropdown
            v-model="this.modelValue.publication.queue.queue_uuid"
            class="mt-2"
            option-label="name"
            option-value="uuid"
            :options="this.queues"
        />
      </div>
    </div>

    <div class="content-group">
      <div>
        <Checkbox
            :binary="true"
            v-model="this.deletePost"
        />
        <span class="ms-2">Необходимо удалить публикацию</span>
      </div>

      <div v-if="this.deletePost" class="mt-2">
        <Dropdown
            v-model="this.deletePostType"
            class="mt-2"
            option-label="label"
            option-value="value"
            :options="this.getDeleteConditions()"
        />

        <div v-if="this.deletePostType === DeleteType.afterDateTime" class="mt-2">

          <p class="m-0 required">Дата и время удаления:</p>
          <Calendar
              v-model="this.modelValue.delete.after_datetime.datetime"
              show-time
              hour-format="24"
              :date-format="this.getCalendarFormat()"
          />
          <p class="p-error">{{this.errors['delete.after_datetime.datetime']}}</p>
        </div>

        <div v-if="this.deletePostType === DeleteType.afterTime" class="mt-2">

          <p class="m-0 required">Время удаления</p>
          <Calendar
              v-model="this.modelValue.delete.after_time.time"
              time-only
              hour-format="24"
          />
          <p class="p-error">{{this.errors['delete.after_time.time']}}</p>

          <div class="mt-2 ms-1">
            <div class="d-inline-block me-2">
              <p class="m-0">Пн</p>
              <Checkbox :binary="true" v-model="this.modelValue.delete.after_time.day1"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Вт</p>
              <Checkbox :binary="true" v-model="this.modelValue.delete.after_time.day2"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Ср</p>
              <Checkbox :binary="true" v-model="this.modelValue.delete.after_time.day3"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Чт</p>
              <Checkbox :binary="true" v-model="this.modelValue.delete.after_time.day4"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Пт</p>
              <Checkbox :binary="true" v-model="this.modelValue.delete.after_time.day5"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Сб</p>
              <Checkbox :binary="true" v-model="this.modelValue.delete.after_time.day6"/>
            </div>

            <div class="d-inline-block me-2">
              <p class="m-0">Вс</p>
              <Checkbox :binary="true" v-model="this.modelValue.delete.after_time.day7"/>
            </div>
          </div>
        </div>

        <div v-if="this.deletePostType === DeleteType.afterValue" class="mt-2">

          <p class="m-0 required">Удалить через</p>
          <InputNumber
              v-model="this.modelValue.delete.after_value.value"
          />

          <Dropdown
              v-model="this.modelValue.delete.after_value.time_unit"
              class="ms-2"
              option-label="label"
              option-value="value"
              :options="this.getTimeUnits()"
          />
          <p class="p-error">{{this.errors['delete.after_value.value']}}</p>
        </div>

      </div>
    </div>

    <div class="mt-3" style="flex-direction: row-reverse; display: flex">
      <Button
          label="Сохранить"
          severity="contrast"
          class="rounded"
          :loading="this.isLoadingCreatePost"
          @click="() => {
               this.isLoadingCreatePost = true;
               this.errors = []

               const promiseAction = this.modelValue.uuid ?
                 updatePostContent(this.modelValue) :
                 createPostContent(this.modelValue)

               promiseAction
                  .then(() => {
                    this.$emit('saved')
                  })
                  .catch((error: ErrorResponse) => {
                    this.errors = error.getValidationErrors()
                  })
                  .finally(() => {
                    this.isLoadingCreatePost = false
                  })
          }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import SelectButton from "primevue/selectbutton";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import AutoComplete from "primevue/autocomplete";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import draggable from 'vuedraggable'
import {
  CreatePostCmd,
  createPostContent,
  DeletePostAfter,
  deletePostContent,
  DeletePostDateTime,
  DeletePostTime,
  DeletePostValue,
  DeleteType,
  getPostsForList,
  IterationPublication,
  OneTimePublication,
  PinPost,
  PostButton,
  PostButtonsRow,
  PostFormat,
  PostPublication,
  PostsForListResult,
  PostType,
  PublishType,
  QueuePublication,
  RangePublication,
  sendPreviewPost,
  SendPreviewPostCmd,
  UnpinAfter,
  updatePostContent,
} from "@/api/post";
import {
  searchDialogByName,
  SearchDialogItem,
  SearchDialogRes,
  searchSubscriberByName,
  SearchSubscriberItem,
  SearchSubscriberRes
} from "@/api/edge";
import {getTimeZones} from "@/api/scenario";
import {NodeFile, NodeFileType, TimeUnit} from "@/api/graph";
import {DialogType} from "@/api/dialog";
import TimeInput from "@/components/common/TimeInput.vue";
import {ErrorResponse} from "@/api/common";
import Image from "primevue/image";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import {FileOwner, uploadFile} from "@/api/file";
import FilesViewer from "@/components/common/FilesViewer.vue";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import PreviewOption from "@/components/common/PreviewOption.vue";
import HelpInfo from "@/components/view/HelpInfo.vue";
import {ButtonGroupType} from "@/api/buttonGroupPanel";
import {getQueues, QueueGetForListItem} from "@/api/queue";

export default defineComponent({
  name: 'PostForm',
  components: {
    HelpInfo,
    PreviewOption,
    ButtonGroupPanel,
    FilesViewer,
    RichTextEditor,
    Image,
    TimeInput,
    draggable,
    Dropdown,
    InputNumber,
    FileUpload,
    Calendar,
    Checkbox,
    Button,
    DialogTagPanel,
    AutoComplete,
    Textarea,
    InputText,
    SelectButton
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<CreatePostCmd>,
    },
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  emits: [
      'update:modelValue',
      'saved',
  ],
  data: function () {
    return {
      // почему-то не получилось прокинуть через пропсы
      buttonTypes: [ButtonGroupType.link],
      postTypes: [
        {
          label: 'Опубликовать несколько каналов',
          value: PostType.publishToOneOrSeveralChannels,
        },
        {
          label: 'Опубликовать в канале и переслать пост в другие каналы',
          value: PostType.publishToOneAndForwardToSeveralChannels,
        },
      ],

      needNotifierSubscribers: this.modelValue.notification_subscribers.subscribers.length !== 0,
      sentPostToChannels: [],
      notificationSubscribersSuggestions: [] as SearchSubscriberItem[],
      fakeSearchSentPostToDialog: '',
      fakeSearchNotificationSubscriber: '',

      isPreviewSendLoading: false as boolean,
      fakeSearchSearchSubscriberPreview: '',
      searchSubscriberPreview: null as SearchSubscriberItem|null,
      searchSubscriberSuggestions: [] as SearchSubscriberItem[],
      errors: [] as Array<string, string>,
      isLoadingCreatePost: false as boolean,

      queues: [] as QueueGetForListItem[]
    }
  },
  methods: {
    handleUploader: function (event: FileUploadUploaderEvent) {
      const files = event.files instanceof Array ? event.files : [event.files]

      const mustSentFiles = files.filter((item: File) => {
        if (item.uuid) {
          return false
        }
        return true
      })

      uploadFile(this.scenario_uuid, FileOwner.post, mustSentFiles).then((res) => {
        mustSentFiles.forEach((item: File, index: number) => {
          item.uuid = res.items[index].uuid
          this.modelValue.files.push(res.items[index])
        })
      })
    },

    uploadFile,
    updatePostContent,
    // todo: костыль, но почему то в шаблоне не работали условия с enum
    isImage: function (file: NodeFile): boolean {
      return file.type === NodeFileType.image
    },

    isVideo: function (file: NodeFile): boolean {
      return file.type === NodeFileType.video
    },

    isDocument: function (file: NodeFile): boolean {
      return file.type === NodeFileType.document
    },

    isAudio: function (file: NodeFile): boolean {
      return file.type === NodeFileType.audio
    },

    previewPost: function () {
      this.isPreviewSendLoading = true
      const sendPreviewPostCmd = this.modelValue as SendPreviewPostCmd
      sendPreviewPostCmd.subscriber_uuid = this.searchSubscriberPreview.uuid
      this.errors = []

      sendPreviewPost(sendPreviewPostCmd)
          .catch((error: ErrorResponse) => {
            this.errors = error.getValidationErrors()
          })
          .finally(() => {this.isPreviewSendLoading = false})
    },
    sendPreviewPost,
    searchSubscriberByName,
    searchDialogByName,
    deletePostContent,
    getPostsForList,
    getTimeZones,
    createPostContent,
    searchChannels: function () {

    },

    resolveDeleteType: function (object: DeletePostAfter): DeleteType {
      if (object.after_datetime !== null) {
        return DeleteType.afterDateTime
      }

      if (object.after_time !== null) {
        return DeleteType.afterTime
      }

      if (object.after_value !== null) {
        return DeleteType.afterValue
      }

      throw new Error('Обратитесь в тех.поддержку!')
    },

    createDeleteType: function (deleteType: DeleteType): DeletePostAfter {
      switch (deleteType) {
        case DeleteType.afterTime:
          return new DeletePostAfter(null, new DeletePostTime(), null)

        case DeleteType.afterDateTime:
          return new DeletePostAfter(new DeletePostDateTime(), null, null)

        case DeleteType.afterValue:
          return new DeletePostAfter(null, null, new DeletePostValue())
      }

      throw new Error('Обратитесь в тех.поддержку!')
    },

    getTimeUnits: function () {
      return [
        {
          label: 'минут',
          value: TimeUnit.minute,
        },
        {
          label: 'часа',
          value: TimeUnit.hour,
        },
        {
          label: 'дней',
          value: TimeUnit.day,
        },
      ]
    },

    getCalendarFormat: function (): string {
      return 'dd-mm-yy'
    },

    getDeleteConditions: function () {
      return [
        {
          label: 'Удалить в определенную дату',
          value: DeleteType.afterDateTime,
        },
        // {
        //   label: 'Удалить определенное время',
        //   value: DeleteType.afterTime,
        // },
        {
          label: 'Удалить через время',
          value: DeleteType.afterValue,
        },
        // todo: завести такую операцию
        // {
        //   label: 'После выхода следующего в рамках данной рассылки',
        // }
      ]
    },

    getUnpinConditions: function () {
      return [
        {
          label: 'Не откреплять',
          value: DeleteType.none,
        },
        {
          label: 'Открепить в определенную дату',
          value: DeleteType.afterDateTime,
        },
        // {
        //   label: 'Открепить определенное время',
        //   value: DeleteType.afterTime,
        // },
        {
          label: 'Открепить через время',
          value: DeleteType.afterValue,
        }
      ]
    },
  },
  computed: {
    PostType() {
      return PostType
    },
    sentToDialogsLabel: function () {
      if (this.modelValue.type === PostType.publishToOneOrSeveralChannels) {
        return 'Каналы в которые будет производиться публикация'
      }

      return 'Канал в который будет производиться публикация'
    },

    isDisabledSentToDialogs: function () {
      return this.modelValue.type === PostType.publishToOneAndForwardToSeveralChannels && this.modelValue.sent_to_dialogs.length !== 0
    },

    CreatePostCmd() {
      return CreatePostCmd
    },
    ErrorResponse() {
      return ErrorResponse
    },
    NodeFileType() {
      return NodeFileType
    },
    FileUploadUploaderEvent() {
      return FileUploadUploaderEvent
    },
    SendPreviewPost() {
      return SendPreviewPostCmd
    },
    SearchDialogItem() {
      return SearchDialogItem
    },
    SearchSubscriberItem() {
      return SearchSubscriberItem
    },
    PostButtonsRow() {
      return PostButtonsRow
    },
    PostButton() {
      return PostButton
    },
    SearchSubscriberRes() {
      return SearchSubscriberRes
    },
    DialogType() {
      return DialogType
    },
    PostFormat() {
      return PostFormat
    },
    PublishType() {
      return PublishType
    },
    DeleteType() {
      return DeleteType
    },

    DialogType() {
      return DialogType
    },
    SearchDialogRes() {
      return SearchDialogRes
    },
    PostButtons() {
      return PostButtons
    },
    PostButton() {
      return PostButton
    },
    PostButtonsRow() {
      return PostButtonsRow
    },
    PostsForListResult() {
      return PostsForListResult
    },
    DeleteType() {
      return DeleteType
    },
    PostFormat() {
      return PostFormat
    },
    TimeUnit() {
      return TimeUnit
    },
    PublishType() {
      return PublishType
    },
    CreatePostCmd() {
      return CreatePostCmd
    },

    // todo: перенести логику в requestCreatePost
    deletePost: {
      get: function () {
        return this.modelValue.delete !== null
      },
      set: function (newVal) {
        if (newVal === true) {
          this.modelValue.delete = new DeletePostAfter(new DeletePostDateTime(), null, null)
          return
        }

        this.modelValue.delete = null
      },
    },

    deletePostType: {
      get: function (): DeleteType {
        return this.resolveDeleteType(this.modelValue.delete)
      },
      set: function (newVal: DeleteType) {
        this.modelValue.delete = this.createDeleteType(newVal)
      },
    },

    pin: {
      get: function (): boolean {
        return this.modelValue.pin !== null
      },
      set: function (newVal: boolean) {
        if (newVal === true) {
          this.modelValue.pin = new PinPost()
          return
        }

        this.modelValue.pin = null
      },
    },

    pinUnpin: {
      get: function (): boolean {
        return this.modelValue.pin.unpin !== null
      },
      set: function (newVal: boolean) {
        if (newVal === true) {
          this.modelValue.pin.unpin = new UnpinAfter(new DeletePostDateTime(), null, null)
          return
        }

        this.modelValue.pin.unpin = null
      },
    },

    pinUnpinType: {
      get: function (): DeleteType {
        if (this.modelValue.pin.unpin === null) {
          return DeleteType.none
        }

        return this.resolveDeleteType(this.modelValue.pin.unpin)
      },
      set: function (newVal: DeleteType|null) {
        if (newVal === null || newVal == DeleteType.none) {
          this.modelValue.pin.unpin = null
          return
        }

        this.modelValue.pin.unpin = this.createDeleteType(newVal)
      },
    },

    publishType: {
      get: function (): PublishType {
        if (this.modelValue.publication.iteration !== null) {
          return PublishType.iteration
        }

        if (this.modelValue.publication.one_time !== null) {
          return PublishType.oneTime
        }

        if (this.modelValue.publication.range !== null) {
          return PublishType.range
        }

        if (this.modelValue.publication.queue !== null) {
          return PublishType.queue
        }

        throw new Error('Обратитесь в тех.поддержку!')
      },
      set: function (newVal) {
        switch (newVal) {
          case PublishType.iteration:
            this.modelValue.publication = new PostPublication(null, null, new IterationPublication(), null)
            return

          case PublishType.range:
            this.modelValue.publication = new PostPublication(null, new RangePublication(), null, null)
            return

          case PublishType.oneTime:
            this.modelValue.publication = new PostPublication(new OneTimePublication(), null, null, null)
            return

          case PublishType.queue:
            this.modelValue.publication = new PostPublication(null, null, null, new QueuePublication())
            return
        }

        throw new Error('Обратитесь в тех.поддержку!')
      }
    }
  },
  created() {
    getQueues(this.scenario_uuid, null).then((res) => {
      this.queues = res.items
    })
  }
})
</script>

<style scoped>
.image-container {
  position: relative;
  display: inline-block; /* Это может быть изменено в зависимости от вашего дизайна */
}

.image-container img {
  width: 100%; /* Установите ширину по вашему усмотрению */
  height: auto; /* Поддерживает пропорции изображения */
}

.material-icons {
  position: absolute;
  top: 10px; /* Расположение по вертикали */
  right: 10px; /* Расположение по горизонтали */
  color: white; /* Цвет иконки */
  font-size: 24px; /* Размер иконки */
}
</style>