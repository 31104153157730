import axios, {AxiosRequestConfig, name} from "axios";
import client from './client';
import {plainToClass} from "class-transformer";
import {TriggerGroupNode} from "@/api/graph";
import {uuid} from "vue-uuid";
import _default from "chart.js/dist/plugins/plugin.tooltip";
import type = _default.defaults.animations.numbers.type;

export type ContextItem = {
    name: string;
    code: string;
    type_print: string;
    type: ContextType;
    value: any;
    can_remove: boolean
}

export type UpdateContextDto = {
    uuid: string;
    name: string;
    value: any;
}

export enum ContextType {
    String = 'string',
    Integer = 'integer',
}

export class CreateContextDto
{
    name: string = '';
    type: ContextType = ContextType.Integer;
    is_global: boolean = true;
    scenario_uuid: string;
    code: string = '';
    value: string = ''

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }

    public clear(): void
    {
        this.name = ''
        this.type = ContextType.Integer
        this.is_global = true
        this.code = ''
    }
}

export class ContextListResult
{
    // @ts-ignore
    global_system_variables: ContextItem[];
    // @ts-ignore
    global_custom_variables: ContextItem[];
    // @ts-ignore
    person_system_variables: ContextItem[];
    // @ts-ignore
    person_custom_variables: ContextItem[];
}

export function fetchContextList(scenarioUuid: string): Promise<ContextListResult> {
    return client
        .post('/api/v1/context/get-for-list', {scenario_uuid: scenarioUuid})
        .then((response: AxiosRequestConfig) => {
            const responseData = response.data;
            return plainToClass(ContextListResult, responseData);
        })
}

export function removeContext(uuid: string): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/context/remove', {uuid: uuid})
}

export class ContextListForBuilderRes
{
    global_system_variables: ContextItemForBuilder[] = [];
    global_custom_variables: ContextItemForBuilder[] = [];
    person_system_variables: ContextItemForBuilder[] = [];
    person_custom_variables: ContextItemForBuilder[] = [];
}

export class ContextItemForBuilder
{
    name: string
    uuid: string;
    type: ContextType;
    code: string

    constructor(name: string, uuid: string, type: ContextType, code: string) {
        this.name = name;
        this.uuid = uuid;
        this.type = type;
        this.code = code;
    }
}

export function fetchContextListForBuilder(scenarioUuid: string): Promise<ContextListForBuilderRes>
{
    // @ts-ignore
    return client
        .get('/api/v1/edge/' + scenarioUuid + '/get-context')
        .then((response: AxiosRequestConfig) => {
            const responseData = response.data;
            return plainToClass(ContextListForBuilderRes, responseData);
        })
}

export function updateContext(command: UpdateContextDto): Promise<AxiosRequestConfig> {
    return client.post('/api/v1/context/update', command)
}

export function createContext(command: CreateContextDto): Promise<AxiosRequestConfig> {
    return client.post('/api/v1/context/create', command)
}

export function getContextTypes() {
    return [
        {
            code: ContextType.Integer,
            name: 'Число',
        },
        {
            code: ContextType.String,
            name: 'Строки',
        }
    ]
}
