<template>
  <AutoComplete
      :disabled="this.disabled"
      :hidden="this.hidden"
      v-model="this.fakeSearch"
      @update:model-value="($event) => {
        if ($event instanceof SearchDialogItem) {
           this.$emit('update:modelValue', $event)
        } else {
            this.$emit('update:modelValue', null)
        }
      }"
      optionLabel="name"
      data-key="uuid"
      :suggestions="this.dialogOptions.dialogs"
      :placeholder="this.placeholder"
      @complete="this.searchOptions"
      :loading="this.isLoading"
      :dropdown="this.dropdown"
      :force-selection="true"
  >
    <template #option="slotProps">
      <div class="d-flex justify-content-between">
        <div>
          <img :alt="slotProps.option.name" v-if="slotProps.option.icon_image" :src="slotProps.option.icon_image" class="me-2 rounded-circle align-middle" style="height: 30px;"/>
          <span class="align-middle">{{ slotProps.option.name }}</span>
        </div>
        <div>
          <p class="align-middle">{{ slotProps.option.type_print }}</p>
        </div>
      </div>
    </template>
  </AutoComplete>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import AutoComplete, {AutoCompleteCompleteEvent} from "primevue/autocomplete";
import {searchDialogByName, SearchDialogItem, SearchDialogRes} from "@/api/edge";
import {DialogType} from "@/api/dialog";

export default defineComponent({
  name: "SearchDialog",
  computed: {
    SearchDialogItem() {
      return SearchDialogItem
    }
  },
  components: {
    AutoComplete
  },
  props: {
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    hidden: {
      required: false,
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: null,
      type: String,
    },
    // todo: в будущем затипизировать
    modelValue: {
    },
    dropdown: {
      required: false,
      default: true,
      type: Boolean,
    },
    showPrivateDialogs: {
      required: true,
      type: Boolean,
    }
  },
  emits: [
    'update:modelValue',
  ],
  inject: [
      'scenario_uuid'
  ],
  data: function () {
    return {
      isLoading: false,
      dialogOptions: new SearchDialogRes,
      fakeSearch: this.modelValue,
    }
  },
  watch: {
    modelValue: function (val) {
      this.fakeSearch = val
    }
  },
  methods: {
    searchOptions: function (event: AutoCompleteCompleteEvent) {
      this.isLoading = true

      const filterDialogTypes = [DialogType.channel, DialogType.group];
      if (this.showPrivateDialogs) {
        filterDialogTypes.push(DialogType.private)
      }

      searchDialogByName(event.query, this.scenario_uuid, filterDialogTypes).then((result: SearchDialogRes) => {
        this.dialogOptions = result
        this.isLoading = false
      })
    },
  }
})
</script>