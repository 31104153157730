import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    disabled: this.disabled,
    hidden: this.hidden,
    modelValue: this.data,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.data) = $event)),
    optionLabel: "name",
    "data-key": "uuid",
    suggestions: this.dialogOptions.dialogs,
    placeholder: this.placeholder,
    onComplete: this.searchOptions,
    loading: this.isLoading,
    dropdown: this.dropdown,
    multiple: ""
  }, null, 8, ["disabled", "hidden", "modelValue", "suggestions", "placeholder", "onComplete", "loading", "dropdown"]))
}