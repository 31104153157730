<template>
  <BaseTemplate
      :dialog_uuid="this.dialog_uuid"
      :scenario_uuid="this.scenario_uuid"
      :type="TriggerWelcomeType.welcome"
  />
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import draggable from "vuedraggable";
import Image from "primevue/image";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import Divider from "primevue/divider";
import Menu from "primevue/menu";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import FilesViewer from "@/components/common/FilesViewer.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Panel from "primevue/panel";
import BaseTemplate from "@/components/view/GroupComponents/WelcomeGoodbyeTrigger/WelcomeForm.vue";
import {TriggerWelcomeType} from "@/api/welcomeTrigger";

export default defineComponent({
  name: "WelcomeCard",
  computed: {
    TriggerWelcomeType() {
      return TriggerWelcomeType
    }
  },
  components: {
    BaseTemplate,
    Panel,
    FilesViewer,
    ButtonGroupPanel,
    Menu,
    Divider,
    FileUpload,
    Image,
    draggable,
    RichTextEditor,
    InputSwitch,
    Dropdown,
    InputText,
    Button,
    AutoComplete,
    Accordion,
    AccordionTab,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
})
</script>