<template>
  <div class="custom-border rounded">
    <div class="p-2">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb m-1">
          <li class="breadcrumb-item" v-for="(item, index) in this.breadcrumbItems">
            <span :class="item.icon" class="me-1 align-middle"/>
            <router-link
                :to="{name: item.route, params: item.route_params}"
                class="text-decoration-none text-muted align-middle"
                :class="{'fw-bold': (this.breadcrumbItems.length - 1) === index}">{{item.label}}
            </router-link>
          </li>
          <li v-if="this.isLoading">
            <span class="pi pi-spin pi-spinner align-middle ms-2"/>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Breadcrumb from "primevue/breadcrumb";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

export default defineComponent({
  name: "Breadcrumbs",
  components: {
    Breadcrumb,
  },
  props: {
    items: {
      required: true,
      type: Array as PropType<BreadcrumbItem[]>,
    },
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()

    return {graphConfigStorage}
  },
  data: function () {
    return {
      home: new BreadcrumbItem(this.graphConfigStorage.getName, 'bx bx-bot', 'about_bot', null),
    }
  },
  computed: {
    breadcrumbItems: function () {
      return [new BreadcrumbItem(this.graphConfigStorage.getName, 'bx bx-bot', 'about_bot', null)].concat(this.items)
    },

    isLoading: function () {
      return this.items.length === 0
    }
  }
})
</script>