<template>
  <div>
    <h5 class="fw-bold d-inline-block">Условие</h5>

    <p class="text-break">
      Данный блок чем-то схож с <a href="#start-trigger">условием старта сценария</a>, но имеет иное предназначение.
      Если условие старта срабатывает всегда при получении новых данных, то условие срабатывает в конкретном участке сценария.
    </p>

    <p class="text-break">
      Условие предназначено для модификации поведения бота в зависимости от других параметров (переменных, наличия/отсутствия тега, времени/даты)
      Если условие было выполненною, то исполнение перейдет по ветке "верно", иначе по "не верно".
    </p>

    <Image
        src="/trigger.png"
        alt="Блок условия"
        height="300"
        preview
        class="m-1"
        :image-style="{
                objectFit: 'contain',
                width: '100px',
                height: '100px',
                }"
    />
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Divider from "primevue/divider";

export default defineComponent({
  name: "DocsBuilderTrigger",
  components: {
    Divider,
    Image,
  },
  created() {

  },

})
</script>