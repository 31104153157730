<template>
  <div class="m-2 pb-4">
    <Breadcrumbs :items="this.breadcrumbs"/>

    <div>
      <h5 class="fw-bold mt-2 ms-2">Периодические сообщения</h5>
    </div>

    <HelpInfo>
      Используются для публикации сообщений с указанным периодом, например правил сообщества.
    </HelpInfo>

    <div class="mt-4 custom-border content-group">
      <div>
        <InputSwitch class="align-middle me-2" v-model="this.card.active"/>
        <span class="align-middle">Активность</span>
      </div>

      <div class="mt-2">
        <InputSwitch class="align-middle me-2" v-model="this.canSendAnyTime"/>
        <span class="align-middle">Публиковать в любое время</span>
      </div>

      <div class="mt-2" v-if="!this.canSendAnyTime">
        <div class="d-inline-block me-4">
          <p class="m-0">Можно публиковать с</p>
          <TimeInput v-model="this.card.filter_time.start_at" class=""/>
        </div>

        <div class="d-inline-block">
          <p class="m-0">Можно публиковать до</p>
          <TimeInput v-model="this.card.filter_time.finish_at" class=""/>
        </div>
      </div>
    </div>

    <div class="mt-4 custom-border content-group">

      <div class="d-flex justify-content-between">
        <div class="mt-2">
          <button class="pi pi-arrow-left bg-transparent border-0" :disabled="this.currentTextVariant === 0" @click="this.currentTextVariant -= 1"/>
          <span class="ms-1 me-1">{{this.currentTextVariant + 1}} из {{this.card.texts.length}}</span>
          <button class="pi pi-arrow-right bg-transparent border-0" :disabled="this.currentTextVariant === (this.card.texts.length - 1)" @click="this.currentTextVariant += 1"/>
        </div>

        <div>
          <Button
              label="Добавить вариант"
              class="rounded me-2"
              icon="pi pi-plus"
              severity="contrast"
              @click="() => {
                this.card.texts.push(new PeriodicMessageText())
                this.currentTextVariant = (this.card.texts.length - 1)
              }"
          />

          <Button
              class="rounded"
              icon="pi pi-trash"
              severity="danger"
              :disabled="this.card.texts.length === 1"
              @click="() => {
                this.card.texts.splice(this.currentTextVariant, 1)
                this.currentTextVariant -= 1
              }"
          />
        </div>
      </div>

      <p class="required mt-3 mb-0">Текст который будет периодически публиковаться</p>
      <RichTextEditor
          v-model="this.card.texts[this.currentTextVariant].text"
          ref="editor"
          class="mt-2"
          placeholder="Текст сообщения которое будет периодически публиковаться"
      />
      <p class="p-error">{{ this.errors['text'] }}</p>

      <ButtonGroupPanel v-model="this.card.texts[this.currentTextVariant].buttons" :button-types="[ButtonGroupType.link]"/>

      <FilesViewer v-model="this.card.texts[this.currentTextVariant].files"/>

      <FileUpload
          class="mt-2"
          name="file[]"
          :showUploadButton="false"
          :multiple="true"
          choose-label="Выберите файлы"
          :show-cancel-button="false"
          mode="basic"
          :auto="true"
          :custom-upload="true"
          @uploader="this.handleUploader"
      />

      <Panel header="Расширенные настройки" class="mt-4 mb-2" :collapsed="true" toggleable :pt="{ header: { style: {justifyContent: 'start'} } }">
        <PreviewOption v-model="this.card.preview_option"/>

        <div class="mt-2">
<!--          <div class="mt-2">-->
<!--            <InputSwitch class="align-middle me-2"/>-->
<!--            <span class="align-middle">Удалять сообщение через</span>-->
<!--          </div>-->

<!--          <div class="mt-2">-->
<!--            <InputSwitch class="align-middle me-2"/>-->
<!--            <span class="align-middle">Закрепить сообщение</span>-->
<!--          </div>-->

          <div class="mt-2">
            <InputSwitch class="align-middle me-2" v-model="this.card.protect_content"/>
            <span class="align-middle">Защитить контент от копирования/пересылки</span>
          </div>

          <div class="mt-2">
            <InputSwitch class="align-middle me-2" v-model="this.card.remove_prev_message"/>
            <span class="align-middle">Удалять предыдущее сообщение</span>
          </div>

          <div class="mt-2">
            <InputSwitch class="align-middle me-2" v-model="this.card.disable_notification"/>
            <span class="align-middle">Бесшумная отправка</span>
          </div>
        </div>
      </Panel>
    </div>

    <div class="mt-4 custom-border content-group">
      <p class="required m-0">Период публикации</p>
      <div>
        <InputNumber
            placeholder="Введите период публикации"
            class="mt-1 me-2"
            v-model="this.card.period.interval_value"
        />
        <Dropdown
            v-model="this.card.period.interval_unit"
            :options="this.getSendThroughOptions()"
            optionLabel="name"
            option-value="code"
            class="mt-1"
        />
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-end">
      <Button
          label="Сохранить"
          severity="contrast"
          class="rounded"
          :loading="this.updateLoading"
          @click="() => {
          this.updateLoading = true
          this.errors = []

          updatePeriodicMessage(this.card)
            .catch((error: ErrorResponse) => {
              this.errors = error.getValidationErrors()
            })
            .finally(() => {
              this.updateLoading = false
            });
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import {TimeUnit} from "@/api/graph";
import HelpInfo from "@/components/view/HelpInfo.vue";
import TimeInput from "@/components/common/TimeInput.vue";
import {
  FilterTime,
  getPeriodicMessage,
  PeriodicMessageText,
  updatePeriodicMessage,
  UpdatePeriodicMessageCmd
} from "@/api/periodicMessage";
import {ErrorResponse} from "@/api/common";
import PreviewOption from "@/components/common/PreviewOption.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import {ButtonGroupType} from "@/api/buttonGroupPanel";
import Panel from "primevue/panel";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import FilesViewer from "@/components/common/FilesViewer.vue";
import {FileOwner, uploadFile} from "@/api/file";

export default defineComponent({
  name: "PeriodicMessages",
  components: {
    FilesViewer,
    FileUpload,
    ButtonGroupPanel,
    Breadcrumbs,
    PreviewOption,
    TimeInput,
    HelpInfo,
    Dropdown, InputNumber,
    RichTextEditor,
    InputSwitch,
    Button,
    AutoComplete,
    Panel,
  },
  props: {
    dialog_uuid: {
      required: true,
      type: String,
    },
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      errors: [],
      card: new UpdatePeriodicMessageCmd(this.dialog_uuid),
      updateLoading: false,
      breadcrumbs: [],
      currentTextVariant: 0 as number,
    }
  },
  methods: {
    updatePeriodicMessage,
    getSendThroughOptions: function () {
      return [
        {
          name: 'Дней',
          code: TimeUnit.day,
        },
        {
          name: 'Часов',
          code: TimeUnit.hour,
        },
        {
          name: 'Минут',
          code: TimeUnit.minute,
        },
      ]
    },

    handleUploader: function (event: FileUploadUploaderEvent) {
      const files = event.files instanceof Array ? event.files : [event.files]

      const mustSentFiles = files.filter((item: File) => {
        if (item.uuid) {
          return false
        }
        return true
      })

      uploadFile(this.scenario_uuid, FileOwner.messagePeriodic, mustSentFiles).then((res) => {
        mustSentFiles.forEach((item: File, index: number) => {
          item.uuid = res.items[index].uuid
          this.card.texts[this.currentTextVariant].files.push(res.items[index])
        })
      })
    },
  },
  computed: {
    PeriodicMessageText() {
      return PeriodicMessageText
    },
    ButtonGroupType() {
      return ButtonGroupType
    },
    ErrorResponse() {
      return ErrorResponse
    },
    canSendAnyTime: {
      set: function (value) {
        if (value) {
          this.card.filter_time = null
          return
        }

        this.card.filter_time = new FilterTime()
      },
      get: function () {
        return this.card.filter_time === null
      }
    }
  },
  created() {
    getPeriodicMessage(this.dialog_uuid).then(res => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Периодические сообщения'),
      ]
    })
  }
})
</script>