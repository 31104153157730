import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mt-2 mb-2" }
const _hoisted_2 = { class: "mt-2 me-2 rounded d-flex" }
const _hoisted_3 = { class: "custom-border flex-grow-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  controls: "",
  style: {"height":"300px","max-width":"100%"}
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 2 }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "pi pi-element bg-info text-white p-4 rounded m-0" }
const _hoisted_11 = { class: "ms-2 fw-bold" }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable, {
      modelValue: this.modelValueData,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.modelValueData) = $event)),
      class: "d-flex flex-wrap",
      "item-key": "uuid"
    }, {
      item: _withCtx(({element, index}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (this.isImage(element))
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_Image, {
                    src: element.url,
                    preview: "",
                    height: "300",
                    "image-style": {
                      objectFit: 'contain',
                      maxWidth: '100%',
                  }
                  }, null, 8, ["src"])
                ]))
              : _createCommentVNode("", true),
            (this.isVideo(element))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("video", _hoisted_6, [
                    _createElementVNode("source", {
                      src: element.url
                    }, null, 8, _hoisted_7)
                  ])
                ]))
              : _createCommentVNode("", true),
            (this.isDocument(element))
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("a", {
                    href: element.url
                  }, [
                    _createElementVNode("p", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(element.name), 1)
                    ])
                  ], 8, _hoisted_9)
                ]))
              : _createCommentVNode("", true),
            (this.isAudio(element))
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("audio", {
                    controls: "",
                    src: element.url
                  }, null, 8, _hoisted_13)
                ]))
              : _createCommentVNode("", true)
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("button", {
              type: "button",
              class: "pi pi-trash p-1 bg-transparent m-0 rounded border-0 text-danger",
              onClick: () => { this.modelValueData.splice(index, 1) }
            }, null, 8, _hoisted_14)
          ], 512), [
            [_vShow, this.showRemoveButton]
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}