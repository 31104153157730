<template>
 <div class="m-2">
   <HelpInfo route-doc="doc_dictionary" class="mt-3">
     Документация по справочникам
   </HelpInfo>

   <DataTable
       :value="this.dictionaryList.items"
       dataKey="uuid"
       :paginator="false"
       :rows="10"
       v-model:selection="this.selectedDictionaries"
       :rowsPerPageOptions="[5,10,25]"
       class="mt-4 custom-border"
       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
   >
     <template #header>
       <div class="row justify-content-between">
         <div class="col-auto d-flex align-items-center">
           <h5 class="m-0">Список справочников</h5>
         </div>
         <div class="col-auto">
           <Button
               label="Удалить"
               icon="pi pi-trash"
               severity="danger"
               class="rounded me-2"
               :loading="this.removeIsLoading"
               v-if="this.selectedDictionaries.length > 0"
               @click="this.removeSelectedDictionaries"
           />
           <Button
               label="Добавить справочник"
               icon="pi pi-plus"
               severity="contrast"
               class="me-2 rounded"
               @click="this.isShowCreateDictionary = true"
               :disabled="this.dictionaryList.items.length >= 2"
           />
         </div>
       </div>
     </template>

     <Column selectionMode="multiple" style="width: 3rem" :exportable="false"/>
     <Column field="name" header="Название" style="min-width:12rem"/>
     <Column field="words_counter" header="Количество слов" style="min-width:12rem"/>

     <Column :exportable="false" style="min-width:8rem">
       <template #body="slotProps">
         <div class="d-flex justify-content-end">
           <router-link :to="{name: 'dictionary_for_card', params: {scenario_uuid: this.scenario_uuid, uuid: slotProps.data.uuid}}">
             <Button icon="pi pi-pencil" label="Открыть" severity="contrast" outlined class="me-2 rounded"/>
           </router-link>
         </div>
       </template>
     </Column>
   </DataTable>

   <Dialog v-model:visible="this.isShowCreateDictionary" header="Создание справочника" modal :draggable="false" class="w-75" :contentStyle="{ height: '70vh' }" @after-hide="this.createDictionaryCmd = new CreateDictionaryCmd(this.scenario_uuid)">
     <DictionaryForm
         v-model:model-value="this.createDictionaryCmd"
         :scenario-uuid="this.scenario_uuid"
         @saved="() => {
           this.renderList().then(() => {
             this.isShowCreateDictionary = false
           })
         }"
     />
   </Dialog>
 </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Toolbar from "primevue/toolbar";
import FileUpload from "primevue/fileupload";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import {
  CreateDictionaryCmd, createWordDictionary, GetDictionaryForListItem,
  GetDictionaryForListResult,
  getWordDictionaryForList, removeWordDictionaries,
} from "@/api/dictionary";
import {ErrorResponse} from "@/api/common";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import DictionaryForm from "@/components/view/Dictionaries/DictionaryForm.vue";
import HelpInfo from "@/components/view/HelpInfo.vue";

export default defineComponent({
  name: "DictionariesForList",
  methods: {
    getWordDictionaryForList,
    createWordDictionary
  },
  computed: {
    CreateDictionaryCmd() {
      return CreateDictionaryCmd
    },
    ErrorResponse() {
      return ErrorResponse
    },
  },
  components: {
    HelpInfo,
    DictionaryForm,
    Dropdown,
    Dialog,
    DataTable,
    Column,
    Button,
    Toolbar,
    FileUpload,
    InputText,
    Divider,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      isShowCreateDictionary: false,
      createDictionaryCmd: new CreateDictionaryCmd(this.scenario_uuid),
      dictionaryList: new GetDictionaryForListResult(),
      removeIsLoading: false,
      errors: [] as Array<string, string>,
      selectedDictionaries: [] as Array<GetDictionaryForListItem>,
    }
  },
  created() {
    this.renderList()
  },
  methods: {
    renderList: function () {
      return getWordDictionaryForList(this.scenario_uuid)
          .then((res: GetDictionaryForListResult) => {
            this.dictionaryList = res
          })
    },

    removeSelectedDictionaries: function () {
      const uuids = this.selectedDictionaries.map((item: GetDictionaryForListItem) => {
        return item.uuid
      })

      this.removeIsLoading = true
      removeWordDictionaries(uuids)
          .then(() => {
            this.selectedDictionaries = []
            this.renderList()
          })
          .finally(() => {
            this.removeIsLoading = false
          });
    },
  }
})
</script>