<template>
  <div>
    <slot></slot>
    <div v-show="!this.isReadOnly" class="mt-2">

      <Button
          class="rounded"
          severity="success"
          @click="$emit('node-save')"
          :disabled="this.disableSaveButton"
          label="Сохранить"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Button from "primevue/button";

export default defineComponent({
  name: 'EditableContent',
  emits: [
    'node-save',
  ],
  props: {
    isReadOnly: {
      required: true,
      type: Boolean,
    },
    disableSaveButton: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  components: {
    Button,
  },
})
</script>
