import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToInstance, Type} from "class-transformer";
import {CommandType, NodeFile, TimeUnit} from "./graph";
import {ButtonRow} from "./buttonGroupPanel";
import {PreviewLinkOption} from "./post";
import {Period} from "./periodicMessage";
import {SearchDialogItem} from "./edge";
import {
    FilterAction,
    FilterActionBanSubscriber,
    FilterActionKickSubscriber,
    FilterActionMuteSubscriber, FilterActionNothingDo
} from "./abuseFilter";

export enum TriggerWelcomeType
{
    welcome = 'welcome',
    goodbye = 'goodbye',
}

export abstract class AbstractUpdateTrigger
{
    dialog_uuid: string
    text: string = ''
    active: boolean = false

    @Type(() => NodeFile)
    files: NodeFile[] = []

    @Type(() => ButtonRow)
    buttons: ButtonRow[] = []

    @Type(() => Period)
    remove_after_period: Period|null = null
    remove_prev_message: boolean = false

    @Type(() => PreviewLinkOption)
    preview_option: PreviewLinkOption = PreviewLinkOption.belowText

    disable_notification: boolean = false
    protect_content: boolean = false

    protected constructor(dialog_uuid: string) {
        this.dialog_uuid = dialog_uuid;
    }
}

export abstract class AbstractCardTrigger extends AbstractUpdateTrigger
{
    name: string
    link_to_telegram: string|null = null

    protected constructor(dialog_uuid: string) {
        super(dialog_uuid)
    }
}

export enum CapthaType
{
    chooseTextFromPicture = 'choose_text_from_picture',
    buttonIAmNotBot = 'button_i_am_not_bot',
    sendAnything = 'send_anything',
    selectOneRightButton = 'select_one_right_button',
    subscribedOnChannel = 'subscribed_on_channel',
}

export abstract class AbstractCaptcha
{
    active: boolean = false
    text: string

    abstract type: CapthaType

    @Type(() => PreviewLinkOption)
    preview_option: PreviewLinkOption = PreviewLinkOption.belowText

    @Type(() => Period)
    time_to_answer: Period = new Period(5, TimeUnit.minute)

    @Type(() => {}, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: FilterActionBanSubscriber, name: CommandType.banSubscriber },
                { value: FilterActionKickSubscriber, name: CommandType.kickSubscriber },
                { value: FilterActionMuteSubscriber, name: CommandType.muteSubscriber },
                { value: FilterActionNothingDo, name: CommandType.nothingDo },
            ]
        }
    })
    action: FilterAction = new FilterActionKickSubscriber()


    constructor(type: CapthaType, text: string) {
        this.type = type;
        this.text = text
    }
}

export class CaptchaChooseTextFromPicture extends AbstractCaptcha
{
    constructor(text: string) {
        super(CapthaType.chooseTextFromPicture, text);
    }
}

export class CaptchaButtonIAmNotBot extends AbstractCaptcha
{
    constructor(text: string) {
        super(CapthaType.buttonIAmNotBot, text);
    }
}

export class CaptchaSelectOneRightButton extends AbstractCaptcha
{
    //  todo: завести новый тип кнопок (простая)
    buttons: []

    constructor(text: string) {
        super(CapthaType.selectOneRightButton, text);
    }
}

export class CaptchaSendAnything extends AbstractCaptcha
{
    constructor(text: string) {
        super(CapthaType.sendAnything, text);
    }
}

export class CaptchaSubscribedOnChannel extends AbstractCaptcha
{
    channels: SearchDialogItem[] = []

    constructor(text: string) {
        super(CapthaType.subscribedOnChannel, text);
    }
}

export class UpdateWelcomeTrigger extends AbstractUpdateTrigger
{
    @Type(() => {}, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: CaptchaChooseTextFromPicture, name: CapthaType.chooseTextFromPicture },
                { value: CaptchaButtonIAmNotBot, name: CapthaType.buttonIAmNotBot },
                { value: CaptchaSelectOneRightButton, name: CapthaType.selectOneRightButton },
                { value: CaptchaSubscribedOnChannel, name: CapthaType.subscribedOnChannel },
                { value: CaptchaSendAnything, name: CapthaType.sendAnything },
            ]
        }
    })
    captcha: AbstractCaptcha
}

export class WelcomeTriggerGetForCard extends UpdateWelcomeTrigger
{

    constructor(dialog_uuid: string) {
        super(dialog_uuid);

        this.captcha = new CaptchaButtonIAmNotBot()
    }
}

export class UpdateGoodbyeTrigger extends AbstractUpdateTrigger
{

}

export class GoodbyeTriggerGetForCard extends UpdateGoodbyeTrigger
{

}

export async function welcomeTriggerGetForCard(dialogUuid: string): Promise<WelcomeTriggerGetForCard>
{
    return await client
        .post('/api/v1/trigger/welcome/get-for-card', {dialog_uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(WelcomeTriggerGetForCard, response.data);
        })
}

export async function updateWelcomeTrigger(cmd: UpdateWelcomeTrigger): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/trigger/welcome/update', cmd);
}



export async function goodbyeTriggerGetForCard(dialogUuid: string): Promise<GoodbyeTriggerGetForCard>
{
    return await client
        .post('/api/v1/trigger/goodbye/get-for-card', {dialog_uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(GoodbyeTriggerGetForCard, response.data);
        })
}

export async function updateGoodbyeTrigger(cmd: UpdateGoodbyeTrigger): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/trigger/goodbye/update', cmd);
}