import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToClass, plainToInstance} from "class-transformer";
import {TagForList} from "./tags";
import {UpdateScenarioTriggerCmd} from "./scenarioTrigger";

export class GroupsListItemResult
{
    uuid: string
    name: string
    icon_image: string|null = null
    link_to_telegram: string|null = null
    triggers_count: number
}

export class GroupsListResult
{
    items: GroupsListItemResult[] = []
}

export async function getGroupsForList(scenarioUuid: string): Promise<GroupsListResult>
{
    return await client
        .post('/api/v1/dialog/groups/get-for-list', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(GroupsListResult, response.data);
        })
}

export class GroupForCard
{
    uuid: string
    name: string
    triggers: UpdateScenarioTriggerCmd[] = []
}

export async function getGroupsForCard(dialogUuid: string): Promise<GroupForCard>
{
    return await client
        .post('/api/v1/dialog/groups/get-for-card', {dialog_uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(GroupForCard, response.data);
        })
}

export async function leaveGroup(dialogUuid: string): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/dialog/groups/leave', {dialog_uuid: dialogUuid})
}

export class SubscriberStatItem
{
    uuid: string
    name: string
    datetime: string
}

export class StatsCommonData
{
    datasets: []
    subscribers: SubscriberStatItem[] = []
    count: number = 0
}

export class StatisticsResult
{
    labels: string[] = []

    subscribed: StatsCommonData
    unsubscribed: StatsCommonData


    constructor() {
        this.subscribed = new StatsCommonData();
        this.unsubscribed = new StatsCommonData();
    }
}

export class QueryGetStatistics
{
    dialog_uuid: string
    from: string
    to: string

    constructor(dialog_uuid: string, from: string, to: string) {
        this.dialog_uuid = dialog_uuid;
        this.from = from;
        this.to = to;
    }
}

export async function getStatistics(query: QueryGetStatistics): Promise<StatisticsResult>
{
    return await client
        .post('/api/v1/dialog/groups/statistics', query)
        .then((response: AxiosResponse) => {
            return plainToInstance(StatisticsResult, response.data);
        })
}
