import {defineStore} from "pinia";
import {JwtTokenPayload} from "./api/client";
import {InteractiveLessons, TutorialEnum} from "./api/interactiveLessons";
import {Route} from "@sentry/vue/types/router";


export class AuthStorageState
{
    token: string;
    refresh_token: string;
}

export const useAuthStorage = defineStore('authStorage', {
    state: (): AuthStorageState => {
        return {
            token: null,
            refresh_token: null,
        }
    },
    getters: {
        getToken(state): string {
            return state.token;
        },

        getRefreshToken(state): string {
          return state.refresh_token
        },
        getUserUuid(state): string {
            if (!state.token) {
                return ''
            }

            const jwtParts = state.token.split('.')
            // base64 decode
            const payloadData = JSON.parse(window.atob(jwtParts[1])) as JwtTokenPayload
            return payloadData.sub
        }
    },
    actions: {
        setToken(token: string) {
            this.token = token
        },

        setRefreshToken(refreshToken: string) {
            this.refresh_token = refreshToken
        },

        logout() {
            this.token = null
            this.refresh_token = null
        }
    },
    persist: true,
})

export class TutorialStorageState
{
    tutorial: TutorialEnum|null = null

    constructor(tutorial: TutorialEnum | null) {
        this.tutorial = tutorial;
    }
}

export const useTutorialStorage = defineStore('TutorialStorage', {
    state: (): TutorialStorageState => {
        return {tutorial: null}
    },
    getters: {
        getCurrentTutorial: function (state: TutorialStorageState): TutorialEnum|null {
            return state.tutorial
        },

        getTutorial: function (state: TutorialStorageState): Object {
            return InteractiveLessons.getLessons(state.tutorial)
        }
    },
    actions: {
        setTutorial(tutorial: TutorialEnum|null) {
            this.tutorial = tutorial
        },
    },
})

export class UserStorageState
{
    redirectAfterAuthRouteName: string|null = null
    redirectAfterAuthRouteParams: Object|null = null
}

export const useUserStorage = defineStore('UserStorage', {
    state: (): UserStorageState => {
        return {
            redirectAfterAuthRouteName: null,
            redirectAfterAuthRouteParams: null,
        }
    },
    getters: {
        getRedirectAfterAuthRouteName: function (state: UserStorageState): string|null {
            return state.redirectAfterAuthRouteName
        },

        getRedirectAfterAuthRouteParams: function (state: UserStorageState): Object|null {
            return state.redirectAfterAuthRouteParams
        },
    },
    actions: {
        setRedirectAfterAuthRoute(redirectAfterAuthRouteName: string, redirectAfterAuthRouteParams: Object|null) {
            if (this.redirectAfterAuthRouteName !== null) {
                return
            }

            this.redirectAfterAuthRouteName = redirectAfterAuthRouteName
            this.redirectAfterAuthRouteParams = redirectAfterAuthRouteParams
        },

        clearRedirectAfterAuth() {
            this.redirectAfterAuthRouteName = null;
            this.redirectAfterAuthRouteParams = null;
        }
    },
    persist: true,
})