import {NodeFile, NodePosition, TimeUnit} from "./graph";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import client from "./client";
import {PreviewLinkOption, UnpinAfter, UpdatePostCmd} from "./post";
import {plainToClass, Type} from "class-transformer";
import {UpdateGroupSettings} from "./groupSettings";
import {ButtonRow} from "./buttonGroupPanel";


export class FilterTime
{
    start_at: string|null = null
    finish_at: string|null = null
}

// Универсальная структура данных, расширять через extend
export class Period
{
    interval_value: number
    interval_unit: TimeUnit

    constructor(interval_value: number, interval_unit: TimeUnit) {
        this.interval_value = interval_value;
        this.interval_unit = interval_unit;
    }
}

export class PeriodicMessageText
{
    text: string = ''

    @Type(() => ButtonRow)
    buttons: ButtonRow[] = []

    @Type(() => NodeFile)
    files: NodeFile[] = []
}


export class UpdatePeriodicMessageCmd
{
    name: string
    dialog_uuid: string

    @Type(() => FilterTime)
    filter_time: FilterTime|null = null

    @Type(() => PeriodicMessageText)
    texts: PeriodicMessageText[] = []

    @Type(() => Period)
    period: Period
    active: boolean = false

    @Type(() => PreviewLinkOption)
    preview_option: PreviewLinkOption = PreviewLinkOption.belowText

    disable_notification: boolean = false
    protect_content: boolean = false
    remove_prev_message: boolean = false

    constructor(dialog_uuid: string) {
        this.dialog_uuid = dialog_uuid;
        this.period = new Period(
            1,
            TimeUnit.day,
        )
    }
}

export async function updatePeriodicMessage(command: UpdatePeriodicMessageCmd): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/dialog/group/settings/periodic-messages/update', command)
}

export async function getPeriodicMessage(dialogUuid: string): Promise<UpdatePeriodicMessageCmd>
{
    return await client
        .post('/api/v1/dialog/group/settings/periodic-messages/card', {dialog_uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(UpdatePeriodicMessageCmd, response.data);
        })
}
