<template>
  <div class="container mt-2">
    <div class="row">
      <div class="col text-center">
        <div v-for="row in this.buttons" class="mb-2">
          <div v-for="element in row.items" class="d-inline-block rounded me-2">
            <Button
                :label="element.name"
                :icon="resolveButtonIcon(element.type)"
                class="rounded align-middle"
            />
            <slot name="button_footer" :button="element"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import SelectButton from "primevue/selectbutton";
import type { PropType } from 'vue'
import {ButtonRow, resolveButtonIcon} from "@/api/buttonGroupPanel";
import Button from "primevue/button";

export default defineComponent({
  name: 'ButtonsPanel',
  methods: {resolveButtonIcon},
  components: {
    Button,
    SelectButton,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    buttons: {
      type: Array as PropType<ButtonRow[]>,
      required: true,
      default: [],
    },
  },
})
</script>
