import {Period} from "./periodicMessage";
import {
    FilterAction,
    FilterActionBanSubscriber,
    FilterActionKickSubscriber,
    FilterActionMuteSubscriber, FilterActionNothingDo
} from "./abuseFilter";
import {ButtonType, CommandType, NodePosition, TimeUnit} from "./graph";
import {plainToClass, plainToInstance, Type} from "class-transformer";
import {AxiosResponse} from "axios";
import client from "./client";
import {GetDictionaryForListItem, Word} from "./dictionary";


export abstract class AbstractFilter
{
    reset_warning_after: Period
    remove_warning_message_after: Period|null = null

    @Type(() => {}, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: FilterActionBanSubscriber, name: CommandType.banSubscriber },
                { value: FilterActionKickSubscriber, name: CommandType.kickSubscriber },
                { value: FilterActionMuteSubscriber, name: CommandType.muteSubscriber },
                { value: FilterActionNothingDo, name: CommandType.nothingDo },
            ]
        }
    })
    actions: FilterAction[] = []

    warning_text: string

    active: boolean = false
    remove_violation_message: boolean = true

    protected constructor(actions: FilterAction[]) {
        this.reset_warning_after = new Period(1, TimeUnit.day)
        this.actions = actions
    }
}

export class FilterAbuse extends AbstractFilter
{
    @Type(() => Word)
    ignore_words: Word[] = []

    @Type(() => Word)
    additional_words: Word[] = []
}

export class FilterLink extends AbstractFilter
{
    @Type(() => Word)
    white_list: Word[] = []
}

export class FilterSpam extends AbstractFilter
{
    spam_window: Period
    allow_duplicate_messages_count: number
}

export class FilterMixedAbc extends AbstractFilter
{
}

export enum FilterStopWordSource
{
    dictionary = 'dictionary',
    words = 'words',
}

export class FilterStopWords extends AbstractFilter
{
    sense_mixed_abc: boolean = false
    type: FilterStopWordSource = FilterStopWordSource.words

    dictionaries: string[] = []
    words: Word[] = []
}

export class FilterTelegramLink extends AbstractFilter
{
    react_username: boolean = false
    react_channel: boolean = false
    react_group: boolean = false
    react_bot: boolean = false

    white_list: Word[] = []
}

export class FilterTelegramLinkForNewSubscribers extends FilterTelegramLink
{
    new_subscriber_for_period: Period
    new_subscriber_for_messages_count: number
}

export class FilterForward extends AbstractFilter
{
    react_username: boolean = false
    react_channel: boolean = false
    react_group: boolean = false
    react_bot: boolean = false
}

export class FilterWarning extends AbstractFilter
{
}

export class UpdateGroupSettings
{
    name: string

    dialog_uuid: string

    @Type(() => FilterAbuse)
    filter_abuse: FilterAbuse

    @Type(() => FilterForward)
    filter_forward: FilterForward

    @Type(() => FilterTelegramLink)
    filter_telegram_link: FilterTelegramLink

    @Type(() => FilterTelegramLinkForNewSubscribers)
    filter_telegram_link_new_subscribers: FilterTelegramLinkForNewSubscribers

    @Type(() => FilterLink)
    filter_link: FilterLink

    @Type(() => FilterSpam)
    filter_spam: FilterSpam

    @Type(() => FilterStopWords)
    filter_stop_words: FilterStopWords

    @Type(() => FilterMixedAbc)
    filter_mixed_abc: FilterMixedAbc

    filter_service_message: boolean = false

    constructor(dialog_uuid: string) {
        this.dialog_uuid = dialog_uuid;
        this.filter_abuse = new FilterAbuse([])
        this.filter_forward = new FilterForward([])

        this.filter_telegram_link = new FilterTelegramLink([])
        this.filter_telegram_link_new_subscribers = new FilterTelegramLinkForNewSubscribers([])

        this.filter_link = new FilterLink([])
        this.filter_spam = new FilterSpam([])
        this.filter_stop_words = new FilterStopWords([])
        this.filter_mixed_abc = new FilterMixedAbc([])
    }
}

export class ContextOption
{
    code: string
    description: string
}

export class GroupContextOptions
{
    @Type(() => ContextOption)
    filter_abuse: ContextOption[] = []

    @Type(() => ContextOption)
    filter_links: ContextOption[] = []

    @Type(() => ContextOption)
    filter_telegram_links: ContextOption[] = []

    @Type(() => ContextOption)
    filter_forwarded_message: ContextOption[] = []

    @Type(() => ContextOption)
    filter_spam: ContextOption[] = []

    @Type(() => ContextOption)
    filter_stop_words: ContextOption[] = []

    @Type(() => ContextOption)
    filter_mixed_abc: ContextOption[] = []
}

export class GroupSettingsCard extends UpdateGroupSettings
{
    @Type(() => GroupContextOptions)
    context_options: GroupContextOptions
}

export async function getGroupSettings(dialogUuid: string): Promise<GroupSettingsCard>
{
    return await client
        .post('/api/v1/dialog/group/settings/get', {dialog_uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(GroupSettingsCard, response.data);
        })
}

export async function updateGroupSettings(command: UpdateGroupSettings): Promise<UpdateGroupSettings>
{
    return await client
        .post('/api/v1/dialog/group/settings/update', command)
}