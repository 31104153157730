<template>
  <div class="mt-2">
    <InputText
        class="w-75"
        v-model="this.regexp_pattern"
        placeholder="Регулярное выражение"
        :disabled="!this.isEditMode"
        :invalid="!this.regexp_pattern"
    />

    <div class="mt-1">
      <a href="https://regex101.com/" target="_blank">Проверить Regexp</a>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: 'QuestionRegexp',
  components: {
    InputText,
  },
  props: {
    regexp_pattern: {
      type: String,
      required: true,
    },
    isEditMode: {
      required: true,
      type: Boolean,
    }
  },
  data: function() {
    return {
      regexp_pattern: this.regexp_pattern,
    }
  },
})
</script>
