<template>
  <div class="">
    <Dropdown
        :model-value="this.card.type"
        option-label="label"
        option-value="value"
        :options="[
              {
                label: 'Ничего не делать',
                value: CommandType.nothingDo,
              },
              {
                label: 'Заблокировать',
                value: CommandType.banSubscriber,
              },
              {
                label: 'Выгнать из чата с возможностью вернуться',
                value: CommandType.kickSubscriber,
              },
              {
                label: 'Запретить писать в чат',
                value: CommandType.muteSubscriber,
              },
          ]"
        @change="($event) => {
          const ev = $event as DropdownChangeEvent

          switch (ev.value) {
            case CommandType.kickSubscriber:
              this.card = new FilterActionKickSubscriber()
              break

            case CommandType.banSubscriber:
              this.card = new FilterActionBanSubscriber()
              break

            case CommandType.muteSubscriber:
              this.card = new FilterActionMuteSubscriber()
              break

            case CommandType.nothingDo:
              this.card = new FilterActionNothingDo()
              break
          }
        }"
    />

    <div class="d-inline-block" v-if="this.card instanceof FilterActionMuteSubscriber || this.card instanceof FilterActionBanSubscriber">
      <span class="ms-2 me-2">на</span>

      <InputNumber
          v-model="this.card.period.interval_value"
      />
      <Dropdown
          v-model="this.card.period.interval_unit"
          class="ms-2"
          option-label="label"
          option-value="value"
          :options="this.getTimeUnits()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Button from "primevue/button";
import Dropdown, {DropdownChangeEvent} from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import {CommandType, TimeUnit} from "@/api/graph";
import {
  FilterAction,
  FilterActionBanSubscriber,
  FilterActionKickSubscriber,
  FilterActionMuteSubscriber, FilterActionNothingDo
} from "@/api/abuseFilter";

export default defineComponent({
  name: "FilterAction",
  computed: {
    FilterActionNothingDo() {
      return FilterActionNothingDo
    },
    FilterActionMuteSubscriber() {
      return FilterActionMuteSubscriber
    },
    FilterActionBanSubscriber() {
      return FilterActionBanSubscriber
    },
    FilterActionKickSubscriber() {
      return FilterActionKickSubscriber
    },
    CommandType() {
      return CommandType
    },
    DropdownChangeEvent() {
      return DropdownChangeEvent
    },
    TimeUnit() {
      return TimeUnit
    },

    modelValueData: {
      set: function (filterAction: FilterAction) {
        this.$emit('update:modelValue', filterAction)
      },
      get: function (): FilterAction {
        return this.modelValue
      }
    }
  },
  components: {InputNumber, Dropdown, Button},
  props: {
    action: {
      required: true,
      type: Object as PropType<FilterAction>
    },
  },
  data: function () {
    return {
      card: this.action,
    }
  },
  watch: {
    card: function (val) {
      this.$emit('update:action', val)
    },
    
    action: function (val) {
      this.card = val
    }
  },
  emits: [
      'update:action',
  ],
  methods: {
    getTimeUnits: function () {
      return [
        {
          label: 'минут',
          value: TimeUnit.minute,
        },
        {
          label: 'часа',
          value: TimeUnit.hour,
        },
        {
          label: 'дней',
          value: TimeUnit.day,
        },
      ]
    },
  },
})
</script>