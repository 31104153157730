<template>
  <div class="text-center pb-4 rounded-top node_bg_custom" style="width: 400px;">
    <TitleNode
        :node-uuid="this.data.uuid"
        :node-name="this.data.name"
        :node-position="this.data.position"
        :items="this.items"
        color="#278c78"
        @pressed-remove="() => { this.$emit('remove', this.data.uuid, true) }"
    />

    <EditableContent :is-read-only="true">
      <Dropdown
          class="mt-2 mb-2 fw-bold"
          v-model="data.type"
          :options="[
              {value: TriggerGroupType.OR, name: 'Проверка условий через ИЛИ'},
              {value: TriggerGroupType.AND, name: 'Проверка условий через И'}
          ]"
          option-value="value"
          option-label="name"
          :disabled="this.readOnly"
      />

      <div class="content-trigger-group">
        <TriggerItem
            v-for="(element, index) in this.data.triggers"
            :key="element.uuid"
            :model-value="this.data.triggers[index]"
            @completed-fill-trigger="() => {this.isShowAddTriggerItem = false}"
            :is-edit-mode="!this.readOnly"
            :context-options="this.contextOptions"
            :errors="this.errors[index]"
            :trigger-options="this.graphConfigStorage.getTriggerGroupTargetTypes"
        />
      </div>

      <TargetHandle/>

      <div class="mt-5">
        <SourceHandle :id="EdgeType.triggerToCommandOnPositive" style="bottom: 40px; top: auto; background-color: #31f10f">
          <div style="white-space: nowrap; position: absolute; top: -10px; right: 20px; ">
            <p>Условия выполнены</p>
          </div>
        </SourceHandle>

        <SourceHandle :id="EdgeType.triggerToCommandOnNegative" style="bottom: 10px; top: auto; background-color: #f10f17;">
          <div style="white-space: nowrap; position: absolute; top: -10px; right: 20px; ">
            <p>Условия не выполнены</p>
          </div>
        </SourceHandle>
      </div>
    </EditableContent>
  </div>
</template>

<script lang="ts">
import {Handle, Position} from "@vue-flow/core";
import {defineComponent, provide, ref} from "vue";
import {EdgeType, GraphConfigResult, Trigger, TriggerGroupNode} from "@/api/graph";
import TriggerItem from "@/components/view/Bots/Trigger/TriggerItem.vue";
import {TriggerGroupType, updateTriggerGroup} from "@/api/trigger";
import TitleNode from "@/components/view/Bots/TitleNode.vue";
import {MenuItem} from "primevue/menuitem";
import EditableContent from "@/components/view/Bots/EditableContent.vue";
import SelectButton from "primevue/selectbutton";
import TargetHandle from "@/components/view/Bots/TargetHandle.vue";
import SourceHandle from "@/components/view/Bots/SourceHandle.vue";
import type { PropType } from 'vue'
import {ContextItem, ContextItemForBuilder} from "@/api/context";
import Dropdown from "primevue/dropdown";
import CommandItem from "@/components/view/Bots/Command/CommandItem.vue";
import {uuid} from "vue-uuid";
import {ErrorResponse} from "@/api/common";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

export default defineComponent({
  name: 'TriggerGroup',
  props: {
    name: {
      type: String,
      required: false,
    },
    data: {
      type: Object as PropType<TriggerGroupNode>,
      required: true,
    },
    contextOptions: {
      type: Array as PropType<ContextItemForBuilder[]>,
      required: true,
    },
  },
  emits: [
    'remove',
  ],
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  computed: {
    EdgeType() {
      return EdgeType
    },
    TriggerGroupType() {
      return TriggerGroupType
    },
    Position() {
      return Position
    },
    items: function (): MenuItem[] {
      if (this.graphConfigStorage.isSharedTemplate) {
        return []
      }

      let result = [] as MenuItem[]

      if (this.readOnly) {
        result.push({
          label: 'Редактировать',
          icon: 'pi pi-file-edit',
          command: () => {
            this.readOnly = false
          },
        })
      }

      result.push({
        label: 'Удалить',
        icon: 'pi pi-trash',
        command: () => {
          this.$emit('remove', this.data.uuid, true)
        }
      })

      return result
    },
  },
  components: {
    CommandItem,
    Dropdown,
    SourceHandle,
    TargetHandle,
    EditableContent,
    TitleNode,
    TriggerItem,
    Handle,
    SelectButton,
  },
  data: function () {
    return {
      isShowAddTriggerItem: false,
      readOnly: true,
      errors: [],
    }
  },
  methods: {
    uuid() {
      return uuid
    },
  }
})
</script>

<style scoped>
.content-trigger-group > div {
  border: 1px dashed rgb(172,171,171);
  background-color: rgb(193, 209, 242);
  border-radius: 4px;
}
</style>
