<template>
  <div>
    <AutoComplete
        v-model="this.fakeSearchSourceName"
        :suggestions="this.options"
        @complete="this.searchDialogs"
        @item-select="($event) => {
          this.data.push($event.value);
          this.fakeSearchSourceName = ''
        }"
        :disabled="this.disabled"
        option-label="name"
        data-key="uuid"
        dropdown
        :force-selection="true"
        empty-search-message="Проверьте что бот добавлен в необходимый диалог"
        :loading="this.isLoading"
        class="align-middle"
        :placeholder="this.placeholder"
    >
      <template #option="slotProps">
        <div class="d-flex">
          <img :alt="slotProps.option.name" v-if="slotProps.option.icon_image" :src="slotProps.option.icon_image" class="me-2 rounded-circle align-middle" style="height: 30px;"/>
          <p class="align-middle">{{ slotProps.option.name }}</p>
        </div>
      </template>
    </AutoComplete>

    <div class="mt-2">
      <div class="m-1 ms-0 mb-0 d-inline-block p-2 rounded bg-dark text-white" v-for="(item, index) in this.data" :key="index">
        <span class="fw-bold">{{item.name}}</span>

        <Dropdown
            v-if="this.showTopicForGroups && item.topics.length !== 0"
            :options="[{name: 'General', uuid: null}].concat(item.topics)"
            class="ms-2 me-1"
            v-model="this.data[index].selected_topic_uuid"
            option-label="name"
            option-value="uuid"
            placeholder="General"
            :input-style="{padding: '4px'}"
        />

        <button
            class="bg-dark text-white border-0"
            @click="this.data.splice(index, 1)"
        >
          <span class="pi pi-trash"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {SourceItemResult} from "@/api/forwarder";
import Button from "primevue/button";
import AutoComplete, {AutoCompleteCompleteEvent} from "primevue/autocomplete";
import {searchDialogByName, SearchDialogItem, SearchDialogRes} from "@/api/edge";
import {DialogType} from "@/api/dialog";
import Menu from "primevue/menu";
import Dropdown from "primevue/dropdown";

export default defineComponent({
  name: "DialogTagPanel",
  components: {AutoComplete, Button, Menu, Dropdown},
  props: {
    modelValue: {
      required: true,
      type: Array as PropType<SearchDialogItem[]>
    },
    scenarioUuid: {
      required: true,
      type: String,
    },
    dialogTypes: {
      required: true,
      type: Array as PropType<DialogType[]>
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    showTopicForGroups: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
      'modelValue:updateValue',
  ],
  data: function () {
    return {
      fakeSearchSourceName: '',
      options: [] as SourceItemResult[],
      isLoading: false,
      data: this.modelValue ?? [],

      items: [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' },
      ]
    }
  },
  methods: {
    searchDialogs: function (event: AutoCompleteCompleteEvent) {
      this.isLoading = true

      searchDialogByName(event.query, this.scenarioUuid, this.dialogTypes)
          .then((result: SearchDialogRes) => {
            // Не отображаем уже выбранные диалоги
              const tmp = []
              result.dialogs.forEach((item: SearchDialogItem) => {
                const isExists = this.data.find((findItem: SearchDialogItem) => {
                  return findItem.uuid === item.uuid
                })

                if (!isExists) {
                  tmp.push(item)
                }
              })

              this.options = tmp
          })
          .finally(() => {
            this.isLoading = false
          })
    },
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        this.$emit('modelValue:updateValue', newVal)
      },
      deep: true,
    },

    modelValue: function (val) {
      if (val.length === 0 && this.data.length === 0) {
        return
      }

      this.data = val
    }
  },
})
</script>