import axios, {AxiosResponse} from "axios";
import {QuestionNode} from "@/api/graph";
import {plainToClass} from "class-transformer";
import client from "./client";

export async function updateQuestionNode(cmd: QuestionNode): Promise<QuestionNode> {
    return client
        .post('/api/v1/question-node/update', cmd)
        .then((response: AxiosResponse) => {
            return plainToClass(QuestionNode, response.data);
        })
}

export enum AnswerTypeEnum
{
    Regexp = 'regexp',
    Buttons = 'buttons',
    IsNumber = 'is_number',
    Length = 'length',
    EmailMask = 'email_mask',
    PhoneMask = 'phone_mask',
    Calendar = 'calendar',
    withoutValidation = 'without_validation',
}

export async function addQuestionNode(questionNode: QuestionNode): Promise<QuestionNode>
{
    return client
        .post('/api/v1/question-node/create', questionNode)
        .then((response: AxiosResponse) => {
            return plainToClass(QuestionNode, response.data);
        })
}
