import {GraphResult, NodePosition, QuestionNode, TimeUnit} from "./graph";
import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToClass, plainToInstance, Type} from "class-transformer";

export class CreateQueue
{
    scenario_uuid: string
    name: string = ''

    timezone: string = '+03:00'

    interval_value: number = 3
    interval_unit: TimeUnit = TimeUnit.hour

    @Type(() => QueueDateFilter)
    filter_date: QueueDateFilter

    @Type(() => QueueTimeFilter)
    filter_time: QueueTimeFilter

    @Type(() => QueueDayOfWeekFilter)
    filter_days_of_week: QueueDayOfWeekFilter

    @Type(() => QueueDayOfMonthFilter)
    filter_days_of_month: QueueDayOfMonthFilter

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;

        this.filter_date = new QueueDateFilter()
        this.filter_time = new QueueTimeFilter()
        this.filter_days_of_week = new QueueDayOfWeekFilter()
        this.filter_days_of_month = new QueueDayOfMonthFilter()
    }
}

export class QueueDayOfMonthFilter {
    day1: boolean = true
    day2: boolean = true
    day3: boolean = true
    day4: boolean = true
    day5: boolean = true
    day6: boolean = true
    day7: boolean = true
    day8: boolean = true
    day9: boolean = true
    day10: boolean = true
    day11: boolean = true
    day12: boolean = true
    day13: boolean = true
    day14: boolean = true
    day15: boolean = true
    day16: boolean = true
    day17: boolean = true
    day18: boolean = true
    day19: boolean = true
    day20: boolean = true
    day21: boolean = true
    day22: boolean = true
    day23: boolean = true
    day24: boolean = true
    day25: boolean = true
    day26: boolean = true
    day27: boolean = true
    day28: boolean = true
    day29: boolean = true
    day30: boolean = true
    day31: boolean = true

    isEmpty(): boolean {
        return this.day1 === true &&
                this.day2 === true &&
                this.day3 === true &&
                this.day4 === true &&
                this.day5 === true &&
                this.day6 === true &&
                this.day7 === true &&
                this.day8 === true &&
                this.day9 === true &&
                this.day10 === true &&
                this.day11 === true &&
                this.day12 === true &&
                this.day13 === true &&
                this.day14 === true &&
                this.day15 === true &&
                this.day16 === true &&
                this.day17 === true &&
                this.day18 === true &&
                this.day19 === true &&
                this.day20 === true &&
                this.day21 === true &&
                this.day22 === true &&
                this.day23 === true &&
                this.day24 === true &&
                this.day25 === true &&
                this.day26 === true &&
                this.day27 === true &&
                this.day28 === true &&
                this.day29 === true &&
                this.day30 === true &&
                this.day31 === true
    }

    empty(): void {
        this.day1 = true
        this.day2 = true
        this.day3 = true
        this.day4 = true
        this.day5 = true
        this.day6 = true
        this.day7 = true
        this.day8 = true
        this.day9 = true
        this.day10 = true
        this.day11 = true
        this.day12 = true
        this.day13 = true
        this.day14 = true
        this.day15 = true
        this.day16 = true
        this.day17 = true
        this.day18 = true
        this.day19 = true
        this.day20 = true
        this.day21 = true
        this.day22 = true
        this.day23 = true
        this.day24 = true
        this.day25 = true
        this.day26 = true
        this.day27 = true
        this.day28 = true
        this.day29 = true
        this.day30 = true
        this.day31 = true
    }
}

export class QueueDateFilter
{
    from: string|null = null
    to: string|null = null

    isEmpty(): boolean {
        return this.from === null && this.to === null
    }

    empty(): void {
        this.from = null
        this.to = null
    }
}

export class QueueTimeFilter
{
    from: string|null = null
    to: string|null = null

    isEmpty(): boolean {
        return this.from === null && this.to === null
    }

    empty(): void {
        this.from = null
        this.to = null
    }
}

export class QueueDayOfWeekFilter
{
    day1: boolean = true
    day2: boolean = true
    day3: boolean = true
    day4: boolean = true
    day5: boolean = true
    day6: boolean = true
    day7: boolean = true

    isEmpty(): boolean {
        return this.day1 === true &&
                this.day2 === true &&
                this.day3 === true &&
                this.day4 === true &&
                this.day5 === true &&
                this.day6 === true &&
                this.day7 === true
    }

    empty(): void {
        this.day1 = true
        this.day2 = true
        this.day3 = true
        this.day4 = true
        this.day5 = true
        this.day6 = true
        this.day7 = true
    }
}

export class UpdateQueue extends CreateQueue
{
    uuid: string

    constructor(scenario_uuid: string, uuid: string) {
        super(scenario_uuid);
        this.uuid = uuid;
    }
}

export class QueueForCard extends UpdateQueue
{

}

export async function createQueue(queue: CreateQueue): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/post/queue/create', queue)
}

export async function updateQueue(queue: UpdateQueue): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/post/queue/update', queue)
}

export class QueueGetForListItem
{
    uuid: string
    name: string
    interval_print: string
    filter_date_print: string
    active: boolean

    posts: QueueGetForListItemPost[] = []
}

export class QueueGetForListItemPost
{
    uuid: string
    name: string
    publication_at_print: string
}

export class QueueGetForListResult
{
    items: QueueGetForListItem[] = []
}

export async function getQueues(scenarioUuid: string, queue_uuid: string|null): Promise<QueueGetForListResult>
{
    return client
        .post('/api/v1/post/queue/get-for-list', {scenario_uuid: scenarioUuid, queue_uuid: queue_uuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(QueueGetForListResult, response.data);
        })
}

export async function getQueueForCard(queueUuid: string): Promise<QueueForCard>
{
    return client
        .post('/api/v1/post/queue/get-for-card/' + queueUuid)
        .then((response: AxiosResponse) => {
            return plainToInstance(QueueForCard, response.data);
        })
}

export async function removeQueue(queueUuid: string): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/post/queue/remove/' + queueUuid)
}

export async function changeQueueActiveStatus(queueUuid: string, active: boolean): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/post/queue/active', {queue_uuid: queueUuid, active: active})
}