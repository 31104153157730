<template>
  <div class="p-2 vh-100 gridmap" style="overflow-y: auto;">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <div class="mt-2 p-2 bg-light rounded">
      <AdminCommandItem
          :item="this.card.kick"
          @update:item="($event) => { this.card.kick = $event }"
      />
    </div>

    <div class="mt-2 p-2 bg-light rounded">
      <AdminCommandItem
          :item="this.card.ban"
          @update:item="($event) => { this.card.ban = $event }"
      />
    </div>

    <div class="mt-2 p-2 bg-light rounded">
      <AdminCommandItem
          :item="this.card.warn"
          @update:item="($event) => { this.card.warn = $event }"
      />

      <Filter
          v-model="this.card.warn.filter"
          @update:model-value="($event) => { this.card.warn = $event }"
          :context-options="[]"
      />
    </div>

    <div class="mt-2 p-2 bg-light rounded">
      <AdminCommandItem
          :item="this.card.report"
          @update:item="($event) => { this.card.report = $event }"
      />
    </div>

    <div class="d-flex justify-content-end m-3">
      <Button
        label="Сохранить"
        severity="secondary"
        class="rounded"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import AdminCommandItem from "@/components/view/GroupComponents/AdminCommand/AdminCommandItem.vue";
import {CommandsSettingsGetForCard} from "@/api/commandsSettings";
import Button from "primevue/button";
import FilterAction from "@/components/view/GroupComponents/FilterAction.vue";
import FilterActionWrapper from "@/components/view/GroupComponents/FilterActionWrapper.vue";
import Filter from "@/components/view/GroupComponents/Filter.vue";

export default defineComponent({
  name: "CommandsSettings",
  components: {
    Filter,
    FilterActionWrapper,
    FilterAction,
    AdminCommandItem,
    Breadcrumbs,
    Button,
  },
  data: function () {
    return {
      breadcrumbs: [],
      card: new CommandsSettingsGetForCard()
    }
  }
})
</script>