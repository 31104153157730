<template>
  <div/>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {useScenarioShareLink} from "@/api/scenario";
import {ErrorResponse} from "@/api/common";
import {error} from "@/api/ToastService";
import ProgressSpinner from "primevue/progressspinner";

export default defineComponent({
  name: "UseShareLink",
  components: {
    ProgressSpinner
  },
  props: {
    share_link_uuid: {
      required: true,
      type: String,
    },
  },
  created() {
    useScenarioShareLink(this.share_link_uuid)
        .catch((r: ErrorResponse) => {
          error(r.error.message, '', 10000)
        })
        .finally(() => {
          this.$router.push({name: 'bots_list'})
        })
  }
})
</script>