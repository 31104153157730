import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createBlock(_component_Calendar, {
    class: "w-100",
    modelValue: this.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.value) = $event)),
    "show-button-bar": this.showBar,
    invalid: this.invalid,
    disabled: this.disabled,
    placeholder: this.placeholder,
    "date-format": "dd-mm-yy",
    "show-time": this.showTime,
    "manual-input": false
  }, null, 8, ["modelValue", "show-button-bar", "invalid", "disabled", "placeholder", "show-time"]))
}