import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_InputNumber, {
      "model-value": this.modelValue.interval_value,
      min: 1,
      "onUpdate:modelValue": this.onSelectValue
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(_component_Dropdown, {
      "model-value": this.modelValue.interval_unit,
      "onUpdate:modelValue": this.onSelectUnit,
      class: "ms-2",
      "option-label": "label",
      "option-value": "value",
      options: this.timeUnits,
      "scroll-height": "500px"
    }, null, 8, ["model-value", "onUpdate:modelValue", "options"])
  ]))
}