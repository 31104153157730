<template>
  <AutoComplete
      :disabled="this.disabled"
      :hidden="this.hidden"
      v-model="this.data"
      optionLabel="name"
      data-key="uuid"
      :suggestions="this.dialogOptions.dialogs"
      :placeholder="this.placeholder"
      @complete="this.searchOptions"
      :loading="this.isLoading"
      :dropdown="this.dropdown"
      multiple
  />
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import AutoComplete, {AutoCompleteCompleteEvent} from "primevue/autocomplete";
import {searchDialogByName, SearchDialogItem, SearchDialogRes} from "@/api/edge";

export default defineComponent({
  name: "SearchDialogMultiple",
  components: {
    AutoComplete
  },
  props: {
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    hidden: {
      required: false,
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: null,
      type: String,
    },
    modelValue: {
      required: true,
      type: Array as PropType<SearchDialogItem>
    },
    // todo: выяснить почему при наличии dropdown и флага multiple, кнопка dropdown съезжает вниз
    dropdown: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data: function () {
    return {
      isLoading: false,
      dialogOptions: new SearchDialogRes,
    }
  },
  inject: [
      'scenario_uuid'
  ],
  computed: {
    data: {
      get: function (): SearchDialogItem|null {
        return this.modelValue
      },

      set: function (value: SearchDialogItem|null|string) {
        // if (typeof value === 'string') {
        //   value = null
        // }

        this.$emit('update:modelValue', value)
      }
    },
  },
  methods: {
    searchOptions: function (event: AutoCompleteCompleteEvent) {
      this.isLoading = true

      searchDialogByName(event.query, this.scenario_uuid, []).then((result: SearchDialogRes) => {
        // Не отображаем уже выбранные диалоги
        const tmp = new SearchDialogRes()
        result.dialogs.forEach((item: SearchDialogItem) => {
          const isExists = this.modelValue.find((findItem: SearchDialogItem) => {
            return findItem.uuid === item.uuid
          })

          if (!isExists) {
            tmp.dialogs.push(item)
          }
        })

        this.dialogOptions = tmp
        this.isLoading = false
      })
    },
  }
})
</script>