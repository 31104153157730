import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default"),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        class: "rounded",
        severity: "success",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('node-save'))),
        disabled: this.disableSaveButton,
        label: "Сохранить"
      }, null, 8, ["disabled"])
    ], 512), [
      [_vShow, !this.isReadOnly]
    ])
  ]))
}