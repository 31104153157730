<template>
  <div>
    <div>
      <div class="mt-4">
        <p class="m-0 required">Название справочника</p>
        <InputText v-model="this.modelValue.name"/>
        <p class="p-error">{{ this.errors['name'] }}</p>
      </div>

      <div class="custom-border content-group mt-2 mb-2">
        <p class="p-error" v-show="this.errors['import_file']">{{this.errors['import_file']}}</p>
        <FileUpload
            mode="basic"
            accept="text/plain"
            :maxFileSize="1000000"
            chooseLabel="Импортировать из файла (.txt)"
            class="rounded"
            :auto="true"
            :custom-upload="true"
            :multiple="false"
            @uploader="($event: FileUploadUploaderEvent) => {
              const file = $event.files instanceof Array ? $event.files[0] : $event.files
              this.errors = []

              uploadImportFile(this.scenarioUuid, file).then((res) => {
                this.modelValue.words = this.modelValue.words.concat(res.words)
              })
              .catch((error: ErrorResponse) => {
                this.errors = error.getValidationErrors()
              })
            }"
          />
      </div>

      <div class="content-group custom-border rounded">
        <div class="bg-primary-subtle p-2 border-custom rounded">
          <p class="m-0 align-middle"><span class="pi pi-question-circle me-1 align-middle"/> Максимальное количество слов в справочнике {{this.maxWordInDictionary}}, слова поверх этого значения будут отброшены</p>
        </div>

        <div class="mt-2">
          <div class="mb-2 mt-2">
            <p class="m-0">Количество слов в справочнике: {{this.modelValue.words.length}}</p>
          </div>

          <div class="d-flex justify-content-between">
            <div>
              <Button
                  icon="pi pi-plus"
                  severity="contrast"
                  label="Добавить слово"
                  class="rounded me-2"
                  :disabled="this.isReachedWordsLimit"
                  @click="this.modelValue.words.unshift(new Word(WordType.fullEquals))"
              />

              <Button
                  icon="pi pi-trash"
                  severity="danger"
                  label="Удалить все слова"
                  class="rounded"
                  v-show="this.modelValue.words.length > 0"
                  @click="this.modelValue.words = []"
              />
            </div>

            <div>
              <Button
                  label="Сохранить"
                  icon="pi pi-check"
                  severity="contrast"
                  class="rounded"
                  :loading="this.createIsLoading"
                  @click="() => {
                     this.createIsLoading = true

                     this.errors = []
                     const promise = this.modelValue instanceof UpdateDictionaryCmd ?
                        updateWordDictionary(this.modelValue) :
                        createWordDictionary(this.modelValue)

                     promise
                      .then((res: UpdateDictionaryCmd) => {
                        this.isShowCreateDictionary = false
                        this.$emit('saved')

                        this.$emit('update:modelValue', res)
                      })
                      .catch((error: ErrorResponse) => {
                        this.errors = error.getValidationErrors()
                      })
                      .finally(() => {
                        this.createIsLoading = false
                      });
                   }"
                />
            </div>
          </div>

          <p class="p-error mt-2" v-if="this.errors['words']">{{ this.errors['words'] }}</p>

          <Divider/>
        </div>

        <div class="mt-2">
          <div class="d-inline-block" v-for="(word, index) in this.modelValue.words">
            <div class="me-2 mt-2">
              <InputText v-model="this.modelValue.words[index].value" :invalid="index >= this.maxWordInDictionary" placeholder="Слово для справочника" class="m-0"/>
              <button
                  class="pi pi-trash text-danger border-0 bg-transparent ms-1 me-1"
                  @click="() => { this.modelValue.words.splice(index, 1) }"
              />
              <p class="p-error">{{this.errors['words[' + index + ']']}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, type PropType} from 'vue'
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import {
  CreateDictionaryCmd,
  createWordDictionary,
  getWordDictionaryForList,
  ImportFile,
  UpdateDictionaryCmd,
  updateWordDictionary,
  uploadImportFile,
  Word, WordType
} from "@/api/dictionary";
import {ErrorResponse} from "@/api/common";
import HelpInfo from "@/components/view/HelpInfo.vue";
import Divider from "primevue/divider";

export default defineComponent({
  name: "DictionaryForm",
  computed: {
    WordType() {
      return WordType
    },
    Word() {
      return Word
    },
    ImportFile() {
      return ImportFile
    },
    FileUploadUploaderEvent() {
      return FileUploadUploaderEvent
    },
    UpdateDictionaryCmd() {
      return UpdateDictionaryCmd
    },
    ErrorResponse() {
      return ErrorResponse
    },
    isReachedWordsLimit: function () {
      return this.modelValue.words.length >= this.maxWordInDictionary
    }
  },
  methods: {uploadImportFile, updateWordDictionary, getWordDictionaryForList, createWordDictionary},
  components: {
    HelpInfo,
    FileUpload, 
    Dropdown, 
    Button, 
    InputText,
    Divider,
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<CreateDictionaryCmd>,
    },
    scenarioUuid: {
      required: true,
      type: String,
    }
  },
  emits: [
      'saved',
      'update:modelValue',
  ],
  data: function () {
    return {
      isShowCreateDictionary: false,
      createIsLoading: false,
      errors: [] as Array<string, string>,
      maxWordInDictionary: 200, // todo: получить этот параметр из конфига бека
    }
  },
})
</script>