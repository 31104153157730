import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {CommandGroupNode, NodePosition, TriggerGroupNode, TriggerOperation, TriggerType} from "@/api/graph";
import {plainToClass} from "class-transformer";
import client from "./client";

export enum TriggerGroupType {
    OR = 'OR',
    AND = 'AND'
}

export async function updateTriggerGroup(command: TriggerGroupNode): Promise<TriggerGroupNode>
{
    const response: AxiosResponse = await client.post('/api/v1/trigger-group/update', command)
    const responseData = response.data;
    return plainToClass(TriggerGroupNode, responseData);
}
