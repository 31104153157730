import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToInstance, Type} from "class-transformer";
import {SearchSubscriberItem} from "./edge";


export class TagForList
{
    @Type(() => TagForListItem)
    items: TagForListItem[] = []
}

export class TagForListItem
{
    uuid: string
    name: string

    constructor(uuid: string, name: string) {
        this.uuid = uuid;
        this.name = name;
    }
}

export class CreateTagResult
{
    // @ts-ignore
    uuid: string
    // @ts-ignore
    name: string
}

export async function getForCardTag(tagUuid: string): Promise<UpdateTagCommand>
{
    return await client
        .post('/api/v1/tags/get-for-card', {uuid: tagUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(UpdateTagCommand, response.data);
        })
}

export async function getForListTags(scenarioUuid: string): Promise<TagForList>
{
    return await client
        .post('/api/v1/tags/get-for-list', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(TagForList, response.data);
        })
}

export class CreateTagCommand
{
    name: string
    scenario_uuid: string
    subscribers: SearchSubscriberItem[] = []

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }
}

export class UpdateTagCommand extends CreateTagCommand
{
    uuid: string

    constructor(scenario_uuid: string, uuid: string) {
        super(scenario_uuid);
        this.uuid = uuid;
    }
}

export async function createTag(cmd: CreateTagCommand): Promise<CreateTagResult>
{
    return await client
        .post('/api/v1/tags/create', cmd)
        .then((response: AxiosResponse) => {
            return plainToInstance(CreateTagResult, response.data);
        })
}

export async function updateTag(cmd: UpdateTagCommand): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/tags/update', cmd)
}
