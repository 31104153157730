<template>
  <AutoComplete
      :disabled="this.disabled"
      :hidden="this.hidden"
      v-model="this.data"
      optionLabel="name"
      data-key="uuid"
      :suggestions="this.tagOptions.tags"
      :placeholder="this.placeholder"
      @complete="this.searchOptions"
      :loading="this.isLoading"
      empty-search-message="Список тегов пуст, создайте тег"
      :force-selection="true"
  />
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import AutoComplete, {AutoCompleteCompleteEvent} from "primevue/autocomplete";
import {SearchTagRes, searchTagsByName} from "@/api/edge";

export default defineComponent({
  name: "SearchTag",
  components: {
    AutoComplete
  },
  props: {
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    hidden: {
      required: false,
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: null,
      type: String,
    },
    // todo: в будущем добавить типизацию
    modelValue: {
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  inject: [
      'scenario_uuid',
  ],
  data: function () {
    return {
      isLoading: false,
      tagOptions: new SearchTagRes(),
    }
  },
  computed: {
    data: {
      get: function (): SearchTagRes|null {
        return this.modelValue
      },

      set: function (value: SearchTagRes|null|string) {
        // if (typeof value === 'string') {
        //   value = null
        // }

        this.$emit('update:modelValue', value)
      }
    },
  },
  methods: {
    searchOptions: function (event: AutoCompleteCompleteEvent) {
      this.isLoading = true

      searchTagsByName(event.query, this.scenario_uuid).then((result: SearchTagRes) => {
        this.tagOptions = result
        this.isLoading = false
      })
    },
  }
})
</script>