<template>
<div class="p-2">
  <h2 class="mb-2">{{ botName }}</h2>
  <DataTable :value="this.userBotList.items" paginator :rows="this.userBotList.pagination.quantity" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem">
    <Column field="name" header="Имя"></Column>
    <Column header="Права" :exportable="false">
      <template #body="slotProps">
        <Dropdown v-model="selectedWrite" :options="[{name: 'Чтение', code: 'READ'}, {name: 'Чтение и запись', code: 'WRITE'}]" optionLabel="name" option-value="code" placeholder="Права доступа"/>
      </template>
    </Column>
    <Column header="Операции" v-if="this.userBotList.is_can_remove" :exportable="false" style="min-width:8rem">
      <template #body="slotProps">
        <Button icon="pi pi-user-minus" class="rounded-circle" outlined severity="danger" @click="showDialogRemoveUserFromBot(slotProps.data)" />
      </template>
    </Column>
  </DataTable>
  <Button class="btn btn-primary align-middle" type="button" @click="this.showDialogAddUserToBot"><p class="pi pi-user-plus me-2 align-middle"></p>Добавить доступ</Button>

  <!-- Модальное подтверждения удаления пользователя от бота  -->
  <Dialog v-model:visible="this.isShowDialogRemoveUserFromBot" header="Удаление доступа к боту" modal>
    Вы уверены что хотите лишить пользователя <strong>{{this.selectedUserName}}</strong> доступа к конструктору вашего бота?
    <template #footer>
      <Button label="Ok" icon="pi pi-check" @click="this.confirmRemoveBot" />
    </template>
  </Dialog>

  <!-- Модальное окно добавления пользователя к боту -->
  <Dialog v-model:visible="this.isShowDialogAddUserToBot" header="Добавление доступа к боту" modal>
    Сгенерировать одноразовую ссылку для предоставления доступа к редактору бота другим пользователям
    <template #footer>
      <Button label="Сгенерировать" icon="pi pi-check" @click="" />
    </template>
  </Dialog>
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {getUsersOfScenario, removeUserFromScenario, UserBot, UserBotResult} from "@/api/scenario";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";

export default defineComponent({
  name: 'BotUsers',
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    Dropdown,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {
      botName: 'Какое то название бота...',
      userBotList: {items: [], pagination: {page: 1, by_page: 10, quantity: 0 }, is_can_remove: false} as UserBotResult,
      isShowDialogRemoveUserFromBot: false,
      selectedUserBotUuid: null as string|null,
      selectedUserName: null as string|null,
      isShowDialogAddUserToBot: false,

      selectedWrite: '',
    }
  },
  mounted: function () {
    this.loadUsersFromBackend()

    // todo: метод получения карточки сценария, с целью получения названия
  },
  methods: {
    showDialogRemoveUserFromBot: function (user: UserBot) {
      this.isShowDialogRemoveUserFromBot = true
      this.selectedUserBotUuid = user.uuid
      this.selectedUserName = user.name
    },
    closeDialogRemoveUserFromBot: function () {
      this.isShowDialogRemoveUserFromBot = false
      this.selectedUserBotUuid = null
      this.selectedUserName = null
    },
    confirmRemoveBot: function () {
      removeUserFromScenario(this.scenario_uuid, this.selectedUserBotUuid as string).then(() => {
        this.closeDialogRemoveUserFromBot()
        this.loadUsersFromBackend()
      })
    },
    loadUsersFromBackend: function () {
      getUsersOfScenario(this.scenario_uuid).then((result: UserBotResult) => {
        this.userBotList = result
      })
    },
    showDialogAddUserToBot: function () {
      this.isShowDialogAddUserToBot = true
    },
  }
})
</script>
