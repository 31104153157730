<template>
  <div>

    <div v-if="this.isCurrentTemplate">
      <p>
        Поведение бота задается через алгоритм, представляющий собой блоки и взаимосвязи между ними.
        Блоки бывают разных типов, различные типы блоков выполняют разные функции.
      </p>

      <h5 class="fw-bold mt-4 mb-2">Краткое описание видов блоков</h5>
      <div class="ms-4">
        <ul>
          <li><span class="fw-bold">Условие запуска</span> - определяет стартовые условия по которым бот начнет выполнять сценарий, например ввод команды, содержание в сообщение определенных слов и т.д</li>
          <li><span class="fw-bold">Сообщение</span> - выполняет отправку Telegram сообщения</li>
          <li><span class="fw-bold">Вопрос</span> - выполняет отправку Telegram сообщения с ожиданием ответа от пользователя (ответ можно сохранить в переменную)</li>
          <li><span class="fw-bold">Действие</span> - выполняет обращение в внешний API, модифицирует значение переменных, закрепляет или удаляет сообщение подписчика и т.д</li>
          <li><span class="fw-bold">Задержка</span> - выполняет задержку перед переходом на следующий блок</li>
          <li><span class="fw-bold">Условие</span> - позволяет менять поведение бота в зависимости от выполнимости или невыполнимости блока условия, условия можно комбинировать</li>
        </ul>
      </div>

      <Divider/>

      <!--    todo: тут примеры расшаренных шаблонов с наиболее полным комбинированным содержанием разных видов блоков -->
    </div>

    <router-view/>

  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Divider from "primevue/divider";

export default defineComponent({
  name: "DocsBuilder",
  components: {
    Divider,
    Image,
  },
  computed: {
    isCurrentTemplate: function (): boolean {
      // todo: хардкод названия роута
      return this.$route.name === 'doc_builder'
    }
  }
})
</script>