

export class Timer
{
    private static timers: Array<string, number> = []


    // todo: типизировать прием функции
    public static runTimeout(key: string, func: any, periodMilliSeconds: number): void
    {
        if (this.timers[key]) {
            clearTimeout(this.timers[key])
        }

        this.timers[key] = setTimeout(func, periodMilliSeconds)
    }
}
