<template>
  <div>
    <div>
      <h4 class="fw-bold">О сервисе</h4>

      <div class="mt-4">
        <div>
          <h5 class="fw-bold d-inline-block m-0">Ninja-bot</h5> —
          это платформа для создания Telegram ботов, которые могут в автоматическом режиме модерировать группы, публиковать посты в каналах по расписанию и вести клиента по воронке продаж.
          В зависимости от того каким функционалом Telegram вы пользуетесь, у нас есть разные решения.
        </div>

        <h5 class="fw-bold mt-4">Работа в каналах</h5>
        <ul class="ms-4">
          <li class="mb-2">
            Функционал <router-link :to="{name: 'doc_delay_publish'}">«отложенного постинга»</router-link> обеспечит бесперебойный выход контента в один или несколько каналов,
            с гибкими параметрами даты и времени публикации,
            гибкие фильтры открепления, удаления поста, оповещение рекламодателей, прикрепление к постам кнопок и многое другое.
          </li>
          <li class="mb-2">
            Функционал  <router-link :to="{name: 'doc_forwarder'}">«перенаправления постов»</router-link> позволит перенаправлять контент из сторонних каналов в ваш канал.
            <p class="m-0"><span class="fw-bold">Важно:</span> бот должен находиться в канале откуда и куда идет пересылка.</p>
          </li>
        </ul>

      </div>

      <div>
        <h5 class="fw-bold mt-3">Работа в группах</h5>
        <ul class="ms-4">
          <li>Приветственное сообщение для новых участников</li>
          <li>Прощальное сообщение для покинувших участников</li>
          <li>Команды: /ban, /kick, /mute и т.д станут отличным помощником ручной модерации</li>
          <li>Фильтры сообщений позволят не пропустить: мат, ссылки, telegram ссылки и пересланные сообщения из других чатов</li>
          <li>Триггеры - позволят задавать собственные условия и действия которые боту необходимо предпринять при их выполнении</li>
          <li>Статистика - анализируйте количество подписавшихся, отписавшихся, кикнутых и забаненных пользователей</li>
        </ul>
      </div>

      <div>
        <h5 class="fw-bold mt-3">Визуальный конструктор ботов</h5>
        <ul class="ms-4">
          <li class="mb-2">
            Вам необходимо реализовать автоворонку продаж (сценарий работы бота) в Telegram? в зависимости от ответа пользователя менять алгоритм воронки? - тогда наш сервис точно для вас.
            Создавайте уникальных ботов, опрашивайте пользователей, выгружайте ответы и используйте встроенные инструменты статистики для анализа.
            А встроенный механизм записи на прием станет идеальным дополнением - записывайте клиентов на прием к психологу, стоматологу и т.д используя автоматизацию.
<!--            <p class="text-danger">todo: Тут примеры воронок с ссылками на шаблон и самого бота</p>-->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";

export default defineComponent({
  name: "DocsGetStarted",
  components: {
    Image,
  },
})
</script>