import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "text-center pb-4 rounded-top node_bg_custom",
  style: {"width":"600px"}
}
const _hoisted_2 = { class: "content-trigger-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleNode = _resolveComponent("TitleNode")!
  const _component_CommandItem = _resolveComponent("CommandItem")!
  const _component_SourceHandle = _resolveComponent("SourceHandle")!
  const _component_TargetHandle = _resolveComponent("TargetHandle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TitleNode, {
      "node-uuid": this.data.uuid,
      "node-name": this.data.name,
      "node-position": this.data.position,
      items: this.items,
      color: "#cf2a2a",
      onPressedRemove: _cache[0] || (_cache[0] = () => { this.$emit('remove', this.data.uuid, true) })
    }, null, 8, ["node-uuid", "node-name", "node-position", "items"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.data.commands, (element, index) => {
        return (_openBlock(), _createBlock(_component_CommandItem, {
          key: element.uuid,
          command_group_uuid: this.data.uuid,
          modelValue: this.data.commands[index],
          "onUpdate:modelValue": ($event: any) => ((this.data.commands[index]) = $event),
          onPressedButtonRemove: ($event: any) => {this.data.commands.splice(index, 1); this.isShowAddCommandButton = true},
          onCompletedFilledCommandItem: _cache[1] || (_cache[1] = ($event: any) => (this.isShowAddCommandButton = true)),
          "read-only": this.readOnly,
          "context-options": this.contextOptions,
          "is-edit-mode": !this.readOnly,
          "command-options": this.graphConfigStorage.getCommandTypes,
          "read-context-options": []
        }, null, 8, ["command_group_uuid", "modelValue", "onUpdate:modelValue", "onPressedButtonRemove", "read-only", "context-options", "is-edit-mode", "command-options"]))
      }), 128))
    ]),
    _createVNode(_component_SourceHandle),
    _createVNode(_component_TargetHandle)
  ]))
}