<template>
  <div class="bg-white p-2">
    <Breadcrumbs :items="this.breadcrumbs"/>

    <TriggerForm
        class="mt-2"
        :errors="this.errors"
        v-model="this.scenarioTrigger"
    />

    <div class="mt-5 d-flex justify-content-end" v-if="!this.graphConfigStorage.isSharedTemplate">
      <Button
          label="Сохранить"
          icon="pi pi-check"
          severity="contrast"
          class="rounded"
          :loading="this.updateLoading"
          @click="() => {
            this.updateLoading = true
            this.errors = []

            updateScenarioTrigger(this.scenarioTrigger)
              .then(() => {})
              .catch((error: ErrorResponse) => {
                this.errors = error.getValidationErrors()
              })
              .finally(() => {this.updateLoading = false})
            }
          "
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, provide, ref} from 'vue'
import {getScenarioTriggerItemsForCard, updateScenarioTrigger, UpdateScenarioTriggerCmd} from "@/api/scenarioTrigger";
import TriggerForm from "@/components/view/Triggers/TriggerForm.vue";
import Button from "primevue/button";
import {ErrorResponse} from "@/api/common";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";

export default defineComponent({
  name: "TriggerForCard",
  computed: {
    ErrorResponse() {
      return ErrorResponse
    }
  },
  methods: {updateScenarioTrigger},
  components: {Breadcrumbs, Button, TriggerForm},
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    },
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  data: function () {
    return {
      scenarioTrigger: new UpdateScenarioTriggerCmd(this.scenario_uuid,  this.uuid),
      updateLoading: false,
      errors: [],
      breadcrumbs: [],
    }
  },
  created() {
    getScenarioTriggerItemsForCard(this.uuid).then((res: UpdateScenarioTriggerCmd) => {
      this.scenarioTrigger = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.dialog_name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: res.dialog_uuid}),
        new BreadcrumbItem('Триггеры', '', 'triggers', {scenario_uuid: this.scenario_uuid, dialog_uuid: res.dialog_uuid}),
        new BreadcrumbItem(res.name),
      ]
    })
  }
})
</script>