<template>
  <div>
    <HelpInfo route-doc="doc_triggers">
      Документация по триггерам
    </HelpInfo>

    <div class="mt-3">
      <label class="required d-block">Название триггера:</label>
      <InputText
          :disabled="this.graphConfigStorage.isSharedTemplate"
          v-model="this.modelValue.name"
      />
      <p class="p-error">{{this.errors['name']}}</p>
    </div>

    <div class="mt-2">
      <InputSwitch
          :disabled="this.graphConfigStorage.isSharedTemplate"
          v-model="this.modelValue.active"
          class="me-2 align-middle"
      />
      <label class="align-middle">Активность</label>
    </div>

    <div class="mt-2">
      <InputSwitch
          :disabled="this.graphConfigStorage.isSharedTemplate"
          v-model="this.modelValue.continue_check_triggers"
          class="me-2 align-middle"
      />
      <label class="align-middle">При срабатывании продолжить проверку оставшихся триггеров</label>
    </div>

    <div class="mt-2">
      <InputSwitch
          :disabled="this.graphConfigStorage.isSharedTemplate"
          v-model="this.modelValue.handle_edit_message"
          class="me-2 align-middle"
      />
      <label class="align-middle">Проверять отредактированные сообщения</label>
    </div>

    <div class="mt-2 d-flex justify-content-between">
      <div>
        <p class="m-0">Приоритет срабатывания <span class="pi pi-question-circle" v-tooltip="'Триггеры с наивысшим приоритетом проверяются первыми'"/></p>
        <InputNumber
            :disabled="this.graphConfigStorage.isSharedTemplate"
            v-model="this.modelValue.priority"
        />
      </div>

      <div v-show="this.modelValue instanceof UpdateScenarioTriggerCmd">
        <span class="me-2 pi pi-spin pi-sync" v-show="this.copyLoading"/>
        <span class="text-muted">Копировать триггер в чат</span>
        <DialogTagPanel
            :dialog-types="[DialogType.group]"
            :scenario-uuid="this.scenario_uuid"
            v-model="this.dialogs"
            @modelValue:update-value="this.onClickCopyTrigger"
        />
      </div>
    </div>
    <Divider/>

    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div>
            <div class="d-flex justify-content-center">
              <div>
                <span class="required">Группы условий</span>
                <p class="p-error">{{this.errors['triggers']}}</p>
              </div>
            </div>
            <span class="pi pi-question-circle me-1"></span>
            <span class="text-muted">
              Чтобы триггер начал выполнять <span class="fw-bold">группы действий</span>, необходимо чтобы <span class="fw-bold">ВСЕ</span>
              условия внутри хотя-бы одной из <span class="fw-bold">групп условий</span> выполнились.
            </span>
          </div>
        </div>
        <div class="col d-flex justify-content-center">
          <div>
            <div class="d-flex justify-content-center">
              <div>
                <span class="required">Группы действий</span>
                <p class="p-error">{{this.errors['commands']}}</p>
              </div>
            </div>
            <span class="pi pi-question-circle me-1"></span>
            <span class="text-muted">
              Действия внутри одной <span class="fw-bold">группы действий</span> выполняются последовательно, между разными <span class="fw-bold">группами действий</span> - параллельно.
              Зажмите <span class="fw-bold">действие</span> мышкой и перемещайте чтобы добиться желаемой последовательности.
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="container p-0">
      <div class="row">
        <div class="col p-0 m-0">
          <div class="me-2">
            <div v-for="(triggerGroup, triggerGroupIndex) in this.modelValue.triggers" :key="triggerGroup.uuid">
              <div class="mt-2 rounded custom-border" style="background: rgba(245, 245, 245, 0.81);">

                <div class="d-flex justify-content-center align-items-center p-2 custom-border">
                  <div class="text-center flex-grow-1">
                    <p>Группа условий</p>
                  </div>
                  <button
                      type="button"
                      v-if="!this.graphConfigStorage.isSharedTemplate"
                      class="pi pi-trash border-0 bg-transparent text-danger"
                      @click="() => {
                        this.modelValue.triggers.splice(triggerGroupIndex, 1)
                      }"
                  />
                </div>
                <p class="p-error text-center">{{this.errors['triggers[' + triggerGroupIndex + '].items']}}</p>

                <div class="m-2">
                  <div v-for="(trigger, triggerIndex) in triggerGroup.items">
                    <TriggerItem
                        class="custom-border rounded"
                        :context-options="this.contextOptionsForTrigger"
                        :key="trigger.uuid"
                        :is-edit-mode="!this.graphConfigStorage.isSharedTemplate"
                        v-model="this.modelValue.triggers[triggerGroupIndex].items[triggerIndex]"
                        @pressed-button-remove="() => {
                          this.modelValue.triggers[triggerGroupIndex].items.splice(triggerIndex, 1)
                        }"
                        :errors="ErrorResponse.getValidationErrorsNested(this.errors, 'triggers[' + triggerGroupIndex + '].items[' + triggerIndex + ']')"
                        :trigger-options="this.graphConfigStorage.getScenarioTriggerTargetTypes"
                    />

                    <p class="text-center m-2 fw-bold" v-show="triggerIndex !== (this.modelValue.triggers[triggerGroupIndex].items.length - 1)">И</p>
                  </div>
                </div>

                <div class="d-flex justify-content-center"  v-if="!this.graphConfigStorage.isSharedTemplate">
                  <Button
                      label="Добавить условие"
                      severity="secondary"
                      class="rounded p-2 m-2"
                      @click="() => {
                        this.modelValue.triggers[triggerGroupIndex].items.push(new Trigger)
                      }"
                  />
                </div>
              </div>

              <p class="text-center m-4 fw-bold" v-show="triggerGroupIndex !== (this.modelValue.triggers.length - 1)">ИЛИ</p>
            </div>

            <div class="d-flex justify-content-center mt-4"  v-if="!this.graphConfigStorage.isSharedTemplate">
              <Button
                  label="Добавить группу условий"
                  severity="secondary"
                  class="rounded p-2"
                  @click="() => {
                  this.modelValue.triggers.push(new TriggerGroupItem())
                }"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="ms-2">
            <div v-for="(commandGroup, commandGroupIndex) in this.modelValue.commands" :key="commandGroup.uuid">
              <div class="mt-2 rounded custom-border" style="background: rgba(245, 245, 245, 0.81);">
                <div class="d-flex justify-content-center align-items-center p-2 custom-border">
                  <div class="text-center flex-grow-1">
                    <p>Группа действий</p>
                  </div>
                  <button
                      type="button"
                      v-if="!this.graphConfigStorage.isSharedTemplate"
                      class="pi pi-trash border-0 bg-transparent text-danger"
                      @click="() => {
                    this.modelValue.commands.splice(commandGroupIndex, 1)
                  }"
                  />
                </div>
                <p class="p-error text-center">{{this.errors['commands[' + commandGroupIndex + '].items']}}</p>

                <div class="m-2">
                  <draggable
                      v-model="commandGroup.items"
                      item-key="uuid">
                    <template #item="{element, index}">
                      <div>
                        <CommandItem
                            :is-edit-mode="!this.graphConfigStorage.isSharedTemplate"
                            :read-only="this.graphConfigStorage.isSharedTemplate"
                            :context-options="this.contextOptions"
                            :read-context-options="this.readContextOptions"
                            class="custom-border rounded"
                            v-model="this.modelValue.commands[commandGroupIndex].items[index]"
                            @pressed-button-remove="() => {
                            this.modelValue.commands[commandGroupIndex].items.splice(index, 1)
                          }"
                            :command-options="this.graphConfigStorage.getScenarioTriggerCommandTypes"
                        />
                        <p class="text-center m-2 fw-bold" v-show="index !== (this.modelValue.commands[commandGroupIndex].items.length - 1)">потом</p>
                      </div>
                    </template>
                  </draggable>
                </div>

                <div class="d-flex justify-content-center"  v-if="!this.graphConfigStorage.isSharedTemplate">
                  <Button
                      label="Добавить действие"
                      severity="secondary"
                      class="rounded p-2 m-2"
                      @click="() => {
                        this.modelValue.commands[commandGroupIndex].items.push(new Command())
                      }"
                  />
                </div>
              </div>

              <p class="text-center m-4 fw-bold" v-show="commandGroupIndex !== (this.modelValue.commands.length - 1)">ВЫПОЛНИТЬ ПАРАЛЛЕЛЬНО</p>
            </div>

            <div class="d-flex justify-content-center mt-4" v-if="!this.graphConfigStorage.isSharedTemplate">
              <Button
                  label="Добавить группу действий"
                  severity="secondary"
                  class="rounded p-2"
                  @click="() => {
                    this.modelValue.commands.push(new CommandGroupItem())
                  }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import TriggerItem from "@/components/view/Bots/Trigger/TriggerItem.vue";
import Divider from "primevue/divider";
import CommandItem from "@/components/view/Bots/Command/CommandItem.vue";
import InputText from "primevue/inputtext";
import {
  CommandGroupItem,
  copyScenarioTrigger,
  CreateScenarioTriggerCmd,
  TriggerGroupItem,
  UpdateScenarioTriggerCmd
} from "@/api/scenarioTrigger";
import {Command, Trigger} from "@/api/graph";
import InputSwitch from "primevue/inputswitch";
import draggable from "vuedraggable";
import {ErrorResponse} from "@/api/common";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import InputNumber from "primevue/inputnumber";
import HelpInfo from "@/components/view/HelpInfo.vue";
import SearchDialog from "@/components/common/SearchDialog.vue";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";
import {ContextItemForBuilder, fetchContextListForBuilder} from "@/api/context";
import Menu from "primevue/menu";
import {SourceItemResult} from "@/api/forwarder";
import {info} from "@/api/ToastService";
import {getConfig} from "@/api/edge";

export default defineComponent({
  name: "TriggerForm",
  computed: {
    UpdateScenarioTriggerCmd() {
      return UpdateScenarioTriggerCmd
    },
    DialogType() {
      return DialogType
    },
    ErrorResponse() {
      return ErrorResponse
    },
    CommandGroupItem() {
      return CommandGroupItem
    },
    Command() {
      return Command
    },
    TriggerGroupItem() {
      return TriggerGroupItem
    },
    Trigger() {
      return Trigger
    },
  },
  components: {
    Menu,
    DialogTagPanel,
    SearchDialog,
    HelpInfo,
    draggable,
    InputSwitch,
    InputText, 
    CommandItem, 
    Divider, 
    TriggerItem, 
    Checkbox, 
    Button,
    InputNumber,
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<CreateScenarioTriggerCmd>,
    },
    errors: {
      required: true,
      type: Array as PropType<Array<string, string>>
    },
  },
  emits: [
    'update:modelValue'
  ],
  data: function () {
    return {
      contextOptions: [] as ContextItemForBuilder[],
      readContextOptions: [] as ContextItemForBuilder[],
      contextOptionsForTrigger: [] as ContextItemForBuilder[],

      dialogs: [],
      copyLoading: false,
    }
  },
  inject: [
      'scenario_uuid'
  ],
  created() {
    fetchContextListForBuilder(this.scenario_uuid).then(res => {
      this.contextOptions = res.global_custom_variables
          .concat(res.person_custom_variables)

      this.contextOptionsForTrigger = res.global_custom_variables
          .concat(res.person_custom_variables)

      this.readContextOptions = res.global_custom_variables
          .concat(res.global_system_variables)
          .concat(res.person_custom_variables)
          .concat(res.person_system_variables)
    })

    getConfig(this.scenario_uuid).then((r) => {
      this.graphConfigStorage.updateFromServerData(r)
    })
  },
  methods: {
    onClickCopyTrigger: function (event: SourceItemResult[]) {
      this.dialogs = []

      if (!this.modelValue instanceof UpdateScenarioTriggerCmd) {
        return
      }

      this.copyLoading = true

      copyScenarioTrigger(this.modelValue.uuid, event[0].uuid)
          .then(() => {
            info('', 'Триггер успешно скопирован в "' + event[0].name + '"')
          })
          .finally(() => { this.copyLoading = false })
    }
  }
})
</script>
