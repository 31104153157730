import {
    CommandGroupNode,
    DelayBlockNode,
    Edge,
    EdgeType,
    MessageNode,
    QuestionNode,
    StartTriggerNode,
    TriggerGroupNode
} from "./graph";
import {NodeType} from "./scenario";
import {Elements, MarkerType} from "@vue-flow/core";

export function addMessageNodeToElements(messageNode: MessageNode, elements: Elements): void
{
    elements.push({
        id: messageNode.uuid,
        label: messageNode.name,
        position: {
            x: messageNode.position.pos_x,
            y: messageNode.position.pos_y,
        },
        type: NodeType.messageNode,
        data: messageNode as MessageNode,
    })
}

export function addDelayNodeToElements(delayNode: DelayBlockNode, elements: Elements): void
{
    elements.push({
        id: delayNode.uuid,
        label: delayNode.name,
        position: {
            x: delayNode.position.pos_x,
            y: delayNode.position.pos_y,
        },
        type: NodeType.delayNode,
        data: delayNode as DelayBlockNode,
    })
}

export function addStartTriggerNodeToElements(startNode: StartTriggerNode, elements: Elements): void
{
    elements.push({
        id: startNode.uuid,
        label: startNode.name,
        position: {
            x: startNode.position.pos_x,
            y: startNode.position.pos_y,
        },
        type: NodeType.startNode,
        data: startNode,
    })
}

export function addEdgeToElements(edge: Edge, elements: Elements): void
{
    let params = {
        id: edge.uuid,
        source: edge.source_node_uuid,
        target: edge.target_node_uuid,
        markerEnd: MarkerType.ArrowClosed,
        sourceHandle: EdgeType.common as string,
        type: 'button',
        style: {
            strokeWidth: '2px',
        },
    }

    if (edge.type !== EdgeType.common) {
        params.sourceHandle = edge.type
    }

    if (edge.type === EdgeType.button) {
        params.sourceHandle = edge.button_uuid
    }

    elements.push(params)
}

export function addQuestionNodeToElements(questionNode: QuestionNode, elements: Elements): void
{
    elements.push({
        id: questionNode.uuid,
        label: questionNode.name,
        position: {
            x: questionNode.position.pos_x,
            y: questionNode.position.pos_y,
        },
        type: NodeType.questionNode,
        data: questionNode as QuestionNode,
    })
}

export function addCommandNodeToElements(commandGroup: CommandGroupNode, elements:Elements): void
{
    elements.push({
        id: commandGroup.uuid,
        label: commandGroup.name,
        position: {
            x: commandGroup.position.pos_x,
            y: commandGroup.position.pos_y,
        },
        type: NodeType.commandGroup,
        data: commandGroup as CommandGroupNode,
    })
}

export function addTriggerNodeToElements(triggerGroup: TriggerGroupNode, elements:Elements): void
{
    elements.push({
        id: triggerGroup.uuid,
        label: triggerGroup.name,
        position: {
            x: triggerGroup.position.pos_x,
            y: triggerGroup.position.pos_y,
        },
        type: NodeType.triggerGroup,
        data: triggerGroup as TriggerGroupNode,
    })
}
