import {AxiosResponse} from "axios";
import client from './client';
import {plainToInstance} from "class-transformer";
import {DelayBlockNode} from "@/api/graph";

export async function updateDelayNode(cmd: DelayBlockNode): Promise<DelayBlockNode> {
    // @ts-ignore
    return client
        .post('/api/v1/delay/update', cmd)
        .then((response: AxiosResponse) => {
            return plainToInstance(DelayBlockNode, response.data);
        })
}

export async function createDelayNode(cmd: DelayBlockNode): Promise<DelayBlockNode>
{
    return client
        .post('/api/v1/delay/create', cmd)
        .then((response: AxiosResponse) => {
            return plainToInstance(DelayBlockNode, response.data);
        });
}

