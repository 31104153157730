<template>
  <div>
    <Filter v-model="this.card" :context-options="this.contextOptions" />

    <Divider/>

    <div>
      <p>Разрешенный ссылки:</p>

      <WordsList
          :show-word-types="[WordType.fullEquals]"
          :words="this.card.white_list"
          label-add-word-button="Добавить ссылку"
          @words:update="($event) => { this.card.white_list = $event }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import InputSwitch from "primevue/inputswitch";
import Filter from "@/components/view/GroupComponents/Filter.vue";
import {ContextOption, FilterLink} from "@/api/groupSettings";
import WordsList from "@/components/view/GroupComponents/WordsList.vue";
import {WordType} from "@/api/dictionary";
import Divider from "primevue/divider";

export default defineComponent({
  name: "FilterLink",
  computed: {
    WordType() {
      return WordType
    }
  },
  components: {WordsList, InputSwitch, Filter, Divider},
  props: {
    filter: {
      type: Object as PropType<FilterLink>,
      required: true,
    },
    contextOptions: {
      type: Array as PropType<ContextOption>,
      required: true,
    }
  },
  data: function () {
    return {
      card: this.filter,
    }
  },
  emits: ['update:filter'],
  watch: {
    card: function (val) {
      this.$emit('update:filter', val)
    },

    filter: function (val) {
      this.card = val
    },
  }
})
</script>