<template>
  <Calendar
      class="w-100"
      v-model="this.value"
      :show-button-bar="this.showBar"
      :invalid="this.invalid"
      :disabled="this.disabled"
      :placeholder="this.placeholder"
      date-format="dd-mm-yy"
      :show-time="this.showTime"
      :manual-input="false"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {CalendarState} from "primevue/calendar";
import Calendar from "primevue/calendar";

export default defineComponent({
  name: 'DateInput',
  props: {
    modelValue: {
      required: true,
      type: [String, Date, null],
    },
    invalid: {
      required: false,
      default: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    showBar: {
      required: false,
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    showTime: {
      required: false,
      default: false,
      type: Boolean,
    }
  },
  emits: [
    'update:modelValue'
  ],
  components: {
    Calendar,
  },
  methods: {
    // Закрываем календарь при потери фокуса
    focusOut: function () {
      const vuePrimeCalendar = this.$refs.calendar as CalendarState;
      vuePrimeCalendar.overlayVisible = false;
    },
  },
  computed: {
    value: {
      get: function (): any {
        return this.modelValue
      },
      set: function (value: Date|null) {
        if (!value) {
          this.$emit('update:modelValue', null)
          return
        }

        this.$emit('update:modelValue', value);
      }
    }
  }
})
</script>
