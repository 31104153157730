import {defineStore} from "pinia";
import {GraphConfigCommandType, GraphConfigResult, GraphConfigTriggerType, ViewPort} from "../api/graph";
import {ref} from "vue";
import {GetDictionaryForListItem} from "../api/dictionary";
import {SearchDialogItem} from "../api/edge";


export const useGraphConfigStorage = defineStore('GraphConfigStorage', {
    state: (): GraphConfigResult => {
        return {
            viewport: ref(new ViewPort()),
            viewed_alert_how_to_use_constructor: ref(true),
            is_shared_template: ref(false),
            start_trigger_target_types: ref([]),
            trigger_group_target_types: ref([]),
            command_types: ref([]),
            tags: ref([]),
            scenario_trigger_target_types: ref([]),
            scenario_trigger_command_types: ref([]),
            dictionaries: ref([]),
            name: ref(''),
            groups: ref([]),
            link_to_telegram: ref(''),
        }
    },
    getters: {
        isSharedTemplate: function (state: GraphConfigResult): boolean {
            return state.is_shared_template
        },

        getScenarioTriggerTargetTypes: function (state: GraphConfigResult): GraphConfigTriggerType[]
        {
            return state.scenario_trigger_target_types
        },

        getScenarioTriggerCommandTypes: function (state: GraphConfigResult): GraphConfigCommandType[]
        {
            return state.scenario_trigger_command_types
        },

        getTriggerGroupTargetTypes: function (state: GraphConfigResult): GraphConfigTriggerType[]
        {
            return state.trigger_group_target_types
        },

        getStartTriggerTargetTypes: function (state: GraphConfigResult): GraphConfigTriggerType[]
        {
            return state.start_trigger_target_types
        },

        getCommandTypes: function (state: GraphConfigResult): GraphConfigCommandType[]
        {
            return state.command_types
        },

        getDictionaries: function (state: GraphConfigResult): GetDictionaryForListItem[]
        {
            return state.dictionaries
        },

        getGroups: function (state: GraphConfigResult): SearchDialogItem[]
        {
            return state.groups
        },

        getLinkToTelegram: function (state: GraphConfigResult): string
        {
            return state.link_to_telegram
        },

        getName: function (state: GraphConfigResult): string {
            return state.name
        }
    },
    actions: {
        updateFromServerData(config: GraphConfigResult) {
            this.viewport                            = config.viewport
            this.viewed_alert_how_to_use_constructor = config.viewed_alert_how_to_use_constructor
            this.is_shared_template                  = config.is_shared_template
            this.start_trigger_target_types          = config.start_trigger_target_types
            this.trigger_group_target_types          = config.trigger_group_target_types
            this.command_types                       = config.command_types
            this.tags                                = config.tags
            this.scenario_trigger_target_types       = config.scenario_trigger_target_types
            this.scenario_trigger_command_types      = config.scenario_trigger_command_types
            this.dictionaries                        = config.dictionaries
            this.name                                = config.name
            this.groups                              = config.groups
            this.link_to_telegram                    = config.link_to_telegram
        },
    },
    persist: true,
})