import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createBlock(_component_Textarea, {
    modelValue: this.msg,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((this.msg) = $event)),
      _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    ],
    disabled: this.disabled,
    "auto-resize": true,
    invalid: this.invalid
  }, null, 8, ["modelValue", "disabled", "invalid"]))
}