import client from "./client";
import {UpdateGroupSettings} from "./groupSettings";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToInstance} from "class-transformer";


export class DialogUpdateJoinRequest
{
    active: boolean = false
    name: string
}

export async function updateDialogJoinRequestSettings(dialogUuid: string, command: DialogUpdateJoinRequest): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/dialog/' + dialogUuid + '/join-request/update', command)
}

export async function getDialogJoinRequestSettings(dialogUuid: string): Promise<DialogUpdateJoinRequest>
{
    return await client
        .post('/api/v1/dialog/' + dialogUuid + '/join-request/get-for-card')
        .then((response: AxiosResponse) => {
            return plainToInstance(DialogUpdateJoinRequest, response.data);
        })
}
