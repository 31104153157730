<template>
  <div class="text-center rounded-top node_bg_custom" style="width: 600px;">
    <TitleNode
        :node-uuid="this.data.uuid"
        :node-name="this.data.name"
        :node-position="this.data.position"
        :items="this.items"
        color="#68695c"
        @pressed-remove="() => { this.$emit('remove', this.data.uuid, true) }"
    />

    <EditableContent :is-read-only="true">
      <div class="m-2">
        <Editor v-model="this.data.text" :readonly="true" :pt="{toolbar: {hidden: true}}"/>

        <ButtonsPanel
            :buttons="this.data.buttons"
        >
          <template #button_footer="slotProps">
            <div class="d-inline" v-if="slotProps.button.uuid && slotProps.button instanceof ButtonScenario">
              <SourceHandle style="position: relative" :id="slotProps.button.uuid"/>
            </div>
          </template>
        </ButtonsPanel>

        <FilesViewer v-model="this.data.files" :show-remove-button="false"/>

        <SourceHandle :id="EdgeType.common"/>
        <TargetHandle/>
      </div>
    </EditableContent>
  </div>
</template>

<script lang="ts">
import {Handle, Position} from "@vue-flow/core";
import {defineComponent, PropType} from "vue";
import {EdgeType, MessageNode} from "@/api/graph";
import Fieldset from "primevue/fieldset";
import InputGroup from "primevue/inputgroup";
import Button from "primevue/button";
import TitleNode from "@/components/view/Bots/TitleNode.vue";
import ButtonsPanel from "@/components/view/Bots/ButtonsPanel.vue";
import EditableContent from "@/components/view/Bots/EditableContent.vue";
import {MenuItem} from "primevue/menuitem";
import SelectButton from "primevue/selectbutton";
import TextAreaInput from "@/components/common/TextAreaInput.vue";
import TargetHandle from "@/components/view/Bots/TargetHandle.vue";
import SourceHandle from "@/components/view/Bots/SourceHandle.vue";
import Checkbox from "primevue/checkbox";
import SearchDialog from "@/components/common/SearchDialog.vue";
import SearchDialogMultiple from "@/components/common/SearchDialogMultiple.vue";
import ImageUploadAndViewer from "@/components/common/ImageUploadAndViewer.vue";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Editor from "primevue/editor";
import FilesViewer from "@/components/common/FilesViewer.vue";
import {ButtonScenario} from "@/api/buttonGroupPanel";


export default defineComponent({
  name: 'Message',
  props: {
    data: {
      type: Object as PropType<MessageNode>,
      required: true,
    },
  },
  emits: [
    'remove',
    'data:update',
  ],
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  computed: {
    ButtonScenario() {
      return ButtonScenario
    },
    EdgeType() {
      return EdgeType
    },
    Position() {
      return Position
    },
    items: function (): MenuItem[] {
      if (this.graphConfigStorage.isSharedTemplate) {
        return []
      }

      let result = [] as MenuItem[]

      if (this.readOnly) {
        result.push({
          label: 'Редактировать',
          icon: 'pi pi-file-edit',
          command: () => {
            this.readOnly = false
          },
        })
      }

      result.push({
        label: 'Удалить',
        icon: 'pi pi-trash',
        command: () => {
          this.$emit('remove', this.data.uuid, true)
        }
      })

      return result
    },
  },
  components: {
    FilesViewer,
    Editor,
    RichTextEditor,
    ImageUploadAndViewer,
    SearchDialogMultiple,
    SearchDialog,
    SourceHandle,
    TargetHandle,
    TextAreaInput,
    EditableContent,
    TitleNode,
    Handle,
    Fieldset,
    InputGroup,
    Button,
    ButtonsPanel,
    SelectButton,
    Checkbox,
  },
  data: function () {
    return {
      readOnly: true,

      sendMessageToSameDialog: this.data.send_message_to_dialogs.length === 0,
      selectedDialogUuid: [],
    }
  },
})
</script>
