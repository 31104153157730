import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabView, {
      onTabChange: this.handleTabChange,
      pt: {panelContainer: {hidden: true}},
      "active-index": this.activeTabIndex
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getMenuItems(), (item) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            header: item.header
          }, null, 8, ["header"]))
        }), 256))
      ]),
      _: 1
    }, 8, ["onTabChange", "active-index"]),
    _createVNode(_component_router_view)
  ]))
}