import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc01815c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "p-4",
  style: {"background":"white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullCalendar = _resolveComponent("FullCalendar")!
  const _component_EventRecordCard = _resolveComponent("EventRecordCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FullCalendar, {
      options: this.calendarOptions,
      ref: "calendar"
    }, null, 8, ["options"]),
    _createVNode(_component_EventRecordCard, {
      uuid: this.selectRecordUuid,
      onCloseRecordItem: _cache[0] || (_cache[0] = ($event: any) => (this.selectRecordUuid = null))
    }, null, 8, ["uuid"])
  ]))
}