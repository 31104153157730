<template>
  <Textarea
      v-model="this.msg"
      @update:modelValue="$emit('update:modelValue', $event)"
      :disabled="this.disabled"
      :auto-resize="true"
      :invalid="this.invalid"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Textarea from "primevue/textarea";


// todo: завести входной параметр для приема списка названий существующих переменных с их названиями
//    (при наведении на переменную, отображается подсказка)
// todo: искать в шаблоне сообщения/вопроса только по списку переменных
// <!--      todo: сейчас не подкрашивается textarea при невалидном значении, в будущем поправить -->

export default defineComponent({
  name: 'TextAreaInput',
  components: {
    Textarea,
  },
  props: {
    modelValue: {
      required: true,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    invalid: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      msg: this.modelValue as string,
    }
  },
  emits: [
    'update:modelValue'
  ],
})
</script>

<style>

</style>
<script setup>
</script>
