import {AxiosResponse} from "axios";
import {CommandGroupNode, CommandType, GraphConfigCommandType} from "@/api/graph";
import {plainToClass} from 'class-transformer';
import client from "./client";

// TODO: Переименовать и перенести в граф.ts
export class SendMailItem {
    to: string = '';
    content: string = '';
}

export class AddTagToSubscriber
{
    uuid: string
    name: string
}

export async function updateCommandGroup(commandGroupNode: CommandGroupNode): Promise<CommandGroupNode> {

    const response: AxiosResponse = await client.post('/api/v1/command-group/update', commandGroupNode)
    const responseData = response.data;
    return plainToClass(CommandGroupNode, responseData);
}