<template>
  <div>
    <div>
      <InputSwitch
          class="align-middle me-1"
          v-model="this.filter.active"
      />
      <span class="align-middle">Активность</span>
    </div>

    <div class="d-flex justify-content-between mt-2">
      <div>
        <p class="m-0 required">Через какое время снять нарушение</p>
        <Period v-model="this.filter.reset_warning_after"/>
      </div>
      <div class="mt-auto">
        <Dropdown
            :options="this.contextOptions"
            placeholder="Переменная"
            option-label="description"
            option-value="code"
            @update:model-value="($event) => {
              this.filter.warning_text = this.filter.warning_text + $event
            }"
        />
      </div>
    </div>

    <div class="mt-2">
      <p class="text-muted m-0">Общий текст предупреждения</p>
      <RichTextEditor v-model:model-value="this.filter.warning_text"/>
    </div>

    <div class="mt-2 bg-secondary-subtle p-2 rounded bg-secondary-subtle" v-for="(action, index) in this.filter.actions">
      <div class="d-flex justify-content-between">
        <p class="m-0">Наказание при <span class="fw-bold">{{ this.convertIndexToPrintNumber(index) }}</span> {{ (this.filter.length - 1) === index ? ' и последующих нарушениях' : 'нарушении' }}</p>
        <button
            class="pi pi-trash border-0 bg-transparent text-danger"
            @click="() => {
            this.filter.actions.splice(index, 1)
          }"
        />
      </div>

      <FilterActionWrapper
          :action="action"
          @update:action="($event) => { this.modelValue.actions[index] = $event }"
          :context-options="this.contextOptions"
      />
    </div>

    <!--    todo: брать 5 из конфига    -->
    <Button
        v-show="this.filter.actions.length < 5"
        label="Добавить наказание"
        class="rounded mt-2"
        icon="pi pi-plus"
        @click="() => {
          this.filter.actions.push(new FilterActionNothingDo())
        }"
    />

    <Divider/>

    <div>
      <InputSwitch
          class="align-middle me-1"
          v-model="this.filter.remove_violation_message"
      />
      <span class="align-middle">Удалять сообщение нарушителя</span>
    </div>

    <div class="mt-1">
      <InputSwitch
          class="align-middle me-1"
          v-model="this.removeWarningMessage"
      />
      <span class="align-middle">Удалять сообщение о нарушении</span>

      <div v-if="this.removeWarningMessage" class="mt-2">
        <p class="m-0 required">Удалять через</p>
        <Period v-model="this.filter.remove_warning_message_after" :show-all-units="true"/>
      </div>
    </div>

    <Divider/>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {AbstractFilter, ContextOption} from "@/api/groupSettings";
import Button from "primevue/button";
import FilterAction from "@/components/view/GroupComponents/FilterAction.vue";
import {FilterActionMuteSubscriber, FilterActionNothingDo} from "@/api/abuseFilter";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import FilterActionWrapper from "@/components/view/GroupComponents/FilterActionWrapper.vue";
import InputSwitch from "primevue/inputswitch";
import {Period as PeriodDto} from "@/api/periodicMessage";
import {TimeUnit} from "@/api/graph";
import Period from "@/components/common/Period.vue";

export default defineComponent({
  name: "Filter",
  components: {
    InputSwitch,
    FilterActionWrapper,
    Dropdown,
    Divider,
    RichTextEditor,
    FilterAction,
    Button,
    Period
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<AbstractFilter>,
    },
    contextOptions: {
      required: true,
      type: Array as PropType<ContextOption[]>,
    }
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    FilterActionNothingDo() {
      return FilterActionNothingDo
    },
    FilterActionMuteSubscriber() {
      return FilterActionMuteSubscriber
    },
    modelData: {
      set: function (val) {
        //   todo:
      },
      get: function () {
        return this.modelValue
      },
    },

    removeWarningMessage: {
      set: function (val) {
        if (val) {
          this.filter.remove_warning_message_after = new PeriodDto(30, TimeUnit.second)
        } else {
          this.filter.remove_warning_message_after = null
        }
      },
      get: function () {
        return this.filter.remove_warning_message_after !== null
      }
    }
  },
  methods: {
    getTimeUnits: function () {
      return [
        {
          label: 'минут',
          value: TimeUnit.minute,
        },
        {
          label: 'часа',
          value: TimeUnit.hour,
        },
        {
          label: 'дней',
          value: TimeUnit.day,
        },
      ]
    },

    convertIndexToPrintNumber: function (index: number): string {
      switch (index) {
        case 0:
          return 'первом'

        case 1:
          return 'втором'

        case 2:
          return 'третьем'

        case 3:
          return 'четвертом'

        case 4:
          return 'пятом'

        case 5:
          return 'шестом'
      }

      return (index + 1) as string
    }
  },
  data: function () {
    return {
      filter: this.modelValue,
    }
  },
  watch: {
    modelValue: function (val) {
      this.filter = val
    },
    
    filter: function (val) {
      this.$emit('update:modelValue', val)
    }
  }
})
</script>