import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Handle = _resolveComponent("Handle")!

  return (_openBlock(), _createBlock(_component_Handle, {
    id: this.id,
    type: "source",
    position: _ctx.Position.Right,
    style: _normalizeStyle(this.style),
    connectable: this.connectableHandle
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["id", "position", "style", "connectable"]))
}