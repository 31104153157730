import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "p-2 vh-100 gridmap",
  style: {"overflow-y":"auto"}
}
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "m-4 custom-border rounded p-3 bg-white" }
const _hoisted_4 = { class: "d-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumbs, {
      items: this.breadcrumbs,
      class: "bg-white"
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.features, (feature) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          to: {name: feature.route_name, params: feature.route_params},
          class: "text-decoration-none text-black"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("span", {
                  class: _normalizeClass([feature.icon, "me-3"])
                }, null, 2),
                _createElementVNode("h5", _hoisted_4, _toDisplayString(feature.name), 1)
              ]),
              _createElementVNode("p", {
                class: _normalizeClass(["m-1 text-muted", {'fs-5': feature.bold, 'fw-bold': feature.bold}])
              }, _toDisplayString(feature.description), 3)
            ])
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 256))
    ])
  ]))
}