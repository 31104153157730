<template>
  <div class="m-2" style="border: 2px dashed #ddd;">
    <p class="mt-0 mb-2 fw-bold" :hidden="this.readOnly">Изображения:</p>

    <div>
<!--      <video controls width="250" v-for="imageUrl in this.imageUrls">-->
<!--        <source :src="imageUrl.url" type="video/mp4" />-->
<!--      </video>-->


      <div
          class="img-wrap d-inline-block me-2 mb-2"
          v-for="(imageUrl, index) in this.imageUrls"
          :key="imageUrl.uuid"
      >
        <button
            class="close bg-light m-1 rounded-circle"
            :hidden="this.readOnly"
            @click="() => {
              const clonedImageUrls = this.imageUrls.slice();
              clonedImageUrls.splice(index, 1);

              this.$emit('updateImages', clonedImageUrls)
            }"
        >&times;
        </button>
        <img :src="imageUrl.url" width="250" alt="Изображение">
      </div>

    </div>

    <input
        v-if="!this.readOnly"
        type="file"
        id="images"
        accept="image/*"
        required
        multiple
        @change="this.sendImageToServer"
    >
  </div>

</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import ProgressBar from "primevue/progressbar";
import FileUpload from "primevue/fileupload";
import Badge from "primevue/badge";
import Button from "primevue/button";
import Image from "primevue/image";
import {FileItem, NodeFile} from "@/api/graph";
import {UploadRes} from "@/api/post";
import {FileOwner, uploadFile} from "@/api/file";

export default defineComponent({
  name: "ImageUploadAndViewer",
  components: {
    Button,
    Badge,
    FileUpload,
    ProgressBar,
    Image,
  },
  props: {
    readOnly: {
      type: Boolean,
      required: true,
    },
    imageUrls: {
      type: Array as PropType<NodeFile[]>,
      required: true,
    },
    nodeUuid: {
      type: String,
      required: true,
    }
  },
  emits: {
    updateImages(images: FileItem[]): FileItem[] {
      return images
    }
  },
  data() {
    return {
      wantToUploadFile: false,
    };
  },
  inject: ['scenario_uuid'],
  methods: {
    sendImageToServer: function (event: any) {
      const files = event.target.files as File[];

      uploadFile(this.scenario_uuid, FileOwner.constructor, files)
          .then((result: UploadRes) => {
            this.$emit('updateImages', this.imageUrls.concat(result.items))
          })
    },
  },
})
</script>

<style scoped>
.img-wrap {
  position: relative;
}
.img-wrap .close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}
</style>