<template>
  <div>
    <InputNumber
        :model-value="this.modelValue.interval_value"
        :min="1"
        @update:model-value="this.onSelectValue"
    />
    <Dropdown
        :model-value="this.modelValue.interval_unit"
        @update:model-value="this.onSelectUnit"
        class="ms-2"
        option-label="label"
        option-value="value"
        :options="this.timeUnits"
        scroll-height="500px"
    />
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import {Period} from "@/api/periodicMessage";
import {TimeUnit} from "@/api/graph";
import {Period as PeriodDto} from "@/api/periodicMessage";

export default defineComponent({
  name: "Period",
  computed: {
    Period() {
      return Period
    }
  },
  components: {
    InputNumber,
    Dropdown
  },
  props: {
    modelValue: {
      type: Object as PropType<Period>
    },
    showUnits: {
      required: false,
      type: Array as PropType<TimeUnit[]>,
      default: [TimeUnit.minute, TimeUnit.hour, TimeUnit.day],
    },
    showAllUnits: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'update:modelValue',
  ],
  methods: {
    onSelectUnit: function (event) {
      this.$emit('update:modelValue', new PeriodDto(this.modelValue.interval_value, event))
    },

    onSelectValue: function (event) {
      this.$emit('update:modelValue', new PeriodDto(event, this.modelValue.interval_unit))
    },
  },
  computed: {
    timeUnits: function () {
      const sortBy = [TimeUnit.second, TimeUnit.minute, TimeUnit.hour, TimeUnit.day]
      const units = this.showAllUnits ? sortBy : this.showUnits

      const result = []
      sortBy.forEach((item) => {
        if (units.includes(item)) {
          switch (item) {
            case TimeUnit.second:
              result.push({
                label: 'секунд',
                value: item,
              })
              break;

            case TimeUnit.minute:
              result.push({
                label: 'минут',
                value: item,
              })
              break;

            case TimeUnit.hour:
              result.push({
                label: 'часов',
                value: item,
              })
              break;

            case TimeUnit.day:
              result.push({
                label: 'дней',
                value: item,
              })
              break;
          }
        }
      })

      return result
    }
  }
})
</script>