<template>
  <div>
    <FilterAction
        :action="this.card"
        @update:action="($event) => { this.card = $event }"
    />

    <div class="mt-2">
      <div class="d-flex justify-content-between">
        <div class="mt-auto">
          <InputSwitch
              v-model="this.useCommonWarningText"
              class="me-1 align-middle"
          />
          <span class="align-middle">Использовать общий текст предупреждения</span>
        </div>

        <div v-show="!this.useCommonWarningText">
          <SearchContextOption
              :context-options="this.contextOptions"
              @selected-context="($event) => { this.card.warning_text += $event }"
          />
        </div>
      </div>

      <RichTextEditor
          class="mt-2"
          v-if="this.card.warning_text !== null"
          v-model="this.card.warning_text"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Button from "primevue/button";
import Dropdown, {DropdownChangeEvent} from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import {CommandType, TimeUnit} from "@/api/graph";
import FilterAction from "@/components/view/GroupComponents/FilterAction.vue";
import {FilterAction as FilterActionDto} from "@/api/abuseFilter";
import {
  FilterActionBanSubscriber,
  FilterActionKickSubscriber,
  FilterActionMuteSubscriber,
  FilterActionNothingDo
} from "@/api/abuseFilter";
import {ContextOption} from "@/api/groupSettings";
import SearchContext from "@/components/common/SearchContext.vue";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import InputSwitch from "primevue/inputswitch";
import AutoComplete from "primevue/autocomplete";
import SearchContextOption from "@/components/common/SearchContextOption.vue";

export default defineComponent({
  name: "FilterActionWrapper",
  computed: {
    FilterActionNothingDo() {
      return FilterActionNothingDo
    },
    FilterActionMuteSubscriber() {
      return FilterActionMuteSubscriber
    },
    FilterActionBanSubscriber() {
      return FilterActionBanSubscriber
    },
    FilterActionKickSubscriber() {
      return FilterActionKickSubscriber
    },
    CommandType() {
      return CommandType
    },
    DropdownChangeEvent() {
      return DropdownChangeEvent
    },
    TimeUnit() {
      return TimeUnit
    },

    useCommonWarningText: {
      get: function () {
        return this.card.warning_text === null
      },
      set: function (val) {
        if (val) {
          this.card.warning_text = null
        } else {
          this.card.warning_text = ''
        }
      },
    },
  },
  components: {
    SearchContextOption,
    AutoComplete,
    FilterAction,
    InputNumber,
    Dropdown,
    Button,
    SearchContext,
    RichTextEditor,
    InputSwitch,
  },
  props: {
    action: {
      required: true,
      type: Object as PropType<FilterActionDto>
    },
    contextOptions: {
      required: true,
      type: Array as PropType<ContextOption[]>,
    },
  },
  emits: [
    'update:action',
  ],
  data: function () {
    return {
      card: this.action,
    }
  },
  watch: {
    action: function (val) {
      this.card = val
    },

    card: function (val) {
      this.$emit('update:action', val)
    }
  }
})
</script>