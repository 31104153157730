<template>
  <div class="p-2 gridmap">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <TabMenu :model="itemsTab" class="mt-4 bg-white rounded" :pt="{action: { class: 'text-decoration-none', style: {color: 'black'} }}">
      <template #item="{ item, props }">
        <router-link :to="{name: item.route_name}" v-bind="props.action" v-ripple class="flex align-items-center gap-2">
          <span class="font-bold m-2">{{ item.name }}</span>
        </router-link>
      </template>
    </TabMenu>

    <div>
      <router-view/>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import TriggersList from "@/components/view/Triggers/TriggersList.vue";
import Breadcrumb from "primevue/breadcrumb";
import Panel from "primevue/panel";
import Image from "primevue/image";
import {
  getGroupsForCard,
} from "@/api/group";
import Chart from "primevue/chart";
import Toolbar from "primevue/toolbar";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputSwitch from "primevue/inputswitch";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import AutoComplete from "primevue/autocomplete";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputGroup from "primevue/inputgroup";
import DateInput from "@/components/common/DateInput.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import TabMenu from "primevue/tabmenu";

export default defineComponent({
  name: "GroupStatistics",
  components: {
    Breadcrumbs,
    DateInput,
    Divider, Chip,
    Button,
    InputText,
    Textarea,
    InputSwitch,
    InputIcon,
    AutoComplete,
    IconField,
    Image,
    Breadcrumb,
    TriggersList,
    Panel,
    Chart,
    Toolbar,
    Dropdown,
    Calendar,
    InputGroupAddon,
    InputGroup,
    DataTable,
    Column,
    TabMenu,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      itemsTab: [
        { name: 'Подписки', route_name: 'group_statistics_subscription' },
        // { name: 'Ежедневная статистика'  },
        // { name: 'Капча' }
      ],

      breadcrumbs: [],
    }
  },
  created() {
    getGroupsForCard(this.dialog_uuid).then((res) => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Статистика'),
      ]
    })
  },
})
</script>