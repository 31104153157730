<template>
  <div class="p-2 rounded">
    <TabView>
    <TabPanel header="Настройка">

      <div class="mt-2 mb-0">
        <p class="m-0">Название</p>
        <InputText v-model="this.requestForwardUpdate.name"/>
      </div>

      <div class="mt-4 mb-0">
        <Checkbox :binary="true" v-model="this.requestForwardUpdate.active" class="me-2"/>
        <label>Активность</label>
      </div>

      <p class="mt-4 mb-0">Откуда производить пересылку:</p>
      <div class="mt-2">
        <DialogTagPanel
            v-model="this.requestForwardUpdate.source_dialogs"
            :dialog-types="[DialogType.channel]"
            :scenario-uuid="this.scenario_uuid"
        />
      </div>

      <p class="mt-4 mb-0">Куда производить пересылку:</p>
      <div class="mt-2">
        <DialogTagPanel
            v-model="this.requestForwardUpdate.target_dialogs"
            :dialog-types="[DialogType.channel]"
            :scenario-uuid="this.scenario_uuid"
        />
      </div>

      <div class="mt-3">
        <Button
            type="button"
            class="rounded me-2"
            label="Удалить"
            @click="this.isShowDialogRemoveForwarder = true"
            severity="danger"
        />

        <Button
            type="button"
            class="rounded"
            label="Сохранить"
            severity="contrast"
            :loading="this.isLoadingSaveSources"
            @click="this.updateSources"
        />
      </div>

      <Dialog v-model:visible="this.isShowDialogRemoveForwarder" header="Вы уверены что хотите удалить перенаправление?" modal :draggable="false">
        <template #footer>
          <Button
              type="button"
              class="rounded me-2"
              label="Да"
              @click="() => {removeForwarder(this.forwarder_uuid).then(() => {
                this.$router.push({name: 'forwarder_list', params: {scenario_uuid: this.scenario_uuid}})
              })}"
              severity="danger"
          />
        </template>
      </Dialog>
    </TabPanel>
<!--    <TabPanel header="Фильтры">-->

<!--    </TabPanel>-->

<!--    <TabPanel header="Опубликованные">-->

<!--    </TabPanel>-->

<!--    <TabPanel header="Отклоненные">-->

<!--    </TabPanel>-->

<!--&lt;!&ndash;    todo: перенести в ForwarderList &ndash;&gt;-->
<!--    <TabPanel header="Журнал ">-->

<!--    </TabPanel>-->
  </TabView>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import PickList from "primevue/picklist";
import {
  getForwarderForCard,
  GetPostingResult,
  getPostingSources,
  removeForwarder,
  SourceItemResult,
  UpdateForwardDialogCmd,
  updateForwarderDialogs
} from "@/api/forwarder";
import Dialog from "primevue/dialog";
import Badge from "primevue/badge";
import AutoComplete from "primevue/autocomplete";
import Checkbox from "primevue/checkbox";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: "Forwarder",
  computed: {
    DialogType() {
      return DialogType
    }
  },
  components: {
    InputText,
    DialogTagPanel,
    AutoComplete,
    Dialog,
    Button,
    TabPanel,
    TabView,
    DataTable,
    Column,
    PickList,
    Badge,
    Checkbox,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    forwarder_uuid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isLoadingSaveSources: false,
      dialogs: [] as SourceItemResult[],
      requestForwardUpdate: new UpdateForwardDialogCmd(this.forwarder_uuid, [], []),
      isShowDialogRemoveForwarder: false,


      sourcesFromBack: new GetPostingResult,
      fakeSearchSourceName: '',
      fakeSearchTargetName: '',

      sources: [] as SourceItemResult[],
      targets: [] as SourceItemResult[],
    }
  },
  methods: {
    removeForwarder,
    updateSources: function () {
      this.isLoadingSaveSources = true

      updateForwarderDialogs(this.requestForwardUpdate).finally(() => {
        this.isLoadingSaveSources = false
      });
    },

    searchChannels: function (event: {originalEvent: PointerEvent, query: string}) {
      getPostingSources(this.scenario_uuid, event.query).then((result: GetPostingResult) => {
        const dontUsedSources = result.items.filter((value: SourceItemResult) => {
          return !this.requestForwardUpdate.source_dialogs.find((element: SourceItemResult) => {
            return element.uuid === value.uuid
          })
        })


        const dontUsedTargets = result.items.filter((value: SourceItemResult) => {
          return !this.requestForwardUpdate.target_dialogs.find((element: SourceItemResult) => {
            return element.uuid === value.uuid
          })
        })

        this.sources = dontUsedSources
        this.targets = dontUsedTargets
      })
    },
  },
  mounted() {
    getForwarderForCard(this.forwarder_uuid).then((result: UpdateForwardDialogCmd) => {
      this.requestForwardUpdate = result
    })

    getPostingSources(this.scenario_uuid, null).then((result: GetPostingResult) => {
      this.dialogs = result.items
    })
  },
})
</script>