<template>
  <div>
    <HelpInfo route-doc="doc_forwarder" class="mt-2">
      Документация по перенаправлениям
    </HelpInfo>

    <DataTable
        :value="this.forwarderList"
        dataKey="uuid"
        :paginator="false"
        :rows="10"
        :rowsPerPageOptions="[5,10,25]"
        class="mt-4 custom-border rounded"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
    >
      <template #empty>
        Список пуст
      </template>

      <template #header>
        <div class="row justify-content-between">
          <div class="col-auto d-flex align-items-center">
            <h5 class="m-0">Список перенаправлений</h5>
          </div>
          <div class="col-auto">
            <Button
                class="rounded"
                label="Добавить перенаправление"
                icon="pi pi-plus"
                @click="this.isOpenDialogCreateForward = true"
                severity="contrast"
            />
          </div>
        </div>
      </template>


<!--      <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>-->
      <Column field="name" header="Название" style="min-width:16rem"></Column>
      <Column field="inventoryStatus" header="Статус" style="min-width:12rem">
                <template #body="slotProps">
                  <Tag :value="slotProps.data.active ? 'Активно' : 'Не активно'" :severity="slotProps.data.active ? 'secondary' : 'danger'" />
                </template>
      </Column>
      <Column :exportable="false" style="min-width:8rem">
        <template #body="slotProps">
          <router-link :to="{name: 'posting', params: {scenario_uuid: this.scenario_uuid, forwarder_uuid: slotProps.data.uuid}}">
            <Button icon="pi pi-pencil" label="Открыть" severity="secondary"  outlined class="me-2 rounded"/>
          </router-link>

          <Button icon="pi pi-trash" label="Удалить" outlined rounded severity="danger" class="rounded" @click="() => {this.requestRemoveForwarder = slotProps.data}" />
        </template>
      </Column>
    </DataTable>


    <Dialog v-model:visible="this.isOpenDialogCreateForward" header="Создать перенаправление" modal :draggable="false" class="w-50">
<!--      todo: добавить мини справку о чем это -->

      <div style="height: 40vh;">
        <label class="required d-block">Название перенаправления:</label>
        <InputText
            v-model="this.requestCreateForwarder.name"
            class="w-50"
        />

        <div class="mt-2">
          <label class="d-block required">Откуда пересылать контент</label>
          <div class="mt-2">
            <DialogTagPanel
                v-model="this.requestCreateForwarder.sources"
                :dialog-types="[DialogType.channel]"
                :scenario-uuid="this.scenario_uuid"
            />
          </div>
        </div>

        <div class="mt-2">
          <label class="d-block required">Куда пересылать контент</label>

          <div class="mt-2">
            <DialogTagPanel
                v-model="this.requestCreateForwarder.targets"
                :dialog-types="[DialogType.channel]"
                :scenario-uuid="this.scenario_uuid"
            />
          </div>
        </div>
      </div>

      <template #footer>
        <Button
            label="Создать"
            icon="pi pi-check"
            severity="contrast"
            class="rounded"
            @click="createForwarder(this.requestCreateForwarder)
            .then(() => {
              this.requestCreateForwarder = new CreateForwardCmd(this.scenario_uuid, '');
              this.isOpenDialogCreateForward = false

              getForwarderItemsForList(this.scenario_uuid).then((result: ForwardedForList) => {
                this.forwarderList = result.items
              })
            })
            .finally(() => {this.isCreateForwarderLoading = false})
          "
            :disabled="this.isDisableCreateForwardButton"
        />
      </template>
    </Dialog>

    <Dialog v-model:visible="this.isOpenDialogRemoveForward" header="Вы уверены что хотите удалить перенаправление?" modal :draggable="false">
      <p>Перенаправление <span class="fw-bold">{{this.requestRemoveForwarder.name}}</span> будет безвозвратно удалено!</p>

      <template #footer>
        <Button
            type="button"
            class="rounded me-2"
            label="Удалить"
            :loading="this.isRemoveForwarderLoading"
            @click="() => {
              this.isRemoveForwarderLoading = true
              removeForwarder(this.requestRemoveForwarder.uuid)
              .then(() => {
                getForwarderItemsForList(this.scenario_uuid).then((result: ForwardedForList) => {
                  this.forwarderList = result.items
                  this.isOpenDialogRemoveForward = false
                })
              }).finally(() => {
                this.isRemoveForwarderLoading = false
              })}
            "
            severity="danger"
        />

        <Button
            type="button"
            severity="contrast"
            class="rounded"
            label="Не удалять"
            @click="this.isOpenDialogRemoveForward = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import PickList from "primevue/picklist";
import Dialog from "primevue/dialog";
import {
  CreateForwardCmd,
  createForwarder, ForwardedForList,
  ForwardedForListItem, getForwarderItemsForList,
  GetPostingResult,
  getPostingSources, removeForwarder,
  SourceItemResult
} from "@/api/forwarder";
import AutoComplete from "primevue/autocomplete";
import InputIcon from "primevue/inputicon";
import IconField from "primevue/iconfield";
import Tag from "primevue/tag";
import Rating from "primevue/rating";
import InputText from "primevue/inputtext";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";
import HelpInfo from "@/components/view/HelpInfo.vue";

export default defineComponent({
  name: "ForwarderList",
  components: {
    HelpInfo,
    DialogTagPanel,
    Dialog,
    Button,
    TabPanel,
    TabView,
    DataTable,
    Column,
    PickList,
    AutoComplete,
    IconField,
    InputIcon,
    Tag,
    Rating,
    InputText,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {
      fakeSearchSourceName: '' as string,
      fakeSearchTargetName: '' as string,

      isOpenDialogCreateForward: false,
      requestCreateForwarder: new CreateForwardCmd(this.scenario_uuid, ''),
      sourceAndTargetsChannels: [] as SourceItemResult[],
      isCreateForwarderLoading: false,
      isRemoveForwarderLoading: false,

      forwarderList: [] as ForwardedForListItem[],
      requestRemoveForwarder: null as ForwardedForListItem|null,
    }
  },
  mounted() {
    getForwarderItemsForList(this.scenario_uuid).then((result: ForwardedForList) => {
      this.forwarderList = result.items
    })
  },
  methods: {
    removeForwarder,
    getForwarderItemsForList,
    createForwarder,
    searchChannels: function (event: {originalEvent: PointerEvent, query: string}) {
      getPostingSources(this.scenario_uuid, event.query).then((result: GetPostingResult) => {
        this.sourceAndTargetsChannels = result.items
      })
    },
  },
  watch: {
    'isOpenDialogCreateForward': function (newVal) {
      if (newVal === false) {
        this.requestCreateForwarder = new CreateForwardCmd(this.scenario_uuid, '');
      }
    },
  },
  computed: {
    DialogType() {
      return DialogType
    },
    isOpenDialogRemoveForward: {
      get: function () {
        return this.requestRemoveForwarder !== null
      },
      set: function (newVal) {
        if (newVal === false) {
          this.requestRemoveForwarder = null
        }
      },
    },

    ForwardedForList() {
      return ForwardedForList
    },
    CreateForwardCmd() {
      return CreateForwardCmd
    },
    suggestionsForSourceChannels: function () {
        return this.sourceAndTargetsChannels.filter((value: SourceItemResult) => {
          const isAlreadyExists = this.requestCreateForwarder.sources.find((element: SourceItemResult) => {
            return element.uuid === value.uuid
          })

          if (isAlreadyExists) {
            return false
          }
          return true
        })
    },

    suggestionsForTargetChannels: function () {
      return this.sourceAndTargetsChannels.filter((value: SourceItemResult) => {
        const isAlreadyExists = this.requestCreateForwarder.targets.find((element: SourceItemResult) => {
          return element.uuid === value.uuid
        })

        if (isAlreadyExists) {
          return false
        }
        return true
      })
    },

    isDisableCreateForwardButton: function (): boolean {
      return this.requestCreateForwarder.name.length === 0 ||
          this.requestCreateForwarder.sources.length === 0 ||
          this.requestCreateForwarder.targets.length === 0
    },
  }
})
</script>