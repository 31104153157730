<template>
  <div>
    <h3 class="fw-bold">Триггеры</h3>

    <p class="bg-primary-subtle p-3 custom-border rounded mt-2">
      Расположение функционала <span class="fw-bold">Боты - Выбираете бота - Триггеры - Добавить триггеры</span>
    </p>

    <p class="mt-2">
      <span class="fw-bold">Триггер</span> - представляет с собой набор условий, при выполнении которых производятся определенные действия.
    </p>

<!--    <Panel header="Пример #1. Удаление сообщений содержащих мат" class="mt-2" :toggleable="true" :collapsed="true">-->
<!--      <p>-->
<!--        Давайте представим что у нас есть Telegram группа "Курилка" из которой необходимо удалять сообщения содержащие мат <span class="fw-bold">или</span> сообщения пересланные из сторонних чатов.-->
<!--        Просто удалять было бы слишком просто, давайте бот будет отвечать пользователю в чате и знакомить его с правилами сообщества,-->
<!--        а так же бот будет пересылать удаляемое сообщение в стороннюю группу "Удаленные сообщения".-->
<!--      </p>-->

<!--      <h6>Условия</h6>-->
<!--      <p>-->
<!--        Так как нам необходимо проверить два условия независимо друг от друга (через ИЛИ), то создаем две группы условий,-->
<!--        в первой из которых будет условие по наличию в сообщении слов из <router-link :to="{name: 'doc_dictionary'}" target="_blank">справочника</router-link> матерных выражений. А в второй группе-->
<!--        условие по типу сообщения - "Пересланное".-->
<!--      </p>-->

<!--      <p class="text-danger">TODO: сделать слово справочник a-href с ссылкой на доку по справочнику</p>-->

<!--      <div>-->
<!--        <h6>Действия</h6>-->
<!--        <p>У нас есть три действия</p>-->
<!--        <ul class="ms-3">-->
<!--          <li>1. Переслать сообщение пользователя</li>-->
<!--          <li>2. Удалить сообщение пользователя</li>-->
<!--          <li>3. Тегнуть пользователя и напомнить ему правила сообщества</li>-->
<!--        </ul>-->

<!--        <p>-->
<!--          Первые два действия необходимо выполнить строго последовательно, третье действие можно выполнить пареллельно относительно первых двух (для ускорения реакции бота).-->
<!--          Создаем две группы действий, в первую включаем действия "Переслать сообщение пользователя" и "Удалить сообщение пользователя".-->
<!--          В второй группе действий производим отправку сообщения с тегированием пользователя и текстом правил сообщества.-->
<!--        </p>-->
<!--      </div>-->

<!--      <a href="https://t.me/test_trigger_1" class="me-2">-->
<!--        <Button-->
<!--          icon="pi pi-telegram"-->
<!--          class="rounded"-->
<!--          label="Группа «Курилка» из урока"-->
<!--          severity="info"-->
<!--        />-->
<!--      </a>-->

<!--      <a href="https://t.me/test_trigger_1_moderator">-->
<!--        <Button-->
<!--            icon="pi pi-telegram"-->
<!--            class="rounded"-->
<!--            label="Группа «Удаленные сообщения» из урока"-->
<!--            severity="info"-->
<!--        />-->
<!--      </a>-->

<!--      <p class="text-danger">Тут ссылка на расшаренный шаблон с этим триггером и кнопка для перехода в публичную группу где можно проверить это условие</p>-->
<!--    </Panel>-->

<!--    <Panel header="Пример #2. Удаление сообщений в ночное время" class="mt-2" :toggleable="true" :collapsed="true"/>-->

<!--    <Panel header="Пример #3. Ответ на частозадаваемый вопрос" class="mt-2" :toggleable="true" :collapsed="true"/>-->

<!--    <Panel header="Пример #4. Приветствие новых участников" class="mt-2" :toggleable="true" :collapsed="true"/>-->

<!--    <Panel header="Пример #5. Удаление сообщений содержащие медиа файлы" class="mt-2" :toggleable="true" :collapsed="true"/>-->

<!--    <Panel header="Пример #6. Отправка данных в сторонний сервис по API" class="mt-2" :toggleable="true" :collapsed="true"/>-->


<!--    <div class="mt-3">-->
<!--      <h5>Другие примеры применения:</h5>-->
<!--      <ul class="ms-4">-->
<!--        <li>Удалять сообщения содержащих ссылки</li>-->
<!--        <li>Удалять сообщения в которых содержатся запрещенные правилами сообщества слова</li>-->
<!--        <li>Приветствие новых участников чата</li>-->
<!--        <li>Удалять сообщения подписчиков которые не оплатили доступ к чату</li>-->
<!--        <li>Защита от спама, повторяющихся сообщений</li>-->
<!--        <li>Удаление сообщений содержащих медиа файлы</li>-->
<!--        <li>Удалять сообщения пересланные из других чатов</li>-->
<!--        <li>Удалять сообщения пользователей с запрещенными никами</li>-->
<!--        <li>Ответ на часто задаваемый вопрос</li>-->
<!--        <li>Запрет на написание новых сообщений в течении заданного промежутка</li>-->
<!--      </ul>-->
<!--    </div>-->


    <Divider/>
    <div>
      <div class="d-flex justify-content-between m-4">
        <div>
          <h6 class="fw-bold">Виды условий</h6>
          <div class="ms-3">
            <p class="fw-bold mb-0 mt-2 text-secondary">По сообщению</p>
            <div class="ms-4">
              <ul>
                <li>По сообщению</li>
                <li>По слову в сообщении</li>
                <li>По типу сообщения (текстовое, фото, видео)</li>
                <li>Длина сообщения</li>
                <li>Регулярное выражение по тексту сообщения</li>
              </ul>
            </div>

            <p class="fw-bold mb-0 mt-2 text-secondary">По пользователю</p>
            <div class="ms-3">
              <ul>
                <li>Длина юзернейма</li>
                <li>Длина имени и фамилии</li>
                <li>По определенному username или фамилии</li>
                <li>Регулярное выражение по username</li>
              </ul>
            </div>

            <p class="fw-bold mb-0 mt-2 text-secondary">По дата и времени</p>
            <div class="ms-3">
              <ul>
                <li>Промежуток дат</li>
                <li>Диапазон дней в рамках месяца</li>
                <li>Промежуток времени в течении дня</li>
                <li>День недели соответствует определенным дням</li>
                <li>Текущая дата попадает в выбранный диапазон</li>
              </ul>
            </div>

            <p class="fw-bold mb-0 mt-2 text-secondary">Прочее</p>
            <div class="ms-3">
              <ul>
                <li>Тема группы</li>
                <li>Количество срабатываний триггера за N период</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div class="mt-4">
            <h6 class="fw-bold">Виды действий</h6>
            <ul class="ms-2">
              <ul>
                <li>Отправить сообщение (возможно отправить в другой чат)</li>
                <li>Отправить личным сообщением (пользователь до этого должен хоть раз написать боту)</li>
                <li>Удалить сообщение</li>
                <li>Переслать сообщение</li>
                <li>Закрепить сообщение</li>
                <li>Открепить сообщение</li>
                <li>Удалить пользователя из чата с возможностью вернуться</li>
                <li>Удалить пользователя из чата на время</li>
                <li>Запретить пользователю писать сообщения</li>
                <li>Разрешить пользователю писать сообщения</li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <Divider/>

<!--    <iframe-->
<!--        src="http://localhost/bot/12394046-d9b8-44be-b7bb-7ba6b271b0cc/triggers/950db694-fb00-4c22-99db-43cb24b0b585"-->
<!--        class="w-100 vh-100 custom-border"-->
<!--    />-->
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import OrganizationChart from "primevue/organizationchart";
import Panel from "primevue/panel";
import Divider from "primevue/divider";
import Button from "primevue/button";

export default defineComponent({
  name: "DocsTriggers",
  components: {
    OrganizationChart,
    Image,
    Panel,
    Divider,
    Button,
  },
  data: function () {
    return {
      concurrentGraph: {
        label: 'Сработал триггер',
        children: [
          {
            label: 'Группа действий 1',
            children: [
              {
                label: 'Пересылаем сообщение в диалог "тест 123"',
                children: [
                  {
                    label: 'Удаляем сообщение подписчика в исходном диалоге'
                  }
                ]
              },
            ],
          },
          {
            label: 'Группа действий 2',
            children: [
              {
                label: 'Отправляем HTTP запрос в сторону X сервиса',
              },
            ],
          },
        ]
      }
    }
  }
})
</script>

<style scoped>

* {
  font-family: "Inter var", sans-serif;
}

</style>