<template>
  <div class="m-4">
    <DictionaryForm
        v-model:model-value="this.updateDictionaryWord"
        :scenario-uuid="this.scenario_uuid"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {getWordDictionaryForCard, UpdateDictionaryCmd} from "@/api/dictionary";
import DictionaryForm from "@/components/view/Dictionaries/DictionaryForm.vue";

export default defineComponent({
  name: "DictionaryForCard",
  components: {DictionaryForm},
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      updateDictionaryWord: new UpdateDictionaryCmd(this.scenario_uuid, this.uuid)
    }
  },
  created() {
    getWordDictionaryForCard(this.uuid).then((res) => {
      this.updateDictionaryWord = res
    })
  }
})
</script>