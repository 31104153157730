<template>
  <div class="m-2">
    <Breadcrumbs :items="this.breadcrumbs"/>

    <HelpInfo route-doc="doc_triggers" class="mt-2">
      Документация по триггерам

      <template #content>
        <p class="text-muted m-0">
          Задавайте собственные условия по которым бот будет выполнять действия
        </p>
      </template>
    </HelpInfo>

    <DataTable
        :value="this.triggerList"
        dataKey="uuid"
        :paginator="false"
        :rows="10"
        :rowsPerPageOptions="[5,10,25]"
        class="mt-4 custom-border rounded"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
    >
      <template #empty>
        Список пуст
      </template>

      <template #header>
        <div class="row justify-content-between">
          <div class="col-auto d-flex align-items-center">
            <h5 class="m-0">Триггеры</h5>
          </div>
          <div class="col-auto">
            <Button
                class="rounded"
                label="Добавить триггер"
                icon="pi pi-plus"
                @click="this.isOpenDialogCreateTrigger = true"
                severity="contrast"
            />
          </div>
        </div>
      </template>
      <!--      <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>-->
      <Column field="name" header="Название" style="min-width:16rem"/>
      <Column field="active" header="Статус" style="min-width:12rem">
        <template #body="slotProps">
          <Tag :value="slotProps.data.active ? 'Активен' : 'Не активен'" :severity="slotProps.data.active ? 'success' : 'danger'" />
        </template>
      </Column>
      <Column field="priority" header="Приоритет срабатывания"  style="min-width:12rem">
        <template #header>
          <span class="pi pi-question-circle me-2" v-tooltip="'Сначала проверяются триггеры с наивысшим приоритетом'"/>
        </template>
      </Column>
<!--      <Column field="triggered_counter" header="Количество срабатываний" style="min-width:12rem"/>-->
      <Column :exportable="false" style="min-width:8rem">
        <template #body="slotProps">
          <div class="d-flex justify-content-end">
            <router-link :to="{name: 'trigger_for_card', params: {scenario_uuid: this.scenario_uuid, uuid: slotProps.data.uuid}}">
              <Button icon="pi pi-pencil" label="Открыть" severity="secondary"  outlined class="me-2 rounded"/>
            </router-link>

            <Button icon="pi pi-trash" label="Удалить" outlined rounded severity="danger" class="rounded" @click="() => {this.selectRemoveScenarioTrigger = slotProps.data}" />
          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog v-model:visible="this.selectRemoveScenarioTrigger as boolean" header="Удаление триггера" modal :draggable="false">
      <p>Вы уверены что хотите удалить триггер <span class="fw-bold">{{this.selectRemoveScenarioTrigger.name}}</span> ?</p>

      <template #footer>
        <Button
            label="Удалить"
            icon="pi pi-trash"
            severity="danger"
            class="rounded"
            @click="() => {
              this.isRemoveTriggerLoading = true

              removeScenarioTrigger(this.selectRemoveScenarioTrigger.uuid)
                .then(() => {
                  this.selectRemoveScenarioTrigger = null
                  getScenarioTriggerItemsForList(this.dialog_uuid).then((result: ScenarioTriggerForList) => {
                    this.triggerList = result.items
                  })
                })
                .finally(() => {this.isRemoveTriggerLoading = false})
            }"
          />
      </template>
    </Dialog>


    <Dialog v-model:visible="this.isOpenDialogCreateTrigger" header="Создание триггера" modal maximizable :draggable="false" class="w-75">
      <TriggerForm
          :errors="this.errorsCreateTrigger"
          v-model="this.requestCreateTrigger"
      />

      <template #footer>
        <Button
            label="Создать"
            icon="pi pi-check"
            severity="contrast"
            class="rounded"
            :loading="this.isCreateTriggerLoading"
            @click="() => {
              this.isCreateTriggerLoading = true
              createScenarioTrigger(this.requestCreateTrigger)
                .then(() => {
                  this.requestCreateTrigger = new CreateScenarioTriggerCmd(this.scenario_uuid);
                  this.isOpenDialogCreateTrigger = false

                  getScenarioTriggerItemsForList(this.dialog_uuid).then((result: ScenarioTriggerForList) => {
                    this.triggerList = result.items
                  })
                })
              .finally(() => {this.isCreateTriggerLoading = false})
              .catch((err: ErrorResponse) => {
                this.errorsCreateTrigger = err.getValidationErrors()
              })
            }
          "
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {defineComponent, provide, ref} from 'vue'
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Tag from "primevue/tag";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import {DialogType} from "@/api/dialog";
import {
  CommandGroupItem,
  createScenarioTrigger,
  CreateScenarioTriggerCmd,
  getScenarioTriggerItemsForList,
  removeScenarioTrigger,
  ScenarioTriggerForList,
  ScenarioTriggerForListItem,
  TriggerGroupItem
} from "@/api/scenarioTrigger";
import Checkbox from "primevue/checkbox";
import {Command, GraphConfigResult, Trigger} from "@/api/graph";
import TriggerItem from "@/components/view/Bots/Trigger/TriggerItem.vue";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import CommandItem from "@/components/view/Bots/Command/CommandItem.vue";
import TriggerForm from "@/components/view/Triggers/TriggerForm.vue";
import {ErrorResponse} from "@/api/common";
import HelpInfo from "@/components/view/HelpInfo.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";

export default defineComponent({
  name: "TriggersList",
  methods: {
    removeScenarioTrigger,
    getScenarioTriggerItemsForList,
    createScenarioTrigger,},
  computed: {
    ErrorResponse() {
      return ErrorResponse
    },
    ScenarioTriggerForList() {
      return ScenarioTriggerForList
    },
    Command() {
      return Command
    },
    CommandGroupItem() {
      return CommandGroupItem
    },
    Trigger() {
      return Trigger
    },
    TriggerGroupItem() {
      return TriggerGroupItem
    },
    CreateScenarioTriggerCmd() {
      return CreateScenarioTriggerCmd
    },
    DialogType() {
      return DialogType
    }
  },
  components: {
    Breadcrumbs,
    HelpInfo,
    TriggerForm,
    CommandItem,
    Dropdown,
    TriggerItem,
    Checkbox,
    DataTable,
    Dialog,
    Tag,
    InputText,
    Button,
    Column,
    Divider,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      isOpenDialogCreateTrigger: false,
      isOpenDialogRemoveTrigger: false,
      isRemoveTriggerLoading: false,
      selectRemoveScenarioTrigger: null as CreateScenarioTriggerCmd|null,
      triggerList: [] as ScenarioTriggerForListItem[],
      requestCreateTrigger: new CreateScenarioTriggerCmd(this.scenario_uuid, this.dialog_uuid),
      errorsCreateTrigger: [],
      isCreateTriggerLoading: false,
      breadcrumbs: [],
    }
  },
  created() {
    getScenarioTriggerItemsForList(this.dialog_uuid).then((res) => {
      this.triggerList = res.items

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.dialog_name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Триггеры'),
      ]
    })
  },
  watch: {
    dialog_uuid: function (newDialogUuid: string) {
      this.triggerList = []

      getScenarioTriggerItemsForList(newDialogUuid).then((res) => {
        this.triggerList = res.items
      })
    }
  }
})
</script>