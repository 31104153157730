<template>
  <div class="m-1 p-1 bg-primary-subtle">

    <div class="d-flex justify-content-between">
      <div v-if="this.trigger.type === TriggerType.msg">
        <div>
          <div class="m-1">
            <InputSwitch
                v-model="this.trigger.case_sensitive"
                class="align-middle me-1"
                :disabled="!this.isEditMode"
            />
            <span class="align-middle">Чувствительность к регистру</span>
          </div>
          <div class="m-1">
            <InputSwitch
                v-model="this.trigger.invert"
                class="align-middle me-1"
                :disabled="!this.isEditMode"
            />
            <span class="align-middle">Обратное условие</span>
          </div>
          <div class="m-1" v-show="[
              TriggerOperation.equals,
              TriggerOperation.substr,
              TriggerOperation.beginWith,
              TriggerOperation.endWith,
              TriggerOperation.contains_word_from_dictionary,
              TriggerOperation.containsWord,
            ].includes(this.trigger.operation)"
          >
            <InputSwitch
                v-model="this.trigger.sense_mixed_abc"
                class="align-middle me-1"
                :disabled="!this.isEditMode"
            />
            <span class="align-middle">Учитывать смешанный алфавит</span>
          </div>
        </div>
      </div>

      <button
          type="button"
          class="pi pi-trash border-0 bg-transparent text-danger m-2"
          v-if="this.isEditMode"
          @click="this.isEditMode && $emit('pressed-button-remove')"
      />
    </div>


    <Dropdown
        v-model="this.trigger.type"
        :options="this.triggerOptions"
        placeholder="Тип условия"
        option-label="name"
        option-value="value"
        :disabled="!this.isEditMode"
        class="w-100"
        scroll-height="500px"
        @change="this.onChangeTriggerType"
    />
    <p class="p-error" v-show="this.errors['type']">{{ this.errors['type'] }}</p>

<!--  todo: в будущем сделать вложенный список (категория переменной (системная|пользовательская) - конкретная переменная)  -->
    <Dropdown
        v-show="this.trigger.type === TriggerType.context"
        v-model="this.trigger.context_code"
        :options="this.contextOptions"
        placeholder="Переменная"
        option-label="name"
        option-value="uuid"
        :disabled="!this.isEditMode"
        class="w-100"
        scroll-height="500px"
    />
    <!--  todo: Отсутствует операции  -->

    <div v-show="this.isShowOperations">
      <Dropdown
          v-model="this.trigger.operation"
          :options="this.availableOperations"
          placeholder="Тип операции"
          option-label="name"
          option-value="code"
          :disabled="!this.isEditMode"
          class="w-100"
          scroll-height="500px"
      />
      <p class="p-error" v-show="this.errors['operation']">{{ this.errors['operation'] }}</p>
    </div>

    <!--  todo: trigger.pattern = string  -->

    <div v-show="this.isShowPattern">
      <InputText
          class="w-100 text-center"
          v-model="this.trigger.pattern"
          :placeholder="this.patternPlaceholder"
          :disabled="!this.isEditMode"
      />
      <p class="p-error" v-show="this.errors['pattern']">{{ this.errors['pattern'] }}</p>
    </div>

<!--  Отображаем список справочников  -->
    <div v-show="this.trigger.operation === TriggerOperation.contains_word_from_dictionary">
      <Dropdown
          class="w-100 text-center"
          v-model="this.trigger.pattern"
          :options="this.graphConfigStorage.getDictionaries"
          option-label="name"
          option-value="uuid"
          placeholder="Выберите справочник"
          empty-message="Список справочников пуст"
          :disabled="!this.isEditMode"
      >
        <template #empty>
          <router-link :to="{name: 'dictionary_for_list', params: {scenario_uuid: this.scenario_uuid}}" target="_blank">Создайте справочник</router-link>
        </template>
      </Dropdown>
      <p class="p-error" v-show="this.errors['pattern']">{{ this.errors['pattern'] }}</p>
    </div>

    <!--  todo: trigger.type === time  -->

    <div v-show="this.trigger.type === TriggerType.has_tag">
      <Dropdown
          class="w-100 text-center"
          v-model="this.trigger.pattern"
          :options="this.graphConfigStorage.tags"
          option-label="name"
          option-value="uuid"
          placeholder="Выберите тег"
          empty-message="Список тегов пуст"
          :disabled="!this.isEditMode"
      >
        <template #empty>
          <router-link :to="{name: 'tags_list', params: {scenario_uuid: this.scenario_uuid}}" target="_blank">Список тегов пуст, создать тег</router-link>
        </template>
      </Dropdown>
      <p class="p-error" v-show="this.errors['pattern']">{{ this.errors['pattern'] }}</p>
    </div>

    <!--  todo: trigger.type === date  -->

    <div v-if="(this.trigger.type === TriggerType.date || this.trigger.type === TriggerType.date_on_subscribed) && this.trigger.operation">
      <DateInput
        v-model="this.trigger.pattern"
        :disabled="!this.isEditMode"
      />
      <p class="p-error" v-show="this.errors['pattern']">{{ this.errors['pattern'] }}</p>
    </div>

<!--  todo: trigger.type === time  -->

    <div v-if="this.trigger.type === TriggerType.time && this.trigger.operation">
      <TimeInput
          v-model="this.trigger.pattern"
          :disabled="!this.isEditMode"
      />
      <p class="p-error" v-show="this.errors['pattern']">{{ this.errors['pattern'] }}</p>
    </div>

    <div v-if="this.trigger.type === TriggerType.subscribed_on_chat || this.trigger.type === TriggerType.not_subscribed_on_chat">
      <SearchDialog
          v-model="this.trigger.subscribed_on_chats"
          :show-private-dialogs="false"
          :disabled="!this.isEditMode"
          class="w-100"
      />
      <p class="p-error" v-show="this.errors['subscribed_on_chats']">{{ this.errors['subscribed_on_chats'] }}</p>
    </div>

    <div v-if="this.trigger.type === TriggerType.current_chat_is">
      <SearchDialog
          :model-value="this.trigger.current_chat_is"
          @update:model-value="($event) => {
            if ($event instanceof SearchDialogItem) {
              this.trigger.current_chat_is = $event
            } else {
              this.trigger.current_chat_is = null
            }
          }"
          :show-private-dialogs="true"
          :disabled="!this.isEditMode"
          class="w-100"
      />
    </div>

    <InputText
        class="w-100 text-center"
        v-show="this.trigger.type === TriggerType.messageCommand"
        v-model="this.trigger.command_description"
        :placeholder="this.trigger.isNew ? 'Введите описание команды' : ''"
        :disabled="!this.isEditMode"
    />

<!--    todo: не показывать для regexp'a -->
    <div v-if="this.trigger.type === TriggerType.msg">
<!--      <Checkbox />-->
<!--      <label class="ms-2">Учитывать регистр</label>-->
    </div>
  </div>
</template>

<script lang="ts">
import {GraphConfigTriggerType, Trigger, TriggerOperation, TriggerType} from "@/api/graph";
import {defineComponent, inject, PropType} from "vue";
import {ContextItemForBuilder} from "@/api/context";
import flatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Dropdown, {DropdownChangeEvent} from "primevue/dropdown";
import TimeInput from "@/components/common/TimeInput.vue";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import DateInput from "@/components/common/DateInput.vue";
import Calendar from "primevue/calendar";
import SearchDialog from "@/components/common/SearchDialog.vue";
import Button from "primevue/button";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import InputSwitch from "primevue/inputswitch";
import {SearchDialogItem} from "@/api/edge";

export default defineComponent({
  name: 'TriggerItem',
  components: {
    Button,
    SearchDialog,
    Calendar,
    DateInput,
    TimeInput,
    flatPicker,
    Dropdown,
    Checkbox,
    InputText,
    InputSwitch,
  },
  setup() {
    const scenario_uuid = inject('scenario_uuid')
    const graphConfigStorage = useGraphConfigStorage()
    
    return {
      graphConfigStorage,
      scenario_uuid,
    }
  },
  emits: [
    'pressed-button-remove',
    'completed-fill-trigger',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<Trigger>,
      required: true,
    },
    // todo: реализовать через inject
    isEditMode: {
      required: true,
      type: Boolean,
    },
    contextOptions: {
      type: Array as PropType<ContextItemForBuilder[]>,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
      default: [],
    },
    triggerOptions: {
      type: Array as PropType<GraphConfigTriggerType[]>,
      required: true,
    }
  },

  data: function () {
    return {
      trigger: this.modelValue,
    }
  },
  methods: {
    onChangeTriggerType: function (event: DropdownChangeEvent) {
      if (
          event.value === TriggerType.has_tag ||
          event.value === TriggerType.subscribed_on_chat ||
          event.value === TriggerType.current_chat_is ||
          event.value === TriggerType.messageCommand ||
          event.value === TriggerType.wasSubscribed ||
          event.value === TriggerType.isAdmin ||
          event.value === TriggerType.isNotAdmin
      ) {
        this.trigger.operation = TriggerOperation.equals
      }
    },
  },
  computed: {
    patternPlaceholder: function () {
      if (this.trigger.type === TriggerType.messageCommand) {
        return 'Команда'
      }

      if (
          this.trigger.type === TriggerType.msg &&
          this.trigger.operation === TriggerOperation.regexp
      ) {
        return 'Пример: ~^[a-zA-Z]{2}$~'
      }

      if (this.trigger.isNew) {
        return 'Сравниваемое значение'
      }

      return ''
    },

    SearchDialogItem() {
      return SearchDialogItem
    },
    TriggerOperation() {
      return TriggerOperation
    },
    TriggerType() {
      return TriggerType
    },
    pattern: function (): string|null {
      return this.trigger.pattern;
    },
    isShowButtonCreate: function (): boolean {
      if (!this.trigger.type) {
        return false
      }

      switch (this.trigger.type) {
        case TriggerType.wasSubscribed:
          return true

        case TriggerType.context:
          if (
              this.trigger.context_code !== null &&
              this.trigger.operation !== null &&
              this.trigger.pattern !== null
          ) {
            return true
          }
          return false

        case TriggerType.date:
        case TriggerType.date_on_subscribed:
          if (this.trigger.pattern) {
            return true
          }
          return false

        case TriggerType.time:
          if (this.trigger.pattern) {
            return true
          }
          return false

        case TriggerType.msg:
          if (this.trigger.pattern) {
            return true
          }
          return false

        case TriggerType.messageCommand:
          if (this.trigger.pattern && this.trigger.command_description) {
            return true
          }
          return false
      }

      return false
    },
    isShowOperations: function (): boolean {
      if (this.trigger.type === TriggerType.wasSubscribed) {
        return false
      }

      if (this.trigger.type === TriggerType.isReply || this.trigger.type === TriggerType.isNotReply) {
        return false
      }

      if (this.trigger.type === TriggerType.isAdmin || this.trigger.type === TriggerType.isNotAdmin) {
        return false
      }

      if (this.trigger.type === TriggerType.has_tag) {
        return false
      }

      if (this.trigger.type === TriggerType.subscribed_on_chat || this.trigger.type === TriggerType.not_subscribed_on_chat) {
        return false
      }

      if (this.trigger.type === TriggerType.current_chat_is) {
        return false
      }

      if (this.trigger.type === TriggerType.published_post_in_channel) {
        return false
      }

      if (this.trigger.type === TriggerType.messageCommand) {
        return false
      }

      if (this.trigger.type === TriggerType.messageHasTelegramLinks) {
        return false
      }

      if (
          this.trigger.type === null
      ) {
        return false
      }

      return true
    },
    isShowPattern: function (): boolean {
      if (this.trigger.type === null) {
        return false
      }

      if (this.trigger.type === TriggerType.messagesCounter) {
        return true
      }

      if (
          this.trigger.type === TriggerType.msg
      ) {
        if (this.trigger.operation === TriggerOperation.contains_word_from_dictionary) {
          return false
        }
        return true
      }

      if (
          this.trigger.type === TriggerType.messageCommand
      ) {
        return true
      }

      if (
          this.trigger.type === TriggerType.context &&
          this.trigger.context_code
      ) {
        return true
      }

      return false
    },
    // todo: этот механизм должен передавать с бека в рамках конфига
    availableOperations: function () {
      switch (this.trigger.type) {
        case TriggerType.date_on_subscribed:
        case TriggerType.date:
        case TriggerType.time:
        case TriggerType.messagesCounter:
          return [
            {
              code: 'more_or_equals',
              name: 'Больше или равно',
            },
            {
              code: 'less_or_equals',
              name: 'Меньше или равно',
            },
            {
              code: 'less',
              name: 'Меньше',
            },
            {
              code: 'more',
              name: 'Больше',
            },
          ]

        case TriggerType.context:
          return [
            // {
            //   code: 'regexp',
            //   name: 'Соответствует regexp',
            // },
            {
              code: 'equals',
              name: 'Равенство',
            },
            {
              code: 'more_or_equals',
              name: 'Больше или равно',
            },
            {
              code: 'less_or_equals',
              name: 'Меньше или равно',
            },
            {
              code: 'less',
              name: 'Меньше',
            },
            {
              code: 'more',
              name: 'Больше',
            },
          ]

        case TriggerType.msg:
          if (this.modelValue.invert) {
            return [
              {
                code: TriggerOperation.regexp,
                name: 'Не соответствует регулярному выражению',
              },
              {
                code: TriggerOperation.equals,
                name: 'Целиком не равно',
              },
              {
                code: TriggerOperation.substr,
                name: 'Не содержит в себе',
              },
              {
                code: TriggerOperation.beginWith,
                name: 'Не начинается с',
              },
              {
                code: TriggerOperation.endWith,
                name: 'Не заканчивается на',
              },
              {
                code: TriggerOperation.contains_word_from_dictionary,
                name: 'Не содержит слово из справочника',
              },
              {
                code: TriggerOperation.containsWord,
                name: 'Не содержит слово',
              },
            ]
          }

          return [
            {
              code: TriggerOperation.regexp,
              name: 'Соответствует регулярному выражению',
            },
            {
              code: TriggerOperation.equals,
              name: 'Целиком равно',
            },
            {
              code: TriggerOperation.substr,
              name: 'Содержит в себе',
            },
            {
              code: TriggerOperation.beginWith,
              name: 'Начинается с',
            },
            {
              code: TriggerOperation.endWith,
              name: 'Заканчивается на',
            },
            {
              code: TriggerOperation.contains_word_from_dictionary,
              name: 'Содержит слово из справочника',
            },
            {
              code: TriggerOperation.containsWord,
              name: 'Содержит слово',
            },
          ]

          throw new Error('Обратитесь в тех.поддержку!')
      }
    }
  },
  watch: {
    trigger: {
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
    },

    "trigger.type": function (newValue, prevValue) {
      if (!this.isShowOperations) {
        this.$emit('completed-fill-trigger')
      }
    },

    "trigger.pattern": function (newValue, prevValue) {
      // Событие что завершили заполнять данные для данного триггера
      this.$emit('completed-fill-trigger')
    },
    "trigger.current_chat_is": function () {
      // Событие что завершили заполнять данные для данного триггера
      this.$emit('completed-fill-trigger')
    },
    "trigger.subscribed_on_chats": function () {
      // Событие что завершили заполнять данные для данного триггера
      this.$emit('completed-fill-trigger')
    }
  },
  
})
</script>


