import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AutoComplete, {
      class: "mt-auto",
      modelValue: this.searchContext,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.searchContext) = $event)),
      "option-label": "name",
      "data-key": "uuid",
      suggestions: this.searchContextOptions,
      placeholder: "Подстановка переменных",
      onComplete: this.searchOptions,
      dropdown: true,
      onItemSelect: _cache[1] || (_cache[1] = ($event) => {
          this.$emit('selected-context', $event.value.code)
        })
    }, null, 8, ["modelValue", "suggestions", "onComplete"])
  ]))
}