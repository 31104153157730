<template>
  <Calendar
      ref="calendar"
      :class="this.class"
      v-model="this.value"
      time-only
      @focusout="this.focusOut"
      :show-button-bar="this.showBar"
      :invalid="this.invalid"
      :disabled="this.disabled"
      :placeholder="this.placeholder"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {CalendarState} from "primevue/calendar";
import Calendar from "primevue/calendar";

export default defineComponent({
  name: 'TimeInput',
  props: {
    modelValue: {
      required: true,
      type: [String, null],
    },
    invalid: {
      required: false,
      default: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    showBar: {
      required: false,
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    class: {
      required: false,
      default: 'w-100',
    }
  },
  emits: [
    'update:modelValue'
  ],
  components: {
    Calendar,
  },
  methods: {
    // Закрываем календарь при потери фокуса
    focusOut: function () {
      const vuePrimeCalendar = this.$refs.calendar as CalendarState;
      vuePrimeCalendar.overlayVisible = false;
    },
  },
  computed: {
    value: {
      get: function (): any {
        if (!this.modelValue) {
          return null
        }

        // Если в modelValue уже находится дата и время, то так и возвращаем
        if (!isNaN(Date.parse(this.modelValue))) {
          return this.modelValue
        }

        // костыль, т.к календарь от primevue всегда ожидает на входе дату,
        //  даже когда требуется отображение только времени
        return new Date('0000-01-01 ' + this.modelValue)
      },
      set: function (value: Date|null) {
        if (!value) {
          this.$emit('update:modelValue', null)
          return
        }

        this.$emit('update:modelValue',  (value.getHours() + ":" + value.getMinutes()));
      }
    }
  }
})
</script>
