import {MenuItem, MenuItemCommandEvent} from "primevue/menuitem";


export class BreadcrumbItem
{
    label: string;

    icon: string|null = null;

    route: string

    route_params: Object|null;

    constructor(label: string, icon: string | null, route: string, route_params: Object|null) {
        this.label = label;
        this.icon = icon;
        this.route = route;
        this.route_params = route_params
    }
}