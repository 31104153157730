<template>
  <div>
    <div class="rounded-pill d-inline-block p-1 me-1 mb-1" style="background-color: #d7d9d9" v-for="(word, index) in this.wordsList">
      <div class="d-inline-block">
        <span v-show="word.type === WordType.anyBegin">%</span>
        <div
            contenteditable="true"
            class="d-inline-block p-2 pe-4"
            @input="this.handleEditWord(index, $event)"
        >{{word.value}}</div>
        <span v-show="word.type === WordType.anyEnd">%</span>
      </div>

      <button
          class="bg-transparent border-0 pi pi-times-circle text-danger"
          @click="() => {
            this.wordsList.splice(index, 1)
          }"
      />
    </div>

    <Button
        icon="pi pi-plus"
        severity="contrast"
        :label="this.labelAddWordButton"
        class="rounded-pill align-middle"
        @click="this.toggle"
    />
    <Menu
        ref="menu"
        :popup="true"
        :model="this.wordTypeItems"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Button from "primevue/button";
import Menu from "primevue/menu";
import {Word, WordType} from "@/api/dictionary";

export default defineComponent({
  name: "WordsList",
  computed: {
    WordType() {
      return WordType
    },
    Word() {
      return Word
    },

    wordTypeItems: function () {
      const result = []

      this.showWordTypes.forEach((wordType: WordType) => {
        let label = ''

        switch (wordType) {
          case WordType.anyEnd:
            label = 'После слова могут стоять любые буквы'
            break;

          case WordType.anyBegin:
            label = 'Перед словом могут стоять любые буквы'
            break;

          case WordType.fullEquals:
            label = 'Слово полностью совпадает'
            break;
        }

        result.push({
          label: label,
          icon: 'pi pi-sort-alpha-up',
          command: () => this.handleAddWord(wordType),
        })
      })

      return result
    },
  },
  components: {
    Menu,
    Button,
  },
  props: {
    words: {
      type: Array as PropType<Word[]>,
      required: true,
    },
    showWordTypes: {
      type: Array as PropType<WordType[]>,
      required: true,
    },
    labelAddWordButton: {
      required: false,
      type: String,
      default: 'добавить слово',
    }
  },
  data: function () {
    return {
      wordsList: this.words,
    }
  },
  watch: {
    words: function (val: Word[]) {
      this.wordsList = val
    },

    wordsList: function (val: Word[]) {
      this.$emit('words:update', val)
    }
  },
  methods: {
    toggle: function (event) {
      if (this.showWordTypes.length === 1) {
        this.handleAddWord(this.showWordTypes[0])
        return
      }

      this.$refs.menu.toggle(event);
    },

    handleAddWord: function (wordType: WordType): void {
      this.wordsList.push(new Word(wordType))
    },

    handleEditWord: function (index: number, event: InputEvent): void {
      this.wordsList[index].value = event.target.innerText
    }
  },
  emits: [
      'words:update',
  ],
})
</script>