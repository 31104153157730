import {SearchDialogItem} from "./edge";
import {ButtonRow} from "./buttonGroupPanel";
import {plainToClass, plainToInstance, Type} from "class-transformer";
import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {ForwardedForList} from "./forwarder";
import {GetForListResult} from "./dialog";


export class FeedbackBotFirstMessage
{
    text: string = ''

    @Type(() => ButtonRow)
    buttons: ButtonRow[] = []
}

export class AllowMessageTypes
{
    photo: boolean = true
    video: boolean = true
    audio: boolean = true
    document: boolean = true
}

export class UpdateFeedbackBot
{
    scenario_uuid: string

    @Type(() => FeedbackBotFirstMessage)
    first_message: FeedbackBotFirstMessage

    @Type(() => SearchDialogItem)
    feedback_dialog: SearchDialogItem|null = null

    @Type(() => AllowMessageTypes)
    allowed_media_types: AllowMessageTypes

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }
}

export class ScenarioFeedbackCard extends UpdateFeedbackBot
{
}

export function updateScenarioFeedback(cmd: UpdateFeedbackBot): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/scenario/feedback/update', cmd)
}

export function getScenarioFeedback(scenarioUuid: string): Promise<ScenarioFeedbackCard>
{
    return client
        .post('/api/v1/scenario/feedback/get', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(ScenarioFeedbackCard, response.data);
        })
}