import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f9e0707c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex bd-highlight" }
const _hoisted_2 = { class: "bd-highlight w-25" }
const _hoisted_3 = { style: {"overflow-y":"scroll","height":"100vh"} }
const _hoisted_4 = { class: "list-group rounded-0" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "mb-0 fw-bold" }
const _hoisted_7 = { class: "font-italic mt-2 mb-2 text-muted" }
const _hoisted_8 = { class: "small font-weight-bold" }
const _hoisted_9 = {
  class: "w-100 bd-highlight",
  style: {"background":"linear-gradient(90deg, #413f3d, #5f5e5e)"}
}
const _hoisted_10 = {
  class: "",
  style: {"display":"flex","flex-direction":"column","height":"100vh"}
}
const _hoisted_11 = {
  class: "p-3",
  ref: "message_scroll",
  style: {"flex-grow":"1","overflow-y":"scroll"}
}
const _hoisted_12 = { class: "w-50 text-break" }
const _hoisted_13 = { class: "media-body ml-3" }
const _hoisted_14 = { class: "small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.dialogs.dialogs, (dialog) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (this.selectDialog = dialog),
              class: "m-2"
            }, [
              _createElementVNode("a", {
                class: _normalizeClass(["list-group-item list-group-item-action rounded", { 'active text-white': this.currentDialogUuid == dialog.uuid, 'list-group-item-light': this.currentDialogUuid != dialog.uuid }])
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("h6", _hoisted_6, _toDisplayString(dialog.name), 1),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(dialog.last_message), 1),
                  _createElementVNode("small", _hoisted_8, _toDisplayString(dialog.last_message_time), 1)
                ])
              ], 2)
            ], 8, _hoisted_5))
          }), 256))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _withDirectives(_createElementVNode("div", {
          class: "p-3 text-white fw-bold text-center",
          style: {"background-color":"#0e1525A0"}
        }, _toDisplayString(this.selectDialog?.name), 513), [
          [_vShow, this.selectDialog?.name]
        ]),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.messages.messages, (message) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass({ 'd-flex justify-content-end': !message.from_bot })
            }, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["rounded py-2 px-3 mb-2", {'bg-primary': message.from_bot, 'bg-light': !message.from_bot }])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["text-small mb-0 mt-2 message-text", {'text-white': message.from_bot, 'text-muted': !message.from_bot}])
                    }, [
                      _createTextVNode(_toDisplayString(message.message) + " ", 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(message.files, (file) => {
                        return (_openBlock(), _createElementBlock("div", null, [
                          (file.type == 'image')
                            ? (_openBlock(), _createBlock(_component_Image, {
                                key: 0,
                                src: file.link,
                                alt: "Image",
                                height: "300",
                                preview: "",
                                "image-style": {maxWidth: '100%'}
                              }, null, 8, ["src"]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 256))
                    ], 2)
                  ], 2),
                  _createElementVNode("p", _hoisted_14, _toDisplayString(message.date), 1)
                ])
              ])
            ], 2))
          }), 256))
        ], 512),
        _createElementVNode("div", {
          class: "p-1 d-flex",
          style: {"background-color":"#0e1525A0"},
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (this.sendMessage && this.sendMessage(...args)), ["enter"]))
        }, [
          _createVNode(_component_Textarea, {
            placeholder: "Сообщение",
            class: "rounded p-2 flex-grow-1 bg-transparent text-white",
            "auto-resize": true,
            rows: 3,
            modelValue: this.sendMsgContent,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.sendMsgContent) = $event)),
            disabled: this.currentDialogUuid === null
          }, null, 8, ["modelValue", "disabled"]),
          _createElementVNode("button", {
            class: "pi pi-send border-0 bg-transparent text-white align-top",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (this.sendMessage && this.sendMessage(...args)))
          })
        ], 32)
      ])
    ])
  ]))
}