<template>
  <div class="m-2">
    <Breadcrumbs :items="this.breadcrumbs"/>

    <div class="content-group rounded mt-2">
      <div>
        <Checkbox :binary="true" v-model="this.card.active" class="me-2"/>
        <span>Принимать все заявки на вступление</span>
      </div>
    </div>

    <div class="mt-4 pb-4">
      <div class="d-flex justify-content-end">
        <Button
            label="Сохранить"
            severity="contrast"
            class="rounded"
            :loading="this.isLoadingUpdate"
            @click="() => {
               this.isLoadingUpdate = true;

               updateDialogJoinRequestSettings(this.dialog_uuid, this.card)
                  .finally(() => {
                    this.isLoadingUpdate = false
                  })
          }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import {
  DialogUpdateJoinRequest,
  getDialogJoinRequestSettings,
  updateDialogJoinRequestSettings
} from "@/api/dialogJoinRequestSettings";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";

export default defineComponent({
  name: "AutoApproveJoinRequest",
  methods: {updateDialogJoinRequestSettings},
  components: {
    Breadcrumbs,
    Button,
    Checkbox,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      isLoadingUpdate: false,
      card: new DialogUpdateJoinRequest(),

      breadcrumbs: [],
    }
  },
  created() {
    getDialogJoinRequestSettings(this.dialog_uuid).then((res) => {
      this.card = res

      this.breadcrumbs = [
        new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
        new BreadcrumbItem(res.name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
        new BreadcrumbItem('Автоприем заявок на вступление'),
      ]
    })
  }
})
</script>