import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container mt-2" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col text-center" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { class: "d-inline-block rounded me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.buttons, (row) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.items, (element) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_Button, {
                  label: element.name,
                  icon: _ctx.resolveButtonIcon(element.type),
                  class: "rounded align-middle"
                }, null, 8, ["label", "icon"]),
                _renderSlot(_ctx.$slots, "button_footer", { button: element })
              ]))
            }), 256))
          ]))
        }), 256))
      ])
    ])
  ]))
}