<template>
  <div>
    <h5 class="fw-bold">Сообщение</h5>

    <p class="m-0">Данный блок служит для отправки сообщения в Telegram, например для ответа пользователю, оповещения модератора о совершении какого-либо действия и т.д</p>

    <div class="mt-4">
      <p>
        Как видно из примера ниже, мы обращаемся к подписчику по имени и отображаем название бота используя механизм шаблонизации,
        подставив название переменной между <span v-pre>{{ }}</span>, бот подставит значение данной переменной
      </p>

      <div v-pre>
        <code class="code-style">
          Привет {{_first_name}}, меня зовут {{_bot_name}} и я собран на платформе https://ninja-bot.ru,
          давай я помогу тебе выбрать квартиру. Выбери город в котором ты рассматриваешь варианты
        </code>
      </div>
    </div>


    <div class="d-flex justify-content-center mt-5">
      <iframe
          src="https://ninja-bot.ru/bot/67daa36e-89ce-4a0c-b73d-b33e11bdc57f/builder"
          class="custom-border"
          style="height: 600px; width: 80%"
      />
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Divider from "primevue/divider";
import Button from "primevue/button";

export default defineComponent({
  name: "DocsBuilderMessage",
  components: {
    Divider,
    Image,
    Button,
  },
  data: function () {
    return {
      expandTemplate: false,
    }
  }
})
</script>