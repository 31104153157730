<template>
  <router-view/>
</template>

<script lang="ts">
import {defineComponent, provide, ref} from "vue";

export default defineComponent({
  name: 'BotLayout',
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const scenario_uuid = ref(props.scenario_uuid)
    provide('scenario_uuid', scenario_uuid)

    return {
      scenario_uuid
    }
  },
})
</script>
