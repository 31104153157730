<template>
  <div>
    <AutoComplete
        class="mt-auto"
        v-model="this.searchContext"
        option-label="name"
        data-key="uuid"
        :suggestions="this.searchContextOptions"
        placeholder="Подстановка переменных"
        @complete="this.searchOptions"
        :dropdown="true"
        @item-select="($event) => {
          this.$emit('selected-context', $event.value.code)
        }"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import AutoComplete from "primevue/autocomplete";
import {ContextItemForBuilder} from "@/api/context";

export default defineComponent({
  name: "SearchContext",
  components: {
    AutoComplete,
  },
  props: {
    contextOptions: {
      type: Array as PropType<ContextItemForBuilder[]>,
      required: true,
    }
  },
  data: function () {
    return {
      searchContext: '',
      searchContextOptions: [] as ContextItemForBuilder[],
    }
  },
  emits: [
      'selected-context',
  ],
  methods: {
    searchOptions: function (searchOptionsEvent) {
      this.searchContextOptions = this.contextOptions.filter((item: ContextItemForBuilder) => {
        return item.name.toLowerCase().startsWith(searchOptionsEvent.query.toLowerCase())
      })
    },
  }
})
</script>