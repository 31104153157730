<template>
  <div class="parent">
    <div class="block">
      <p>Необходимо авторизоваться:</p>
      <button type="button" class="google-sign-in-button" @click="this.redirectToGoogleAuth" >
        Войти через Google
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {AuthType, getAuthLink, GetLinkResult} from "@/api/auth";
import {useAuthStorage, useUserStorage} from "@/store";

export default defineComponent({
  name: 'SignIn',
  methods: {
    redirectToGoogleAuth: function () {
      getAuthLink(AuthType.google).then((getLinkResult: GetLinkResult) => {
        window.location.href = getLinkResult.redirect_url
      })
    },
  },
  mounted() {
    const token = this.$route.query.token
    const refresh = this.$route.query.refresh

    if (token) {
      this.authStore.setToken(token)
      this.authStore.setRefreshToken(refresh)


      if (this.userStorage.getRedirectAfterAuthRouteName) {
        this.$router.push({name: this.userStorage.getRedirectAfterAuthRouteName, params: this.userStorage.getRedirectAfterAuthRouteParams})
        this.userStorage.clearRedirectAfterAuth()
      } else {
        this.$router.push({name: 'bots_list'})
      }
    }
  },
  setup() {
    const authStore = useAuthStorage()
    const userStorage = useUserStorage()
    return {authStore, userStorage}
  },
})
</script>


<style scoped>
.google-sign-in-button {
  cursor: pointer;
  transition: background-color .3s, box-shadow .3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.google-sign-in-button:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button:active {
  background-color: #eeeeee;
}

.google-sign-in-button:active {
  outline: none;
  box-shadow:
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
}

.google-sign-in-button:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  cursor: not-allowed;
}

.parent {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;
}

.block {
  //background: #60a839;

  img {
    display: block;
    border: none;
  }
}
</style>
