import {AxiosError} from "axios";


export class Error
{
    message: string
    code: number
    validations: ValidationErrorItem[] = []


    constructor(message: string, code: number, validations: ValidationErrorItem[]) {
        this.message = message;
        this.code = code;
        this.validations = validations;
    }
}

export class ValidationErrorItem
{
    field: string
    message: string
}

export class ErrorResponse
{
    data: object|null = null
    success: boolean = false
    error: Error

    constructor(error: AxiosError | Error) {
        this.data = null;
        this.success = false
        this.error = new Error(
            error.message,
            error.code,
            error.validations ?? []
        )
    }

    getValidationErrors(): Array<string, string>
    {
        if (!this.error.validations) {
            return []
        }

        const result = []
        this.error.validations.forEach((item: ValidationErrorItem) => {
            result[item.field] = item.message
        })
        return result
    }

    static getValidationErrorsNested(errors: Object, groupKey: string): Array<string, string>
    {
        const nestedErrors = []

        for (let errorKey in errors) {
            if (errors.hasOwnProperty(errorKey) && errorKey.startsWith(groupKey)) {
                let subKey = errorKey.substring(groupKey.length)
                if (subKey.startsWith('.')) {
                    subKey = subKey.substring(1)
                }

                nestedErrors[subKey] = errors[errorKey]
            }
        }

        return nestedErrors
    }
}
