<template>
  <div class="bg-white p-5">
    <FullCalendar :options="this.calendarOptions" ref="calendar"/>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import FullCalendar from "@fullcalendar/vue3";
import DateInput from "@/components/common/DateInput.vue";
import TimeInput from "@/components/common/TimeInput.vue";
import Column from "primevue/column";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import MultiSelect from 'primevue/multiselect';
import Tag from "primevue/tag";
import {getPostsForCalendar, GetPostsForCalendarQuery, PostForCalendar, PostsForCalendar} from "@/api/post";
import {CalendarApi, CalendarOptions, DatesSetArg} from "@fullcalendar/core";
import {GetForCardRecord} from "@/api/calendar";
import {EventImpl} from "@fullcalendar/core/internal";
import listPlugin from '@fullcalendar/list';

export default defineComponent({
  name: "PostsCalendar",
  components: {
    Tag,
    DateInput,
    TimeInput,
    Column,
    Button,
    DataTable,
    Dialog,
    FullCalendar,
    MultiSelect,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {
      // Для проверки что поменялся месяц и нужно дозапросить данные с бэка
      currentStartDate: null as string|null,

      calendarOptions: {
        // eventBackgroundColor: "#de1f1f",
        // eventBorderColor: "#224e83",
        // eventTextColor: 'rgb(0,0,0)',
        timeZone: 'UTC',
        locale: 'ru',
        weekNumberCalculation: 'ISO',

        eventDidMount: function(info) {
        },

        plugins: [
          listPlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],

        views: {
          dayGridMonth: { buttonText: 'Месяц' },
          timeGridWeek: { buttonText: 'Неделя' },

          listMonth: { buttonText: 'Месяц списком' },
          listWeek: { buttonText: 'Неделя списком' },
          listDay: { buttonText: 'День списком' },
        },

        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek listMonth,listWeek,listDay'
        },
        initialView: 'dayGridMonth',
        // initialView: 'dayGridMonth',

        // Событие изменения дат
        datesSet: this.handleDatesSet,

        // todo: пока запрещает перемещать записи, в следующих релизах реализовать такой функционал
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,

        // https://fullcalendar.io/docs/event-dragging-resizing почитать про обработку событий
        eventResize: () => {
          console.log('eventResize')
        },
        eventDrag: () => {
          console.log('eventDrag')
        },
        eventDragMutationMassagers: () => {
          console.log('eventDragMutationMassagers')
        },



        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */

        buttonText: {
          today: 'Сегодня',
          prev: '<',
          next: '>',
          prevYear: 'Следующий год',
          nextYear: 'Предыдущий год',
        },
      } as CalendarOptions,
    }
  },
  methods: {
    handleDatesSet: function (event: DatesSetArg) {
      const eventStartDate = '01-' + (event.view.currentStart.getMonth() + 1) + '-' + event.view.currentStart.getFullYear();

      if (this.currentStartDate === eventStartDate) {
        return
      }

      this.currentStartDate = eventStartDate
      getPostsForCalendar(new GetPostsForCalendarQuery(this.scenario_uuid, this.currentStartDate)).then((result: PostsForCalendar) => {
        // @ts-ignore
        const calendarApi = this.$refs?.calendar?.calendar as CalendarApi

        if (calendarApi) {
          this.calendarOptions.events = result.items.map((item: PostForCalendar) => {
            return {
              id: item.uuid,
              title: item.name,
              start: item.datetime,
            }
          });
        }
      })
    },

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo: any) {
      return;

      // todo: реализовать поддержку создания записи на прием из UI

      let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection
    },
    handleEventClick(clickInfo: Object) {
      const event = clickInfo.event as EventImpl

      console.log(event)

      // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      //   clickInfo.event.remove()
      // }
    },


    handleOnDrag: function () {
      console.log('handleOnDrag')
    }
  },
})
</script>

<style lang='css' scoped>

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

</style>