import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82bce196"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "text-center pb-4 rounded-top node_bg_custom",
  style: {"width":"400px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleNode = _resolveComponent("TitleNode")!
  const _component_SourceHandle = _resolveComponent("SourceHandle")!
  const _component_EditableContent = _resolveComponent("EditableContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TitleNode, {
      "node-uuid": this.data.uuid,
      "node-name": this.data.name,
      "node-position": this.data.position,
      items: [],
      "is-read-only": true
    }, null, 8, ["node-uuid", "node-name", "node-position"]),
    _createVNode(_component_EditableContent, {
      "is-read-only": true,
      "disable-save-button": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SourceHandle)
      ]),
      _: 1
    })
  ]))
}