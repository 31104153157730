import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { class: "align-middle" }
const _hoisted_4 = { class: "align-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    disabled: this.disabled,
    hidden: this.hidden,
    modelValue: this.fakeSearch,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((this.fakeSearch) = $event)),
      _cache[1] || (_cache[1] = ($event) => {
        if ($event instanceof _ctx.SearchDialogItem) {
           this.$emit('update:modelValue', $event)
        } else {
            this.$emit('update:modelValue', null)
        }
      })
    ],
    optionLabel: "name",
    "data-key": "uuid",
    suggestions: this.dialogOptions.dialogs,
    placeholder: this.placeholder,
    onComplete: this.searchOptions,
    loading: this.isLoading,
    dropdown: this.dropdown,
    "force-selection": true
  }, {
    option: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          (slotProps.option.icon_image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: slotProps.option.name,
                src: slotProps.option.icon_image,
                class: "me-2 rounded-circle align-middle",
                style: {"height":"30px"}
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_3, _toDisplayString(slotProps.option.name), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(slotProps.option.type_print), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["disabled", "hidden", "modelValue", "suggestions", "placeholder", "onComplete", "loading", "dropdown"]))
}