

export class SideBarMenu
{
    name: string
    route_name: string|null = null
    icon: string
    submenu: SideBarMenu[] = []
    is_expanded: boolean = false
    route_params: Object|null = null
    target: string
    as_header: boolean = false

    constructor(name: string, route_name: string|null, route_params: Object|null, icon: string, submenu: SideBarMenu[], target: string = '_self') {
        this.name = name;
        this.route_name = route_name;
        this.icon = icon;
        this.submenu = submenu;
        this.route_params = route_params
        this.target = target

        if (submenu.length !== 0) {
            this.is_expanded = true
        }

        if (route_name === null && route_params === null) {
            this.as_header = true
        }
    }
}