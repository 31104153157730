<template>
  <div>
    <div class="content-group">
      <p class="m-0 required">Название очереди</p>
      <InputText v-model="this.card.name"/>

      <div class="mt-2">
        <p class="m-0 required">Часовой пояс</p>
        <Dropdown
            v-model="this.card.timezone"
            option-label="label"
            option-value="value"
            :options="getTimeZones()"
        />
      </div>
    </div>

    <div class="content-group">
      <p class="mb-0 required">Пауза между публикациями:</p>
      <InputNumber
          v-model="this.card.interval_value"
      />
      <Dropdown
          v-model="this.card.interval_unit"
          class="ms-2"
          option-label="label"
          option-value="value"
          :options="this.getTimeUnits()"
          scroll-height="500px"
          :date-format="this.getCalendarFormat()"
      />
    </div>

    <div class="content-group">
      <div>
        <Checkbox
            v-model="this.filterPostAnyDate"
            :binary="true"
            @update:model-value="($event) => {
              if ($event === true) {
                this.card.filter_date.empty()
              }
            }"
        />
        <span class="ms-2">Посты могут публиковаться в любую дату</span>
      </div>

      <div v-show="this.filterPostAnyDate === false">
        <div class="mt-2">
          <p class="m-0">Начать публиковать посты из очереди</p>
          <Calendar
              v-model="this.card.filter_date.from"
              hour-format="24"
              :date-format="this.getCalendarFormat()"
              :show-icon="true"
              :show-time="true"
          />
        </div>
        <div class="mt-2">
          <p class="m-0">Закончить публиковать посты из очереди</p>
          <Calendar
              v-model="this.card.filter_date.to"
              hour-format="24"
              :date-format="this.getCalendarFormat()"
              :show-icon="true"
              :show-time="true"
          />
        </div>
      </div>
    </div>

    <div class="content-group">
      <div>
        <Checkbox
            :binary="true"
            v-model="this.filterPostAnyTime"
            @update:model-value="($event) => {
              if ($event === true) {
                this.card.filter_time.empty()
              }
            }"
        />
        <span class="ms-2">Посты могут публиковаться в любое время в течении дня</span>
      </div>

      <div v-show="this.filterPostAnyTime === false">
        <div class="mt-2">
          <p class="m-0">С какого времени можно публиковать посты</p>
          <TimeInput v-model="this.card.filter_time.from" class=""/>
        </div>
        <div>
          <p class="m-0">По какое времени можно публиковать посты</p>
          <TimeInput v-model="this.card.filter_time.to" class=""/>
        </div>
      </div>
    </div>

    <div class="content-group">
      <div>
        <Checkbox
            :binary="true"
            v-model="this.filterPostAnyDayOfWeek"
            @update:model-value="($event) => {
              if ($event) {
                this.card.filter_days_of_week.empty()
              }
            }"
        />
        <span class="ms-2">Посты могут публиковаться в любой день недели</span>
      </div>

      <div class="mt-4 mb-2" v-show="this.filterPostAnyDayOfWeek === false">
        <div>
          <div class="d-inline-block me-2">
            <p class="m-0">Пн</p>
            <Checkbox :binary="true" v-model="this.card.filter_days_of_week.day1"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Вт</p>
            <Checkbox :binary="true" v-model="this.card.filter_days_of_week.day2"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Ср</p>
            <Checkbox :binary="true" v-model="this.card.filter_days_of_week.day3"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Чт</p>
            <Checkbox :binary="true" v-model="this.card.filter_days_of_week.day4"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Пт</p>
            <Checkbox :binary="true" v-model="this.card.filter_days_of_week.day5"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Сб</p>
            <Checkbox :binary="true" v-model="this.card.filter_days_of_week.day6"/>
          </div>

          <div class="d-inline-block me-2">
            <p class="m-0">Вс</p>
            <Checkbox :binary="true" v-model="this.card.filter_days_of_week.day7"/>
          </div>
        </div>
      </div>
    </div>

    <div class="content-group">
      <div>
        <Checkbox
            :binary="true"
            v-model="this.filterPostAnyDayOfMonth"
            @update:model-value="($event) => {
              if ($event) {
                this.card.filter_days_of_month.empty()
              }
            }"
        />
        <span class="ms-2">Посты могут публиковаться в любой день месяца</span>
      </div>

      <div class="mt-4 mb-2" v-show="this.filterPostAnyDayOfMonth === false">
        <div>
          <div class="d-inline-block me-2" v-for="(item, index) in Object.keys(this.card.filter_days_of_month)">
            <p class="text-center m-0">{{index + 1}}</p>
            <Checkbox :binary="true" v-model="(this.card.filter_days_of_month[item])"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import DateInput from "@/components/common/DateInput.vue";
import Dropdown from "primevue/dropdown";
import {getTimeZones} from "@/api/scenario";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import TimeInput from "@/components/common/TimeInput.vue";
import InputNumber from "primevue/inputnumber";
import {TimeUnit} from "@/api/graph";
import {CreateQueue} from "@/api/queue";

export default defineComponent({
  name: "QueueForm",
  methods: {
    getTimeZones,
    getCalendarFormat: function (): string {
      return 'dd-mm-yy'
    },

    getTimeUnits: function () {
      return [
        {
          label: 'минут',
          value: TimeUnit.minute,
        },
        {
          label: 'часа',
          value: TimeUnit.hour,
        },
        {
          label: 'дней',
          value: TimeUnit.day,
        },
      ]
    },
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<CreateQueue>,
    }
  },
  components: {
    InputNumber,
    TimeInput,
    Checkbox,
    Calendar,
    Dropdown,
    DateInput,
    InputText,
  },
  emits: [
      'update:modelValue',
  ],
  watch: {
    modelValue: function (val) {
      this.card = val
    }
  },
  data: function () {
    return {
      card: this.modelValue,

      filterPostAnyDate: this.modelValue.filter_date.isEmpty(),
      filterPostAnyTime: this.modelValue.filter_time.isEmpty(),
      filterPostAnyDayOfWeek: this.modelValue.filter_days_of_week.isEmpty(),
      filterPostAnyDayOfMonth: this.modelValue.filter_days_of_month.isEmpty(),
    }
  }
})
</script>