import client from "./client";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {plainToInstance} from "class-transformer";


export class WorkHoursUpdate
{
    active: boolean = false

    remove_prev_message: boolean = false
    start_at: string
    finish_at: string

    message_open_chat: string|null = null
    message_close_chat: string|null = null
}

export class WorkHoursGetForCard extends WorkHoursUpdate
{
    dialog_name: string
}

export async function updateWorkHours(cmd: WorkHoursUpdate): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/dialog/group/work-hours/update', cmd)
}

export async function getWorkHours(dialogUuid: string): Promise<WorkHoursGetForCard>
{
    return await client
        .post('/api/v1/dialog/group/work-hours/get-for-card', {dialog_uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToInstance(WorkHoursGetForCard, response.data);
        })
}