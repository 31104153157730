<template>
  <div>
    <h3 class="fw-bold">Бот обратной связи</h3>

    <div class="mt-4">
      <p>
        Бот нужно добавить в специальную группу, в нее будут перенаправляться все обращения клиентов в виде отдельных диалогов,
        для этого в данной группе нужно обязательно активировать режим "Темы".
      </p>
      <div>
        <Image
            height="300"
            src="/docs_feedback_bot_4.png"
            preview
            :image-style="{
              display: 'block',
              height: '100%',
              maxWidth: '100%',
          }"
        />
      </div>
    </div>

    <div class="mt-4">
      <p class="m-0">
        Для начала необходимо переключить бота в режим обратной связи.
        Бот не может одновременно исполнять сценарий заданный через конструктор и работать в режиме обратной связи,
        необходимо выбрать что-то одно
      </p>

      <div>
        <Image
            height="300"
            src="/docs_feedback_bot_1.jpg"
            preview
            :image-style="{
              display: 'block',
              height: '100%',
              maxWidth: '100%',
          }"
        />
      </div>
    </div>

    <div class="mt-4">
      <p>
        не забудьте добавить бота в эту группу и выдать ему административные права
      </p>
      <div>
        <Image
            height="300"
            src="/docs_feedback_bot_2.jpg"
            preview
            :image-style="{
              display: 'block',
              height: '100%',
              maxWidth: '100%',
          }"
        />
      </div>

      <p class="mb-0">
        Чтобы ответить на сообщение клиента - необходимо использовать реплай (ответ на само сообщение).
        Это сделано чтобы можно было оставлять пометки для себя и коллег.
      </p>
      <div>
        <Image
            height="300"
            src="/docs_feedback_bot_3.jpg"
            preview
            :image-style="{
              display: 'block',
              height: '100%',
              maxWidth: '100%',
          }"
        />
      </div>
    </div>

    <HelpInfo class="mt-2 pb-4">
      Если бот пишет что в группе не включена поддержка тем, то просто удалите и вновь добавьте бота в группу.
    </HelpInfo>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Panel from "primevue/panel";
import HelpInfo from "@/components/view/HelpInfo.vue";

export default defineComponent({
  name: "DocsFeedbackBot",
  components: {
    HelpInfo,
    Image,
    Panel,
  },
})
</script>