<template>
  <div>
    <h3 class="fw-bold">Справочники</h3>

    <div class="mt-2 mb-4">
      <p class="bg-primary-subtle p-2 custom-border rounded mt-2">
        Расположение функционала <span class="fw-bold">Боты - Выбираете бота - Справочники - Добавить справочник</span>
      </p>
    </div>

    <div>
      <p>
        Справочники представляют с собой список справочных слов, по которым можно выполнять различные условия в триггерах и условиях конструктора,
        например проверить что сообщение содержит запрещенное слово и удалить его или отловить сообщение содержащее ключевое слово и перенаправить его себе в ЛС.
      </p>

      <p class="text-danger">Описать функционал импорта слов из .txt файлов</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Panel from "primevue/panel";

export default defineComponent({
  name: "DocsDictionary",
  components: {
    Image,
    Panel,
  },
})
</script>

<style scoped>

</style>