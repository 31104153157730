<template>
  <div class="m-2">
    <Breadcrumbs :items="this.breadcrumbs" class="bg-white"/>

    <div class="m-4">
      <h5 class="fw-bold">Режим работы чата</h5>

      <div class="bg-primary-subtle p-2 rounded">
        <p class="m-0"><span class="pi pi-question-circle me-2"/>Запрещает отправку сообщений в указанном временном промежутке для не админов</p>
      </div>

      <div class="content-group mt-4">
        <div class="">
          <InputSwitch class="align-middle me-2" v-model="this.card.active"/>
          <span class="m-0 align-middle">Активность</span>
        </div>

        <div class="mt-2">
          <p class="m-0 required">С какого времени блокировать возможность общаться в чате</p>
          <TimeInput class="w-25" v-model="this.card.start_at"/>
        </div>

        <div class="mt-2">
          <p class="m-0 required">По какое время блокировать возможность общаться в чате</p>
          <TimeInput class="w-25" v-model="this.card.finish_at"/>
        </div>

        <div class="mt-2">
          <p class="m-0">Сообщение при открытии чата</p>
          <RichTextEditor v-model="this.card.message_open_chat"/>
        </div>

        <div class="mt-2">
          <p class="m-0">Сообщение при закрытии чата</p>
          <RichTextEditor v-model="this.card.message_close_chat"/>
        </div>

        <div class="mt-2">
          <InputSwitch class="align-middle me-2" v-model="this.card.remove_prev_message"/>
          <span class="m-0 align-middle">Удалять предыдущее сообщение</span>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <Button
          label="Сохранить"
          :loading="this.isLoadingSave"
          class="rounded"
          severity="secondary"
          @click="() => {
            this.isLoadingSave = true

            updateWorkHours(this.card)
              .finally(() => { this.isLoadingSave = false })
          }"
        />
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Dropdown from "primevue/dropdown";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import TimeInput from "@/components/common/TimeInput.vue";
import Textarea from "primevue/textarea";
import {getWorkHours, updateWorkHours, WorkHoursUpdate} from "@/api/workHours";
import Button from "primevue/button";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";

export default defineComponent({
  name: "WorkHours",
  methods: {updateWorkHours},
  components: {
    Breadcrumbs,
    TimeInput,
    RichTextEditor,
    InputNumber,
    InputSwitch,
    Dropdown,
    Textarea,
    Button,
  },
  data: function () {
    return {
      card: new WorkHoursUpdate(),
      isLoadingSave: false,
      breadcrumbs: [],
    }
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
  created() {
    getWorkHours(this.dialog_uuid)
        .then((res) => {
          this.card = res

          this.breadcrumbs = [
            new BreadcrumbItem('Telegram группы', 'bot_groups', 'bot_groups', {scenario_uuid: this.scenario_uuid}),
            new BreadcrumbItem(res.dialog_name, '', 'group_card', {scenario_uuid: this.scenario_uuid, dialog_uuid: this.dialog_uuid}),
            new BreadcrumbItem('Часы работы'),
          ]
        })
  },
})
</script>