import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Handle = _resolveComponent("Handle")!

  return (_openBlock(), _createBlock(_component_Handle, {
    id: this.targetHandleId,
    type: "target",
    position: _ctx.Position.Left,
    style: _normalizeStyle(this.style),
    "connectable-start": false
  }, null, 8, ["id", "position", "style"]))
}