<template>
  <div class="pb-4">
    <div class="mt-4">
      <h6 class="fw-bold">Процедура добавления бота в платформу</h6>

      <p class="m-0">Сам бот создаётся через официального бота самого Telegram <a href="https://t.me/BotFather">BotFather</a>, наш сервис всего лишь управляет его поведением</p>

      <Image
          src="/docs_create_bot_1.png"
          height="300"
          preview
          :image-style="{
                objectFit: 'contain',
                width: '500px',
                maxWidth: '100%',
          }"
      />
    </div>

    <div class="mt-4">
      <p class="m-0">Необходимо задать имя боту и его никнейм (из него будет формироваться ссылка на бота, поэтому желательно давать осмысленное название)</p>

      <Image
          src="/docs_create_bot_2.png"
          height="300"
          preview
          :image-style="{
                objectFit: 'contain',
                width: '500px',
                maxWidth: '100%',
          }"
      />
    </div>

    <div class="mt-4">
      <p class="m-0">Далее копируем токен бота и переходим в нашу платформу</p>
      <Image
          src="/docs_create_bot_3.png"
          height="300"
          preview
          :image-style="{
                objectFit: 'contain',
                width: '500px',
                maxWidth: '100%',
          }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";

export default defineComponent({
  name: "DocsHowAddBot",
  components: {Image}
})
</script>