<template>
  <div>
    <Dropdown
        :model-value="this.modelValue"
        @update:model-value="($event) => { this.$emit('update:modelValue', $event) }"
        option-label="label"
        option-value="value"
        :options="this.getPreviewOptions()"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Dropdown from "primevue/dropdown";
import {PreviewLinkOption} from "@/api/post";

export default defineComponent({
  name: "PreviewOption",
  components: {
    Dropdown
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<PreviewLinkOption>,
    }
  },
  emits: [
    'update:modelValue',
  ],
  methods: {
    getPreviewOptions: function () {
      return [
        {
          label: 'Показывать превью ссылки под текстом',
          value: PreviewLinkOption.belowText,
        },
        {
          label: 'Показывать превью ссылки над текстом',
          value: PreviewLinkOption.aboveText,
        },
        {
          label: 'Не показывать превью ссылок',
          value: PreviewLinkOption.withoutPreview,
        },
      ]
    },
  }
})
</script>