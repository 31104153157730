import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "text-center rounded-top node_bg_custom",
  style: {"width":"600px"}
}
const _hoisted_2 = { class: "m-2" }
const _hoisted_3 = {
  key: 0,
  class: "d-inline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleNode = _resolveComponent("TitleNode")!
  const _component_Editor = _resolveComponent("Editor")!
  const _component_SourceHandle = _resolveComponent("SourceHandle")!
  const _component_ButtonsPanel = _resolveComponent("ButtonsPanel")!
  const _component_FilesViewer = _resolveComponent("FilesViewer")!
  const _component_TargetHandle = _resolveComponent("TargetHandle")!
  const _component_EditableContent = _resolveComponent("EditableContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TitleNode, {
      "node-uuid": this.data.uuid,
      "node-name": this.data.name,
      "node-position": this.data.position,
      items: this.items,
      color: "#68695c",
      onPressedRemove: _cache[0] || (_cache[0] = () => { this.$emit('remove', this.data.uuid, true) })
    }, null, 8, ["node-uuid", "node-name", "node-position", "items"]),
    _createVNode(_component_EditableContent, { "is-read-only": true }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Editor, {
            modelValue: this.data.text,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.data.text) = $event)),
            readonly: true,
            pt: {toolbar: {hidden: true}}
          }, null, 8, ["modelValue"]),
          _createVNode(_component_ButtonsPanel, {
            buttons: this.data.buttons
          }, {
            button_footer: _withCtx((slotProps) => [
              (slotProps.button.uuid && slotProps.button instanceof _ctx.ButtonScenario)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_SourceHandle, {
                      style: {"position":"relative"},
                      id: slotProps.button.uuid
                    }, null, 8, ["id"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["buttons"]),
          _createVNode(_component_FilesViewer, {
            modelValue: this.data.files,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.data.files) = $event)),
            "show-remove-button": false
          }, null, 8, ["modelValue"]),
          _createVNode(_component_SourceHandle, {
            id: _ctx.EdgeType.common
          }, null, 8, ["id"]),
          _createVNode(_component_TargetHandle)
        ])
      ]),
      _: 1
    })
  ]))
}