<template>
<!--  todo: мешает удалять данные в input'ах, сделать ctrl + delete или тип того и описать в доке для пользователей -->
<!--  @keydown.delete="this.showModalDeleteSelectedElements" -->
  <div class="gridmap vh-100" tabindex="0">
    <ProgressSpinner
        v-if="this.loadingGraph"
        class="position-absolute top-50 start-50"
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        aria-label="Custom ProgressSpinner"
    />

    <div class="position-absolute top-0 end-0 m-2" style="z-index: 300">
      <a href="https://t.me/+KcV0DT6Eb0E5N2Vi" target="_blank" class="me-2">
        <Button
            label="Каталог полезных ТГ-ботов"
            icon="pi pi-telegram"
            severity="secondary"
            class="rounded"
        />
      </a>

      <a :href="this.graphConfigStorage.getLinkToTelegram" target="_blank">
        <Button
            label="Перейти в бота"
            icon="pi pi-telegram"
            severity="info"
            class="rounded"
        />
      </a>
    </div>

    <div class="position-absolute mt-2 ms-2" v-show="this.loadingSavingGraph">
      <!--    todo: pi-sync -->
      <p class="fs-6 text-white"><span class="pi pi-spin pi-spinner me-2"/>Сохранение...</p>
    </div>

    <NodeEditPanel
        v-if="this.selectedNode !== null"
        :model-value="this.selectedNode"
        @update:model-value="this.updateNode"
        @collapsed="() => { this.selectedNode = null }"
    />

    <VueFlow
        ref="flow"
        :min-zoom=0.125
        :max-zoom=1
        v-model="this.elements"
        @node-drag-stop="this.onNodeMovedStopped"
        @connect="this.onCreateNewEdge"
        class="customnodeflow"
        @pane-context-menu="this.openToolBar"
        @viewportChangeStart="this.$refs.menu.hide()"
        @viewportChangeEnd="this.viewPortChangeHandler"
        :viewport="this.defaultViewPort"
        :delete-key-code="null"
        :connection-radius="100"
        :connection-mode="ConnectionMode.Strict"
        :nodes-connectable="this.loadingSavingGraph === false"
        @node-click="($event) => {
          this.selectedNode = $event.node.data
        }"
        @pane-click="() => { this.selectedNode = null }"
    >
      <Controls position="bottom-right" />

      <template #edge-button="buttonEdgeProps">
        <EdgeWithButton
            :uuid="buttonEdgeProps.id"
            :source-x="buttonEdgeProps.sourceX"
            :source-y="buttonEdgeProps.sourceY"
            :target-x="buttonEdgeProps.targetX"
            :target-y="buttonEdgeProps.targetY"
            :source-position="buttonEdgeProps.sourcePosition"
            :target-position="buttonEdgeProps.targetPosition"
            :marker-end="buttonEdgeProps.markerEnd"
            :style="buttonEdgeProps.style"
            @remove-edge="this.handleRemoveElementEvent($event, false)"
        />
      </template>

      <template #edge-custom="customEdgeProps">
        <CustomEdge
            :id="customEdgeProps.id"
            :source-x="customEdgeProps.sourceX"
            :source-y="customEdgeProps.sourceY"
            :target-x="customEdgeProps.targetX"
            :target-y="customEdgeProps.targetY"
            :source-position="customEdgeProps.sourcePosition"
            :target-position="customEdgeProps.targetPosition"
            :data="customEdgeProps.data"
            :marker-end="customEdgeProps.markerEnd"
            :style="customEdgeProps.style"
        />
      </template>

      <template #node-trigger-group="{ data }">
        <TriggerGroup
            :data="data"
            @remove="this.handleRemoveElementEvent"
            :context-options="this.contextOptionsForTriggers"
        />
      </template>

      <template #node-command-group="{ data }">
        <CommandGroup
            :data="data"
            @remove="this.handleRemoveElementEvent"
            :context-options="this.contextOptionsForCommands"
        />
      </template>

      <template #node-message-node="{ data }">
        <Message
            :data="data"
            @remove="this.handleRemoveElementEvent"
        />
      </template>

      <template #node-question-node="{ data }">
        <Question
            :data="data"
            @remove="this.handleRemoveElementEvent"
            :context-options="this.contextOptionsForQuestions"
        />
      </template>

      <template #node-delay-node="{ data }">
        <DelayNode
            :data="data"
            @remove="this.handleRemoveElementEvent"
        />
      </template>

      <template #node-start-node="{ data }">
        <StartTrigger
            :data="data"
            @remove="this.handleRemoveElementEvent"
            :context-options="this.contextOptionsForTriggers"
        />
      </template>

      <ContextMenu
          ref="menu"
          :model="this.contextMenuItems"
          class="bg-transparent border-0"
      >
        <template #item="{item, label, props}">
          <button class="bg-transparent border-0 m-0 w-100 mb-2">
            <div class="rounded text-center p-1" :style="{backgroundColor: item.bg_color}">
              <p class="fw-bold text-wrap m-0 text-white">{{ label }}</p>
              <p class="text-black-50" v-if="item.description">{{ item.description }}</p>
            </div>
          </button>
        </template>
      </ContextMenu>

      <Dialog v-model:visible="this.isShowConfirmDeleteNodes" header="Вы уверены что хотите удалить выбранные элементы" modal :draggable="false">
        <button
            type="button"
            class="btn btn-danger p-2"
            @click="this.handleRemoveSelectedElements"
        >Да, удалить!</button>
      </Dialog>
    </VueFlow>
  </div >
</template>

<script lang="ts">
// https://github.com/bcakmakoglu/vue-flow
// https://vueflow.dev/guide/edge.html
// https://vueflow.dev/examples/
// https://jethrojeff.com/
// https://types.vueflow.dev/interfaces/EdgeProps.html#labelStyle
// https://codesandbox.io/p/devbox/vue-flow-basic-gfgro4?file=%2Fsrc%2FCustomEdge.vue%3A10%2C50
// https://d3js.org/d3-path#path (если vue-flow юзает d3 под капотом, то должно сработать)
import {
  ConnectionMode,
  GraphNode,
  NodeDragEvent,
  useVueFlow,
  ViewportTransform,
  VueFlow,
  VueFlowStore,
  XYPosition,
} from '@vue-flow/core'
import {defineComponent, h, provide, ref} from 'vue'
import TriggerGroup from "@/components/view/Bots/Trigger/TriggerGroup.vue";
import CommandGroup from "@/components/view/Bots/Command/CommandGroup.vue";
import ToolBar from "@/components/view/Bots/ToolBar.vue";
import {
  changeViewPort,
  CommandGroupNode,
  DelayBlockNode,
  DelayNodeType,
  Edge,
  EdgeType,
  getGraph,
  GraphConfigResult,
  GraphResult,
  MessageNode,
  NodePosition,
  QuestionNode,
  StartTriggerNode,
  TimeUnit,
  TriggerGroupNode
} from "@/api/graph";
import {TriggerGroupType, updateTriggerGroup} from "@/api/trigger";
import {Elements} from "@vue-flow/core/dist/types/flow";
import {createEdge, removeElements, updateNodePositionAndName} from "@/api/edge";
import Message from "@/components/view/Bots/Message/Message.vue";
import Question from "@/components/view/Bots/Question/Question.vue";
import StartTrigger from "@/components/view/Bots/StartTriggerNode/StartTrigger.vue";
import {addQuestionNode, AnswerTypeEnum, updateQuestionNode} from "@/api/questionNode";
import client from "@/api/client";
import Button from "primevue/button";
import DelayNode from "@/components/view/Bots/Delay/DelayNode.vue";
import ContextMenu from "primevue/contextmenu";
import {MenuItemCommandEvent} from "primevue/menuitem";
import EdgeWithButton from "@/components/view/Bots/EdgeWithButton.vue";
import CustomEdge from "@/components/view/Bots/CustomEdge.vue";
import {
  addCommandNodeToElements,
  addDelayNodeToElements,
  addEdgeToElements,
  addMessageNodeToElements,
  addQuestionNodeToElements,
  addStartTriggerNodeToElements,
  addTriggerNodeToElements
} from "@/api/botBuilder";
import {AxiosResponse} from "axios";
import {plainToClass, plainToInstance} from "class-transformer";
import Dialog from "primevue/dialog";
import {ContextItemForBuilder, ContextListForBuilderRes, fetchContextListForBuilder} from "@/api/context";
import Toast from "primevue/toast";
import {info} from "@/api/ToastService";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import ProgressSpinner from "primevue/progressspinner";
import InputText from "primevue/inputtext";
import TextAreaInput from "@/components/common/TextAreaInput.vue";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Image from "primevue/image";
import AccordionTab from "primevue/accordiontab";
import Accordion from "primevue/accordion";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import FileUpload from "primevue/fileupload";
import NodeEditPanel from "@/components/view/Bots/NodeEdit/NodeEditPanel.vue";
import {updateMessageNode} from "@/api/messageNode";
import {Timer} from "@/Timer";
import {updateCommandGroup} from "@/api/command";
import {createDelayNode, updateDelayNode} from "@/api/delayNode";
import {uuid} from "vue-uuid";
import {Controls} from "@vue-flow/controls";

const { v4: uuidv4, validate: uuidValidate } = require('uuid');

const elements = ref([])

const gradient = ref(false)


const connectionLineStyle = { stroke: '#fff' }
const { getSelectedEdges, getSelectedElements, getSelectedNodes, getNode, screenToFlowCoordinate, getViewport } = useVueFlow()


function outputColorLabel() {
  return h('div', {}, '#fff')
}
function outputNameLabel() {
  return h('div', {}, '#fff')
}


const store = useVueFlow()

// useVueFlow provides access to the event handlers
// const {
//   onNodeDragStart,
//   onNodeDrag,
//   onNodeDragStop,
//   onNodeClick,
//   onNodeDoubleClick,
//   onNodeContextMenu,
//   onNodeMouseEnter,
//   onNodeMouseLeave,
//   onNodeMouseMove
// } = useVueFlow()
//
//
// // bind listeners to the event handlers
// onNodeDragStart((event) => {
//   console.log('Node drag started', event)
// })
//
// onNodeDrag((event) => {
//   console.log('Node dragged', event)
// })
//
// onNodeDragStop((event) => {
//   console.log('Node drag stopped', event)
// })



export default defineComponent({
  name: 'BotBuilder',
  computed: {
    Timer() {
      return Timer
    },
    MessageNode() {
      return MessageNode
    },
    ConnectionMode() {
      return ConnectionMode
    },
  },
  components: {
    Controls,
    NodeEditPanel,
    FileUpload,
    SelectButton,
    Dropdown,
    Checkbox,
    Accordion,
    AccordionTab,
    Image,
    RichTextEditor,
    TextAreaInput,
    InputText,
    ProgressSpinner,
    StartTrigger,
    Dialog,
    CustomEdge,
    EdgeWithButton,
    DelayNode,
    Question,
    Message,
    MessageNode,
    ToolBar,
    CommandGroup,
    TriggerGroup,
    VueFlow,
    Button,
    ContextMenu,
    Toast,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      selectedNode: null as MessageNode|QuestionNode|CommandGroupNode|TriggerGroupNode|StartTriggerNode|null,

      loadingSavingGraph: false,
      loadingGraph: true,
      isShowConfirmDeleteNodes: false,

      elements: [] as Elements,
      defaultViewPort: {x: 0, y: 0, zoom: 0} as ViewportTransform,

      contextMenuItems: [
        // {
        //   label: 'Условие запуска бота',
        //   icon: 'pi pi-play',
        //   command: (event: MenuItemCommandEvent) => {
        //     const originalEvent = event.originalEvent as PointerEvent
        //     this.createStartTrigger(this.$refs.flow.screenToFlowCoordinate(
        //             {x: originalEvent.clientX, y: originalEvent.clientY}
        //         )
        //     )
        //   }
        // },

        {
          label: 'Сообщение',
          icon: 'pi pi-comment',
          description: 'Отправить сообщение пользователю',
          bg_color: '#828374',
          command: (event: MenuItemCommandEvent) => {
            const originalEvent = event.originalEvent as PointerEvent
            this.createMessageNode(this.$refs.flow.screenToFlowCoordinate(
                {x: originalEvent.clientX, y: originalEvent.clientY}
              )
            )
          },
        },

        {
          label: 'Вопрос',
          icon: 'pi pi-comments',
          description: 'Позволяет записать ответ пользователя в переменную',
          bg_color: '#c927de',
          command: (event: MenuItemCommandEvent) => {
            const originalEvent = event.originalEvent as PointerEvent
            this.createQuestionNode(this.$refs.flow.screenToFlowCoordinate(
                    {x: originalEvent.clientX, y: originalEvent.clientY}
                )
            )
          }
        },

        {
          label: 'Условие',
          icon: 'pi pi-question',
          description: 'Проверить условие',
          bg_color: '#2cb197',
          command: (event: MenuItemCommandEvent) => {
            const originalEvent = event.originalEvent as PointerEvent
            this.createNewTriggerGroup(this.$refs.flow.screenToFlowCoordinate(
                    {x: originalEvent.clientX, y: originalEvent.clientY}
                )
            )
          }
        },
        {
          label: 'Действие',
          icon: 'pi pi-forward',
          description: 'Выполнить действие',
          bg_color: '#e74949',
          command: (event: MenuItemCommandEvent) => {
            const originalEvent = event.originalEvent as PointerEvent
            this.createNewCommandGroup(this.$refs.flow.screenToFlowCoordinate(
                    {x: originalEvent.clientX, y: originalEvent.clientY}
                )
            )
          }
        },

        {
          label: 'Задержка',
          icon: 'pi pi-hourglass',
          description: 'Задержать выполнение сценария',
          bg_color: '#54e268',
          command: (event: MenuItemCommandEvent) => {
            const originalEvent = event.originalEvent as PointerEvent

            const position = this.$refs.flow.screenToFlowCoordinate(
                {x: originalEvent.clientX, y: originalEvent.clientY}
            ) as XYPosition

            const cmd = DelayBlockNode.createSentThrough(uuid.v4(), NodePosition.createFromXYPosition(position), 'Задержка', this.scenario_uuid)

            createDelayNode(cmd).then((delayNode) => {
              addDelayNodeToElements(delayNode, this.elements)
            })
          }
        },
      ]
    }
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    const contextOptionsForTriggers = ref([]) as ContextItemForBuilder[];
    const contextOptionsForCommands = ref([]) as ContextItemForBuilder[];
    const contextOptionsForQuestions = ref([]) as ContextItemForBuilder[];
    const contextOptionsForMessages = ref([]) as ContextItemForBuilder[];
    const selectedNodeUuid = ref(null) as string|null

    provide('contextOptionsForTriggers', contextOptionsForTriggers)
    provide('contextOptionsForCommands', contextOptionsForCommands)
    provide('contextOptionsForQuestions', contextOptionsForQuestions)
    provide('contextOptionsForMessages', contextOptionsForMessages)
    provide('selectedNodeUuid', selectedNodeUuid)

    return {
      graphConfigStorage,
      contextOptionsForTriggers,
      contextOptionsForCommands,
      contextOptionsForQuestions,
      contextOptionsForMessages,
      selectedNodeUuid,
    }
  },
  mounted() {

    this.renderGraphFromBackendData().then(() => {
      this.loadingGraph = false
    })

    fetchContextListForBuilder(this.scenario_uuid).then((contextList: ContextListForBuilderRes) => {
      this.contextOptionsForTriggers =
          contextList.person_custom_variables
          .concat(contextList.person_system_variables)
          .concat(contextList.global_custom_variables)
          .concat(contextList.global_system_variables)

      this.contextOptionsForCommands =
          contextList.person_custom_variables
          .concat(contextList.global_custom_variables)

      this.contextOptionsForQuestions = contextList.person_custom_variables
          .concat(contextList.person_system_variables)
          .concat(contextList.global_custom_variables)
          .concat(contextList.global_system_variables)

      this.contextOptionsForMessages = this.contextOptionsForQuestions
    })
  },
  methods: {
    updateMessageNode,
    // Перетаскивание ноды
    onNodeMovedStopped: function (event: NodeDragEvent) {
      if (this.graphConfigStorage.isSharedTemplate) {
        return
      }

      event.nodes.forEach((item: GraphNode) => {
        updateNodePositionAndName(
            item.id,
            {pos_x: Math.trunc(item.position.x), pos_y: Math.trunc(item.position.y)},
            item.label as string
        )
      })
    },
    updateNode: function (event: MessageNode|QuestionNode|CommandGroupNode|TriggerGroupNode|StartTriggerNode) {
      Timer.runTimeout('update:node', () => {
        this.loadingSavingGraph = true

        let resultPromise = null as Promise|null

        switch(true) {
          case event instanceof MessageNode:
            resultPromise = updateMessageNode(event)
            break

          case event instanceof CommandGroupNode:
            resultPromise = updateCommandGroup(event)
            break

          case event instanceof DelayBlockNode:
            resultPromise = updateDelayNode(event)
            break

          case event instanceof TriggerGroupNode:
            resultPromise = updateTriggerGroup(event)
            break

          case event instanceof QuestionNode:
            resultPromise = updateQuestionNode(event)
            break

          default:
            throw new Error('Обратитесь в тех.поддержку!')
        }

        resultPromise
            .then((res) => {
              this.selectedNode = event
            })
            .finally(() => {
              this.loadingSavingGraph = false
            })
      }, 200)
    },
    // Создание нового ребра
    onCreateNewEdge: function (params: any) {
      let type = null
      let buttonUuid = null

      const allEnumValues = [
          EdgeType.questionAnswer,
          EdgeType.questionDidntAnswer,
          EdgeType.questionInvalidAnswer,
          EdgeType.button,
          EdgeType.common,
          EdgeType.triggerToCommandOnNegative,
          EdgeType.triggerToCommandOnPositive,
      ]

      if (allEnumValues.includes(params.sourceHandle)) {
        type = params.sourceHandle
      }

      if (uuidValidate(params.sourceHandle)) {
        // todo: костыль, но шо поделать, нет времени играться с получением ноды и проверки её типа и принадлежности
        //    <Handle>
        buttonUuid = params.sourceHandle
        type = null
      }

      createEdge(new Edge(
          params.source,
          params.target,
          type,
          buttonUuid,
      )).then((edge: Edge) => {
        addEdgeToElements(edge, this.elements)
      })
    },
    // TRIGGERS
    createNewTriggerGroup: function (position: XYPosition) {
      const data = new TriggerGroupNode(
          'Условие',
          NodePosition.createFromXYPosition(position),
          TriggerGroupType.OR,
          this.scenario_uuid,
      );

      client
          .post('/api/v1/trigger-group/create', data)
          .then((response: AxiosResponse) => {
            const triggerGroup = plainToClass(TriggerGroupNode, response.data) as TriggerGroupNode;
            addTriggerNodeToElements(triggerGroup, this.elements)
          });
    },

    // MESSAGE NODE
    createMessageNode: function (position: XYPosition) {
      const cmd = new MessageNode(
          NodePosition.createFromXYPosition(position),
          'Сообщение...',
          'Сообщение...',
          this.scenario_uuid,
      )

      client
          .post('/api/v1/message-node/create', cmd)
          .then((response: AxiosResponse) => {
            const messageNode = plainToClass(MessageNode, response.data) as MessageNode;
            addMessageNodeToElements(messageNode, this.elements)
          });
    },

    createQuestionNode: function (position: XYPosition) {
      const cmd = new QuestionNode(
          'Вопрос',
          'Вопрос...',
          NodePosition.createFromXYPosition(position),
          AnswerTypeEnum.withoutValidation,
          this.scenario_uuid,
      )

      addQuestionNode(cmd).then((questionNode: QuestionNode) => {
        addQuestionNodeToElements(questionNode, this.elements)
      })
    },

    createStartTrigger: function (position: XYPosition) {
      const cmd = new StartTriggerNode(
          'Условие старта сценария',
          NodePosition.createFromXYPosition(position),
          TriggerGroupType.OR,
          this.scenario_uuid,
      )

      client
          .post('/api/v1/start-node/create', cmd)
          .then((response: AxiosResponse) => {
            const startTriggerNode = plainToClass(StartTriggerNode, response.data) as StartTriggerNode;
            addStartTriggerNodeToElements(startTriggerNode, this.elements)
          });
    },

    // COMMANDS
    createNewCommandGroup: function (position: XYPosition) {
      const data = new CommandGroupNode(
          'Название действия',
          NodePosition.createFromXYPosition(position),
          this.scenario_uuid
      )

      client
          .post('/api/v1/command-group/create', data)
          .then((response: AxiosResponse) => {
            const commandNode = plainToClass(CommandGroupNode, response.data) as CommandGroupNode;
            addCommandNodeToElements(commandNode, this.elements)
          });
    },

    renderGraphByData: function (graph: GraphResult) {
      let newElements:Elements = []

      // При быстром переключении между пунктами сайдбара, может не успеть забраться объект (хз как это работает)
      const flowInstance = this.$refs.flow as VueFlowStore|null;
      if (flowInstance) {
        flowInstance.setViewport({
          x: graph.config.viewport.position.pos_x,
          y: graph.config.viewport.position.pos_y,
          zoom: graph.config.viewport.zoom
        });
      }

      // Обработка триггер групп
      graph.trigger_groups_nodes.forEach((triggerGroup: TriggerGroupNode) => {
        addTriggerNodeToElements(triggerGroup, newElements)
      })

      graph.commands_groups_nodes.forEach((commandGroup: CommandGroupNode) => {
        addCommandNodeToElements(commandGroup, newElements)
      })

      graph.messages.forEach((messageNode: MessageNode) => {
        addMessageNodeToElements(messageNode, newElements)
      })

      graph.questions.forEach((questionNode: QuestionNode) => {
        addQuestionNodeToElements(questionNode, newElements)
      })

      graph.delays.forEach((delay: DelayBlockNode) => {
        addDelayNodeToElements(delay, newElements)
      })

      graph.start_triggers.forEach((startTrigger: StartTriggerNode) => {
        addStartTriggerNodeToElements(startTrigger, newElements)
      })

      graph.edges.forEach((edge: Edge) => {
        addEdgeToElements(edge, newElements)
      })

      this.elements = newElements
    },
    renderGraphFromBackendData: function () {
      return getGraph(this.scenario_uuid).then((graph: GraphResult) => {
        this.renderGraphByData(graph)

        if (!graph.config.viewed_alert_how_to_use_constructor) {
          info('Подсказка', 'нажмите правую кнопку мыши чтобы создать новый элемент', 10000)
        }
      })
    },

    openToolBar: function (event: MouseEvent) {
      if (this.graphConfigStorage.isSharedTemplate) {
        event.preventDefault()
        return
      }

      // @ts-ignore
      this.$refs.menu.show(event)
      this.selectedNode = null
    },

    viewPortChangeHandler: function (event: ViewportTransform) {
      if (this.graphConfigStorage.isSharedTemplate) {
        return
      }

      changeViewPort(
          this.scenario_uuid,
          {pos_x: Math.trunc(event.x), pos_y: Math.trunc(event.y)},
          event.zoom,
      )
    },

    handleRemoveSelectedElements: function () {
      const selectedElements = this.$refs.flow.getSelectedNodes

      if (selectedElements.length === 0) {
        this.isShowConfirmDeleteNodes = false
        return
      }

      removeElements(this.scenario_uuid, [], selectedElements.map((item: GraphNode) => {
          return item.id
        })
      ).then((graphResult: GraphResult) => {
        this.isShowConfirmDeleteNodes = false
        this.renderGraphByData(graphResult)
      })
    },

    showModalDeleteSelectedElements: function (event: KeyboardEvent) {
      const selectedElements = this.$refs.flow.getSelectedNodes

      if (selectedElements.length === 0 || this.isShowConfirmDeleteNodes === true) {
        return
      }

      this.isShowConfirmDeleteNodes = true
    },
    handleRemoveElementEvent: function (uuid: string, isNode: boolean) {
      let handler = null
      if (isNode) {
        handler = removeElements(this.scenario_uuid, [], [uuid])
      } else {
        handler = removeElements(this.scenario_uuid, [uuid], [])
      }

      handler.then((graphResult: GraphResult) => {
        this.renderGraphByData(graphResult)

        if (
            isNode &&
            uuid === this.selectedNodeUuid
        ) {
          this.selectedNode = null
        }
      })
    },
  },
  watch: {
    selectedNode: function (node: MessageNode|QuestionNode|CommandGroupNode|TriggerGroupNode|StartTriggerNode|null) {
      if (node instanceof StartTriggerNode) {
        return
      }

      this.selectedNodeUuid = node ? node.uuid : null
    }
  }
})
</script>

<style>
.btn-group-vertical > button {
  margin-top: 10px;
  margin-bottom: 10px;
}

@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.27.1/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.27.1/dist/theme-default.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/controls@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/minimap@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/node-resizer@latest/dist/style.css';
</style>
