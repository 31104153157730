<template>
  <div class="bg-white">
    <quill-editor
        v-model:value="this.modelValueData"
        :options="this.editorOption"
        :disabled="this.disabled"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Quill from 'quill'

export default defineComponent({
  name: "RichTextEditor",
  props: {
    modelValue: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
      default: 'Вставьте текст...',
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'update:modelValue',
  ],
  data: function () {
    return {
      quill: Quill,
      cursorPosition: 0 as number,
      editorOption: {
        placeholder: this.placeholder,
        modules: {
          toolbar: [
            // todo: The expandable block quotation <blockquote expandable>
            //   todo: inline fixed-width code <code>
            // todo: spoiler <span>
            //   todo: <code class="language-python"
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            ['link'],
          ],
        },
      },
    }
  },
  methods: {
    onFocusEditor: function (event) {
      this.setCursorPosition(this.quill.getSelection().index)
    },

    onChangeEditor: function (event) {
      if (!this.quill.getSelection()) {
        return
      }

      this.setCursorPosition(this.quill.getSelection().index)
    },

    onReadyEditor: function (event) {
      this.quill = event
    },

    // не работает, выпадает ошибка с setRange о выходе за допустимую длину переменной при выделении и удалении
    appendText: function (appendedText: string): void {
      this.quill.focus();
      this.quill.insertText(0, appendedText);
    },

    setCursorPosition(cursorPos: number): void {
      if (cursorPos < 0) {
        cursorPos = 0
      }

      this.cursorPosition = cursorPos
    },
  },
  computed: {
    modelValueData: {
      set: function (newVal): void {
        this.$emit('update:modelValue', newVal)
      },
      get: function (): string {
        return this.modelValue
      }
    },
  },
})
</script>

<style scoped>
.ql-container {
  font-size: 18px;
}
</style>