<template>
  <div>
    <div class="p-3 custom-border rounded bg-primary-subtle">
      <div v-if="this.routeDoc !== null">
        <router-link
            :to="{name: this.routeDoc}"
            class="align-middle"
            style="text-decoration: none;"
            target="_blank"
        >
          <span class="pi pi-question-circle me-2 align-middle"/><slot/>
        </router-link>
      </div>

      <div v-else>
        <span class="pi pi-question-circle me-2 align-middle"/><slot/>
      </div>

      <slot name="content"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "HelpInfo",
  props: {
    routeDoc: {
      required: false,
      type: [String, null],
      default: null,
    },
  }
})
</script>