import * as punycode from "node:punycode";

export enum TutorialEnum
{
    publishPosts1 = 'lesson_publish_posts_1',
}

namespace TutorialEnum
{
    export function parse(tutorial: string|null): TutorialEnum|null {
        if (!tutorial) {
            return null
        }

        switch (tutorial as TutorialEnum) {
            case TutorialEnum.publishPosts1:
                return TutorialEnum.publishPosts1
        }

        return null
    }
}

export class InteractiveLessons
{
    public static getLessons(tutorial: TutorialEnum|null): Object
    {
        if (tutorial === null) {
            return {}
        }

        switch (tutorial) {
            case TutorialEnum.publishPosts1:
                return {
                    options: {
                        highlight: false,
                        texts: {
                            finishButton: 'Завершить урок',
                            nextButton: 'Следующий шаг',
                            previousButton: 'Вернуться назад',
                            skipButtonTitle: 'Пропустить шаг',
                            skipConfirm: 'Вы уверены что хотите завершить урок?',
                            // stepState: '',
                            timeout: 500,
                        }
                    },
                    steps: [
                        {
                            target: '#button-add-scenario',  // a selector to the wanted element. document.querySelector() is used under the hood
                            title: 'Урок "Постинг"',
                            content: 'Добавление бота. Во избежания конфликтов, не добавляйте ботов которые используются в других системах.',
                            actionNext: 'click',
                        },
                        {
                            target: '#telegram-get-token',
                            title: 'Урок "Постинг"',
                            content: 'Получите токен бота в Telegram. Более подробно как это сделать можно ознакомиться - https://....',
                            skipStep: setTimeout(() => {
                                return false
                            }, 100),
                            options: {
                                timeout: 300,
                            },
                        },
                        {
                            target: '#bot_token',
                            title: 'Урок "Постинг"',
                            content: 'Более подробно как это сделать можно ознакомиться - https://....',
                            actionNext: {
                                action: 'input',
                                check: 'is not empty',
                            },
                        },
                        {
                            target: '#time_zone',
                            title: 'Урок "Постинг"',
                            content: 'Выберите временную зону в которой будет работать бот (условия в конструкторе будут работать относительно него), можно поменять позже',
                            skipStep: setTimeout(() => {
                                return false
                            }, 100),
                            options: {
                                timeout: 300,
                            },
                        },
                        {
                            target: '#button-save-scenario',
                            title: 'Урок "Постинг"',
                            content: 'Сохраните правки',
                            actionNext: 'click',
                        },
                        {
                            target: '#bots-list',
                            title: 'Урок "Постинг"',
                            content: 'Выберите созданного бота',
                            actionNext: 'click',
                            skipStep: setTimeout(() => {
                                return false
                            }, 1000),
                            options: {
                                timeout: 1000,
                            },
                        }
                    ],
                }
        }

        return {}
    }
}