<template>
  <div class="m-2">
    <div>
      <p class="m-0">Имя</p>
      <InputText v-model="this.card.name"/>
    </div>

    <div class="mt-2 d-flex justify-content-end">
      <Button
          label="Выйти из системы"
          class="rounded me-2"
          outlined
          severity="danger"
          @click="() => {
            logout()
              .then(() => {
                 this.authStore.logout()
                 this.$router.push({name: 'home_page'})
            })
          }"
      />

      <Button
          label="Сохранить"
          class="rounded"
          outlined
          severity="contrast"
          :loading="this.loadingSave"
          @click="() => {
            this.loadingSave = true

            updateProfile(this.card)
              .finally(() => {
                this.loadingSave = false
              })
          }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Button from "primevue/button";
import {getProfile, logout, ProfileCard, updateProfile} from "@/api/profile";
import InputText from "primevue/inputtext";
import {useAuthStorage, useUserStorage} from "@/store";

export default defineComponent({
  name: "Profile",
  methods: {updateProfile, logout},
  components: {
    Button,
    InputText,
  },
  data: function () {
    return {
      card: new ProfileCard(),
      loadingSave: false,
    }
  },
  setup() {
    const authStore = useAuthStorage()
    return {authStore}
  },
  created() {
    getProfile().then((r) => {
      this.card = r
    })
  }
})
</script>