<template>
<div class="bg-white pt-2">
  <HelpInfo route-doc="doc_delay_publish">
    Документация по постингу
  </HelpInfo>

  <DataTable
      :value="this.postsList.items"
      dataKey="uuid"
      :paginator="false"
      :rows="10"
      :rowsPerPageOptions="[5,10,25]"
      class="mt-4 custom-border rounded"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
  >
    <template #empty>
      Список пуст
    </template>

    <template #header>
      <div class="row justify-content-between">
        <div class="col-auto d-flex align-items-center">
          <h5 class="m-0">Публикации</h5>
        </div>
        <div class="col-auto">
          <Button
              label="Добавить пост"
              class="rounded"
              icon="pi pi-plus"
              severity="contrast"
              @click="this.isShowCreateContentPost = true"
          />
        </div>
      </div>
    </template>

    <Column field="name" header="Название" style="min-width:12rem"/>
    <Column field="text" header="Текст" style="min-width:12rem"/>
    <Column field="next_sent_will_be_at" header="Следующий пост будет" style="min-width:12rem">
      <template #header>
        <span class="pi pi-question-circle me-2" v-tooltip="'Отображается в часовом поясе поста'"/>
      </template>
    </Column>
    <Column field="last_sent_will_be_at" header="Последний пост будет" style="min-width:12rem">
      <template #header>
        <span class="pi pi-question-circle me-2" v-tooltip="'Отображается в часовом поясе поста'"/>
      </template>
    </Column>
    <Column field="sent_counter" header="Сколько раз будет опубликован пост" style="min-width:12rem"/>

    <Column :exportable="false" style="min-width:8rem">
      <template #body="slotProps">
        <router-link :to="{name: 'post_card', params: {scenario_uuid: this.scenario_uuid, post_uuid: slotProps.data.uuid}}">
          <Button
              icon="pi pi-file-edit"
              class="rounded-circle me-2"
              severity="secondary"
              v-tooltip.top="'Редактировать рассылку'"
          />
        </router-link>

        <Button
            icon="pi pi-trash"
            class="rounded-circle"
            severity="danger"
            @click="this.selectContentPostUuidForRemove = slotProps.data.uuid"
            v-tooltip.top="'Удалить рассылку'"
        />
      </template>
    </Column>
  </DataTable>

  <Dialog v-model:visible="this.isShowDeleteContentPost" header="Удаление поста" class="w-50" modal :draggable="false">
    <p>Вы уверены что хотите удалить рассылку? Не вышедшие посты не будут опубликованы</p>

<!--    todo: так же спрашивать нужно ли удалять опублекованные рассылкой посты (те которые возможно по тайм-ауту) -->

    <template #footer>
      <Button
          label="Удалить"
          severity="danger"
          class="rounded"
          :loading="this.isLoadingDeletePost"
          @click="() => {
               this.isLoadingDeletePost = true;
               deletePostContent(this.selectContentPostUuidForRemove)
                .then(() => {
                  getPostsForList(this.scenario_uuid).then((result: PostsForListResult) => {
                    this.postsList = result
                  })

                  this.selectContentPostUuidForRemove = null
                })
                .finally(() => {
                  this.isLoadingDeletePost = false
                })
          }"
      />
    </template>
  </Dialog>

  <Dialog v-model:visible="this.isShowCreateContentPost" header="Создание поста" class="w-75" modal :draggable="false">
    <PostForm
        v-model:model-value="this.requestCreatePost"
        :scenario_uuid="this.scenario_uuid"
        @saved="() => {
          getPostsForList(this.scenario_uuid).then((result: PostsForListResult) => {
            this.postsList = result

            this.isShowCreateContentPost = false
            this.requestCreatePost = new CreatePostCmd(this.scenario_uuid)
          })
        }"
    />
  </Dialog>
</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileupload";
import AutoComplete, {AutoCompleteCompleteEvent} from "primevue/autocomplete";
import Dialog from "primevue/dialog";
import SelectButton from "primevue/selectbutton";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import {
  CreatePostCmd, createPostContent,
  DeletePostAfter, deletePostContent,
  DeletePostDateTime,
  DeletePostTime,
  DeletePostValue,
  DeleteType,
  getPostsForList,
  IterationPublication,
  OneTimePublication,
  PinPost,
  PostButton,
  PostButtonsRow,
  PostFormat,
  PostPublication,
  PostsForListResult,
  PublishType,
  RangePublication,
  UnpinAfter
} from "@/api/post";
import {TimeUnit} from "@/api/graph";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import FullCalendar from "@fullcalendar/vue3";
import Fieldset from "primevue/fieldset";
import InputGroup from "primevue/inputgroup";
import InputText from "primevue/inputtext";
import SplitButton from "primevue/splitbutton";
import CascadeSelect from "primevue/cascadeselect";
import {searchDialogByName, SearchDialogRes} from "@/api/edge";
import {DialogType} from "@/api/dialog";
import {getTimeZones} from "@/api/scenario";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import PostForm from "@/components/view/ContentPlan/PostForm.vue";
import {ErrorResponse, ValidationErrorItem} from "@/api/common";
import HelpInfo from "@/components/view/HelpInfo.vue";

export default defineComponent({
  name: "PostsList",
  computed: {
    ValidationErrorItem() {
      return ValidationErrorItem
    },
    ErrorResponse() {
      return ErrorResponse
    },
    DialogType() {
      return DialogType
    },
    SearchDialogRes() {
      return SearchDialogRes
    },
    isShowDeleteContentPost: function () {
      return this.selectContentPostUuidForRemove !== null
    },

    PostButtons() {
      return PostButtons
    },
    PostButton() {
      return PostButton
    },
    PostButtonsRow() {
      return PostButtonsRow
    },
    PostsForListResult() {
      return PostsForListResult
    },
    DeleteType() {
      return DeleteType
    },
    PostFormat() {
      return PostFormat
    },
    TimeUnit() {
      return TimeUnit
    },
    PublishType() {
      return PublishType
    },
    CreatePostCmd() {
      return CreatePostCmd
    },
  },
  components: {
    HelpInfo,
    PostForm,
    DialogTagPanel,
    SplitButton,
    InputText,
    Dropdown,
    AutoComplete,
    Dialog,
    Tag,
    DataTable,
    TabPanel,
    Column,
    TabView,
    Button,
    FullCalendar,
    Textarea,
    Checkbox,
    FileUpload,
    SelectButton,
    Calendar,
    InputNumber,
    Fieldset,
    InputGroup,
    CascadeSelect,
  },
  props: {
    scenario_uuid: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      isShowCreateContentPost: false,
      selectContentPostUuidForRemove: null as null|string,

      isLoadingCreatePost: false,
      isLoadingDeletePost: false,

      requestCreatePost: new CreatePostCmd(this.scenario_uuid),


      postsList: new PostsForListResult(),


      counties: [
        {
          name: 'Australia',
          code: 'AU',
          states: [
            {
              name: 'New South Wales',
              cities: [
                { cname: 'Sydney', code: 'A-SY' },
                { cname: 'Newcastle', code: 'A-NE' },
                { cname: 'Wollongong', code: 'A-WO' }
              ]
            },
            {
              name: 'Queensland',
              cities: [
                { cname: 'Brisbane', code: 'A-BR' },
                { cname: 'Townsville', code: 'A-TO' }
              ]
            }
          ]
        },
        {
          name: 'Canada',
          code: 'CA',
          states: [
            {
              name: 'Quebec',
              cities: [
                { cname: 'Montreal', code: 'C-MO' },
                { cname: 'Quebec City', code: 'C-QU' }
              ]
            },
            {
              name: 'Ontario',
              cities: [
                { cname: 'Ottawa', code: 'C-OT' },
                { cname: 'Toronto', code: 'C-TO' }
              ]
            }
          ]
        },
        {
          name: 'United States',
          code: 'US',
          states: [
            {
              name: 'California',
              cities: [
                { cname: 'Los Angeles', code: 'US-LA' },
                { cname: 'San Diego', code: 'US-SD' },
                { cname: 'San Francisco', code: 'US-SF' }
              ]
            },
            {
              name: 'Florida',
              cities: [
                { cname: 'Jacksonville', code: 'US-JA' },
                { cname: 'Miami', code: 'US-MI' },
                { cname: 'Tampa', code: 'US-TA' },
                { cname: 'Orlando', code: 'US-OR' }
              ]
            },
            {
              name: 'Texas',
              cities: [
                { cname: 'Austin', code: 'US-AU' },
                { cname: 'Dallas', code: 'US-DA' },
                { cname: 'Houston', code: 'US-HO' }
              ]
            }
          ]
        }
      ]
    }
  },
  methods: {
    deletePostContent,
    getPostsForList,
    createPostContent

  },
  watch: {
    'isShowCreateContentPost': function (newVal) {
      if (newVal === true) {
        this.requestCreatePost = new CreatePostCmd(this.scenario_uuid)
      }
    }
  },
  mounted() {
    getPostsForList(this.scenario_uuid).then((result: PostsForListResult) => {
      this.postsList = result
    })
  }
})
</script>