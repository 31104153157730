<template>
  <div class="m-2">
    <Breadcrumbs :items="this.breadcrumbs"/>

    <HelpInfo :route-doc="null" class="mt-3">
      Здесь отображаются Telegram группы в которых присутствует бот, если группа не отображается - напишите в ней что нибудь, бот увидит сообщение и отобразит группу.
    </HelpInfo>

    <DataTable
        :value="this.items"
        dataKey="uuid"
        :paginator="false"
        :rows="10"
        :rowsPerPageOptions="[5,10,25]"
        class="mt-4 custom-border rounded"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
    >
      <template #empty>
        Список пуст
      </template>

      <template #header>
          <h5 class="m-0">Группы</h5>
      </template>

      <Column field="icon_image" header="">
        <template #body="slotProps">
          <img v-if="slotProps.data.icon_image" :src="slotProps.data.icon_image" height="60px" class="rounded-circle" alt=""/>
        </template>
      </Column>

      <Column field="name" header="Название">
        <template #body="slotProps">
          <h6>{{slotProps.data.name}}</h6>
        </template>
      </Column>
<!--      <Column field="">-->
<!--        <template #body="slotProps">-->
<!--          <div>-->
<!--            <a href="" style="">-->
<!--              <Button-->
<!--                label="Перейти в Telegram"-->
<!--                icon="pi pi-telegram"-->
<!--                class="rounded"-->
<!--                severity="info"-->
<!--              />-->
<!--            </a>-->
<!--          </div>-->
<!--        </template>-->
<!--      </Column>-->
      <Column field="triggers_count" header="Количество триггеров"/>


      <Column :exportable="false" style="min-width:8rem">
        <template #body="slotProps">
          <div class="d-flex justify-content-end">
            <router-link :to="{name: 'group_card', params: {
                  scenario_uuid: this.scenario_uuid,
                  dialog_uuid: slotProps.data.uuid,
                }
              }"
            >

              <Button
                  icon="pi pi-pencil"
                  label="Открыть"
                  severity="secondary"
                  outlined
                  class="me-2 rounded"
              />
            </router-link>

            <Button
                icon="pi pi-sign-out"
                label="Выйти"
                :loading="this.leaveDialogUuid === slotProps.data.uuid"
                severity="warning"
                outlined
                class="me-2 rounded"
                @click="() => {
                    this.leaveDialogUuid = slotProps.data.uuid

                    leaveGroup(slotProps.data.uuid)
                      .then(() => { this.items.splice(slotProps.index, 1) })
                      .finally(() => { this.leaveDialogUuid = null })
                  }"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>


<script lang="ts">
import {defineComponent, inject} from 'vue'
import Tag from "primevue/tag";
import HelpInfo from "@/components/view/HelpInfo.vue";
import TriggerForm from "@/components/view/Triggers/TriggerForm.vue";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import {getGroupsForList, GroupsListItemResult, leaveGroup} from "@/api/group";
import Breadcrumb from "primevue/breadcrumb";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import {BreadcrumbItem} from "@/api/breadcrumbs";

export default defineComponent({
  name: "GroupChatList",
  methods: {leaveGroup},
  components: {
    Breadcrumbs,
    DataTable,
    Button,
    Column,
    Dialog,
    TriggerForm,
    HelpInfo,
    Tag,
    Breadcrumb,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {
      leaveDialogUuid: null as string|null,
      items: [] as GroupsListItemResult[],
      breadcrumbs: [
        new BreadcrumbItem('Telegram группы', 'bot_groups'),
      ],
    }
  },
  created() {
    getGroupsForList(this.scenario_uuid).then((res) => {
      this.items = res.items
    })
  },
})
</script>