import axios, {AxiosResponse} from "axios";
import {plainToClass} from "class-transformer";
import client from "./client";


export class GetForCardResult
{
    messages: DialogForCardItem[] = [];
}

export class DialogForCardItem
{
    // @ts-ignore
    uuid: string;
    // @ts-ignore
    message: string;
    // @ts-ignore
    from_bot: boolean;
    // @ts-ignore
    date: string;
    // @ts-ignore
    dialog_uuid: string

    files: MessageFile[] = []
}

export class MessageFile
{
    // todo: в будущем енам
    type: string
    link: string
}

export class GetForListResult
{
    dialogs: DialogForListItem[] = [];
}

export class DialogForListItem
{
    // @ts-ignore
    uuid: string;
    // @ts-ignore
    name: string;
    // @ts-ignore
    last_message: string;
    // @ts-ignore
    need_attention: boolean;
    // @ts-ignore
    last_message_time: string;
}

export class FetchDialogFilter
{
    bot_uuids: string[] = [];

    constructor(bot_uuids: string[]) {
        this.bot_uuids = bot_uuids;
    }
}

export function getDialogForList(filter: FetchDialogFilter): Promise<GetForListResult>
{
    // @ts-ignore
    return client
        .post('/api/v1/dialog/get-for-list', filter)
        .then((response: AxiosResponse) => {
            return plainToClass(GetForListResult, response.data);
        })
}

export function getDialogForCard(dialogUuid: string): Promise<GetForCardResult>
{
    return client
        .post('/api/v1/dialog/get-for-card', {uuid: dialogUuid})
        .then((response: AxiosResponse) => {
            return plainToClass(GetForCardResult, response.data);
        })
}

export function sendMessage(dialogUuid: string, content: string): Promise<DialogForCardItem>
{
    return client
        .post('/api/v1/dialog/send-message', {dialog_uuid: dialogUuid, message: content})
        .then((response: AxiosResponse) => {
            return plainToClass(DialogForCardItem, response.data);
        })
}

export enum DialogType
{
    private = 'private',
    group = 'group',
    channel = 'channel',
}