import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a3002ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "m-2",
  style: {"border":"2px dashed #ddd"}
}
const _hoisted_2 = ["hidden"]
const _hoisted_3 = ["hidden", "onClick"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: "mt-0 mb-2 fw-bold",
      hidden: this.readOnly
    }, "Изображения:", 8, _hoisted_2),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.imageUrls, (imageUrl, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "img-wrap d-inline-block me-2 mb-2",
          key: imageUrl.uuid
        }, [
          _createElementVNode("button", {
            class: "close bg-light m-1 rounded-circle",
            hidden: this.readOnly,
            onClick: () => {
              const clonedImageUrls = this.imageUrls.slice();
              clonedImageUrls.splice(index, 1);

              this.$emit('updateImages', clonedImageUrls)
            }
          }, "× ", 8, _hoisted_3),
          _createElementVNode("img", {
            src: imageUrl.url,
            width: "250",
            alt: "Изображение"
          }, null, 8, _hoisted_4)
        ]))
      }), 128))
    ]),
    (!this.readOnly)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "file",
          id: "images",
          accept: "image/*",
          required: "",
          multiple: "",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.sendImageToServer && this.sendImageToServer(...args)))
        }, null, 32))
      : _createCommentVNode("", true)
  ]))
}