<template>
  <div class="p-2">
    <HelpInfo route-doc="doc_tags" class="mt-2">
      Документация по тегам
    </HelpInfo>

    <DataTable
        :value="this.tags"
        dataKey="uuid"
        :paginator="false"
        :rows="10"
        :rowsPerPageOptions="[5,10,25]"
        class="mt-4 custom-border rounded"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
    >
      <template #empty>
        Список пуст
      </template>

      <template #header>
        <div class="row justify-content-between">
          <div class="col-auto d-flex align-items-center">
            <h5 class="m-0">Список тегов</h5>
          </div>
          <div class="col-auto">
            <Button
                class="rounded mb-3"
                label="Добавить тег"
                severity="contrast"
                @click="this.isOpenDialogCreateTags = true"
            />
          </div>
        </div>
      </template>

      <Column field="name" header="Название"/>
      <Column field="created_at" header="Дата создания"/>
      <Column field="subscribers_count" header="Количество подписчиков"/>
      <Column :exportable="false" style="min-width:8rem">
        <template #body="slotProps">
          <div class="d-flex justify-content-end">
            <Button
                label="Открыть"
                class="rounded"
                outlined
                severity="contrast"
                @click="this.openTagDialog(slotProps.data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>

    <!--   Модальное окно просмотра карточки подписчика (todo: менять URL, чтобы иметь возможность шарить сабскрайбера)  -->
    <Dialog
        v-model:visible="this.isOpenTagCard"
        header="Обновление тега"
        modal
        :draggable="false"
        class="w-50"
        :contentStyle="{ height: '50vh' }"
    >
      <TagForm
          :tag="this.currentTagForCard"
          @was-saved="() => {
            this.fetchTagsList()
              .then(() => { this.isOpenTagCard = false })
          }"
      />
    </Dialog>

    <Dialog
        v-model:visible="this.isOpenDialogCreateTags"
        header="Создание тега"
        modal
        :draggable="false"
        class="w-50"
        :contentStyle="{ height: '50vh' }"
        @after-hide="() => {
          this.createTagCmd = new CreateTagCommand(this.scenario_uuid);
        }"
    >
      <TagForm
          :tag="this.createTagCmd"
          @was-saved="this.fetchTagsList"
      />
    </Dialog>
  </div>
  <router-view />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import MultiSelect from 'primevue/multiselect';
import {
  CreateTagCommand,
  getForCardTag,
  getForListTags,
  TagForList,
  TagForListItem, UpdateTagCommand,
} from "@/api/tags";
import OrderList from "primevue/orderlist";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import Tag from "primevue/tag";
import {searchSubscriberByName, SearchSubscriberItem, SearchSubscriberRes} from "@/api/edge";
import HelpInfo from "@/components/view/HelpInfo.vue";
import TagForm from "@/components/view/Tags/TagForm.vue";

export default defineComponent({
  name: 'TagsList',
  components: {
    TagForm,
    HelpInfo,
    AutoComplete,
    MultiSelect,
    DataTable,
    Column,
    Button,
    Dialog,
    OrderList,
    InputText,
    Tag,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {
      tags: [] as TagForListItem[],
      selectedFilterByBots: [],
      isOpenDialogCreateTags: false,
      isOpenDialogUpdateTag: false,


      createTagCmd: new CreateTagCommand(this.scenario_uuid),

      currentTagForCard: null as UpdateTagCommand|null,
      validationErrors: {
        name: null,
      },

      searchSubscriberLoading: false,
      searchSubscriberOptions: [] as SearchSubscriberItem[],
      searchSubscriberAutocomplete: '',
    }
  },
  created() {
    this.fetchTagsList()
  },
  methods: {
    fetchTagsList: function () {
      return getForListTags(this.scenario_uuid).then((tags: TagForList) => {
        this.tags = tags.items;
        this.createTagCmd = new CreateTagCommand(this.scenario_uuid)
        this.isOpenDialogCreateTags = false
      })
    },

    searchSubscriberByName,
    openTagDialog: function(tag: TagForListItem) {
      getForCardTag(tag.uuid).then((tagForCard: UpdateTagCommand) => {
        this.currentTagForCard = tagForCard
      })
    },
  },
  computed: {
    TagForList() {
      return TagForList
    },
    CreateTagCommand() {
      return CreateTagCommand
    },
    SearchSubscriberRes() {
      return SearchSubscriberRes
    },

    isOpenTagCard: {
      get: function (): boolean {
        return this.currentTagForCard !== null
      },
      set: function () {
        this.currentTagForCard = null
      },
    },

    computedSearchSubscriberOptions: function () {
      return this.searchSubscriberOptions.filter((filterItem: SearchSubscriberItem) => {
        const isAlreadySelected = this.createTagCmd.subscribers.find((value: SearchSubscriberItem) => {
          return value.uuid === filterItem.uuid
        })

        if (isAlreadySelected) {
          return false
        }
        return true
      })
    }
  },
})
</script>
