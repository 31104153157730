import {AxiosRequestConfig, AxiosResponse} from "axios";
import {TriggerGroupType} from "@/api/trigger";
import {AddTagToSubscriber, SendMailItem} from "@/api/command";
import {plainToClass, Type, plainToInstance} from "class-transformer";
import "reflect-metadata";
import client from "./client";
import {AnswerTypeEnum} from "@/api/questionNode";
import {SearchDialogItem} from "./edge";
import {uuid} from "vue-uuid";
import {XYPosition} from "@vue-flow/core";
import {TagForListItem} from "./tags";
import {GetDictionaryForListItem} from "./dictionary";
import {PreviewLinkOption, UploadRes} from "./post";
import {ButtonRow} from "./buttonGroupPanel";
import {Period} from "./periodicMessage";

export class NodePosition {
    pos_x: number;
    pos_y: number;

    constructor(pos_x: number, pos_y: number) {
        this.pos_x = Math.trunc(pos_x);
        this.pos_y = Math.trunc(pos_y);
    }

    static createFromXYPosition(position: XYPosition) {
        return new NodePosition(position.x, position.y)
    }
}

export enum ButtonType {
    inline = 'inline',
    keyboard = 'keyboard',
}

export enum NodeFileType
{
    audio = 'audio',
    video = 'video',
    image = 'image',
    document = 'document',
}

export class NodeFile
{
    uuid: string
    url: string
    type: NodeFileType
    name: string
}

export class MessageNode {
    uuid: string;
    name: string;
    text: string;

    @Type(() => NodePosition)
    position: NodePosition;

    @Type(() => NodeFile)
    files: NodeFile[] = []

    @Type(() => ButtonType)
    button_type: ButtonType = ButtonType.inline;

    @Type(() => ButtonRow)
    buttons: ButtonRow[] = []

    @Type(() => SearchDialogItem)
    send_message_to_dialogs: SearchDialogItem[] = []
    pin: boolean = false;
    protected: boolean = false;

    @Type(() => PreviewLinkOption)
    preview_option: PreviewLinkOption = PreviewLinkOption.belowText

    // Бек не возвращает данное поле, присутствует только для создания ноды в беке
    scenario_uuid: string;
    delete_after_transition: boolean = false;

    constructor(position: NodePosition, text: string, name: string, scenarioUuid: string) {
        this.position = position
        this.scenario_uuid = scenarioUuid;
        this.text = text
        this.name = name
    }
}

export class RowButtons
{
    index: number
    buttons: QuestionButton[];

    constructor(index: number, buttons: QuestionButton[]) {
        this.index = index;
        this.buttons = buttons;
    }
}

export enum ExcludeDate
{
    excludeFeature = 'feature',
    excludePast = 'past',
}

export enum ChooseDate
{
    withoutFilter  = 'without_filter',
    specifiedDay   = 'specified_day',
    range          = 'range',
    today          = 'today',
    tomorrow       = 'tomorrow',

    window_day     = 'window_day',
    window_week    = 'window_week',
    window_month   = 'window_month',
    window_quarter = 'window_quarter',
    window_year    = 'window_year',
}

export class TimeSlot
{
    uuid: string|null = null
    start_at: string|null = null;
    end_at: string|null = null;
}

export class QuestionNodeCalendar
{
    // @ts-ignore
    exclude_date: ExcludeDate|null = null;
    choose_date: ChooseDate;

    // @ts-ignore
    // для ChooseDateEnum::specifiedDay
    specified_day: Date|null = null;

    // @ts-ignore
    // для ChooseDateEnum::range
    range_start_at: Date|null = null;
    // @ts-ignore
    range_end_at: Date|null = null;

    // @ts-ignore
    // для ChooseDateEnum::window_* (сколько дней календаря отображать)
    window : number|null = null;

    // Дни недели
    day1: boolean = true;
    day2: boolean = true;
    day3: boolean = true;
    day4: boolean = true;
    day5: boolean = true;
    day6: boolean = true;
    day7: boolean = true;

    time_slots: TimeSlot[] = [];

    constructor(choose_date: ChooseDate) {
        this.choose_date = choose_date;
    }
}

export class ButtonsPanel
{
    rows: RowButtons[] = [];
    button_type: ButtonType = ButtonType.inline;
}

export class QuestionNode {
    // @ts-ignore
    uuid: string;
    name: string;
    text: string;

    @Type(() => NodePosition)
    position: NodePosition;
    answer_type: AnswerTypeEnum;

    @Type(() => QuestionNodeCalendar|null)
    calendar: QuestionNodeCalendar|null = null;

    save_answer_to_context: string|null = null;

    protected: boolean = false
    delete_after_transition: boolean = false

    @Type(() => NodeFile)
    files: NodeFile[] = []
    button_type: ButtonType = ButtonType.inline;

    @Type(() => ButtonRow)
    buttons: ButtonRow[] = []

    @Type(() => PreviewLinkOption)
    preview_option: PreviewLinkOption = PreviewLinkOption.belowText

    // присутствует только при создании question node, для бека
    scenario_uuid: string

    // пока только для произвольного тип вопроса
    allow_media_files_in_answer: boolean = false

    constructor(name: string, text: string, position: NodePosition, answer_type: AnswerTypeEnum, scenarioUuid: string) {
        this.name = name
        this.text = text
        this.position = position
        this.answer_type = answer_type
        this.scenario_uuid = scenarioUuid
    }
}

export class QuestionButton {
    uuid: string
    text: string

    constructor(uuid: string, text: string) {
        this.uuid = uuid;
        this.text = text;
    }
}

export class GraphResult {
    @Type(() => TriggerGroupNode)
    trigger_groups_nodes: TriggerGroupNode[] = [];

    @Type(() => CommandGroupNode)
    commands_groups_nodes: CommandGroupNode[] = [];

    @Type(() => Edge)
    edges: Edge[] = [];

    @Type(() => MessageNode)
    messages: MessageNode[] = [];

    @Type(() => QuestionNode)
    questions: QuestionNode[] = [];

    @Type(() => DelayBlockNode)
    delays: DelayBlockNode[] = [];

    @Type(() => StartTriggerNode)
    start_triggers: StartTriggerNode[] = [];

    @Type(() => GraphConfigResult)
    config: GraphConfigResult;
}

export class GraphConfigTriggerType
{
    name: string
    value: TriggerType
}

export class GraphConfigCommandType
{
    name: string
    value: CommandType
}

export class GraphConfigResult
{
    // @ts-ignore
    viewport:                ViewPort;
    viewed_alert_how_to_use_constructor: boolean;
    is_shared_template:      boolean;

    start_trigger_target_types: GraphConfigTriggerType[] = []
    trigger_group_target_types: GraphConfigTriggerType[] = []
    command_types:              GraphConfigCommandType[] = []
    tags: TagForListItem[] = []

    scenario_trigger_target_types: GraphConfigTriggerType[] = [];
    scenario_trigger_command_types: GraphConfigCommandType[] = [];
    dictionaries: GetDictionaryForListItem[] = [];

    name: string
    link_to_telegram: string
    groups: SearchDialogItem[] = []
}

export class Edge {
    uuid: string;
    source_node_uuid: string;
    target_node_uuid: string;
    type: EdgeType;
    button_uuid: string|null;

    constructor(source_node_uuid: string, target_node_uuid: string, type: EdgeType, button_uuid: string | null) {
        this.source_node_uuid = source_node_uuid;
        this.target_node_uuid = target_node_uuid;
        this.type = type;
        this.button_uuid = button_uuid;
    }
}

export enum EdgeType {
    triggerToCommandOnNegative = 'trigger_on_negative',
    triggerToCommandOnPositive = 'trigger_on_positive',

    questionAnswer = 'question_answer',
    questionDidntAnswer = 'question_didnt_answer',
    questionInvalidAnswer = 'question_invalid_answer',

    button = 'button',
    common = 'common',
}

export class CommandGroupNode {
    // @ts-ignore
    uuid: string;
    name: string;
    position: NodePosition;
    commands: Command[] = [];

    // для создания
    scenario_uuid: string

    constructor(name: string, position: NodePosition, scenario_uuid: string) {
        this.name = name;
        this.position = position;
        this.scenario_uuid = scenario_uuid;
    }
}

export class Command {
    uuid: string;
    type: CommandType|null = null;
    value: CommandValue

    constructor() {
        // если данный метод вызывать в v-for триггеров, что появлялся
        //  визуальный рывок
        this.uuid = uuid.v4();
        this.value = new CommandValue()
    }

    public static createSetContext(): Command
    {
        const self = new Command()
        self.type = CommandType.setContext
        self.value = new CommandValue()
        self.value.context = new ContextCommandItem()

        return self
    }

    public static createAppendContext(): Command
    {
        const self = new Command()
        self.type = CommandType.appendContext
        self.value = new CommandValue()
        self.value.context = new ContextCommandItem()

        return self
    }

    public static createRemoveMessage(): Command
    {
        const self = new Command()
        self.type = CommandType.removeMessage

        return self
    }

    public static createPinMessage(): Command
    {
        const self = new Command()
        self.type = CommandType.pinMessage

        return self
    }

    public static createUnpinAllMessages(): Command
    {
        const self = new Command()
        self.type = CommandType.unpinAllMessages

        return self
    }

    public static createAddTagToSubscriber(): Command
    {
        const self = new Command()
        self.type = CommandType.addTagToSubscriber

        return self
    }

    public static createRemoveTagFromSubscriber(): Command
    {
        const self = new Command()
        self.type = CommandType.removeTagFromSubscriber

        return self
    }

    public static createForwardToDialog(): Command
    {
        const self = new Command()
        self.value = new CommandValue()
        self.value.forward_to_dialogs = []
        self.type = CommandType.forwardToDialog

        return self
    }

    public static createNeedAttention(): Command
    {
        const self = new Command()
        self.type = CommandType.markNeedAttention

        return self
    }

    public static createSendWebHook(): Command
    {
        const self = new Command()
        self.type = CommandType.sendWebHook
        self.value = new CommandValue()
        self.value.web_hook = new CommandWebHookItem()
        self.value.web_hook.body = ''
        self.value.web_hook.url = ''
        self.value.web_hook.method = 'POST'

        return self
    }
}

export class CommandValue
{
    @Type(() => CommandWebHookItem)
    web_hook: CommandWebHookItem|null = null;

    @Type(() => SendMailItem)
    mail: SendMailItem|null = null;

    @Type(() => ContextCommandItem)
    context: ContextCommandItem|null = null;

    @Type(() => SearchDialogItem)
    forward_to_dialogs: SearchDialogItem[] = [];

    @Type(() => AddTagToSubscriber)
    add_tag_to_subscriber: AddTagToSubscriber|null = null;

    @Type(() => AddTagToSubscriber)
    remove_tag_from_subscriber: AddTagToSubscriber|null = null;

    @Type(() => SendMessageValue)
    send_message: SendMessageValue|null = null
}

export class SendMessageValue
{
    text: string = ''

    @Type(() => SearchDialogItem)
    dialogs: SearchDialogItem[] = []

    @Type(() => ButtonRow)
    buttons: ButtonRow[] = []

    @Type(() => PreviewLinkOption)
    preview_option: PreviewLinkOption = PreviewLinkOption.belowText

    reply_to_origin_message: boolean = false

    @Type(() => Period)
    delete_after_period: Period|null = null

    disable_notification: boolean = false
}

export class ContextCommandItem
{
    code: string = ''
    append: number|null = null
    value: number|string|null = null
}

export class HttpHeader
{
    name: string = ''
    value: string = ''
}

export class CommandWebHookItem
{
    // @ts-ignore
    body: string|null;
    // todo: ENUM
    // @ts-ignore
    method: string;
    // @ts-ignore
    url: string;

    headers: HttpHeader[] = [];
    parse_response: ParseResponse[] = [];
}

export class ParseResponse
{
    context_dictionary_uuid: string = ''
    json_path_expression: string = ''
}

export enum CommandType {
    sendWebHook = 'send_webhook',
    removeSubscriber = 'remove_subscriber',
    blockSubscriber = 'block_subscriber',
    sendMail = 'send_mail',
    setContext = 'set_context',
    appendContext = 'append_value',
    markNeedAttention = 'dialog_need_attention',
    forwardToDialog = 'forward_message',
    removeMessage = 'remove_message',
    pinMessage = 'pin_message',
    unpinAllMessages = 'unpin_all_messages',
    addTagToSubscriber = 'add_tag_to_subscriber',
    removeTagFromSubscriber = 'remove_tag_from_subscriber',

    // Отдельные типы команд отображаемые пока только в фильтрах сообщений, в будущем так же добавить их в триггеры
    kickSubscriber = 'kick_subscriber',
    banSubscriber = 'ban_subscriber',
    nothingDo = 'nothing_do',
    muteSubscriber = 'mute_subscriber',

    sendMessage = 'send_message',
}

export class Trigger {
    uuid: string;

    @Type(() => TriggerOperation)
    operation: TriggerOperation;

    @Type(() => TriggerType)
    type: TriggerType|null = null;

    context_code: string|null = null;
    pattern: string|null = null;

    // todo: временный костыль, тут SearchDialogItem|null
    @Type(() => SearchDialogItem)
    subscribed_on_chats: SearchDialogItem|null = null;

    @Type(() => SearchDialogItem)
    current_chat_is: SearchDialogItem|null = null;
    case_sensitive: boolean = false
    sense_mixed_abc: boolean = false

    // временный костыль
    isNew: boolean = false;

    command_description: string|null = null

    invert: boolean = false

    constructor() {
        this.uuid = uuid.v4();
        this.isNew = true
        this.operation = TriggerOperation.equals
    }

    public static createTargetMsg(): Trigger
    {
        const self = new Trigger()
        self.type = TriggerType.msg

        return self
    }

    public static createTargetDate(): Trigger
    {
        const self = new Trigger()
        self.type = TriggerType.date

        return self
    }

    public static createTargetTime(): Trigger
    {
        const self = new Trigger()
        self.type = TriggerType.time

        return self
    }

    public static createTargetSubscribedDate(): Trigger
    {
        const self = new Trigger()
        self.type = TriggerType.date_on_subscribed

        return self
    }

    public static createTargetSubscribedOn(): Trigger
    {
        const self = new Trigger()
        self.type = TriggerType.subscribed_on_chat

        return self
    }

    public static createTargetCurrentChatIs(): Trigger
    {
        const self = new Trigger()
        self.type = TriggerType.current_chat_is

        return self
    }

    public static createTargetContext(): Trigger
    {
        const self = new Trigger()
        self.type = TriggerType.context

        return self
    }

    public static createTargetSubscriberHasTag(): Trigger
    {
        const self = new Trigger()
        self.type = TriggerType.has_tag

        return self
    }
}

export enum TriggerOperation {
    regexp = 'regexp',
    moreOrEquals = 'more_or_equals',
    lessOrEquals = 'less_or_equals',
    less = 'less',
    more = 'more',

    equals = 'equals',
    substr = 'substr',
    containsWord = 'contains_word',
    beginWith = 'begin_with',
    endWith = 'end_with',

    contains_word_from_dictionary = 'contains_word_from_dictionary',
}

export enum TriggerType {
    messagesCounter = 'messages_counter',
    context = 'context',
    date = 'date',
    time = 'time',
    has_tag = 'has_tag',
    date_on_subscribed = 'date_on_subscribed',
    msg = 'msg',
    subscribed_on_chat = 'subscribed_on_chat',
    not_subscribed_on_chat = 'not_subscribed_on_chat',
    current_chat_is = 'current_chat_is',
    messageCommand = 'msg_command',
    messageHasTelegramLinks = 'message_has_telegram_links',

    // удалить, т.к поменялась бизнес логика
    published_post_in_channel = 'published_post_in_channel',
    wasSubscribed = 'was_subscribed',

    isAdmin = 'is_admin',
    isNotAdmin = 'is_not_admin',

    isReply = 'is_reply',
    isNotReply = 'is_not_reply',
}

export class TriggerGroupNode {
    // @ts-ignore
    uuid: string;
    name: string;
    position: NodePosition;
    type: TriggerGroupType;
    triggers: Trigger[] = [];

    // для создания
    scenario_uuid: string

    constructor(name: string,  position: NodePosition, type: TriggerGroupType, scenarioUuid: string)
    {
        this.name = name;
        this.position = position;
        this.type = type
        this.scenario_uuid = scenarioUuid
    }
}

export enum TimeUnit
{
    day = 'day',
    hour = 'hour',
    minute = 'minute',
    second = 'second',
}

export class DelayBlockNode {
    // @ts-ignore
    uuid: string;
    // @ts-ignore
    name: string;
    // @ts-ignore
    position: NodePosition;
    // @ts-ignore
    operation: DelayNodeType;


    send_at_time : string|null = null

    // Определенная дата и время
    specified_date : string|null = null
    specified_time : string|null = null

    // Через N период
    send_through_days: number|null = null
    send_through_days_of_week;
    send_through_meter : TimeUnit|null = null
    send_through_time : string|null = null

    // Динамический расчет
    dynamic_context_uuid : string|null = null
    // todo: enum
    dynamic_time_unit_enum : string|null = null
    dynamic_offset : string|null = null

    day1: boolean = true;
    day2: boolean = true;
    day3: boolean = true;
    day4: boolean = true;
    day5: boolean = true;
    day6: boolean = true;
    day7: boolean = true;

    scenario_uuid: string

    private constructor(uuid: string, position: NodePosition, name: string, scenarioUuid: string, type: DelayNodeType) {
        this.uuid = uuid
        this.position = position
        this.scenario_uuid = scenarioUuid;
        this.name = name
        this.operation = type
    }

    public static createSentThrough(uuid: string, position: NodePosition, name: string, scenarioUuid: string): DelayBlockNode
    {
        const self = new DelayBlockNode(uuid, position, name, scenarioUuid, DelayNodeType.send_through_time)

        self.send_through_days = 10
        self.send_through_meter = TimeUnit.day
        return self
    }

    public changeTypeSentAtTime(): void
    {
        this.operation = DelayNodeType.send_to_at_time
        this.send_at_time = '10:00'
    }

    public changeTypeSentThrough(): void
    {
        this.operation = DelayNodeType.send_through_time
        this.send_through_days = 10
        this.send_through_meter = TimeUnit.day
    }

    public changeTypeSentSpecifiedDate(): void
    {
        this.operation = DelayNodeType.specified_datetime

        const now = new Date()
        this.specified_date = now.getDate() + '-' + now.getMonth() + '-' + now.getFullYear()
        this.specified_time = '00:00'
    }

    public static createSentAtTime(uuid: string, position: NodePosition, name: string, scenarioUuid: string): DelayBlockNode
    {
        const self = new DelayBlockNode(uuid, position, name, scenarioUuid, DelayNodeType.send_to_at_time)

        self.send_at_time = '10:00'
        return self
    }

    public static createSentSpecifiedDate(uuid: string, position: NodePosition, name: string, scenarioUuid: string): DelayBlockNode
    {
        const self = new DelayBlockNode(uuid, position, name, scenarioUuid, DelayNodeType.specified_datetime)

        const now = new Date()
        self.specified_date = now.getDate() + '-' + now.getMonth() + '-' + now.getFullYear()
        self.specified_time = null
        return self
    }
}

export enum DelayNodeType
{
    send_to_at_time = 'send_to_at_time',
    send_through_time = 'send_through_time',
    specified_datetime = 'specified_datetime',
    dynamic_interval = 'dynamic_interval',
}

export async function getGraph(scenarioUuid: string): Promise<GraphResult>
{
    return await client
        .post('/api/v1/edge/get-graph', {scenario_uuid: scenarioUuid})
        .then((response: AxiosResponse) => {
            // Конвертирование тела ответа в DTO
            return plainToInstance(GraphResult, response.data);
        })
}

export class ViewPort
{
    // @ts-ignore
    position: NodePosition;
    // @ts-ignore
    zoom: number;
}

export async function changeViewPort(scenarioUuid: string, position: NodePosition, zoom: number): Promise<AxiosRequestConfig>
{
    return await client
        .post('/api/v1/edge/change-viewport', {scenario_uuid: scenarioUuid, position: position, zoom: zoom});
}

export class StartTriggerNode
{
    // @ts-ignore
    uuid: string;
    name: string;
    position: NodePosition;
    type: TriggerGroupType;
    triggers: Trigger[] = [];

    // для создания
    scenario_uuid: string

    constructor(name: string, position: NodePosition, type: TriggerGroupType, scenarioUuid: string) {
        this.name = name;
        this.position = position;
        this.type = type;
        this.scenario_uuid = scenarioUuid
    }
}

export class FileItem
{
    uuid: string
    url: string
}
