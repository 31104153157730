<template>
  <div>
    <h5 class="fw-bold d-inline-block">Действие</h5>

    <p class="text-break">
      Блок действия предоставляет с собой набор команд которые необходимо выполнить боту, например переслать сообщение в другой чат, удалить сообщение, добавить подписчику тег и т.д
    </p>

    <p class="text-break">
      Важно, список команд внутри блока действия выполняются параллельно, соответственно если у вас есть определенная зависимость между командами
      (переслать сообщение и далее удалить его из оригинального диалога), то необходимо сначала переслать сообщение, а далее удалить,
      в противном случае сообщение может не отправится)
    </p>

    <small class="d-block mb-0 mt-2">Пример как выглядит блок действия</small>
    <Image
        src="/command_group.png"
        alt="Блок действия"
        height="300"
        preview
        class="mb-1"
        :image-style="{
                objectFit: 'contain',
                width: '100px',
                height: '100px',
                }"
    />

    <p class="fw-bold">Список действий:</p>
    <ul class="ms-2">
      <li>Переслать сообщение подписчика</li>
      <li>Открепить все сообщения в текущем чате</li>
      <li>Добавить подписчику тег</li>
      <li>Установить значение переменной равной X</li>
      <li>Изменить значение переменной на (полезно для ведения счетчиков)</li>
      <li>Закрепить сообщение подписчика</li>
      <li>Удалить сообщение подписчика</li>
      <li>Отправить HTTP запрос (для интеграции с сторонними системами по API)</li>
    </ul>
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Divider from "primevue/divider";

export default defineComponent({
  name: "DocsBuilderCommand",
  components: {
    Divider,
    Image,
  },
  created() {

  },

})
</script>