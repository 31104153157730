import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2 gridmap" }
const _hoisted_2 = { class: "font-bold m-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumbs, {
      items: this.breadcrumbs,
      class: "bg-white"
    }, null, 8, ["items"]),
    _createVNode(_component_TabMenu, {
      model: _ctx.itemsTab,
      class: "mt-4 bg-white rounded",
      pt: {action: { class: 'text-decoration-none', style: {color: 'black'} }}
    }, {
      item: _withCtx(({ item, props }) => [
        _withDirectives((_openBlock(), _createBlock(_component_router_link, _mergeProps({
          to: {name: item.route_name}
        }, props.action, { class: "flex align-items-center gap-2" }), {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1040, ["to"])), [
          [_directive_ripple]
        ])
      ]),
      _: 1
    }, 8, ["model"]),
    _createElementVNode("div", null, [
      _createVNode(_component_router_view)
    ])
  ]))
}