<template>
  <div>
    <h5 class="fw-bold d-inline-block">Теги</h5>

    <p class="text-break">
      Теги предоставляют с собой функционал определение подписчика в определенную группу пользователей. Например можно создать теги: "Администраторы", "Модераторы", "Оплатившие курс X"
      и далее формировать в сценарии условия наличия или отсутствия данного тега у подписчика и менять поведение бота.
    </p>

<!--    <p>todo: привести ссылку на shared шаблон с примером использования переменной</p>-->
  </div>
</template>


<script lang="ts">
import {Component, defineComponent} from 'vue'
import Image from "primevue/image";
import Divider from "primevue/divider";
import DocsBuilderMessage from "@/components/view/Documentation/DocsBuilderMessage.vue";
import DocsAboutService from "@/components/view/Documentation/DocsAboutService.vue";

export default defineComponent({
  name: "DocsTags",
  components: {
    Divider,
    Image,
  },
  created() {

  },
})
</script>