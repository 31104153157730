<template>
  <div>
<!--    <Panel header="Дополнительно" class="mt-5" toggleable>-->
<!--      <div class="text-start mb-1">-->
<!--        <Checkbox :disabled="!this.isEditMode"/>-->
<!--        <label>Возможно пропустить?</label>-->
<!--      </div>-->

<!--      <div class="text-start mb-1">-->
<!--        <Checkbox :disabled="!this.isEditMode"/>-->
<!--        <label>Удалить сообщение бота после выполнения следующей команды</label>-->
<!--      </div>-->

<!--      <div class="text-start mb-1">-->
<!--        <Checkbox :disabled="!this.isEditMode"/>-->
<!--        <label>Удалить сообщение пользователя после выполнения следующей команды</label>-->
<!--      </div>-->

<!--      <div class="text-start mb-1">-->
<!--        <AutoComplete-->
<!--            dropdown :suggestions="[]"-->
<!--            placeholder="Записать ответ в переменную"-->
<!--            :disabled="!this.isEditMode"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="text-start mb-1">-->
<!--        <label>Время ожидания ответа (в сек):</label>-->
<!--        <InputNumber class="form-control border-0 p-0" :disabled="!this.isEditMode"/>-->
<!--      </div>-->
<!--    </Panel>-->
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import AutoComplete from "primevue/autocomplete";
import Panel from "primevue/panel";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: 'QuestionSettings',
  props: {
    isEditMode: {
      required: true,
      type: Boolean,
    },
  },
  data: function() {
    return {
    }
  },
  components: {
    Dropdown,
    InputNumber,
    Textarea,
    AutoComplete,
    Checkbox,
    Panel,
    InputText,
  },
  computed: {},
  methods: {
    getCalendarOptions: function () {
      return [
        {
          name: '',
          code: '',
        },
      ]
    }
  }
})
</script>
