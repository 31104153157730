import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTemplate = _resolveComponent("BaseTemplate")!

  return (_openBlock(), _createBlock(_component_BaseTemplate, {
    dialog_uuid: this.dialog_uuid,
    scenario_uuid: this.scenario_uuid,
    type: _ctx.TriggerWelcomeType.welcome
  }, null, 8, ["dialog_uuid", "scenario_uuid", "type"]))
}