<template>
  <BaseTemplate
      :dialog_uuid="this.dialog_uuid"
      :scenario_uuid="this.scenario_uuid"
      :type="TriggerWelcomeType.goodbye"
  />
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Button from "primevue/button";
import AutoComplete, {AutoCompleteDropdownClickEvent} from "primevue/autocomplete";
import {TriggerWelcomeType,} from "@/api/welcomeTrigger";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import FilesViewer from "@/components/common/FilesViewer.vue";
import Divider from "primevue/divider";
import BaseTemplate from "@/components/view/GroupComponents/WelcomeGoodbyeTrigger/WelcomeForm.vue";

export default defineComponent({
  name: "WelcomeCard",
  computed: {
    TriggerWelcomeType() {
      return TriggerWelcomeType
    },
  },
  components: {
    BaseTemplate,
    FilesViewer,
    FileUpload,
    RichTextEditor,
    InputSwitch,
    Dropdown,
    InputText,
    Button,
    AutoComplete,
    Divider,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    },
    dialog_uuid: {
      required: true,
      type: String,
    },
  },
})
</script>