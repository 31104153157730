import {ToastSeverity} from 'primevue/api';
import {app} from "../main";

export function info(title: string, body: string, lifeTime: number = 3000): void {
    // console.log(app)
    app.config.globalProperties.$toast.add({severity: ToastSeverity.INFO, summary: title, detail: body, life: lifeTime});
};

export function error(title: string, body: string, lifeTime: number = 3000): void {
    // удаляем все предыдущие алерты (временный костыль, надо по времени или по стейту toast определять что алерт уже отображается
    app.config.globalProperties.$toast.removeAllGroups()
    app.config.globalProperties.$toast.add({severity: ToastSeverity.ERROR, summary: title, detail: body, life: lifeTime});
};