<template>
  <div class="p-2">
    <TabView>
      <TabPanel header="Системные">
        <Panel header="Глобальные" toggleable>
          <DataTable :value="this.systemGlobalContext" tableStyle="min-width: 50rem">
            <Column field="code"       style="width: 30%"/>
            <Column field="type_print" style="width: 20%"/>
            <Column field="name"/>
          </DataTable>
        </Panel>
        <Panel header="Персональные" toggleable>
          <DataTable :value="this.systemPersonContext" tableStyle="min-width: 50rem">
            <Column field="code"       style="width: 30%"/>
            <Column field="type_print" style="width: 20%"/>
            <Column field="name"/>
          </DataTable>
        </Panel>
      </TabPanel>
      <TabPanel header="Пользовательские">
        <Button
            class="rounded mb-2"
            @click="this.isOpenDialogCreateContext = true"
            label="Добавить"
            severity="contrast"
        />

        <Panel header="Глобальные" toggleable>
          <DataTable :value="this.customGlobalContext" tableStyle="min-width: 50rem">
            <Column field="code" header="Код"       style="width: 30%"/>
            <Column field="type_print" header="Тип" style="width: 20%"/>
            <Column field="name" header="Описание"/>
            <Column field="value" header="Значение"/>
            <Column :exportable="false" style="min-width:8rem">
              <template #body="slotProps">
                <Button
                    v-if="slotProps.data.can_remove"
                    icon="pi pi-trash"
                    class="rounded-circle"
                    outlined
                    severity="danger"
                    v-tooltip="'Удалить переменную'"
                    :loading="this.removingContextUuids.includes(slotProps.data.uuid)"
                    @click="() => {
                      this.removingContextUuids.push(slotProps.data.uuid)

                      removeContext(slotProps.data.uuid)
                        .then(() => {
                          this.loadDataFromBackend()
                        })
                        .finally(() => {
                          this.removingContextUuids.splice(
                              this.removingContextUuids.indexOf(slotProps.data.uuid),
                              1
                          )
                        })
                    }"
                />
              </template>
            </Column>
          </DataTable>
        </Panel>
        <Panel header="Персональные" toggleable>
          <DataTable :value="this.customPersonContext" tableStyle="min-width: 50rem">
            <Column field="code" header="Код"       style="width: 30%"/>
            <Column field="type_print" header="Тип" style="width: 20%"/>
            <Column field="name" header="Описание"/>
            <Column :exportable="false" style="min-width:8rem">
              <template #body="slotProps">
                <Button
                    v-if="slotProps.data.can_remove"
                    icon="pi pi-trash"
                    class="rounded-circle"
                    outlined
                    v-tooltip="'Удалить переменную'"
                    severity="danger"
                    :loading="this.removingContextUuids.includes(slotProps.data.uuid)"
                    @click="() => {
                      this.removingContextUuids.push(slotProps.data.uuid)

                      removeContext(slotProps.data.uuid)
                        .then(() => {
                          this.loadDataFromBackend()
                        })
                        .finally(() => {
                          this.removingContextUuids.splice(
                              this.removingContextUuids.indexOf(slotProps.data.uuid),
                              1
                          )
                        })
                    }"
                />
              </template>
            </Column>
          </DataTable>
        </Panel>
      </TabPanel>
    </TabView>

    <Dialog
        v-model:visible="this.isOpenDialogCreateContext"
        header="Создать переменную"
        modal class="w-50" :draggable="false" :contentStyle="{ height: '500px' }"
        @after-hide="this.createContextCmd = new CreateContextDto(this.scenario_uuid)"
    >
      <SelectButton
          :options="this.options"
          v-model="this.createContextCmd.is_global"
          option-label="label"
          option-value="value"
          class="mt-2"
          :allow-empty="false"
          @change="() => {
            if (this.createContextCmd.is_global === false)
              this.createContextCmd.value = ''
            }
          "
      />
      <SelectButton
          :options="this.typesOptions"
          v-model="this.createContextCmd.type"
          option-label="label"
          option-value="value"
          :allow-empty="false"
          class="mt-2"
          @change="this.onChangeTypeOfVariable"
      />

      <div class="mt-2">
        <div>
          <div>
            <span class="me-2 mb-0 align-middle required">Символьный код переменной</span>
            <span class="pi pi-question-circle align-middle" v-tooltip="'Используется для подстановки переменной в шаблон сообщения'"/>
          </div>
          <div>
            <InputText
                v-model="this.createContextCmd.code"
            />
            <p class="p-error" v-if="this.errors['code']">{{ this.errors['code'] }}</p>
          </div>
        </div>

        <div class="mt-2">
          <p class="m-0">Описание</p>
          <InputText
              v-model="this.createContextCmd.description"
          />
        </div>


        <div class="mt-2">
          <p class="m-0">Значение</p>
          <input
              :type="this.createContextCmd.type === ContextType.String ? 'text' : 'number'"
              v-model="this.createContextCmd.value"
              class="form-control"
              :disabled="!this.createContextCmd.is_global"
          />
        </div>
      </div>

      <template #footer>
        <Button
            type="button"
            label="Сохранить"
            class="bg-success text-white rounded"
            @click="() => {
              this.errors = []
              this.isLoading = true

              createContext(this.createContextCmd).then(() => {
                this.loadDataFromBackend().then(() => {
                    this.isOpenDialogCreateContext = false
                    this.createContextCmd = new CreateContextDto(this.scenario_uuid)
                })
              }).catch((error: ErrorResponse) => {
                this.errors = error.getValidationErrors()
              }).finally(() => {
                this.isLoading = false
              });
            }"
            :loading="this.isLoading"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">

import axios, {AxiosError} from "axios";
// https://github.com/vueform/multiselect
import Multiselect from '@vueform/multiselect'
import {defineComponent} from "vue";
import {
  ContextItem, ContextListResult, ContextType,
  createContext,
  CreateContextDto,
  fetchContextList, removeContext,
} from '@/api/context'
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Panel from "primevue/panel";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import SelectButton, {SelectButtonChangeEvent} from "primevue/selectbutton";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import {ErrorResponse, ValidationErrorItem} from "@/api/common";

export default defineComponent({
  name: 'ContextList',
  computed: {
    CreateContextDto() {
      return CreateContextDto
    },
    ErrorResponse() {
      return ErrorResponse
    },
    ContextType() {
      return ContextType
    },
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  components: {
    Multiselect,
    TabPanel,
    TabView,
    DataTable,
    Column,
    Panel,
    Button,
    Dialog,
    SelectButton,
    InputText,
    InputNumber,
  },
  data: function () {
    return {
      // Системные переменные
      systemGlobalContext: [] as ContextItem[],
      systemPersonContext: [] as ContextItem[],
      // Пользовательские переменные
      customGlobalContext: [] as ContextItem[],
      customPersonContext: [] as ContextItem[],
      // Остальные переменные
      isOpenDialogCreateContext: false,
      createContextCmd: new CreateContextDto(this.scenario_uuid),
      isLoading: false,
      removingContextUuids: [] as string[],
      errors: [],

      options: [
        {
          label: 'Глобальная',
          value: true,
        },
        {
          label: 'Персональная',
          value: false,
        }
      ],
      typesOptions: [
        {
          label: 'Число',
          value: ContextType.Integer,
        },
        {
          label: 'Строка',
          value: ContextType.String,
        }
      ],
    }
  },
  mounted() {
    this.loadDataFromBackend()
  },
  methods: {
    createContext,
    removeContext,
    loadDataFromBackend: function (): ContextListResult {
      return fetchContextList(this.scenario_uuid).then((context: ContextListResult) => {
        this.systemGlobalContext = context.global_system_variables
        this.customGlobalContext = context.global_custom_variables

        this.systemPersonContext = context.person_system_variables
        this.customPersonContext = context.person_custom_variables
      })
    },

    // чистим поле от всех символов кроме цифр если тип переменной - число
    onChangeTypeOfVariable: function (event: SelectButtonChangeEvent) {
      const newType = event.value as ContextType

      if (newType === ContextType.Integer) {
        this.createContextCmd.value = this.createContextCmd.value.toString().replace(/[^0-9]/g, '')
      }
    },
  },
})
</script>
