import {AxiosResponse} from "axios";
import {MessageNode} from "@/api/graph";
import {plainToClass} from "class-transformer";
import client from "./client";

export async function updateMessageNode(cmd: MessageNode): Promise<MessageNode> {
    // @ts-ignore
    return client
        .post('/api/v1/message-node/update', cmd)
        .then((response: AxiosResponse) => {
            return plainToClass(MessageNode, response.data);
        })
}
