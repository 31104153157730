import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "content-trigger-group" }
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TriggerItem = _resolveComponent("TriggerItem")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Dropdown, {
        class: "mt-2 mb-2 fw-bold",
        modelValue: this.modelValueData.type,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.modelValueData.type) = $event)),
        options: [
                {value: _ctx.TriggerGroupType.OR, name: 'Проверка условий через ИЛИ'},
                {value: _ctx.TriggerGroupType.AND, name: 'Проверка условий через И'}
            ],
        "option-value": "value",
        "option-label": "name"
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.modelValueData.triggers, (element, index) => {
        return (_openBlock(), _createBlock(_component_TriggerItem, {
          key: element.uuid,
          "model-value": this.modelValueData.triggers[index],
          "onUpdate:modelValue": ($event) => {
            this.modelValueData.triggers[index] = $event
          },
          onPressedButtonRemove: () => {
              this.modelValueData.triggers.splice(index, 1)
              this.isShowAddTriggerItem = false
          },
          onCompletedFillTrigger: _cache[1] || (_cache[1] = () => {this.isShowAddTriggerItem = false}),
          "is-edit-mode": true,
          "context-options": this.contextOptionsForTriggers,
          "trigger-options": this.graphConfigStorage.getTriggerGroupTargetTypes
        }, null, 8, ["model-value", "onUpdate:modelValue", "onPressedButtonRemove", "context-options", "trigger-options"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Button, {
        label: "Добавить условие",
        severity: "secondary",
        class: "mt-2 rounded",
        onClick: _cache[2] || (_cache[2] = ($event) => { this.$refs.menu.toggle($event); })
      }),
      _createVNode(_component_Menu, {
        ref: "menu",
        id: "overlay_menu",
        "base-z-index": 10001,
        model: this.getMenuContextOptions(),
        popup: true
      }, null, 8, ["model"])
    ])
  ]))
}