import axios, {AxiosError, AxiosRequestTransformer, AxiosResponse} from "axios";
import {AxiosInstance} from "axios";
import {error} from "./ToastService";
import {plainToClass} from "class-transformer";
import {useAuthStorage, useUserStorage} from "../store";
import router from '../router/index'
import {ErrorResponse} from "./common";
import {Route} from "@sentry/vue/types/router";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

// Передаем дату в локали клиента (иначе идет преобразование к UTC которое в рамках нашего проекте излишне - используются тайм-зоны на уровне сценария/рассылок)
const dateTransformer: AxiosRequestTransformer = data => {
    // Не модифицируем FormData
    if (data instanceof FormData) {
        return data
    }

    if (data instanceof Date) {
        // костыль, т.к не получилось быстро реализовать форматирование даты к нужному шаблону
        const day = String(data.getDate()).padStart(2, '0');
        const month = String(data.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
        const year = data.getFullYear();
        const hours = String(data.getHours()).padStart(2, '0');
        const minutes = String(data.getMinutes()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
    if (Array.isArray(data)) {
        return data.map(val => dateTransformer(val))
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
            [ key, dateTransformer(val) ]))
    }
    return data
}

const client: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000, // request timeout
    transformRequest: [ dateTransformer ].concat(axios.defaults.transformRequest)
})

client.interceptors.request.use(
    config => {
        // do something before request is sent

        const authStore = useAuthStorage()
        const token = authStore.getToken
        if (token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = `Bearer ` + token
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    },
)

// Перехватываем все ответы и обрабатываем ошибку 401 (Unauthorized)
client.interceptors.response.use(  (response: AxiosResponse) => response.data, handleError);

async function handleError(responseError: AxiosError | Error): Promise<any> {
    const originalRequest = responseError.config;

    if (
        responseError instanceof AxiosError &&
        responseError.response.status === 401 &&
        !responseError.config.__isRetryRequest
    ) {
        // Пытаемся обновить токен
        if (isRefreshing) {
            return new Promise(function(resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return client(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
            const token: string = await getToken()

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            processQueue(null, token);
        } catch (e) {
            processQueue(e, null);
        } finally {
            isRefreshing = false
        }

        // Повторяем оригинальный запрос с установленным флагом __isRetryRequest,
        // чтобы избежать бесконечной петли обновления токена
        responseError.config.__isRetryRequest = true;
        return client(responseError.config);
    }

    if (responseError instanceof AxiosError) {
        if (responseError.response.status === 403) {
            error('', 'Необходимо авторизоваться')

            const userStorage = useUserStorage()
            userStorage.setRedirectAfterAuthRoute(
                router.currentRoute.value.name,
                router.currentRoute.value.params,
            )

            await router.push({name: 'auth'})
        }

        if (responseError.response.status >= 500) {
            error('Серверная ошибка', 'пожалуйста попробуйте позже')
        }    
    }

    return Promise.reject(new ErrorResponse(responseError.response.data.error));
}

export class RefreshTokenRes
{
    token: string
    refresh_token: string
}

export class JwtTokenPayload
{
    sub: string
    exp: number
    iat: number
    roles: string[]
}

export async function getToken(): Promise<string>
{
    // todo: обработать кейс когда refresh токен так же протух
    const authStore = useAuthStorage()

    return client
        .post('/api/v1/auth/refresh-token', {
            refresh_token: authStore.getRefreshToken
        })
        .then((response) => {
            const refreshTokenRes = plainToClass(RefreshTokenRes, response);

            authStore.setToken(refreshTokenRes.token)
            authStore.setRefreshToken(refreshTokenRes.refresh_token)

            return refreshTokenRes.token
        })
}

export default client


export class Pagination
{
    // @ts-ignore
    by_page: number
    // @ts-ignore
    page: number
    // @ts-ignore
    quantity: number
}
