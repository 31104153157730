<template>
  <div class="">

    <div class="d-flex bd-highlight expanded_sidebar">
      <LeftSideBar v-model="this.menuItems" :collapsed-menu-default="false" v-show="this.isShowSideBar"/>

      <div class="flex-grow-1 bd-highlight">
        <router-view/>
      </div>
    </div>

<!--    <main>-->
<!--      <div class="p-0 m-0">-->

<!--        <router-view/>-->
<!--      </div>-->

<!--      <Vue3Tutorial-->
<!--          :tutorial="this.tutorialStorage.getTutorial"-->
<!--          :open="this.tutorialStorage.getCurrentTutorial !== null"-->
<!--      />-->
<!--    </main>-->
  </div>

<!--  todo: временно скрыл иконку пользователя, т.к сейчас не храним иконку пользователя и нет бизнес логики на нее -->
<!--  <div class="position-absolute top-0 end-0 m-2 me-4">-->
<!--    <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" shape="circle" @click="this.showProfileItems" />-->
<!--  </div>-->

</template>

<script lang="ts">
import {defineComponent, provide, ref} from "vue";
import LeftSideBar from "@/components/view/SideBar/LeftSideBar.vue";
import Toolbar from "primevue/toolbar";
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import SpeedDial from 'primevue/speeddial';
import {SideBarMenu} from "@/components/view/SideBar/sidebar";
import {useTutorialStorage} from "@/store";
import {TutorialEnum} from "@/api/interactiveLessons";
import Vue3Tutorial from "vue3-tutorial";
import {GraphConfigResult} from "@/api/graph";
import {getConfig, SearchDialogItem} from "@/api/edge";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

// https://github.com/yaminncco/vue-sidebar-menu
export default defineComponent({
  name: 'Layout',
  components: {
    Vue3Tutorial,
    LeftSideBar,
    Toolbar,
    Avatar,
    Button,
    SpeedDial,
  },
  data: function () {
    return {
      isShowSpeedDialList: true,
      menuItems: [] as SideBarMenu[],
      isShowSideBar: true,
    }
  },
  methods: {
    generateBotItems: function (scenarioUuid: string|null): void {
      const menu = [
        new SideBarMenu('Боты', 'bots_list', null, 'bx bx-list-ol', []),
        new SideBarMenu('Профиль', 'profile', null, 'pi pi-user', []),
        new SideBarMenu('Документация', 'doc_get_started', null, 'bx bxs-file-doc', [], '_blank'),
        new SideBarMenu('Обновления', 'changelog', null, 'pi pi-github', []),
      ]

      if (scenarioUuid) {
        getConfig(scenarioUuid).then((res: GraphConfigResult) => {
          this.isShowSideBar = !res.is_shared_template
          const graphConfigStorage = useGraphConfigStorage()
          menu.push(
              new SideBarMenu(res.name, 'about_bot', {scenario_uuid: scenarioUuid}, 'bx bx-bot', []),
              new SideBarMenu('Диалоги', 'dialogs', {scenario_uuid: scenarioUuid}, 'bx bxs-message-rounded-dots', []),
              new SideBarMenu('База пользователей', 'subscribers', {scenario_uuid: scenarioUuid}, 'bx bxs-group', []),
              new SideBarMenu('Telegram группы', 'bot_groups', {scenario_uuid: scenarioUuid}, 'bx bx-group', []),
              new SideBarMenu('Отложенный постинг', 'posts', {scenario_uuid: scenarioUuid}, 'bx bx-text', []),
              new SideBarMenu('Бот обратной связи', 'feedback', {scenario_uuid: scenarioUuid}, 'pi pi-phone', []),
              new SideBarMenu('Справочники', 'dictionary_for_list', {scenario_uuid: scenarioUuid}, 'bx bx-book', []),
          )

          graphConfigStorage.updateFromServerData(res)

          menu.push(new SideBarMenu('Конструктор воронок', '', {scenario_uuid: scenarioUuid}, 'bx bx-shape-triangle', [
            new SideBarMenu('Сценарий бота', 'bot_builder', {scenario_uuid: scenarioUuid}, 'bx bx-code', []),
            new SideBarMenu('Переменные', 'context_list', {scenario_uuid: scenarioUuid}, 'bx bx-memory-card', []),
            new SideBarMenu('Теги', 'tags_list', {scenario_uuid: scenarioUuid}, 'bx bx-purchase-tag', []),
            new SideBarMenu('Запись на прием', 'calendar', {scenario_uuid: scenarioUuid}, 'bx bx-calendar', []),
          ]))

          this.menuItems = menu
        })
      } else {
        this.menuItems = menu
      }
    },
  },
  computed: {
    scenarioUuid: function (): string | null {
      // Временный костыль, в будущем почитать как правильно реализовывать подобную функциональность
      return this.$route?.params?.scenario_uuid
    },

    tutorialName: function (): TutorialEnum|null {
      return TutorialEnum.parse(this.$route.query.tutorial)
    },
  },
  watch: {
    'scenarioUuid': function (newScenarioUuid: string|null) {
      this.generateBotItems(newScenarioUuid)
    },

    'tutorialName': function (newVal: TutorialEnum|null) {
      this.tutorialStorage.setTutorial(newVal)
    }
  },
  mounted() {
    this.generateBotItems(this.scenarioUuid)
    this.tutorialStorage.setTutorial(this.tutorialName)
  },
  setup() {
    const tutorialStorage = useTutorialStorage()
    return {tutorialStorage}
  },
})
</script>

<style>
body {
  background-color: #fbfbfb;
}

</style>
