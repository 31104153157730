import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "d-flex bd-highlight expanded_sidebar" }
const _hoisted_3 = { class: "flex-grow-1 bd-highlight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftSideBar = _resolveComponent("LeftSideBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_LeftSideBar, {
        modelValue: this.menuItems,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.menuItems) = $event)),
        "collapsed-menu-default": false
      }, null, 8, ["modelValue"]), [
        [_vShow, this.isShowSideBar]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}