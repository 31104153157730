import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-305e5c79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullCalendar = _resolveComponent("FullCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FullCalendar, {
      options: this.calendarOptions,
      ref: "calendar"
    }, null, 8, ["options"])
  ]))
}