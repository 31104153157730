<template>
  <Handle :id="this.id" type="source" :position="Position.Right" :style="this.style" :connectable="this.connectableHandle">
    <slot/>
  </Handle>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {Handle, Position} from "@vue-flow/core";
import {EdgeType} from "@/api/graph";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

// Handler ОТ которого могут исходить ребра
export default defineComponent({
  name: 'SourceHandle',
  components: {Handle},
  props: {
    id: {
      required: false,
      type: String,
      default: EdgeType.common,
    },
    style: {
      required: false,
      default: {top: '30px'}
    },
  },
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    return {graphConfigStorage}
  },
  computed: {
    Position() {
      return Position
    },
    connectableHandle: function () {
      if (this.graphConfigStorage.isSharedTemplate) {
        return false
      }

      return 'single'
    }
  },
})
</script>
<style scoped>
.vue-flow__handle {
  width: 10px;
  height: 10px;
  padding: 5px;
}
</style>
