<template>
  <div>
    <div class="content-group m-2">
      <!--   Медиа файлы к ноде   -->
      <FilesViewer v-model="this.modelValueData.files"/>

      <FileUpload
          class="mt-2"
          name="file[]"
          :showUploadButton="false"
          :multiple="true"
          choose-label="Выберите файлы"
          :show-cancel-button="false"
          mode="basic"
          :auto="true"
          :custom-upload="true"
          @uploader="this.handleUploader"
      />

      <div class="mt-2">
        <SearchContext
            :context-options="this.contextOptionsForMessages"
            @selected-context="($event) => { this.modelValueData.text += $event }"
        />
      </div>

      <RichTextEditor
          class="mt-2"
          v-model="this.modelValueData.text"
      />
    </div>

    <!--   Панель кнопок   -->
    <div class="m-2 mt-4 content-group">
      <div>
        <ButtonGroupPanel v-model="this.modelValueData.buttons" :button-types="[ButtonGroupType.link, ButtonGroupType.scenario]"/>
      </div>
    </div>


    <!--   Дополнительные настройки   -->
    <div class="m-2 mt-4">
      <Accordion>
        <AccordionTab header="Расширенные настройки">

          <PreviewOption v-model="this.modelValueData.preview_option"/>

          <div class="mt-1">
            <Checkbox
                :binary="true"
                class="align-middle me-2"
                v-model="this.modelValueData.pin"
            />
            <span class="align-middle">Закрепить сообщение</span>
          </div>

          <div>
            <Checkbox
                :binary="true"
                class="align-middle me-2"
                v-model="this.modelValueData.protected"
            />
            <span class="align-middle">Запретить копировать</span>
          </div>

          <div>
            <Checkbox
                :binary="true"
                class="align-middle me-2"
                v-model="this.modelValueData.delete_after_transition"
            />
            <span class="align-middle">Удалить сообщение после перехода на следующий блок</span>
          </div>


          <div class="mt-2 mb-2">
            <Checkbox
                :binary="true"
                v-model="this.sendMessageToSameDialog"
                class="me-2"
                @update:model-value="($event) => {
                  if ($event) {
                    this.modelValueData.send_message_to_dialogs = []
                  }
                }"
            />
            <span>Отправить сообщение в тот же диалог</span>

            <DialogTagPanel
                v-show="this.sendMessageToSameDialog === false"
                v-model="this.modelValueData.send_message_to_dialogs"
                :dialog-types="[DialogType.channel, DialogType.group, DialogType.private]"
                :scenario-uuid="this.scenario_uuid"
                placeholder="Куда отправить сообщение"
                :show-topic-for-groups="true"
            />
          </div>

<!--      TODO: Тип отображения превью ссылок (сверху, снизу, не показывать)   -->
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject, PropType} from 'vue'
import AccordionTab from "primevue/accordiontab";
import FileUpload, {FileUploadUploaderEvent} from "primevue/fileupload";
import SelectButton from "primevue/selectbutton";
import Checkbox from "primevue/checkbox";
import Accordion from "primevue/accordion";
import Button from "primevue/button";
import Image from "primevue/image";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import {ButtonType, MessageNode, TriggerGroupNode} from "@/api/graph";
import ButtonGroupPanel from "@/components/view/ButtonGroupPanel.vue";
import {ButtonGroupType, ButtonLink, ButtonRow} from "@/api/buttonGroupPanel";
import {Type} from "class-transformer";
import SearchDialogMultiple from "@/components/common/SearchDialogMultiple.vue";
import {FileOwner, uploadFile} from "@/api/file";
import FilesViewer from "@/components/common/FilesViewer.vue";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";
import SearchContext from "@/components/common/SearchContext.vue";
import PreviewOption from "@/components/common/PreviewOption.vue";
import DialogTagPanel from "@/components/view/Forward/DialogTagPanel.vue";
import {DialogType} from "@/api/dialog";

export default defineComponent({
  name: "MessageNodeEdit",
  computed: {
    DialogType() {
      return DialogType
    },
    ButtonGroupType() {
      return ButtonGroupType
    }
  },
  components: {
    DialogTagPanel,
    PreviewOption,
    FilesViewer,
    SearchDialogMultiple,
    ButtonGroupPanel,
    RichTextEditor,
    Image,
    Button,
    Accordion,
    Checkbox,
    SelectButton,
    FileUpload,
    AccordionTab,
    SearchContext,
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<MessageNode>,
    },
  },
  inject: ['scenario_uuid'],
  emits: [
    'update:modelValue',
  ],
  setup() {
    const graphConfigStorage = useGraphConfigStorage()
    const contextOptionsForMessages = inject('contextOptionsForMessages', [])

    return {graphConfigStorage, contextOptionsForMessages}
  },
  data: function () {
    return {
      modelValueData: this.modelValue,

      sendMessageToSameDialog: this.modelValue.send_message_to_dialogs.length === 0,
    }
  },
  watch: {
    modelValueData: {
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
    },
    modelValue: function (val) {
      this.modelValueData = val
    }
  },
  methods: {
    handleUploader: function (event: FileUploadUploaderEvent) {
      const files = event.files instanceof Array ? event.files : [event.files]

      const mustSentFiles = files.filter((item: File) => {
        if (item.uuid) {
          return false
        }
        return true
      })

      uploadFile(this.scenario_uuid, FileOwner.constructor, mustSentFiles).then((res) => {
        mustSentFiles.forEach((item: File, index: number) => {
          item.uuid = res.items[index].uuid
          this.modelValueData.files.push(res.items[index])
        })
      })
    },
  }
})
</script>