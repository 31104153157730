<template>
<div class="position-absolute top-0 end-0 bg-body vh-100 custom-border" style="z-index: 500; width: 25%; overflow-y: auto;">
  <!--   Заголовок ноды   -->
  <div class="d-flex justify-content-center p-2" style="background-color: rgb(120,120,120);">
    <InputText
        class="w-100 align-middle"
        v-model="this.modelValueData.name"
    />
    <button
        class="pi pi-times bg-transparent border-0 ms-2 text-white align-middle"
        @click="() => {
          this.$emit('collapsed')
        }"
    />
  </div>

  <div v-if="this.modelValueData instanceof MessageNode">
    <MessageNodeEdit
        :model-value="this.modelValueData"
        @update:model-value="this.modelValueData = $event; this.$emit('update:modelValue', $event)"
    />
  </div>

  <div v-if="this.modelValueData instanceof CommandGroupNode">
    <CommandGroupNodeEdit
        :model-value="this.modelValueData"
        @update:model-value="this.modelValueData = $event; this.$emit('update:modelValue', $event)"
    />
  </div>

    <div v-if="this.modelValueData instanceof DelayBlockNode">
      <DelayNodeEdit
          :model-value="this.modelValueData"
          @update:model-value="this.modelValueData = $event; this.$emit('update:modelValue', $event)"
      />
    </div>

    <div v-if="this.modelValueData instanceof TriggerGroupNode">
      <TriggerGroupEditNode
          :model-value="this.modelValueData"
          @update:model-value="this.modelValueData = $event; this.$emit('update:modelValue', $event)"
      />
    </div>

    <div v-if="this.modelValueData instanceof QuestionNode">
      <QuestionNodeEdit
          :model-value="this.modelValueData"
          @update:model-value="this.modelValueData = $event; this.$emit('update:modelValue', $event)"
      />
    </div>
</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import Accordion from "primevue/accordion";
import SelectButton from "primevue/selectbutton";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import AccordionTab from "primevue/accordiontab";
import FileUpload from "primevue/fileupload";
import RichTextEditor from "@/components/view/RichTextEditor.vue";
import Button from "primevue/button";
import Image from "primevue/image";
import {
  CommandGroupNode,
  DelayBlockNode,
  MessageNode,
  QuestionNode,
  StartTriggerNode,
  TriggerGroupNode
} from "@/api/graph";
import MessageNodeEdit from "@/components/view/Bots/NodeEdit/MessageNodeEdit.vue";
import CommandGroup from "@/components/view/Bots/Command/CommandGroup.vue";
import CommandGroupNodeEdit from "@/components/view/Bots/NodeEdit/CommandGroupNodeEdit.vue";
import Dropdown from "primevue/dropdown";
import DelayNodeEdit from "@/components/view/Bots/NodeEdit/DelayNodeEdit.vue";
import TriggerGroup from "@/components/view/Bots/Trigger/TriggerGroup.vue";
import TriggerGroupEditNode from "@/components/view/Bots/NodeEdit/TriggerGroupEditNode.vue";
import QuestionNodeEdit from "@/components/view/Bots/NodeEdit/QuestionNodeEdit.vue";

export default defineComponent({
  name: "NodeEditPanel",
  computed: {
    QuestionNode() {
      return QuestionNode
    },
    TriggerGroupNode() {
      return TriggerGroupNode
    },
    TriggerGroup() {
      return TriggerGroup
    },
    DelayBlockNode() {
      return DelayBlockNode
    },
    CommandGroupNode() {
      return CommandGroupNode
    },
    CommandGroup() {
      return CommandGroup
    },
    MessageNode() {
      return MessageNode
    }
  },
  components: {
    QuestionNodeEdit,
    TriggerGroupEditNode,
    DelayNodeEdit,
    Dropdown,
    CommandGroupNodeEdit,
    MessageNodeEdit,
    Image,
    Button,
    RichTextEditor,
    FileUpload,
    AccordionTab,
    Checkbox,
    InputText,
    SelectButton,
    Accordion
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<MessageNode|QuestionNode|CommandGroupNode|TriggerGroupNode|StartTriggerNode>
    },
  },
  data: function () {
    return {
      show: true,
      modelValueData: this.modelValue,
    }
  },
  emits: [
      'update:modelValue',
      'collapsed',
  ],
  watch: {
    modelValue: function (val) {
      this.modelValueData = val
    }
  },
})
</script>