<template>
  <div>
    <div class="d-flex justify-content-between content-group">
        <p class="m-0"><span class="pi pi-question-circle me-2 mt-3"/>Очереди позволяют публиковать контент последовательно, выдерживая необходимые интервалы между публикациями, без необходимости планировать дату выхода поста самостоятельно</p>

        <Button
            label="Добавить очередь"
            class="rounded"
            severity="contrast"
            style="flex-shrink: 0;"
            @click="() => { this.isShowDialogCreateQueue = true }"
        />
    </div>

    <div class="rounded d-inline-block p-4 content-group me-2 mb-2 align-top" style="border: 4px solid #e2e8f0;" v-for="(queue, queueIndex) in this.items">
      <div class="d-flex justify-content-between">
        <div class="me-5">
          <p class="fw-bold m-0">{{queue.name}}</p>
          <p class="text-muted m-0">{{queue.interval_print}}</p>
          <p class="text-muted m-0">{{queue.filter_date_print}}</p>
        </div>

        <div class="mt-2">
          <Button
              class="rounded me-2"
              icon="pi pi-trash"
              severity="danger"
              v-tooltip.top="'Удалить очередь'"
              :loading="this.removeQueueUuidLoading === queue.uuid"
              @click="() => {
                this.removeQueueUuidLoading = queue.uuid

                removeQueue(queue.uuid)
                  .then(() => {
                     getQueues(this.scenario_uuid, null).then((res) => {
                        this.items = res.items
                     })
                  })
                  .finally(() => {this.removeQueueUuidLoading = null})
              }"
          />

          <Button
              class="rounded me-2"
              :icon="queue.active ? 'pi pi-pause' : 'pi pi-play'"
              :severity="queue.active ? 'warning' : 'success'"
              v-tooltip.top="queue.active ? 'Остановить очередь' : 'Запустить очередь'"
              :loading="this.changeQueueActiveUuidLoading === queue.uuid"
              @click="() => {
                // todo: получить данные конкретно по данной очереди, нет смысла получать все
                this.changeQueueActiveUuidLoading = queue.uuid

                changeQueueActiveStatus(queue.uuid, !queue.active)
                  .then(() => {
                      getQueues(this.scenario_uuid, queue.uuid).then((res) => {
                        this.items[queueIndex] = res.items[0]
                      })
                  })
                  .finally(() => {
                    this.changeQueueActiveUuidLoading = null
                  })
              }"
          />

          <Button
            class="rounded"
            icon="pi pi-cog"
            severity="secondary"
            v-tooltip.top="'Настройки очереди'"
            :loading="this.openQueueCardUuidLoading === queue.uuid"
            @click="() => {
              this.openQueueCardUuidLoading = queue.uuid

              getQueueForCard(queue.uuid)
                .then((res) => {
                  this.card = res
                })
                .finally(() => {
                  this.openQueueCardUuidLoading = null
                })
            }"
          />
        </div>
      </div>

      <div class="mt-4">
        <draggable
            v-model="queue.posts"
            item-key="uuid"
        >
          <template #item="{element, index}">
            <div class="p-2 mb-2 rounded bg-warning" style="cursor: pointer" @click="() => { getPostForCard(element.uuid).then((r) => this.postForm = r) }">
              <div class="d-flex justify-content-between">
                <p class="fw-bold">{{element.name}}</p>
                <button
                    v-tooltip="'Удалить публикацию из очереди'"
                    class="bg-transparent border-0 pi pi-trash text-danger"
                    @click="($event) => {
                      // Чтобы не сработал onclick на род.элементе
                      $event.stopPropagation()

                      deletePostContent(element.uuid).then(() => {
                           getQueues(this.scenario_uuid, queue.uuid).then((res) => {
                             this.items[queueIndex] = res.items[0]
                           })
                      })
                    }"
                />
              </div>

              <div class="mt-4 d-flex justify-content-end">
                <p class="m-0 text-muted">{{element.publication_at_print}}</p>
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <div class="mt-2 d-flex justify-content-center">
        <Button
            class="rounded"
            icon="pi pi-plus"
            severity="secondary"
            label="Добавить пост в очередь"
            @click="() => {
              this.postForm = new CreatePostCmd(this.scenario_uuid)
              this.postForm.publication = new PostPublication(null, null, null, new QueuePublication(queue.uuid))
            }"
        />
      </div>
    </div>

    <Dialog v-model:visible="this.isShowDialogCreateQueue" header="Создание очереди публикаций"  class="w-75" modal :draggable="false">
      <QueueForm
          v-model="this.card"
          @update:model-value="($event) => {
            this.card = $event
          }"
      />

      <template #footer>
        <Button
            label="Сохранить"
            class="rounded"
            severity="contrast"
            :loading="this.saveLoading"
            @click="() => {
              this.saveLoading = true

              let promise = null;
              if (this.card instanceof QueueForCard) {
                 promise = updateQueue(this.card)
              } else {
                 promise = createQueue(this.card)
              }

              promise.then(() => {
                getQueues(this.scenario_uuid, null).then((res) => {
                  this.items = res.items
                  this.card = null
                })
              })

              promise.finally(() => {
                this.saveLoading = false
              })
            }"
        />
      </template>
    </Dialog>

    <!--  Добавление поста в очередь или редактирование существующего поста -->
    <Dialog v-model:visible="this.isShowPostForm" :header="this.postForm instanceof PostGetForCard ? 'Редактирование поста' : 'Создание поста'" class="w-75" modal :draggable="false">
      <PostForm
          v-model:model-value="this.postForm"
          :scenario_uuid="this.scenario_uuid"
          @saved="() => {
            getQueues(this.scenario_uuid, null).then((res) => {
              this.items = res.items
              this.isShowPostForm = false
            })
          }"
      />
    </Dialog>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Button from "primevue/button";
import draggable from "vuedraggable";
import Toolbar from "primevue/toolbar";
import SplitButton from "primevue/splitbutton";
import HelpInfo from "@/components/view/HelpInfo.vue";
import Dialog from "primevue/dialog";
import QueueForm from "@/components/view/Queue/QueueForm.vue";
import {
  changeQueueActiveStatus,
  createQueue,
  CreateQueue, getQueueForCard,
  getQueues, QueueForCard,
  QueueGetForListItem,
  QueueGetForListItemPost, removeQueue, UpdateQueue,
  updateQueue
} from "@/api/queue";
import PostForm from "@/components/view/ContentPlan/PostForm.vue";
import {
  CreatePostCmd,
  deletePostContent,
  getPostForCard,
  PostGetForCard,
  PostPublication,
  QueuePublication
} from "@/api/post";

export default defineComponent({
  name: "QueueList",
  methods: {
    changeQueueActiveStatus,
    deletePostContent, getPostForCard, removeQueue, getQueueForCard, getQueues, createQueue, updateQueue},
  components: {
    PostForm,
    QueueForm,
    Dialog,
    HelpInfo,
    draggable,
    Button,
    Image,
    Toolbar,
    SplitButton,
  },
  props: {
    scenario_uuid: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {
      removeQueueUuidLoading: null as string|null,
      changeQueueActiveUuidLoading: null as string|null,
      openQueueCardUuidLoading: null as string|null,
      saveLoading: false,
      postForm: null as CreatePostCmd|null,
      card: null as CreateQueue|null,
      items: [] as QueueGetForListItem[],
    }
  },
  computed: {
    PostGetForCard() {
      return PostGetForCard
    },
    QueuePublication() {
      return QueuePublication
    },
    PostPublication() {
      return PostPublication
    },
    CreatePostCmd() {
      return CreatePostCmd
    },
    QueueForCard() {
      return QueueForCard
    },
    UpdateQueue() {
      return UpdateQueue
    },
    CreateQueue() {
      return CreateQueue
    },
    isShowDialogCreateQueue: {
      get: function () {
        return this.card !== null
      },
      set: function (val) {
        if (val) {
          this.card = new CreateQueue(this.scenario_uuid)
        } else {
          this.card = null
        }
      }
    },

    isShowPostForm: {
      get: function () {
        return this.postForm !== null
      },
      set: function (val) {
        if (val) {
          this.postForm = new CreatePostCmd(this.scenario_uuid)
        } else {
          this.postForm = null
        }
      }
    },
  },
  created() {
    getQueues(this.scenario_uuid, null).then((res) => {
      this.items = res.items
    })
  }
})
</script>