<script setup>
import {
  BaseEdge,
  BezierEdge,
  EdgeLabelRenderer,
  getBezierPath,
  getSmoothStepPath,
  getStraightPath,
  useVueFlow
} from '@vue-flow/core'
import { computed } from 'vue'
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
  sourceX: {
    type: Number,
    required: true,
  },
  sourceY: {
    type: Number,
    required: true,
  },
  targetX: {
    type: Number,
    required: true,
  },
  targetY: {
    type: Number,
    required: true,
  },
  sourcePosition: {
    type: String,
    required: true,
  },
  targetPosition: {
    type: String,
    required: true,
  },
  markerEnd: {
    type: String,
    required: false,
  },
  style: {
    type: Object,
    required: false,
  },
})

// getStraightPath
// getSmoothStepPath
// getBezierPath

let path = computed(() => getBezierPath(props))
const graphConfigStorage = useGraphConfigStorage()

</script>

<script>
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

export default {
  inheritAttrs: false,
  emits: [
    'remove-edge',
  ],
}
</script>

<template>
  <!-- You can use the `BaseEdge` component to create your own custom edge more easily -->
<!--  todo: скорее всего через BaseEdge можно делать ломанную линию задаваемую пользователем -->
  <BaseEdge :id="uuid" :style="style" :path="path[0]" :marker-end="markerEnd" />

<!--  <svg width="800" height="600" xmlns="http://www.w3.org/2000/svg">-->

<!--    <g>-->
<!--      <title>Layer 1</title>-->
<!--      <path d="m164,175c1,0 0.61775,1.67126 9,6c11.5507,5.965 23.05513,9.83824 35,14c11.05304,3.85103 24.93974,8.19705 41,13c13.94977,4.17178 27.82571,8.81209 40,11c8.9126,1.60173 17.03406,1.31024 24,2c5.07422,0.50244 9.02249,1.63345 12,2c4.09222,0.50377 10,2 14,2c4,0 7.0535,0.4595 9,0c2.17624,-0.51375 3.20889,-2.86588 7,-9c4.23859,-6.85817 6,-14 9,-23c3,-9 5,-15 7,-20c2,-5 1.38907,-9.03746 2,-14c0.50378,-4.09221 1,-9 1,-13c0,-3 -0.67963,-5.02582 -1,-7c-0.50653,-3.12144 -2.513,-3.59399 -4,-6c-1.66251,-2.68999 -1.81265,-6.20681 -4,-8c-2.78833,-2.28588 -8.24429,-4.41548 -16,-8c-9.34573,-4.31939 -18.5386,-9.43895 -29,-12c-9.76163,-2.38973 -18,-2 -25,-3l-4,0l-6,0l-1,0" id="svg_1" stroke="#000" fill="none"/>-->
<!--    </g>-->
<!--  </svg>-->

  <!-- Use the `EdgeLabelRenderer` to escape the SVG world of edges and render your own custom label in a `<div>` ctx -->
  <EdgeLabelRenderer>
    <div
        :style="{
        pointerEvents: 'all',
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${path[1]}px,${path[2]}px)`,
      }"
        class="nodrag nopan"
    >
      <button v-show="!graphConfigStorage.isSharedTemplate" class="edgebutton pi pi-trash text-danger p-2" @click="$emit('remove-edge', uuid)"/>
    </div>
  </EdgeLabelRenderer>
</template>

<style scoped>
.edgebutton {
  border-radius: 999px;
  cursor: pointer
}

.edgebutton:hover {
  transform: scale(1.1);
  transition: all ease .5s;
  box-shadow: 0 0 0 2px #8c4e4e, 0 0 0 4px #f10f17
}
</style>
