<template>
  <div>
    <NodeToolbar :is-visible="this.selectedNodeUuid === this.nodeUuid" :position="this.nodePosition">
      <Button
        icon="pi pi-trash"
        severity="danger"
        class="rounded"
        @click="this.$emit('pressed-remove')"
      />
    </NodeToolbar>

    <div class="p-2 rounded-top text-white" :style="{backgroundColor: this.color}">
      <p class="m-0 fw-bold text-white text-center bg-transparent" style="min-height: 1.5em">{{this.nodeName}}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import Menu from "primevue/menu";
import {NodePosition} from "@/api/graph";
import {MenuItem} from "primevue/menuitem";
import Button from "primevue/button";
import {NodeToolbar} from "@vue-flow/node-toolbar";

export default defineComponent({
  name: 'TitleNode',
  components: {
    NodeToolbar,
    Button,
    Menu,
  },
  props: {
    items: {
      required: false,
      default: [],
      type: Array as PropType<MenuItem[]>,
    },
    nodeUuid: {
      required: true,
      type: String,
    },
    nodeName: {
      required: true,
      type: String,
    },
    nodePosition: {
      required: true,
      type: Object as PropType<NodePosition>,
    },
    updateNameTimeoutMilliSeconds: {
      required: false,
      type: Number,
      default: 200,
    },
    color: {
      required: false,
      default: '#6873eb',
      type: String,
    },
    isReadOnly: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  inject: [
    'selectedNodeUuid',
  ],
  emits: [
    'pressed-remove',
  ],
})
</script>
