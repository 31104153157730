import {AxiosRequestConfig, AxiosResponse} from "axios";
import client from "./client";
import {plainToClass} from "class-transformer";
import {uuid} from "vue-uuid";

export enum WordType
{
    anyEnd = 'any_end',
    anyBegin = 'any_begin',
    fullEquals = 'full_equals'
}

export class Word
{
    identifier: string
    value: string = ''
    type: WordType


    constructor(type: WordType) {
        this.identifier = uuid.v4();
        this.type = type
    }
}

export class CreateDictionaryCmd
{
    name: string = ''
    scenario_uuid: string
    words: Word[] = []

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }
}

export class ImportFile
{
    uuid: string

    constructor(uuid: string) {
        this.uuid = uuid;
    }
}

export function createWordDictionary(cmd: CreateDictionaryCmd): Promise<UpdateDictionaryCmd>
{
    return client
        .post('/api/v1/dictionary/create', cmd)
        .then((response: AxiosResponse) => {
            return plainToClass(UpdateDictionaryCmd, response.data);
        })
}

export class UpdateDictionaryCmd extends CreateDictionaryCmd
{
    uuid: string

    constructor(scenario_uuid: string, uuid: string) {
        super(scenario_uuid);
        this.uuid = uuid;
    }
}

export function updateWordDictionary(cmd: UpdateDictionaryCmd): Promise<UpdateDictionaryCmd>
{
    return client
        .post('/api/v1/dictionary/update', cmd)
        .then((response: AxiosResponse) => {
            return plainToClass(UpdateDictionaryCmd, response.data);
        })
}

export class QueryGetForList
{
    scenario_uuid: string

    constructor(scenario_uuid: string) {
        this.scenario_uuid = scenario_uuid;
    }
}

export class GetDictionaryForListResult
{
    items: GetDictionaryForListItem[] = []
}

export class GetDictionaryForListItem
{
    uuid: string
    name: string
    words_counter: number
}

export function getWordDictionaryForList(scenario_uuid: string): Promise<GetDictionaryForListResult>
{
    return client
        .post('/api/v1/dictionary/get-for-list', new QueryGetForList(scenario_uuid))
        .then((response: AxiosResponse) => {
            return plainToClass(GetDictionaryForListResult, response.data);
        })
}

export function getWordDictionaryForCard(uuid: string): Promise<UpdateDictionaryCmd>
{
    return client
        .post('/api/v1/dictionary/get-for-card', {uuid: uuid})
        .then((response: AxiosResponse) => {
            return plainToClass(UpdateDictionaryCmd, response.data);
        })
}

export function removeWordDictionaries(uuids: string[]): Promise<AxiosRequestConfig>
{
    return client
        .post('/api/v1/dictionary/remove', {uuids: uuids});
}

export class ImportFileRes
{
    words: string[] = []
}

export function uploadImportFile(scenarioUuid: string, file: File): Promise<ImportFileRes>
{
    const formData = new FormData()
    formData.append('files', file)

    // @ts-ignore
    return client
        .postForm('/api/v1/dictionary/' + scenarioUuid + '/upload', formData)
        .then((response: AxiosResponse) => {
            return plainToClass(ImportFileRes, response.data);
        })
}