<template>
  <div>
    <h5 class="fw-bold">Вопрос</h5>
    <p class="m-0">Данный блок используется чтобы запросить у подписчика какую-либо информацию (например возраст, любимый цвет и т.д)</p>


    <p class="fw-bold mt-2 mb-0">Типы вопросов</p>
    <ul class="ms-4">
      <li>Ожидание ответа пользователя</li>
      <li>Выбор ответа через кнопки</li>
      <li>Ответ соответствует регулярному выражению</li>
      <li>Ответ пользователя является числом</li>
      <li>Запись на прием</li>
    </ul>

    <div class="d-flex justify-content-center mt-5">
      <iframe
          src="https://ninja-bot.ru/bot/9b6d83cb-bbf0-40fc-978d-4992d5cb52f8/builder"
          class="custom-border"
          style="height: 600px; width: 80%"
      />
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";
import Divider from "primevue/divider";
import Button from "primevue/button";

export default defineComponent({
  name: "DocsBuilderQuestion",
  components: {
    Button,
    Divider,
    Image,
  },
  data: function () {
    return {
      expandTemplate: false,
    }
  }
})
</script>