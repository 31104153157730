<template>
  <div class="text-center pb-4 rounded-top node_bg_custom" style="width: 400px;">
    <TitleNode
        :node-uuid="this.data.uuid"
        :node-name="this.data.name"
        :node-position="this.data.position"
        :items="[]"
        :is-read-only="true"
    />

    <EditableContent
        :is-read-only="true"
        :disable-save-button="true"
    >
      <SourceHandle/>
    </EditableContent>
  </div>
</template>

<script lang="ts">

import {Handle} from "@vue-flow/core";
import {defineComponent, PropType, provide} from "vue";
import {StartTriggerNode} from "@/api/graph";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";
import flatPicker from "vue-flatpickr-component";
import SelectButton from "primevue/selectbutton";
import TitleNode from "@/components/view/Bots/TitleNode.vue";
import EditableContent from "@/components/view/Bots/EditableContent.vue";
import TimeInput from "@/components/common/TimeInput.vue";
import TargetHandle from "@/components/view/Bots/TargetHandle.vue";
import SourceHandle from "@/components/view/Bots/SourceHandle.vue";
import InputText from "primevue/inputtext";
import client from "@/api/client";
import {AxiosResponse} from "axios";
import TriggerItem from "@/components/view/Bots/Trigger/TriggerItem.vue";
import {TriggerGroupType} from "@/api/trigger";
import {ContextItemForBuilder} from "@/api/context";
import {useGraphConfigStorage} from "@/stores/GraphConfigStorage";

export default defineComponent({
  name: 'StartTrigger',
  computed: {
    TriggerGroupType() {
      return TriggerGroupType
    },
  },
  emits: [
    'remove',
  ],
  components: {
    TriggerItem,
    SourceHandle,
    TargetHandle,
    EditableContent,
    TitleNode,
    flatPicker,
    Handle,
    Dropdown,
    Checkbox,
    InputNumber,
    TimeInput,
    SelectButton,
    InputText,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<StartTriggerNode>,
    },
    contextOptions: {
      type: Array as PropType<ContextItemForBuilder[]>,
      required: true,
    },
  },
  setup() {
    provide('parent_type', 'start-trigger')
    const graphConfigStorage = useGraphConfigStorage()

    return {graphConfigStorage}
  },
  methods: {
    save: function () {
      client
          .post('/api/v1/start-node/update', this.data)
          .then((response: AxiosResponse) => {});
    },
  },
})

</script>

<style scoped>
.content-trigger-group > div {
  border: 1px dashed rgb(172,171,171);
  background-color: rgb(193, 209, 242);
  border-radius: 4px;
}
</style>