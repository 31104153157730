<template>
  <div>
    <div>
      <div v-for="(row, rowIndex) in this.modelValue" class="d-flex mb-2">
        <draggable
            class="d-flex"
            item-key="uuid"
            group="people"
            :list="row.items"
            @change="this.onMovedButtonBetweenRows"
        >
          <template #item="{element, index}">
            <div class="rounded me-2 d-inline-block">
              <Button
                :label="element.name"
                :icon="resolveButtonIcon(element.type)"
                class="rounded align-middle"
                @click="() => {
                  this.button = element
                  this.selectButtonPosition = new SelectButtonPosition(index, rowIndex)
                }"
              />

              <button class="bg-transparent border-0 align-middle" @click="() => {
                  row.items.length === 1 ?
                    this.modelValueData.splice(rowIndex, 1) :
                    this.modelValueData[rowIndex].items.splice(index, 1)
                }">
                <span class="pi pi-trash text-danger p-1"/>
              </button>
            </div>
          </template>
        </draggable>

        <Button
            v-show="this.modelValue[rowIndex].items.length < 4"
            icon="pi pi-plus"
            class="rounded"
            severity="contrast"
            @click="($event) => {
              this.selectButtonPosition = new SelectButtonPosition(index + 1, rowIndex)
              this.toggle($event)
            }"
                aria-haspopup="true"
                aria-controls="overlay_menu"
            />
        <Menu
            ref="menu"
            id="overlay_menu"
            :popup="true"
            :base-z-index="10000000"
            :model="this.getContextMenuItems()"
        />
      </div>

    </div>


    <Button
        :label="this.modelValue.length === 0 ? 'Добавить кнопку' : 'Добавить кнопку на новой строке'"
        icon="pi pi-plus"
        class="rounded"
        severity="contrast"
        @click="($event) => {
          this.selectButtonPosition = new SelectButtonPosition(0, this.modelValueData.length)
          this.toggle($event)
        }"
        aria-haspopup="true"
        aria-controls="overlay_menu"
    />
    <Menu
        ref="menu"
        id="overlay_menu"
        :popup="true"
        :base-z-index="10000000"
        :model="this.getContextMenuItems()"
    />


    <Dialog v-model:visible="this.showButtonDialog" header="Параметры кнопки" modal :draggable="false" class="w-50">
      <div>
        <div class="mb-2">
          <p class="m-0 required">Название кнопки</p>
          <InputText v-model="this.button.name"/>
        </div>

        <div v-if="this.button instanceof ButtonLink">
          <p class="m-0 required">Ссылка</p>
          <InputText v-model="this.button.link"/>
        </div>

        <div v-if="this.button instanceof ButtonScenario">
        </div>
      </div>

      <template #footer>
        <Button
            label="Сохранить"
            class="rounded"
            severity="contrast"
            :disabled="!this.isValidForm"
            @click="() => {
              const result = this.modelValue

              if (!result[this.selectButtonPosition.row]) {
                result[this.selectButtonPosition.row] = new ButtonRow([])
              }

              if (result[this.selectButtonPosition.row].items[this.selectButtonPosition.index]) {
                result[this.selectButtonPosition.row].items[this.selectButtonPosition.index] = this.button
              } else {
                result[this.selectButtonPosition.row].items.push(this.button)
              }

              this.$emit('update:modelValue', result)

              this.button = null
              this.selectButtonPosition = null
            }"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import draggable from "vuedraggable";
import Button from "primevue/button";
import Menu from "primevue/menu";
import InputText from "primevue/inputtext";
import {
  AbstractButton,
  ButtonGroupType,
  ButtonLink,
  ButtonRow, ButtonScenario,
  resolveButtonIcon, resolveButtonName,
  SelectButtonPosition
} from "@/api/buttonGroupPanel";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import {ButtonType} from "@/api/graph";

export default defineComponent({
  name: "ButtonGroupPanel",
  computed: {
    ButtonScenario() {
      return ButtonScenario
    },
    SelectButtonPosition() {
      return SelectButtonPosition
    },
    ButtonRow() {
      return ButtonRow
    },
    ButtonLink() {
      return ButtonLink
    },
    showButtonDialog: {
      set: function (val) {
        if (!val) {
          this.button = null
        }
      },
      get: function () {
        return this.button !== null
      }
    },

    modelValueData: {
      set: function (val) {
        this.$emit('update:modelValue', val)
      },
      get: function () {
        return this.modelValue
      }
    },

    isValidForm: function () {
      if (this.button === null) {
        return true
      }

      switch (true) {
        case this.button instanceof ButtonLink:
          return this.button.name.length && this.button.link && (this.button.link.startsWith('https://') || this.button.link.startsWith('http://'))

        case this.button instanceof ButtonScenario:
          return this.button.name !== ''
      }

      return false
    }
  },
  emits: [
    'update:modelValue'
  ],
  props: {
    modelValue: {
      required: false,
      type: Array as PropType<ButtonRow[]>,
      default: [],
    },
    buttonTypes: {
      required: true,
      type: Array as PropType<ButtonGroupType[]>,
      default: [],
    }
  },
  data: function () {
    return {
      button: null as AbstractButton|null,
      selectButtonPosition: null as SelectButtonPosition|null,
    }
  },
  components: {
    Dropdown, Dialog,
    InputText,
    Menu,
    Button,
    draggable
  },
  methods: {
    resolveButtonIcon,
    toggle: function (event) {
      this.$refs.menu.toggle(event);
    },

    getContextMenuItems: function () {
      const result = []

      this.buttonTypes.forEach((buttonType) => {
        result.push({
          key: buttonType,
          label: resolveButtonName(buttonType),
          icon: resolveButtonIcon(buttonType),
          command: (event: {item: {key: ButtonGroupType}}) => {
            switch (event.item.key) {
              case ButtonGroupType.link:
                this.button = new ButtonLink()
                break

              case ButtonGroupType.scenario:
                this.button = new ButtonScenario()
                break
            }
          }
        })
      })

      return result
    },

    onMovedButtonBetweenRows: function (event: {
        moved: {element: AbstractButton, oldIndex: number, newIndex: number}|null,
        added: {element: AbstractButton, newIndex: number}|null,
        removed: {element: AbstractButton, oldIndex: number}|null,
    }) {
      this.modelValueData = this.modelValueData.filter(row => row.items.length)
    },
  },
})
</script>