<template>
  <div>
    <h3 class="fw-bold">Перенаправление постов</h3>

    <div class="mt-2 mb-4">
      <p>
        Служит для публикации стороннего контента в своем канале. Необходимо добавить бота в канал <span class="fw-bold">откуда</span> будет
        производиться пересылка и в канал <span class="fw-bold">куда</span> будет производиться пересылка.
      </p>

      <p class="bg-primary-subtle p-2 custom-border rounded mt-2">
        Расположение функционала <span class="fw-bold">Боты - Выбираете бота - Перенаправления - Добавить перенаправление</span>
      </p>
    </div>


    <Panel toggleable class="mt-4">
      <template #header>
        <h5 class="fw-bold">Возможности</h5>
      </template>

      <div class="ms-4">
        <ul>
          <li>Возможность перенаправления постов из N каналов в X каналов</li>
        </ul>
      </div>
    </Panel>

  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Image from "primevue/image";

export default defineComponent({
  name: "DocsForwardPost",
  components: {
    Image,
  },
})
</script>